export const BRANCH_USER_ADD = 'BRANCH_USER_ADD';
export const BRANCH_USER_FIND_REQUEST = 'BRANCH_USER_FIND_REQUEST';
export const BRANCH_USER_FIND_FAILURE = 'BRANCH_USER_FIND_FAILURE';
export const BRANCH_USER_FIND_SUCCESS = 'BRANCH_USER_FIND_SUCCESS';
export const BRANCH_USER_HIDE_MODAL_WITH_USERS = 'BRANCH_USER_HIDE_MODAL_WITH_USERS';
export const BRANCH_USER_SHOW_MODAL_WITH_USERS = 'BRANCH_USER_SHOW_MODAL_WITH_USERS';
export const SET_BRANCH_USERS = 'SET_BRANCH_USERS';

export const addBranchUser = branchUser => ({ type: BRANCH_USER_ADD, payload: branchUser });
export const branchUsersHideModalWithUsers = branchId => ({ type: BRANCH_USER_HIDE_MODAL_WITH_USERS, payload: branchId });
export const branchUsersShowModalWithUsers = branchId => ({ type: BRANCH_USER_SHOW_MODAL_WITH_USERS, payload: branchId });
export const findBranchUsersRequest = () => ({ type: BRANCH_USER_FIND_REQUEST });
export const findBranchUsersFailure = errorMessage => ({ type: BRANCH_USER_FIND_FAILURE, payload: { errorMessage: errorMessage } });
export const findBranchUsersSuccess = branchUsers => ({ type: BRANCH_USER_FIND_SUCCESS, payload: branchUsers });
export const setBranchUsers = branchUsers => ({ type: SET_BRANCH_USERS, payload: branchUsers });
