export const BRANCH_FIND_REQUEST = 'BRANCH_FIND_REQUEST';
export const BRANCH_FIND_FAILURE = 'BRANCH_FIND_FAILURE';
export const BRANCH_FIND_SUCCESS = 'BRANCH_FIND_SUCCESS';

export const CITIES_FIND_REQUEST = 'CITIES_FIND_REQUEST';
export const CITIES_FIND_FAILURE = 'CITIES_FIND_FAILURE';
export const CITIES_FIND_SUCCESS = 'CITIES_FIND_SUCCESS';

export const LOCALITIES_FIND_REQUEST = 'LOCALITIES_FIND_REQUEST';
export const LOCALITIES_FIND_FAILURE = 'LOCALITIES_FIND_FAILURE';
export const LOCALITIES_FIND_SUCCESS = 'LOCALITIES_FIND_SUCCESS';

export const ADD_USER_TO_BRANCH_REQUEST = 'ADD_USER_TO_BRANCH_REQUEST';
export const ADD_USER_TO_BRANCH_FAILURE = 'ADD_USER_TO_BRANCH_FAILURE';
export const ADD_USER_TO_BRANCH_SUCCESS = 'ADD_USER_TO_BRANCH_SUCCESS';

export const REMOVE_USER_FROM_BRANCH_REQUEST = 'REMOVE_USER_FROM_BRANCH_REQUEST';
export const REMOVE_USER_FROM_BRANCH_FAILURE = 'REMOVE_USER_FROM_BRANCH_FAILURE';
export const REMOVE_USER_FROM_BRANCH_SUCCESS = 'REMOVE_USER_FROM_BRANCH_SUCCESS';

export const BRANCH_UPSERT_REQUEST = 'BRANCH_UPSERT_REQUEST';
export const BRANCH_UPSERT_FAILURE = 'BRANCH_UPSERT_FAILURE';
export const BRANCH_UPSERT_SUCCESS = 'BRANCH_UPSERT_SUCCESS';

export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_MODAL_FOR_EDIT = 'SHOW_MODAL_FOR_EDIT';
export const SET_CURRENT_BRANCH = 'SET_CURRENT_BRANCH';

export const BLOCK_CURRENT_BRANCH_SELECTION = 'BLOCK_CURRENT_BRANCH_SELECTION';
export const SET_BRANCHES = 'SET_BRANCHES';
export const SET_CITIES = 'SET_CITIES';
export const SET_LOCALITIES = 'SET_LOCALITIES';
export const SET_UPSERT_BRANCH_RESULT = 'SET_UPSERT_BRANCH_RESULT';
export const SET_CURRENT_BRANCH_USER_IDS = 'SET_CURRENT_BRANCH_USER_IDS';
export const SET_BRANCHES_LOADED_FLAG = 'SET_BRANCHES_LOADED_FLAG';

export const BRANCH_DISABLE_REQUEST = 'BRANCH_DISABLE_REQUEST';
export const BRANCH_DISABLE_FAILURE = 'BRANCH_DISABLE_FAILURE';
export const BRANCH_DISABLE_SUCCESS = 'BRANCH_DISABLE_SUCCESS';

export const BRANCH_ENABLE_REQUEST = 'BRANCH_ENABLE_REQUEST';
export const BRANCH_ENABLE_FAILURE = 'BRANCH_ENABLE_FAILURE';
export const BRANCH_ENABLE_SUCCESS = 'BRANCH_ENABLE_SUCCESS';

export const AFTER_CURRENT_BRANCH_WAS_SET = 'AFTER_CURRENT_BRANCH_WAS_SET';

export const findBranches = () => ({ type: BRANCH_FIND_REQUEST });
export const findBranchesFailure = errorMessage => ({ type: BRANCH_FIND_FAILURE, payload: { errorMessage: errorMessage } });
export const findBranchesSuccess = branches => ({ type: BRANCH_FIND_SUCCESS, payload: branches });

export const findCities = () => ({ type: CITIES_FIND_REQUEST });
export const findCitiesFailure = errorMessage => ({ type: CITIES_FIND_FAILURE, payload: { errorMessage: errorMessage } });
export const findCitiesSuccess = cities => ({ type: CITIES_FIND_SUCCESS, payload: cities });

export const findLocalities = cityId => ({ type: LOCALITIES_FIND_REQUEST, payload: cityId });
export const findLocalitiesFailure = errorMessage => ({ type: LOCALITIES_FIND_FAILURE, payload: { errorMessage: errorMessage } });
export const findLocalitiesSuccess = localities => ({ type: LOCALITIES_FIND_SUCCESS, payload: localities });

export const addUserToBranch = (userId, branchId) => ({ type: ADD_USER_TO_BRANCH_REQUEST, payload: { userId, branchId } });
export const addUserToBranchFailure = errorMessage => ({ type: ADD_USER_TO_BRANCH_FAILURE, payload: { errorMessage: errorMessage } });
export const addUserToBranchSuccess = (userId, branchId) => ({ type: ADD_USER_TO_BRANCH_SUCCESS, payload: { userId, branchId } });

export const removeUserFromBranch = (userId, branchId) => ({ type: REMOVE_USER_FROM_BRANCH_REQUEST, payload: { userId, branchId } });
export const removeUserFromBranchFailure = errorMessage => ({ type: REMOVE_USER_FROM_BRANCH_FAILURE, payload: { errorMessage: errorMessage } });
export const removeUserFromBranchSuccess = (userId, branchId) => ({ type: REMOVE_USER_FROM_BRANCH_SUCCESS, payload: { userId, branchId } });

export const upsertBranch = (branch) => ({ type: BRANCH_UPSERT_REQUEST, payload: branch });
export const upsertBranchFailure = errorMessage => ({ type: BRANCH_UPSERT_FAILURE, payload: { errorMessage: errorMessage } });
export const upsertBranchSuccess = (branch) => ({ type: BRANCH_UPSERT_SUCCESS, payload: branch });

export const hideModal = () => ({ type: HIDE_MODAL, payload: false });
export const showModal = () => ({ type: SHOW_MODAL, payload: true });
export const showModalForEdit = branchId => ({ type: SHOW_MODAL_FOR_EDIT, payload: branchId });
export const setCurrentBranch = branch => ({ type: SET_CURRENT_BRANCH, payload: branch });
export const setCurrentBranchUserIds = userIds => ({ type: SET_CURRENT_BRANCH_USER_IDS, payload: userIds });

export const afterCurrentBranchWasSet = branch => ({ type: AFTER_CURRENT_BRANCH_WAS_SET, payload: branch })

export const blockCurrentBranchSelection = shouldBlock => ({ type: BLOCK_CURRENT_BRANCH_SELECTION, payload: shouldBlock });
export const setBranches = branches => ({ type: SET_BRANCHES, payload: branches });
export const setCities = cities => ({ type: SET_CITIES, payload: cities });
export const setLocalities = localities => ({ type: SET_LOCALITIES, payload: localities });
export const setUpsertBranchResult = (isUpsertBranchError, upsertBranchErrorMessage) => ({ type: SET_UPSERT_BRANCH_RESULT, payload: { isUpsertBranchError, upsertBranchErrorMessage } });
export const setBranchesLoadedFlag = loaded => ({ type: SET_BRANCHES_LOADED_FLAG, payload: loaded });

export const disableBranch = (branchId) => ({ type: BRANCH_DISABLE_REQUEST, payload: branchId });
export const disableBranchFailure = errorMessage => ({ type: BRANCH_DISABLE_FAILURE, payload: { errorMessage: errorMessage } });
export const disableBranchSuccess = (status, text) => ({ type: BRANCH_DISABLE_SUCCESS, payload: { status, text } });

export const enableBranch = (data) => ({ type: BRANCH_ENABLE_REQUEST, payload: data });
export const enableBranchFailure = errorMessage => ({ type: BRANCH_ENABLE_FAILURE, payload: { errorMessage: errorMessage } });
export const enableBranchSuccess = () => ({ type: BRANCH_ENABLE_SUCCESS });
