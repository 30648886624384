import {
  generateSmsTokenRequest,
  generateXsrfToken,
  saveUserInformation,
  setLoginError,
  setOtpCaptcha,
  setPasswordCaptcha,
  setPhoneNumberCaptcha,
  setSmsToken,
  setUsernameCaptcha
} from 'actions/user';

import {
  USER_CHANGE_PASSWORD_FAILURE,
  USER_EXPIRED_PASSWORD,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_GENERATE_SMS_TOKEN_SUCCESS,
  USER_GET_USER_DATA_SUCCESS,
  USER_SUBMIT_PASSWORD_FAILURE,
  USER_SUBMIT_PASSWORD_SUCCESS,
  USER_SUBMIT_PHONE_NUMBER_FAILURE,
  USER_SUBMIT_PHONE_NUMBER_SUCCESS,
  USER_SUBMIT_SMS_TOKEN_FAILURE,
  USER_SUBMIT_SMS_TOKEN_SUCCESS,
  USER_SUBMIT_USERNAME_FAILURE,
  USER_SUBMIT_USERNAME_SUCCESS,
  USER_VERIFY_PASSWORD_FAILURE,
  USER_VERIFY_PASSWORD_SUCCESS
} from 'actions/user';

import errorCodes from 'utils/errorCodes';
import { push } from 'connected-react-router';
import routes from 'utils/routes';

function user({ getState, dispatch }) {
  return next => action => {
    switch (action.type) {
      case USER_GET_USER_DATA_SUCCESS: {
        next(saveUserInformation(action.payload));
        break;
      }
      case USER_GENERATE_SMS_TOKEN_SUCCESS: {
        const { otpCode, maskedPhoneNumber, remainingSeconds } = action.payload;

        next(setSmsToken(otpCode));
        next(saveUserInformation({ maskedPhoneNumber }));
        next(saveUserInformation({ remainingSeconds }));
        next(saveUserInformation({ username: getState().user.username }));

        break;
      }
      case USER_VERIFY_PASSWORD_SUCCESS: {
        next(saveUserInformation({ username: getState().user.username, isChangePassword: true }));
        next(setLoginError(''));
        next(setPasswordCaptcha({ captcha: null, error: null }));
        dispatch(generateSmsTokenRequest());
        break;
      }

      case USER_VERIFY_PASSWORD_FAILURE: {
        if (action.payload == errorCodes.changePasswordInvalidCaptcha || action.payload == errorCodes.changePasswordBruteForceAttempt) {
          next(setPasswordCaptcha({ captcha: Math.random(), error: action.payload }));
        } else
          next(setLoginError(action.payload));
        break;
      }

      case USER_CHANGE_PASSWORD_FAILURE: {
        next(setLoginError(action.payload));
        break;
      }
      case USER_SUBMIT_PASSWORD_SUCCESS: {
        next(setLoginError(''));
        next(setPasswordCaptcha({ captcha: null, error: null }));
        dispatch(generateSmsTokenRequest());
        break;
      }
      case USER_SUBMIT_PASSWORD_FAILURE: {
        if (action.payload == errorCodes.enterPasswordInvalidCaptcha || action.payload == errorCodes.enterPasswordBruteForceAttempt) {
          next(setPasswordCaptcha({ captcha: Math.random(), error: action.payload }));
        } else {
          next(setLoginError(action.payload));
        }
        break;
      }
      case USER_SUBMIT_USERNAME_FAILURE: {
        if (action.payload == errorCodes.enterUsernameInvalidCaptcha || action.payload == errorCodes.enterUsernameBruteForceAttempt) {
          next(setUsernameCaptcha({ captcha: Math.random(), error: action.payload }));
        } else {
          next(setLoginError(action.payload));
        }
        break;
      }
      case USER_SUBMIT_SMS_TOKEN_SUCCESS: {
        let userInfo = { smsTokenSubmitted: true };
        if (getState().user.isForgotPassword) {
          userInfo.isForgotPassword = true;
        }
        if (getState().user.isChangePassword) {
          userInfo.isChangePassword = true;
        }

        dispatch(generateXsrfToken());

        userInfo.username = getState().user.username;
        next(saveUserInformation(userInfo));
        next(setOtpCaptcha({ captcha: null, error: null }));
        window.localStorage.removeItem('userLoggedOut');
        break;
      }
      case USER_SUBMIT_SMS_TOKEN_FAILURE: {
        if (action.payload == errorCodes.enterOtpInvalidCaptcha || action.payload == errorCodes.enterOtpBruteForceAttempt) {
          next(setOtpCaptcha({ captcha: Math.random(), error: action.payload }));
        } else {
          next(setLoginError(action.payload));
        }
        break;
      }
      case USER_SUBMIT_USERNAME_SUCCESS: {
        const { userStatus } = action.payload;
        next(saveUserInformation({ userStatus }));
        next(setUsernameCaptcha({ captcha: null, error: null }));
        break;
      }
      case USER_FORGOT_PASSWORD_SUCCESS: {
        const { maskedPhoneNumber } = action.payload;
        next(saveUserInformation({ maskedPhoneNumber }));
        break;
      }
      case USER_SUBMIT_PHONE_NUMBER_SUCCESS: {
        next(setPhoneNumberCaptcha({ captcha: null, error: null }));
        next(push(routes.accountForgotPasswordPassword));
        next(saveUserInformation({ isForgotPassword: true }));
        break;
      }
      case USER_SUBMIT_PHONE_NUMBER_FAILURE: {
        if (action.payload == errorCodes.enterPhoneNumberInvalidCaptcha || action.payload == errorCodes.enterPhoneNumberBruteForceAttempt) {
          next(setPhoneNumberCaptcha({ captcha: Math.random(), error: action.payload }));
        } else
          next(setLoginError(action.payload));
        break;
      }
      case USER_EXPIRED_PASSWORD: {
        const { userStatus, errorMessage } = action.payload;
        next(saveUserInformation({ userStatus }));
        next(setUsernameCaptcha({ captcha: null, error: null }));
        next(setLoginError(errorMessage));
        next(push(routes.accountForgotPassword));
        break;
      }
    }

    const returnValue = next(action)

    return returnValue
  }
}

export default user;
