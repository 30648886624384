import {
  ORDER_SET_ORDERS,
  SET_ORDERS_LOADED_FLAG
} from 'actions/order';

export default function order(state, action) {
  state = state || {
    orders: [],
    ordersLoaded: true
  };

  switch (action.type) {
    case ORDER_SET_ORDERS:
      return {
        ...state,
        orders: [...action.payload]
      };
    case SET_ORDERS_LOADED_FLAG:
      return {
        ...state,
        ordersLoaded: action.payload
      }
    default:
      return state;
  }
}
