import { UploadFileMealTicketHeaderContainer, UploadFileMealTicketScreenContainer, UploadFileMealTicketStepsContainer, UploadFileScreen } from 'components';
import BranchImportErrorsContainer from './BranchImportErrors/index';
import { fileUploadType } from 'utils/enums';
import React from 'react';
import routes from 'utils/routes';

class BranchImport extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnUploadFile = this.handleOnUploadFile.bind(this);
    this.handleOnUploadFileFailed = this.handleOnUploadFileFailed.bind(this);

    this.configs = {
      expectedUploadUrl: routes.dashboardBranchImport,
      uploadScreenBody: {
        acceptFiles: '.csv, .xls, .xlsx',
        validFileTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
        formDataParameters: [{ key: 'uploadTypeId', value: fileUploadType.branchesImport }],
        title: 'Import puncte de lucru',
        description: 'Incarca fisierul csv sau xls(x) cu lista de puncte de lucru, conform modelului.',
        onDownloadTemplate: this.handleOnDownloadTemplate,
        onUploadFile: this.handleOnUploadFile,
        onUploadFileFailed: this.handleOnUploadFileFailed,
        fileUploadErrors: <BranchImportErrorsContainer />
      },
      successfullUpload: {
        title: 'Punctele de lucru au fost adaugate cu succes.',
        description: 'Click mai jos pentru a vedea punctele de lucru.',
        buttonText: 'Vezi puncte de lucru',
        onClick: this.handleOnClick
      }
    }
  }

  componentDidMount() {
    this.props.setUploadConfig(this.configs);
  }

  componentWillUnmount() {
    this.props.setUploadConfig(null);
  }

  handleOnClick() {
    this.props.findBranches();
    this.props.replace(routes.dashboardBranch);
  }

  handleOnDownloadTemplate() {
    window.open('/resources/BT_TICHET_MODEL_INCARCARE_PUNCTE_DE_LUCRU.xlsx', '_blank', 'noreferrer');
  }

  handleOnUploadFile() {
    this.props.blockCurrentBranchSelection(true);
  }

  handleOnUploadFileFailed() {
    this.props.blockCurrentBranchSelection(false);
  }

  render() {
    return (
      <UploadFileScreen
        uploadFileHeader={<UploadFileMealTicketHeaderContainer />}
        uploadFileContent={[<UploadFileMealTicketScreenContainer />,
        <UploadFileMealTicketStepsContainer />]}
      />
    );
  }

}

export default BranchImport;
