import {
  findCardsRequest,
  setCardFilters,
  setCards,
  setCardsCurrentPage,
  setCardsLoadedFlag,
  setSelectedCards
} from 'actions/card';

import { bindActionCreators } from 'redux';
import CardFilters from './CardFilters';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    ...state.card,
    ...state.order
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setCards,
  setCardsCurrentPage,
  setCardsLoadedFlag,
  setSelectedCards,
  findCardsRequest,
  setCardFilters
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardFilters);
