/**
 * Get cookie
 * @param {string} name
 */
const getCookie = (name) => {
  var cookieArr = document.cookie.split(';');

  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');

    if (name == cookiePair[0].trim()) {
      return cookiePair[1];
    }
  }

  return null;
};

/**
 * Set cookie
 * @param {string} name
 * @param {string} value
 * @param {int} expireDays
 */
const setCookie = (name, value, expireDays) => {
  let date = new Date();
  date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
  const expires = 'expires=' + date.toUTCString();

  document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

/**
 * Delete cookie
 * @param {string} name
 */
const deleteCookie = (name) => {
  setCookie(name, null, -1);
}

export default {
  get: getCookie,
  set: setCookie,
  delete: deleteCookie
};
