import CardsDashboard from './CardsDashboard';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    ...state.card,
    currentBranch: state.branch.currentBranch,
    userRole: state.user.roleId
  }
};

export default connect(
  mapStateToProps
)(CardsDashboard);
