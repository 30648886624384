import {
  CARD_ADD_ITEM_TO_SELECTION,
  CARD_CLEAR_SELECTION,
  CARD_CONFIRMATION_HIDE_MODAL,
  CARD_CONFIRMATION_SHOW_MODAL,
  CARD_REISSUE_HIDE_CONFIRMATION_MODAL,
  CARD_REISSUE_SHOW_CONFIRMATION_MODAL,
  CARD_REMOVE_ITEM_FROM_SELECTION,
  CARD_SELECT_ALL,
  CARD_SET_CARD_FILTERS,
  CARD_SET_CARDS,
  CARD_SET_CURRENT_PAGE,
  CARD_SET_PAGE_COUNT,
  CARD_SET_PAGE_SIZE,
  CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS,
  CARD_SET_TOTAL_COUNT,
  CHANGE_TICKET_VALUE_HIDE_MODAL,
  CHANGE_TICKET_VALUE_SHOW_MODAL,
  MIGRATE_CARDS_HIDE_MODAL,
  MIGRATE_CARDS_SHOW_MODAL,
  REISSUE_CARD_HIDE_MODAL,
  REISSUE_CARD_SET_MODAL_ERROR,
  REISSUE_CARD_SHOW_MODAL,
  REISSUE_PIN_HIDE_MODAL,
  REISSUE_PIN_SET_MODAL_ERROR,
  REISSUE_PIN_SHOW_MODAL,
  SET_CARDS_LOADED_FLAG,
  SET_SELECTED_CARDS
} from 'actions/card';

import { cardStatus, selectionType } from 'utils/enums';

export default function card(state, action) {
  state = state || {
    cards: null,
    selectState: selectionType.none,
    selectedCards: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    firstOrLastName: null,
    cardReissueModalShow: { displayed: false, cardId: null }
  };

  switch (action.type) {
    case CARD_SET_CARDS: {
      return {
        ...state,
        cards: action.payload.map(card => Object.assign({}, card, { status: isNaN(card.statusId) ? card.statusId : cardStatus.properties[card.statusId] }))
      };
    }
    case CARD_ADD_ITEM_TO_SELECTION: {
      let updatedSelectState;

      if (state.selectState !== selectionType.all && state.selectedCards.length + 1 === state.totalCount) {
        updatedSelectState = selectionType.all;
      }
      else if (state.selectState === selectionType.none) {
        updatedSelectState = selectionType.multiple;
      }
      else {
        updatedSelectState = state.selectState;
      }

      return {
        ...state,
        selectedCards: state.selectState !== selectionType.all ? [...state.selectedCards, action.payload] : [],
        selectState: updatedSelectState
      };
    }
    case CARD_REMOVE_ITEM_FROM_SELECTION: {
      const isSelectStateDifferentThanMultiple = state.selectState !== selectionType.multiple;
      const selectedCardsCount = state.selectedCards.length;
      const updatedSelectState = isSelectStateDifferentThanMultiple && selectedCardsCount > 1 ? selectionType.multiple
        : isSelectStateDifferentThanMultiple && selectedCardsCount <= 1 ? selectionType.none
          : !isSelectStateDifferentThanMultiple && selectedCardsCount > 1 ? state.selectState
            : selectionType.none;
      return {
        ...state,
        selectedCards: state.selectedCards.filter(card => card.cardId !== action.payload.cardId),
        selectState: updatedSelectState
      };
    }
    case CARD_SELECT_ALL: {
      return {
        ...state,
        selectedCards: [],
        selectState: selectionType.all
      };
    }
    case CARD_CLEAR_SELECTION: {
      return {
        ...state,
        selectedCards: [],
        selectState: selectionType.none
      };
    }
    case SET_SELECTED_CARDS: {
      return {
        ...state,
        selectedCards: action.payload.cards || [],
        selectState: action.payload.selectionType !== null && action.payload.selectionType !== undefined ? action.payload.selectionType : selectionType.none
      }
    }
    case CARD_SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      };
    }
    case CARD_SET_PAGE_COUNT: {
      return {
        ...state,
        pageCount: action.payload
      };
    }
    case CARD_SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload
      };
    }
    case CARD_SET_TOTAL_COUNT: {
      return {
        ...state,
        totalCount: action.payload
      };
    }
    case REISSUE_CARD_SHOW_MODAL: {
      return {
        ...state,
        cardReissueModalShow: { displayed: true, cardId: action.payload }
      };
    }
    case REISSUE_CARD_HIDE_MODAL: {
      return {
        ...state,
        cardReissueModalShow: { displayed: false, cardId: null },
        cardReissueModalError: null
      };
    }
    case REISSUE_PIN_SHOW_MODAL: {
      return {
        ...state,
        pinReissueModalShow: { displayed: true, cardId: action.payload }
      };
    }
    case REISSUE_PIN_HIDE_MODAL: {
      return {
        ...state,
        pinReissueModalShow: { displayed: false, cardId: null },
        pinReissueModalError: null
      };
    }
    case MIGRATE_CARDS_SHOW_MODAL: {
      return {
        ...state,
        cardMigrationModalShow: true
      };
    }
    case MIGRATE_CARDS_HIDE_MODAL: {
      return {
        ...state,
        cardMigrationModalShow: false
      };
    }
    case CHANGE_TICKET_VALUE_SHOW_MODAL: {
      return {
        ...state,
        changeTicketValueModalShow: true
      };
    }
    case CHANGE_TICKET_VALUE_HIDE_MODAL: {
      return {
        ...state,
        changeTicketValueModalShow: false
      };
    }
    case SET_CARDS_LOADED_FLAG:
      return {
        ...state,
        cardsLoaded: action.payload
      }
    case REISSUE_CARD_SET_MODAL_ERROR:
      return {
        ...state,
        cardReissueModalError: action.payload
      }
    case REISSUE_PIN_SET_MODAL_ERROR:
      return {
        ...state,
        pinReissueModalError: action.payload
      }
    case CARD_CONFIRMATION_SHOW_MODAL:
      return {
        ...state,
        cardConfirmationIsVisible: true
      }
    case CARD_CONFIRMATION_HIDE_MODAL:
      return {
        ...state,
        cardConfirmationIsVisible: false
      }
    case CARD_REISSUE_SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        cardReissueConfirmationIsVisible: true
      }
    case CARD_REISSUE_HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        cardReissueConfirmationIsVisible: false
      }
    case CARD_SET_CARD_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      }
    case CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS:
      return {
        ...state,
        sendReissueCardInProgress: action.payload
      }
    default: {
      return state;
    }
  }
}
