import { afterCurrentBranchWasSet, setCurrentBranch } from 'actions/branch';
import { bindActionCreators } from 'redux';
import BranchHeader from './BranchHeader';
import { connect } from 'react-redux';
import { findBranchUsersRequest } from 'actions/branchUser';

const mapStateToProps = state => {
  return {
    ...state.branch,
    users: state.branchUser.branchUsers,
    userRoleId: state.user.roleId,
    fileUploadInProgress: state.upload.loadingScreenShow
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  findBranchUsersRequest,
  setCurrentBranch,
  afterCurrentBranchWasSet
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchHeader);
