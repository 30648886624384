import { setUploadError, uploadCsvFileRequest } from 'actions/upload';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UploadFileMealTicketScreen from './UploadFileMealTicketScreen';

const mapStateToProps = state => { return { ...state.upload, ...state.upload.config.uploadScreenBody } };

const mapDispatchToProps = dispatch => bindActionCreators({
    uploadCsvFileRequest,
    setUploadError,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadFileMealTicketScreen);
