import { Col, Row } from 'react-bootstrap';
import errorCodes from 'utils/errorCodes';
import { FileUpload } from 'components';
import React from 'react';

class UploadFileMealTicketScreen extends React.Component {
    constructor(props) {
        super(props);
        this.drop = this.drop.bind(this);
        this.upload = this.upload.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);

        this.MAX_FILE_SIZE = 20000000;
        this.requestId = Math.floor(Math.random() * 100);
    }

    componentDidMount() {
        this.props.setUploadError('');

        setTimeout(() => {
            if (window.scrollY == 0) {
                window.scroll({ top: document.body.scrollHeight, behaviour: 'smooth' });
            }
        }, 300);
    }

    downloadTemplate() {
        this.props.onDownloadTemplate();
    }

    drop(event) {
        event.preventDefault();
        event.stopPropagation();

        let dataTransfer = event.dataTransfer,
            files = dataTransfer.files;

        let isFileValidForUpload = false;

        if (files[0]) {
            this.props.validFileTypes.some(fileType => {
                if (files[0].type === fileType) {
                    isFileValidForUpload = true;
                    return true; //in order to break from iteration
                }
            });

            if (isFileValidForUpload) {
                this.uploadFile(files[0]);
            }
        }
    }

    uploadFile(file) {
        this.props.onUploadFile && this.props.onUploadFile();

        if (file.size < this.MAX_FILE_SIZE) {
            var formData = new FormData();
            formData.append('file', file);

            this.props.formDataParameters.map(parameter => {
                formData.append(parameter.key, parameter.value); //key should be string and value any (depends on backend)
            })

            this.props.uploadCsvFileRequest(formData, this.requestId);
        }
        else {
            this.props.setUploadError(errorCodes.fileMaxSizeExceeded);
            this.props.onUploadFileFailed && this.props.onUploadFileFailed();
        }
    }

    upload(e) {
        var file = e.target.files[0];
        if (file) {
            this.uploadFile(file);
            e.target.value = null;
        }
    }

    render() {
        return (
            <div className="upload-file-screen">
                <h2 className="bold">{this.props.title}</h2>
                <Row noGutters className="justify-content-between align-items-center mb-3">
                    <Col sm="auto">
                        <p className="cards-upload-subtitle mb-0">{this.props.description}</p>
                    </Col>
                    <Col sm="auto">
                        <button className="main-button white icon" title="Descarca model fisier" onClick={this.downloadTemplate}>
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path id="a" d="M5 20h14a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm7.34-3.15a.461.461 0 0 1-.678.003L5.14 9.894a.528.528 0 0 1 .003-.717.466.466 0 0 1 .337-.147h3.226V2.4c0-.22.179-.4.4-.4h5.79c.221 0 .4.18.4.4v6.63h3.226c.264 0 .479.227.479.507 0 .134-.05.262-.139.357l-6.52 6.956z">
                                </path>
                            </svg>
                            DESCARCA MODEL FISIER
                        </button>
                    </Col>
                </Row>
                <Row noGutters className="file-upload-area-container">
                    <FileUpload onDrop={this.drop}>
                        <div className="file-upload-control text-center">
                            <label htmlFor="csv-upload" className="main-button">INCARCA FISIER</label>
                            <input id="csv-upload" type="file" onChange={this.upload} accept={this.props.acceptFiles} />
                        </div>
                        {this.props.fileUploadErrors}
                    </FileUpload>
                </Row>
            </div>
        );
    }
}

export default UploadFileMealTicketScreen;
