import '../CardsGrid/CardsGrid.scss';
import { Col, Dropdown, DropdownButton, Row, Table } from 'react-bootstrap';
import React, { Component } from 'react';
import { userRole } from 'utils/enums';

class ManagersTable extends Component {
  constructor(props) {
    super(props);

    this.dropdownItemOnClickHandler = this.dropdownItemOnClickHandler.bind(this);
    this.itemActionsDisabled = this.itemActionsDisabled.bind(this);
  }

  dropdownItemOnClickHandler(event, action, item) {
    let value = (event.currentTarget && event.currentTarget.text);
    if (value === action.title)
      action.clickHandler(item);
  }

  itemActionsDisabled(item) {
    return (this.props.userRoleId === userRole.POWER && item.roleName === 'Standard') ||
      (this.props.userRoleId === userRole.ADMIN && item.roleName !== 'Admin') ? false : true;
  }

  render() {
    const itemActionsButton = <svg width="24" height="24" viewBox="0 0 24 24">
      <path id="a" d="M12 9c.81 0 1.512.298 2.107.893.595.595.893 1.297.893 2.107 0 .81-.298 1.512-.893 2.107-.595.595-1.297.893-2.107.893-.81 0-1.512-.298-2.107-.893C9.298 13.512 9 12.81 9 12c0-.81.298-1.512.893-2.107C10.488 9.298 11.19 9 12 9zm8 0c.81 0 1.512.298 2.107.893.595.595.893 1.297.893 2.107 0 .81-.298 1.512-.893 2.107-.595.595-1.297.893-2.107.893-.81 0-1.512-.298-2.107-.893C17.298 13.512 17 12.81 17 12c0-.81.298-1.512.893-2.107C18.488 9.298 19.19 9 20 9zM4 9c.81 0 1.512.298 2.107.893C6.702 10.488 7 11.19 7 12c0 .81-.298 1.512-.893 2.107C5.512 14.702 4.81 15 4 15c-.81 0-1.512-.298-2.107-.893C1.298 13.512 1 12.81 1 12c0-.81.298-1.512.893-2.107C2.488 9.298 3.19 9 4 9z" />
    </svg>;

    return (
      <div className='grid'>
        <Table hover className='dashboard-table'>
          <thead>
            <tr>
              {
                this.props.tableHeaderColumns.map(headerColumn =>
                  <td key={`_${headerColumn}`}>{headerColumn}</td>)
              }
            </tr>
          </thead>
          <tbody>
            {
              this.props.content && this.props.content.map((item, index) => {
                return <tr key={index} className='grid-row'>
                  {this.props.fields.map(field => { return <td key={`${index}_${field}`}>{item[field]}</td> })}
                  {this.props.showItemActions &&
                    <td className='dropdown-button-column' key={item[this.props.idColumn] + '_dropdown_btn'}>
                      <DropdownButton disabled={this.itemActionsDisabled(item)} alignRight size='sm' variant='secondary' drop='left' title={itemActionsButton} className='item-actions-dropdown' >
                        {
                          this.props.itemActions.map(action => {
                            return <Dropdown.Item
                              key={`${this.props.idColumn}_${action.title}`}
                              onClick={(event) => this.dropdownItemOnClickHandler(event, action, item)}>{action.title}
                            </Dropdown.Item>;
                          })
                        }
                      </DropdownButton>
                      {
                        this.itemActionsDisabled(item) &&
                        <div className="item-actions-wrapper small-font">
                          <button disabled className='plain-button disabled'>
                            <span className="tooltip-text">Nu aveti drepturi pentru a intreprinde actiuni asupra acestui manager</span>
                          </button>
                        </div>
                      }
                    </td>
                  }
                </tr>
              })}
          </tbody>
        </Table>
        {this.props.content && this.props.content.length === 0 &&
          <div className='grid-no-result'>
            <Row className='justify-content-md-center' noGutters>
              <Col sm="auto">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                  <g fill="none">
                    <path d="M0 0h24v24H0z" />
                    <path fill="#414751" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </g>
                </svg>
              </Col>
            </Row>
            <Row noGutters><span>Nu am gasit nici un rezultat.</span></Row>
          </div>
        }
      </div>
    )
  }
}


export default ManagersTable;
