import {
    findSapInvoices,
    hideRectifySapInvoiceModal,
    rectifySapInvoice,
    setPageSize,
    setSapCurrentPage,
    setSapInvoiceToRectify,
    showRectifySapInvoiceModal
} from 'actions/sapInvoice';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SapInvoicesDashboard from './SapInvoicesDashboard';
import { showToast } from 'actions/toast';


const mapStateToProps = state => {
    return {
        ...state.sapInvoice,
        userRoleId: state.user.roleId,
        showRectifyModal: state.sapInvoice.showRectifyModal,
        sapInvoiceToRectify: state.sapInvoice.sapInvoiceToRectify,        
        employerId: state.user.employerId
    }
};
const mapDispatchToProps = dispatch => bindActionCreators({
  findSapInvoices,
  hideRectifySapInvoiceModal,
  rectifySapInvoice,
  setSapInvoiceToRectify,
  showRectifySapInvoiceModal,
  showToast,
  setSapCurrentPage,
  setPageSize,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SapInvoicesDashboard);
