import './BranchUsersBadge.scss';
import { Col, Row } from 'react-bootstrap';
import AddUserToBranchModal from '../BranchDashboard/AddUserToBranchModal';
import React from 'react';
import { userRole } from 'utils/enums';

class BranchUsersBadge extends React.Component {
  constructor(props) {
    super(props);
    this.onAddBtnClick = this.onAddBtnClick.bind(this);
  }

  onAddBtnClick() {
    if (this.props.userRoleId !== userRole.STANDARD) {
      this.props.branchUsersShowModalWithUsers(this.props.branchId);
    }
  }

  render() {
    let branchFirstUserCode = '';
    if (this.props.branchUserIds) {
      const branchFirstUser = this.props.users.filter(u => u.id === this.props.branchUserIds[0])[0];
      branchFirstUserCode = branchFirstUser ? branchFirstUser.code : '';
    }

    return (
      <div>
        <Row noGutters className="managers">
          <Col className={'managers-title ' + (this.props.upperCaseTitle ? 'to-upper' : '')}>Manageri</Col>
          <Col sm="auto" className={`badge-container ${this.props.userRoleId !== userRole.STANDARD ? 'clickable' : ''}`} onClick={() => this.onAddBtnClick(true)}>
            {
              this.props.branchUserIds &&
                this.props.branchUserIds.length > 0
                ? <div className="badge-name"><span>{branchFirstUserCode}</span></div>
                : null
            }
            {
              this.props.branchUserIds &&
                this.props.branchUserIds.length > 1
                ? <div className="badge-name"><span>+{this.props.branchUserIds.length - 1}</span></div>
                : null
            }
            {
              !this.props.hideAddButton &&
              this.props.userRoleId !== userRole.STANDARD &&
              <button className="main-button icon only badge-add">
                <svg width="20" height="20" viewBox="0 0 24 24">
                  <path id="a" d="M13.767 10.233h6.466a1.767 1.767 0 0 1 0 3.534h-6.466v6.466a1.767 1.767 0 0 1-3.534 0v-6.466H3.767a1.767 1.767 0 0 1 0-3.534h6.466V3.767a1.767 1.767 0 0 1 3.534 0v6.466z" />
                </svg>
              </button>
            }
          </Col>
        </Row>
        {
          <AddUserToBranchModal
            branchId={this.props.branchId}
            branchName={this.props.branchName}
            branchUserIds={this.props.branchUserIds}
            users={this.props.users}
            isVisible={this.props.isModalWithUsersVisible === this.props.branchId}
          />
        }
      </div>
    );
  }
}

export default BranchUsersBadge;
