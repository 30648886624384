import { hideModal, showModal } from 'actions/branch';
import AddBranchModal from './AddBranchModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';
import routes from 'utils/routes';

class FirstBranchCard extends React.Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  clearSearchBar() {
    const searchBarClearButton = document.querySelector('.search-bar .search-clear');
    searchBarClearButton && searchBarClearButton.click();
  }

  handleClick() {
    this.clearSearchBar();
    this.props.showModal();
  }

  render() {

    return (
      <div className="branch-card first">
        <div>
          <svg className="location-sign" width="32" height="32" viewBox="0 0 24 24">
            <path id="a" d="M11.984 13.1c.592 0 1.141-.149 1.649-.446a3.341 3.341 0 0 0 1.204-1.196c.296-.5.444-1.047.444-1.642s-.148-1.148-.444-1.658a3.303 3.303 0 0 0-1.204-1.212 3.201 3.201 0 0 0-1.649-.446c-.592 0-1.136.149-1.633.446a3.342 3.342 0 0 0-1.188 1.212 3.248 3.248 0 0 0-.444 1.658c0 .914.317 1.69.95 2.327.635.638 1.406.957 2.315.957zM12 1c1.604 0 3.077.344 4.42 1.031 1.323.688 2.376 1.613 3.157 2.777a6.898 6.898 0 0 1 1.173 3.887c0 1.075-.3 2.309-.902 3.702-.521 1.18-1.243 2.459-2.165 3.834a50.723 50.723 0 0 1-2.616 3.41 42.432 42.432 0 0 1-2.165 2.434L12 23l-.902-.925a42.432 42.432 0 0 1-2.165-2.433 50.723 50.723 0 0 1-2.616-3.411c-.922-1.375-1.644-2.653-2.165-3.834-.601-1.393-.902-2.627-.902-3.702 0-1.41.39-2.706 1.173-3.887.781-1.164 1.834-2.09 3.157-2.777C8.923 1.344 10.396 1 12 1z" />
          </svg>
          {this.props.branches && this.props.branches.length === 0
            ? <div>
              <p>Inca nu ai adaugat puncte de lucru. Incepe prin a adauga unul.</p>
            </div>
            : <div>
              <p>Adauga un punct de lucru nou, sau incarca o lista.</p>
            </div>
          }
        </div>
        <div>
          <button className="main-button icon" onClick={this.handleClick}>
            <svg width="20" height="20" viewBox="0 0 24 24">
              <path id="a" d="M13.767 10.233h6.466a1.767 1.767 0 0 1 0 3.534h-6.466v6.466a1.767 1.767 0 0 1-3.534 0v-6.466H3.767a1.767 1.767 0 0 1 0-3.534h6.466V3.767a1.767 1.767 0 0 1 3.534 0v6.466z" />
            </svg>
            PUNCT DE LUCRU NOU
          </button>
          <Link
            to={routes.dashboardBranchImport}
            className="main-button white icon mt-2"
            title="Incarca o lista dintr-un fisier"
            onClick={this.clearSearchBar}
          >
            <svg width="20" height="20" viewBox="0 0 24 24">
              <path id="a" d="M13.767 10.233h6.466a1.767 1.767 0 0 1 0 3.534h-6.466v6.466a1.767 1.767 0 0 1-3.534 0v-6.466H3.767a1.767 1.767 0 0 1 0-3.534h6.466V3.767a1.767 1.767 0 0 1 3.534 0v6.466z"></path>
            </svg>
            INCARCA DIN FISIER
          </Link>
        </div>
        <AddBranchModal
          show={this.props.isModalVisibile}
          onHide={() => { this.props.hideModal(); }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => state.branch;

const mapDispatchToProps = dispatch => bindActionCreators({
  hideModal,
  showModal
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FirstBranchCard);
