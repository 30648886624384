import React, { Component, Fragment } from 'react';
import { ai } from 'utils/telemetryService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter } from 'react-router-dom';

class TelemetryProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false
    };
  }

  componentDidMount() {
    const { history } = this.props,
      { initialized } = this.state;

    if (!initialized && Boolean(this.props.instrumentationKey) && Boolean(history)) {
      ai.initialize(this.props.instrumentationKey, history);
      this.setState({ initialized: true });
    }

    this.props.after();
  }

  render() {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    );
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
