import './AddManagerModal.scss';
import { branchStatus, userRole } from 'utils/enums';
import { Col, Form, Modal } from 'react-bootstrap';
import React from 'react';
import Select from 'react-select';
import validator from 'utils/validator';

class AddManagerModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBranchMultiSelect = this.handleBranchMultiSelect.bind(this);
    this.resetState = this.resetState.bind(this);
    this.validateManagerUserName = this.validateManagerUserName.bind(this);
    this.validateManagerFirstName = this.validateManagerFirstName.bind(this);
    this.validateManagerLastName = this.validateManagerLastName.bind(this);
    this.validateManagerPhoneNumber = this.validateManagerPhoneNumber.bind(this);
    this.validateManagerBranches = this.validateManagerBranches.bind(this);
    this.validateManagerRole = this.validateManagerRole.bind(this);
    this.submit = this.submit.bind(this);
    this.onShowModal = this.onShowModal.bind(this);
    this.onBtnCloseModal = this.onBtnCloseModal.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.mapUiErrorCode = this.mapUiErrorCode.bind(this);
    this.searchInputChange = this.searchInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleBranchMultiSelect(selectedBranches) {
    let characterCount = 0;
    selectedBranches = selectedBranches || [];
    selectedBranches.forEach((item) => { characterCount += item.label.length });

    this.setState({ selectedBranches });

    if (characterCount > 40 && selectedBranches.length > 4) {
      !this.state.longBranchesList && this.setState({ longBranchesList: true });
    } else {
      this.state.longBranchesList && this.setState({ longBranchesList: false });
    }
  }

  initialState() {
    return {
      managerUserName: '',
      isManagerUserNameError: false,
      managerUserNameError: '',

      managerFirstName: '',
      isManagerFirstNameError: false,
      managerFirstNameError: '',

      managerLastName: '',
      isManagerLastNameError: false,
      managerLastNameError: '',

      managerPhoneNumber: '',
      isManagerPhoneNumberError: false,
      managerPhoneNumberError: '',

      managerRole: this.props.userRoleId === userRole.POWER ? userRole.STANDARD : '-1',
      isManagerRoleError: false,
      managerRoleError: '',

      selectedBranches: [],
      isSelectedBranchesError: false,
      selectedBranchesError: '',

      branchOptions: [],

      inputHasValue: false,
      longBranchesList: false,
      isSubmitting: false,
    };
  }

  resetState(callback) {
    this.setState(this.initialState(), callback);
  }

  isModalValid() {

    this.validateManagerUserName();
    this.validateManagerLastName();
    this.validateManagerFirstName();
    this.validateManagerPhoneNumber();
    this.validateManagerBranches();
    this.validateManagerRole();

    if (this.validateManagerUserName()
      && this.validateManagerLastName()
      && this.validateManagerFirstName()
      && this.validateManagerPhoneNumber()
      && this.validateManagerBranches()
      && this.validateManagerRole()) {
      return true;
    } else {
      return false;
    }
  }

  onBtnCloseModal() {
    this.props.hideModal();
  }

  onShowModal() {
    this.resetState();
    let branchOptions = [];
    this.props.branches && this.props.branches.forEach(item =>
      item.id && item.status === branchStatus.enabled && branchOptions.push({ value: item.id, label: item.name }));
    this.setState({ branchOptions });
  }

  onHideModal() {
    this.props.hideModal();
  }

  submit() {
    let isValid = this.isModalValid();
    if (isValid) {
      var data = {
        username: this.state.managerUserName,
        lastName: this.state.managerLastName,
        firstName: this.state.managerFirstName,
        phoneNumber: this.state.managerPhoneNumber,
        roleId: this.state.managerRole,
        branchIds: this.state.selectedBranches.map(b => { return b.value; }),
        branchNames: this.state.selectedBranches.map(b => { return b.label; })
      };

      this.setState({ isSubmitting: true }, () => {
        this.props.createManagerRequest({ ...data, showToast: true }).then(() => {
          this.setState({ isSubmitting: false });
        });
      });
    }
  }

  validateManagerUserName() {
    const result = validator.validateManagerUserName(this.state.managerUserName);
    this.setState({
      isManagerUserNameError: result.isManagerUserNameError,
      managerUserNameError: result.managerUserNameError
    })
    if (result.isManagerUserNameError) {
      this.props.setModalError('');
      return false;
    } else return true;
  }

  validateManagerRole() {
    const result = validator.validateManagerRole(this.state.managerRole);
    this.setState({
      isManagerRoleError: result.isManagerRoleError,
      managerRoleError: result.managerRoleError
    })
    if (result.isManagerRoleError) {
      this.props.setModalError('');
      return false;
    } else return true;
  }

  validateManagerPhoneNumber() {
    const result = validator.validateManagerPhoneNumber(this.state.managerPhoneNumber);
    this.setState({
      isManagerPhoneNumberError: result.isManagerPhoneNumberError,
      managerPhoneNumberError: result.managerPhoneNumberError
    })
    if (result.isManagerPhoneNumberError) {
      this.props.setModalError('');
      return false;
    } else return true;
  }

  validateManagerFirstName() {
    const result = validator.validateManagerFirstName(this.state.managerFirstName);
    this.setState({
      isManagerFirstNameError: result.isManagerFirstNameError,
      managerFirstNameError: result.managerFirstNameError
    })
    if (result.isManagerFirstNameError) {
      this.props.setModalError('');
      return false;
    } else return true;
  }

  validateManagerLastName() {
    const result = validator.validateManagerLastName(this.state.managerLastName);
    this.setState({
      isManagerLastNameError: result.isManagerLastNameError,
      managerLastNameError: result.managerLastNameError
    })
    if (result.isManagerLastNameError) {
      this.props.setModalError('');
      return false;
    } else return true;
  }

  validateManagerBranches() {
    const result = validator.validateManagerBranches(this.state.selectedBranches, this.state.branchOptions);
    this.setState({
      isSelectedBranchesError: result.isSelectedBranchesError,
      selectedBranchesError: result.selectedBranchesError
    })
    if (result.isSelectedBranchesError) {
      this.props.setModalError('');
      return false;
    } else return true;
  }

  searchInputChange(value) {
    if (value !== '') {
      !this.state.inputHasValue && this.setState({ inputHasValue: true });
    }
    else {
      this.state.inputHasValue && this.setState({ inputHasValue: false });
    }
  }

  mapUiErrorCode(error) {
    switch (error) {
      case 'UserAlreadyExists':
        return 'Acest nume de utilizator exista deja. Incearca o alta varianta (ex. stefanescu1.robert)';
      case 'PhoneNumberAlreadyExistsOnSameEmployer':
        return 'Telefonul este deja asignat altei persoane pentru acest angajator';
      case 'ModalInvalid':
        return 'Nu toate datele introduse respecta formatul acceptat.'
      default:
        return 'A aparut o erroare. Te rugam incearca din nou.';
    }
  }

  render() {
    return <Modal
      className="modal-add-manager"
      show={this.props.visible}
      onShow={this.onShowModal}
      onHide={this.onHideModal}
      scrollable="true"
      dialogClassName="modal-add-manager-container"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Adauga manager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="form-group-label" controlId="manager-userName">
            {this.state.isManagerUserNameError ? <span className="error-label">{this.state.managerUserNameError}</span> : null}
            <Form.Control autoFocus
              name="managerUserName" type="text" placeholder="Utilizator"
              onChange={this.handleInputChange}
              value={this.state.managerUserName}
              onBlur={this.validateManagerUserName} />
            <Form.Label>Utilizator</Form.Label>
          </Form.Group>
          <Form.Row>
            <Form.Group className="form-group-label spacer-right" as={Col} controlId="manager-lastName">
              {this.state.isManagerLastNameError ? <span className="error-label">{this.state.managerLastNameError}</span> : null}
              <Form.Control type="text" placeholder="Nume" name="managerLastName"
                onChange={this.handleInputChange}
                value={this.state.managerLastName}
                onBlur={this.validateManagerLastName} />
              <Form.Label>Nume</Form.Label>
            </Form.Group>
            <Form.Group className="form-group-label" as={Col} controlId="manager-firstName">
              {this.state.isManagerFirstNameError ? <span className="error-label">{this.state.managerFirstNameError}</span> : null}
              <Form.Control
                type="text" placeholder="Prenume" name="managerFirstName"
                onChange={this.handleInputChange}
                value={this.state.managerFirstName}
                onBlur={this.validateManagerFirstName} />
              <Form.Label>Prenume</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-group-label spacer-right" as={Col} controlId="manager-phone-number">
              {this.state.isManagerPhoneNumberError ? <span className="error-label">{this.state.managerPhoneNumberError}</span> : null}
              <Form.Control type="text" placeholder="Telefon" name="managerPhoneNumber"
                onChange={this.handleInputChange}
                value={this.state.managerPhoneNumber}
                onBlur={this.validateManagerPhoneNumber} />
              <Form.Label>Telefon</Form.Label>
            </Form.Group>

            <Form.Group className="form-group-label" as={Col} controlId="manager-role">
              {this.state.isManagerRoleError ? <span className="error-label">{this.state.managerRoleError}</span> : null}
              <Form.Control as="select" placeholder="Alege rol" name="managerRole"
                onChange={this.handleInputChange}
                value={this.state.managerRole}
                onBlur={this.validateManagerRole}>
                {
                  this.props.userRoleId === userRole.ADMIN &&
                  <>
                    <option value="-1"></option>
                    <option value="1">Power</option>
                    <option value="2">Standard</option>
                  </>
                }
                {
                  this.props.userRoleId === userRole.POWER &&
                  <>
                    <option value="2">Standard</option>
                  </>
                }
              </Form.Control>
              <Form.Label className={this.state.managerRole !== '-1' ? 'select-label active' : 'select-label'}>Rol</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Group className="form-group-label" controlId="manager-branches">
            {this.state.isSelectedBranchesError ? <span className="error-label-select">{this.state.selectedBranchesError}</span> : null}
            <Select
              className={this.state.longBranchesList ? 'branches-multi-select long-list' : (this.state.selectedBranches.length > 0 ? 'branches-multi-select has-items' : 'branches-multi-select')}
              menuPlacement="top"
              pageSize={2}
              maxMenuHeight={220}
              value={this.state.selectedBranches}
              onChange={this.handleBranchMultiSelect}
              onInputChange={this.searchInputChange}
              isMulti={true}
              isSearchable={true}
              options={this.state.branchOptions}
              placeholder=""
              onBlur={this.validateManagerBranches}
              noOptionsMessage={() => { return ''; }}
            />
            <Form.Label className={(this.state.selectedBranches && this.state.selectedBranches.length > 0) ||
              this.state.inputHasValue ?
              'select-label active large-select' :
              'select-label large-select'}>Puncte de lucru</Form.Label>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {this.props.modalServerError ? <span className="error-label">{this.mapUiErrorCode(this.props.modalServerError)}</span> : null}
        <button type="button" className="main-button white" onClick={this.onBtnCloseModal}>RENUNTA</button>
        <button
          className={'main-button ' + (this.state.isSubmitting ? 'loading disabled' : '')}
          onClick={this.submit}
          disabled={this.state.isSubmitting}>
          <span className="spinner-border spinner-border-sm"></span>
          ADAUGA
        </button>
      </Modal.Footer>
    </Modal>
  }
}

export default AddManagerModal;
