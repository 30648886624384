import { loadAudit, setAuditFilter, setAuditState } from 'actions/audit';
import AuditDashboard from './AuditDashboard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    auditTotalCount: state.audit.totalCount,
    auditEntries: state.audit.entries,
    auditFilter: state.audit.filter,
    isAuditLoadingIconVisible: state.audit.isAuditLoadingIconVisible,
    employerId: state.user.employerId
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  loadAudit,
  setAuditFilter,
  setAuditState,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuditDashboard);
