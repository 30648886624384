import { ItemCorrectionScreenContainer, SuccessfullUploadScreenContainer } from 'components';
import React from 'react';

class UploadFileMealTicketSteps extends React.Component {
    render() {
        if (!this.props.config) {
            return null;
        }

        return (
            this.props.itemsValidated === false ?
                <ItemCorrectionScreenContainer /> :
                <SuccessfullUploadScreenContainer />
        )
    }
}

export default UploadFileMealTicketSteps;
