import { AFTER_CURRENT_BRANCH_WAS_SET, afterCurrentBranchWasSet, setCurrentBranch } from 'actions/branch';

import {
  CARD_CONFIRM_CARDS_FAILURE,
  CARD_CONFIRM_CARDS_SUCCESS,
  CARD_DELETE_FAILURE,
  CARD_DELETE_SUCCESS,
  CARD_FIND_CARDS_FAILURE,
  CARD_FIND_CARDS_SUCCESS,
  CARD_FIND_MINIMAL_CARDS_FAILURE,
  CARD_FIND_MINIMAL_CARDS_SUCCESS,
  CARD_REISSUE_CARDS_FAILURE,
  CARD_REISSUE_CARDS_SUCCESS,
  CARD_SET_PAGE_SIZE,
  CHANGE_TICKET_VALUE_FAILURE,
  CHANGE_TICKET_VALUE_SUCCESS,
  clearSelection,
  findCardsRequest,
  hideCardReissueModal,
  hidePinReissueModal,
  MIGRATE_CARDS_FAILURE,
  MIGRATE_CARDS_SUCCESS,
  REISSUE_CARD_FAILURE,
  REISSUE_CARD_SUCCESS,
  REISSUE_PIN_FAILURE,
  REISSUE_PIN_SUCCESS,
  removeCardFromSelection,
  setCardFilters,
  setCardReissueModalError,
  setCards,
  setCardsCurrentPage,
  setCardsLoadedFlag,
  setCardsPageCount,
  setCardsTotalCount,
  setPinReissueModalError,
  setSelectedCards,
  setSendReissueCardInProgress
} from 'actions/card';

import { cardStatus, selectionType } from '../utils/enums';

import { findOrders } from 'actions/order';
import { hideLoadingSpinner } from 'actions/loadingSpinner';
import routes from 'utils/routes';
import { showToast } from 'actions/toast';

function card(store) {
  return next => action => {
    switch (action.type) {
      case CARD_FIND_CARDS_SUCCESS: {
        const { pageSize, selectedCards, filters } = store.getState().card,
          { cards, totalCount } = action.payload;

        next(setCards(cards));
        next(setCardsPageCount(Math.ceil(totalCount / pageSize)));
        next(setCardsTotalCount(totalCount));
        next(setCardsLoadedFlag(true));
        if (!cards || cards.length == 0) {
          next(clearSelection());
        }
        else if (selectedCards && selectedCards.length > 0) {
          const newSelectedCards = getSelectedCardsAfterFind(selectedCards, filters);
          next(setSelectedCards(newSelectedCards));
        }
        break;
      }
      case CARD_FIND_CARDS_FAILURE: {
        next(setCards([]));
        next(setCardsPageCount(1));
        next(setCardsTotalCount(0));
        break;
      }
      case CARD_SET_PAGE_SIZE: {
        next(setCardsCurrentPage(1));
        break;
      }
      case AFTER_CURRENT_BRANCH_WAS_SET: {
        const pathname = store.getState().router.location.pathname;
        if (pathname.includes(routes.dashboardCards) && action.payload) {
          const { id } = action.payload;

          store.dispatch(setCardsLoadedFlag(false));

          let searchBarInput = document.getElementById('search-bar-input-cards');
          if (searchBarInput) {
            searchBarInput.value = '';
          }

          store.dispatch(setCardsCurrentPage(1));
          store.dispatch(findOrders(id));
          store.dispatch(setCardFilters({ firstOrLastName: null, cnp: null, statusId: null, ticketValue: null, orderId: null }));
          store.dispatch(findCardsRequest(action.payload));
          next(clearSelection());
        }
        break;
      }
      case REISSUE_CARD_SUCCESS: {
        const { cards, currentPage } = store.getState().card,
          { cardId, cardMigrated } = action.payload;
        let pageNumber = cards.length == 1 ? currentPage - 1 : currentPage;
        if (pageNumber < 1) pageNumber = 1;

        if (cardMigrated) {
          const updatedCurrentBranch = { id: null, name: 'Toate punctele de lucru' };
          store.dispatch(setCurrentBranch(updatedCurrentBranch))
            .then(() => {
              store.dispatch(afterCurrentBranchWasSet(updatedCurrentBranch));
            });
        }

        const { currentBranch } = store.getState().branch,
          currentBranchId = currentBranch && currentBranch.id ? currentBranch.id : null;

        store.dispatch(findOrders(currentBranchId)).then(() => {
          next(showToast({
            type: 'info', title: 'Reemitere Card Inregistrata',
            text: 'Solicitarea de reemitere card a fost trimisa spre procesare.'
          }));
          store.dispatch(setCardsCurrentPage(pageNumber));
          store.dispatch(removeCardFromSelection({ cardId: cardId }));
          store.dispatch(findCardsRequest());
          next(setSendReissueCardInProgress(false));
          store.dispatch(hideCardReissueModal());
        });

        break;
      }
      case REISSUE_CARD_FAILURE: {
        next(setSendReissueCardInProgress(false));
        if (action.payload.status) {
          next(setCardReissueModalError(action.payload.errorMessage));
        } else {
          next(showToast({ type: 'error', text: action.payload.errorMessage }));
          next(hideCardReissueModal());
          store.dispatch(clearSelection());
        }
        break;
      }
      case REISSUE_PIN_SUCCESS: {
        next(showToast({ type: 'info', title: 'Reemitere Pin Inregistrata', text: 'Noul cod va fi trimis in scurt timp pe numarul de telefon al angajatului.' }));

        const { cards, currentPage } = store.getState().card,
          { cardId, cardMigrated } = action.payload;
        let pageNumber = cards.length == 1 ? currentPage - 1 : currentPage;
        if (pageNumber < 1) pageNumber = 1;

        store.dispatch(hidePinReissueModal());
        store.dispatch(setCardsCurrentPage(pageNumber));
        store.dispatch(findCardsRequest());
        if (cardMigrated) {
          store.dispatch(removeCardFromSelection(cardId));
        }
        break;
      }
      case REISSUE_PIN_FAILURE: {
        if (action.payload.status) {
          next(setPinReissueModalError(action.payload.errorMessage));
        } else {
          next(showToast({ type: 'error', text: action.payload.errorMessage }));
          next(hidePinReissueModal());
        }
        break;
      }
      case CARD_DELETE_SUCCESS: {

        const { cards, currentPage } = store.getState().card,
          deletedCard = action.payload;
        let pageNumber = cards.length == 1 ? currentPage - 1 : currentPage;
        if (pageNumber < 1) pageNumber = 1;

        next(showToast({ type: 'success', text: 'Cardul a fost sters cu succes.' }));

        store.dispatch(setCardsCurrentPage(pageNumber));
        store.dispatch(removeCardFromSelection(deletedCard));

        const cardsLeft = cards.filter(c => c.id !== deletedCard.cardId);

        if (!cardsLeft || cardsLeft.length === 0) {
          store.dispatch(findOrders(deletedCard.branchId)).then(() => {
            store.dispatch(findCardsRequest());
          });
        }
        else {
          store.dispatch(findCardsRequest());
        }

        next(hideLoadingSpinner());
        break;
      }
      case CARD_DELETE_FAILURE: {
        next(showToast({ type: 'error', text: action.payload.errorMessage }));
        store.dispatch(clearSelection());
        break;
      }
      case MIGRATE_CARDS_SUCCESS: {
        let toast = {};
        switch (action.payload.status) {
          case 'Success':
            toast = { type: 'success', text: action.payload.text };
            break;
          case 'Warning':
            toast = { type: 'warning', text: action.payload.text };
            break;
          case 'Error':
            toast = { type: 'error', text: action.payload.text };
            break;
          default:
            toast = { type: 'info', title: 'Migrarea nu este posibila', text: action.payload.text };
            break;
        }

        next(showToast(toast));

        if (action.payload.status !== 'Error') {
          const { selectState, selectedCards, cards } = store.getState().card,
            { currentBranch } = store.getState().branch;

          const cardsLeft = cards.filter(card => selectedCards.map(s => s.cardId).includes(card.id) === false);

          if (!cardsLeft || cardsLeft.length === 0) {
            store.dispatch(findOrders(currentBranch.id)).then(() => store.dispatch(findCardsRequest()));
          }
          else {
            store.dispatch(findCardsRequest());
          }

          const isAllBranchesSelected = currentBranch && (currentBranch.id === null || currentBranch.id === undefined),
            newSelectState = isAllBranchesSelected ? selectState : selectionType.none,
            newSelectedCardsList = isAllBranchesSelected ? selectedCards : [];
          store.dispatch(setSelectedCards(newSelectedCardsList, newSelectState));
        }
        break;
      }
      case MIGRATE_CARDS_FAILURE: {
        next(showToast({ type: 'error', text: action.payload.errorMessage }));
        break;
      }
      case CHANGE_TICKET_VALUE_SUCCESS: {
        let toast = {};
        switch (action.payload.status) {
          case 'Success':
            toast = { type: 'success', text: action.payload.text };
            break;
          case 'Warning':
            toast = { type: 'warning', text: action.payload.text };
            break;
          case 'Error':
            toast = { type: 'error', text: action.payload.text };
            break;
          default:
            toast = { type: 'info', text: action.payload.text };
            break;
        }

        next(showToast(toast));
        store.dispatch(findCardsRequest());
        break;
      }
      case CHANGE_TICKET_VALUE_FAILURE: {
        next(showToast({ type: 'error', text: action.payload.errorMessage }));
        break;
      }
      case CARD_CONFIRM_CARDS_FAILURE: {
        next(showToast({ type: 'error', text: action.payload.errorMessage }));
        break;
      }
      case CARD_CONFIRM_CARDS_SUCCESS: {
        switch (action.payload.status) {
          case 'Success': next(showToast({ type: 'success', text: action.payload.text }));
            break;
          case 'Warning': next(showToast({ type: 'warning', text: action.payload.text }));
            break;
          default:
            next(showToast({ type: 'info', title: 'Operatiunea nu este posibila', text: action.payload.text }));
            break;
        }

        const selectedCards = store.getState().card.selectedCards;
        const updatedList = selectedCards.map(card => ({ ...card, statusId: cardStatus.confirmed }));

        store.dispatch(setSelectedCards(updatedList))
        store.dispatch(findCardsRequest());
        break;
      }
      case CARD_REISSUE_CARDS_SUCCESS: {
        const { currentBranch } = store.getState().branch,
          currentBranchId = currentBranch ? currentBranch.id : null;

        store.dispatch(findOrders(currentBranchId, false)).then(() => {
          store.dispatch(findCardsRequest());
          next(clearSelection());
          next(hideLoadingSpinner());

          switch (action.payload.status) {
            case 'Success': next(showToast({ type: 'success', text: action.payload.text }));
              break;
            case 'Warning': next(showToast({ type: 'warning', text: action.payload.text }));
              break;
            default:
              next(showToast({ type: 'info', title: 'Operatiunea nu este posibila', text: action.payload.text }));
              break;
          }
        });

        break;
      }
      case CARD_REISSUE_CARDS_FAILURE: {
        next(showToast({ type: 'error', text: action.payload.errorMessage }));
        break;
      }
      case CARD_FIND_MINIMAL_CARDS_SUCCESS: {
        const cards = action.payload;
        next(setSelectedCards(cards, selectionType.all));
        next(setCardsLoadedFlag(true));
        break;
      }
      case CARD_FIND_MINIMAL_CARDS_FAILURE: {
        next(showToast({ type: 'error', text: action.payload }));
        next(setCardsLoadedFlag(true));
        break;
      }
    }

    const returnValue = next(action);

    return returnValue
  }
}

function getSelectedCardsAfterFind(selectedCards, filters) {
  let nameFiltered = filters.firstOrLastName && selectedCards.filter(filterByFirstLastName(filters.firstOrLastName.toLowerCase())),
    cnpFiltered = filters.cnp && selectedCards.filter(sc => sc.cnp.startsWith(filters.cnp)),
    statusFiltered = Number.isInteger(filters.statusId) ? filters.statusId > -1 && selectedCards.filter(sc => sc.statusId === filters.statusId) : null,
    ticketValueFiltered = filters.ticketValue && selectedCards.filter(sc => ('' + sc.ticketValue).startsWith(filters.ticketValue)),
    orderFiltered = filters.orderId && selectedCards.filter(sc => sc.orderId === filters.orderId),
    newSelectedCards = selectedCards;

  newSelectedCards = nameFiltered ? newSelectedCards.filter(c => nameFiltered.map(nf => nf.cardId).includes(c.cardId)) : newSelectedCards;
  newSelectedCards = cnpFiltered ? newSelectedCards.filter(c => cnpFiltered.map(cf => cf.cardId).includes(c.cardId)) : newSelectedCards;
  newSelectedCards = statusFiltered ? newSelectedCards.filter(c => statusFiltered.map(sf => sf.cardId).includes(c.cardId)) : newSelectedCards;
  newSelectedCards = ticketValueFiltered ? newSelectedCards.filter(c => ticketValueFiltered.map(tvf => tvf.cardId).includes(c.cardId)) : newSelectedCards;
  newSelectedCards = orderFiltered ? newSelectedCards.filter(c => orderFiltered.map(of => of.cardId).includes(c.cardId)) : newSelectedCards;

  return newSelectedCards;
}

const filterByFirstLastName = (firstOrLastName) => {
  return (selectedCard) => {
    const allNames = selectedCard.fullName.split(/[\s]+/);
    const firstName = allNames.slice(1).join(' ');
    const lastName = allNames.slice(0, 1).join('');
    return [firstName, lastName]
      .filter(s => !!s)
      .map(s => s.toLowerCase())
      .some(namePart => namePart.startsWith(firstOrLastName));
  }
}

export default card;
