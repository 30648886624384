import './SearchInput.scss';
import { Col, Row } from 'react-bootstrap';
import React from 'react';

class SearchInput extends React.Component {
    constructor(props) {
        super(props);

        this.renderInput = this.renderInput.bind(this);
        this.addSearchIcon = this.addSearchIcon.bind(this);
        this.addResetSearchButton = this.addResetSearchButton.bind(this);
        this.renderContentWithSearchIconLeft = this.renderContentWithSearchIconLeftAndResetSearchButton.bind(this);
        this.renderContentWithSearchIconRightAndNoResetSearchButton = this.renderContentWithSearchIconRightAndNoResetSearchButton.bind(this);
        this.setClassName = this.setClassName.bind(this);
    }

    renderInput() {
        return <Col>
            <input
                id={this.props.searchInputId}
                type="text"
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.props.onChange}
                onKeyDown={this.props.onKeyDown} />
        </Col>
    }

    addSearchIcon() {
        return <Col sm="auto">
            <svg className={'search-icon' + (this.props.noRightMargin ? ' no-margin' : '')} width="15" height="15" viewBox="0 0 24 24">
                <path id="a" d="M22.817 21.037l-5.692-5.734a.636.636 0 0 1-.07-.8C19.389 10.923 18.845 6 15.4 3.06 12.088.23 7.045.328 3.835 3.275.15 6.657.057 12.415 3.557 15.916c2.985 2.987 7.604 3.35 10.996 1.106a.633.633 0 0 1 .804.07l5.68 5.722a.628.628 0 0 0 .89.002l.889-.89a.63.63 0 0 0 .001-.889zm-17.482-6.9a6.244 6.244 0 0 1 0-8.816 6.232 6.232 0 0 1 8.809 0 6.244 6.244 0 0 1 0 8.816 6.233 6.233 0 0 1-8.81 0z" />
            </svg>
        </Col>
    }

    addResetSearchButton() {
        return <Col sm="auto">
            <svg className="clear-search-icon" onClick={this.props.onClear} width="20" height="20" viewBox="0 0 24 24">
                <path id="a" d="M12 1c6.072 0 11 4.928 11 11s-4.928 11-11 11S1 18.072 1 12 5.928 1 12 1zm4.757 7.73a1.051 1.051 0 0 0-1.487-1.487L12 10.513l-3.27-3.27A1.051 1.051 0 1 0 7.243 8.73l3.27 3.27-3.27 3.27a1.051 1.051 0 1 0 1.487 1.487l3.27-3.27 3.27 3.27a1.051 1.051 0 0 0 1.487-1.487L13.487 12l3.27-3.27z" />
            </svg>
        </Col>
    }

    renderContentWithSearchIconLeftAndResetSearchButton(className) {
        return <div className={className}>
            <Row noGutters>
                {this.addSearchIcon()}
                {this.renderInput()}
                {this.addResetSearchButton()}
            </Row>
        </div>
    }

    renderContentWithSearchIconRightAndNoResetSearchButton(className) {
        return <div className={className}>
            <Row noGutters>
                {this.renderInput()}
                {this.addSearchIcon()}
            </Row>
        </div>
    }

    setClassName(variant) {
        let className = 'search-input';

        switch (variant) {
            case 'plain':
                className = `${className} plain`;
                break;
            default:
                break;
        }
        return className;
    }

    render() {
        const className = this.setClassName(this.props.variant);

        return this.props.rightAlign ?
            this.renderContentWithSearchIconRightAndNoResetSearchButton(className) :
            this.renderContentWithSearchIconLeftAndResetSearchButton(className)
    }
}

export default SearchInput;
