import './Header.scss';
import React from 'react';
import Row from 'react-bootstrap/Row';

class Header extends React.Component {
  render() {
    return (
      <div {...this.props}>
        <Row noGutters>
          {this.props.children}
        </Row>
      </div>
    );
  }
}

export default Header;
