import './LoginForm.scss';
import { Col, Container } from 'react-bootstrap';
import { ForgotPasswordView, PasswordSetupView, PasswordView, SmsTokenView, UsernameView } from './LoginViews';
import { Route, Switch } from 'react-router';
import React from 'react';
import routes from 'utils/routes';
import Row from 'react-bootstrap/Row';

class LoginForm extends React.Component {

  render() {
    return (
      <Container fluid>
        <Row noGutters className="justify-content-sm-center">
          <Col xs="auto">
              <Switch>
                <Route exact path={routes.accountLogin} render={() => <UsernameView {...this.props} />} />
                <Route path={routes.accountLoginPassword} render={() => <PasswordView {...this.props} />} />
                <Route path={routes.accountLoginSmsToken} render={() => <SmsTokenView {...this.props} />} />
                <Route path={routes.accountLoginPasswordSetup} render={() => <PasswordSetupView {...this.props} />} />
                <Route exact path={routes.accountForgotPassword} render={() => <ForgotPasswordView {...this.props} />} />
                <Route path={routes.accountForgotPasswordPassword} render={() => <PasswordView {...this.props} />} />
                <Route path={routes.accountForgotPasswordSmsToken} render={() => <SmsTokenView {...this.props} />} />
                <Route path={routes.accountForgotPasswordPasswordSetup} render={() => <PasswordSetupView {...this.props} />} />
              </Switch>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginForm;
