import {
  ADD_USER_TO_BRANCH_FAILURE,
  BLOCK_CURRENT_BRANCH_SELECTION,
  HIDE_MODAL,
  REMOVE_USER_FROM_BRANCH_FAILURE,
  SET_BRANCHES,
  SET_BRANCHES_LOADED_FLAG,
  SET_CITIES,
  SET_CURRENT_BRANCH,
  SET_CURRENT_BRANCH_USER_IDS,
  SET_LOCALITIES,
  SET_UPSERT_BRANCH_RESULT,
  SHOW_MODAL,
  SHOW_MODAL_FOR_EDIT
} from '../actions/branch';

export default function branch(state, action) {
  state = state || {
    branches: [],
    cities: [],
    localities: [],
    isModalVisibile: false,
    isUpsertBranchError: false,
    upsertBranchErrorMessage: '',
    isModalInEditMode: false
  };

  switch (action.type) {
    case SET_BRANCHES:
      return {
        ...state,
        branches: action.payload
      }
    case SET_CITIES:
      return {
        ...state,
        cities: action.payload
      }
    case SET_LOCALITIES:
      return {
        ...state,
        localities: action.payload
      }
    case SET_UPSERT_BRANCH_RESULT:
      return {
        ...state,
        isUpsertBranchError: action.payload.isUpsertBranchError,
        upsertBranchErrorMessage: action.payload.upsertBranchErrorMessage
      }
    case HIDE_MODAL:
      return {
        ...state,
        isModalVisibile: action.payload,
        isUpsertBranchError: false,
        upsertBranchErrorMessage: '',
        cities: [],
        localities: []
      }
    case SHOW_MODAL:
      return {
        ...state,
        isModalVisibile: action.payload,
        isUpsertBranchError: false,
        upsertBranchErrorMessage: '',
        cities: [],
        localities: [],
        isModalInEditMode: false
      }
    case SET_CURRENT_BRANCH:
      return {
        ...state,
        currentBranch: action.payload
      }
    case SET_CURRENT_BRANCH_USER_IDS:
      return {
        ...state,
        currentBranch: { ...state.currentBranch, userIds: action.payload }
      }
    case BLOCK_CURRENT_BRANCH_SELECTION:
      return {
        ...state,
        blockCurrentBranchSelection: action.payload
      }
    case ADD_USER_TO_BRANCH_FAILURE:
    case REMOVE_USER_FROM_BRANCH_FAILURE:
      return {
        ...state,
        addRemoveUserToBranchErrorMessage: action.payload.errorMessage
      }
    case SHOW_MODAL_FOR_EDIT:
      return {
        ...state,
        isModalVisibile: true,
        isUpsertBranchError: false,
        upsertBranchErrorMessage: '',
        cities: [],
        localities: [],
        isModalInEditMode: true
      }
    case SET_BRANCHES_LOADED_FLAG:
      return {
        ...state,
        branchesLoaded: action.payload
      }
    default:
      return state;
  }
}
