import './Checkbox.scss';
import React from 'react';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    return (
      <label className="custom-checkbox">
        <input type="checkbox" checked={Boolean(this.props.checked)} ref={this.ref} onChange={this.props.onChange}/>
        <span className="checkmark"></span>
        {this.props.text && <span className="checkbox-text">{this.props.text}</span>}
      </label>
    );
  }
}

export default Checkbox;
