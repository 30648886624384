import { Captcha, captchaSettings } from 'reactjs-captcha';
import { Col, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import errorCodes from 'utils/errorCodes';
import { push } from 'connected-react-router';
import React from 'react';
import { Redirect } from 'react-router';
import routes from 'utils/routes';
import { setLoginError } from 'actions/user';
import { verifyPasswordRequest } from 'actions/user';

const mapStateToProps = state => ({ ...state.user, ...state.router });

class PasswordView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordVisible: false
    };

    this.submitPasswordButton = React.createRef();
    this.submitPassword = this.submitPassword.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.removeErrorMessage = this.removeErrorMessage.bind(this);
    this.shouldRedirect = this.shouldRedirect.bind(this);
    this.forgotPasswordClick = this.forgotPasswordClick.bind(this);
    this.getShortMaskedPhoneNumber = this.getShortMaskedPhoneNumber.bind(this);
    this.usernameClick = this.usernameClick.bind(this);
    this.passwordOnChange = this.passwordOnChange.bind(this);
    this.togglePasswordShow = this.togglePasswordShow.bind(this);

    captchaSettings.set({
      captchaEndpoint: 'simple-captcha-endpoint.ashx'
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loginError === errorCodes.invalidPassword
      || this.props.loginError == errorCodes.enterPasswordInvalidCaptcha
      || this.props.loginError == errorCodes.enterPasswordBruteForceAttempt
      || this.props.loginError == errorCodes.changePasswordInvalidCaptcha
      || this.props.loginError == errorCodes.changePasswordBruteForceAttempt) {
      document.getElementById('password').value = '';
    }

    if (prevState && prevState.password == this.state.password && prevProps && prevProps.passwordCaptcha) {
      this.captcha && this.captcha.reloadImage();
      setTimeout(function () {
        var captchaInput = document.getElementById('btTichetCaptchaInputCode');
        if (captchaInput) {
          captchaInput.disabled = false;
        }
      }, 500);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loginError) {
      this.setState({
        password: '',
        passwordVisible: false
      });
    }
  }

  removeErrorMessage() {
    if (this.props.loginError) {
      this.props.setLoginError('');
    }
  }

  passwordOnChange(event) {
    this.removeErrorMessage();
    this.setState({ password: event && event.target.value });

    if (!event.target.value) {
      this.setState({ passwordVisible: false });
    }
  }

  togglePasswordShow() {
    const pwdVisible = this.state.passwordVisible;
    this.setState({ passwordVisible: !pwdVisible });
  }

  submitPassword() {
    const captchaCode = this.captcha && this.captcha.getUserEnteredCaptchaCode(),
      captchaId = this.captcha && this.captcha.getCaptchaId();
    if (this.state.password) {
      this.submitPasswordButton.current.classList.add('loading', 'disabled');
      if (this.props.isChangePassword) {
        this.props.verifyPasswordRequest({
          password: this.state.password,
          captchaCode: captchaCode,
          captchaId: captchaId
        });
      }
      else {
        this.props.submitPasswordRequest({
          password: this.state.password,
          captchaCode: captchaCode,
          captchaId: captchaId
        });
      }
    }
    else {
      this.props.setLoginError(errorCodes.fieldRequired);
    }
  }

  forgotPasswordClick() {
    this.props.saveUserInformation({ isForgotPassword: true });
    this.props.push(routes.accountForgotPassword);
    this.props.setLoginError('');
  }

  handleKeyUp(event) {
    const code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      this.submitPassword();
    }
  }

  shouldRedirect() {
    return !this.props.username;
  }

  getShortMaskedPhoneNumber() {
    return this.props.maskedPhoneNumber
      ? this.props.maskedPhoneNumber.slice(-3)
      : null;
  }

  usernameClick() {
    if (this.props.isForgotPassword) {
      this.props.saveUserInformation({ isForgotPassword: false });
    }
    else {
      if (this.props.isChangePassword) return;
    }
    this.props.push(routes.accountLogin);
  }

  componentDidMount() {
    this.removeErrorMessage();
    this.setState({ isMounted: true });
  }

  render() {
    if (this.shouldRedirect()) {
      return <Redirect to={routes.accountLogin} />;
    }

    return (
      <div className="login-form">
        <div className="password-view">
          {
            this.props.isForgotPassword &&
            <h1>Resetare parola</h1>
          }
          {
            this.props.isChangePassword &&
            <h1>Schimbare parola</h1>
          }
          {
            !this.props.isForgotPassword && !this.props.isChangePassword &&
            <h1>Bine ai venit!</h1>
          }
          <Row noGutters className="justify-content-sm-center">
            <div className={this.props.isChangePassword ? 'username-display readonly' : 'username-display'} onClick={() => this.usernameClick()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path fill="#181818" d="M18.2 7.449c0 2.732-1.511 5.068-3.924 6.008 1.433.208 3.102.858 5.008 1.948A5.378 5.378 0 0 1 22 20.073l-.001.926c0 1.105-.903 2-2.016 2H4.017A2.008 2.008 0 0 1 2.001 21L2 20.073a5.378 5.378 0 0 1 2.716-4.668c1.906-1.09 3.575-1.74 5.008-1.948C7.311 12.517 5.8 10.182 5.8 7.45c0-3.533 2.662-6.402 6.2-6.448 3.538.045 6.2 2.915 6.2 6.448zM12 15.5c4.724 0 7.356 1.439 7.896 4.316A1 1 0 0 1 18.913 21H5.087a1 1 0 0 1-.983-1.184C4.644 16.939 7.276 15.5 12 15.5zm.05-3.5c2.485 0 4.25-2.015 4.25-4.5S14.535 3 12.05 3C9.565 3 7.8 5.015 7.8 7.5S9.565 12 12.05 12z" />
                </g>
              </svg>
              {this.props.username}
            </div>
          </Row>
          {
            this.props.isForgotPassword &&
            <Row noGutters className="forgot-password-details">
              <span className="small-font">
                Introdu parola temporara primita prin SMS.
              </span>
            </Row>
          }
          {
            this.props.isChangePassword &&
            <Row noGutters className="forgot-password-details">
              <span className="small-font">
                Introdu parola curenta.
              </span>
            </Row>
          }
          <div className="form-group-label">
            <input
              id="password"
              className="form-control"
              type={this.state.passwordVisible ? 'text' : 'password'}
              placeholder="Parola"
              autoFocus
              onKeyUp={this.handleKeyUp}
              onChange={(event) => { this.passwordOnChange(event) }} />
            <label className="form-label" htmlFor="password">Parola</label>
            {
              this.state.passwordVisible
                ? <svg onClick={() => this.togglePasswordShow()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path fill={this.state.password ? '#181818' : '#c0c0c0'} d="M12 5c2.481 0 5.086 1.111 7.62 3.157C21.401 9.597 23 11.418 23 12.09c0 .67-1.598 2.491-3.38 3.932-2.534 2.045-5.139 3.157-7.62 3.157s-5.086-1.112-7.62-3.157C2.599 14.58 1 12.76 1 12.089c0-.67 1.598-2.492 3.38-3.932C6.915 6.111 9.52 5 12 5zm0 1.467c-2.115 0-4.423.994-6.698 2.831a17.798 17.798 0 0 0-2.172 2.074c-.235.27-.435.52-.571.717.136.197.336.447.571.717.61.697 1.37 1.425 2.172 2.073 2.275 1.838 4.583 2.832 6.698 2.832s4.423-.994 6.698-2.832a17.798 17.798 0 0 0 2.172-2.073c.188-.216.353-.42.482-.593l.09-.124-.09-.124a9.789 9.789 0 0 0-.482-.593 17.798 17.798 0 0 0-2.172-2.074C16.423 7.461 14.115 6.467 12 6.467zm0 1.466a4.156 4.156 0 1 1 0 8.311 4.156 4.156 0 0 1 0-8.31zM12 9.4a2.689 2.689 0 1 0 0 5.378A2.689 2.689 0 0 0 12 9.4z" />
                  </g>
                </svg>
                : <svg onClick={() => this.togglePasswordShow()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path fill={this.state.password ? '#181818' : '#c0c0c0'} d="M19.62 8.565C21.401 10.038 23 11.9 23 12.585c0 .687-1.598 2.549-3.38 4.022-2.534 2.092-5.139 3.229-7.62 3.229-1.51 0-3.065-.421-4.622-1.222l1.1-1.126c1.206.557 2.391.848 3.522.848 2.115 0 4.423-1.017 6.698-2.896a17.979 17.979 0 0 0 2.172-2.121c.188-.22.353-.429.482-.606l.09-.127-.09-.127a9.972 9.972 0 0 0-.482-.606A17.979 17.979 0 0 0 17.166 8.6l1.06-1.085c.466.318.93.668 1.393 1.05zM12 5.335c1.15 0 2.327.245 3.512.716L14.36 7.228A7.71 7.71 0 0 0 12 6.836c-2.115 0-4.423 1.017-6.698 2.896a17.979 17.979 0 0 0-2.172 2.12 8.892 8.892 0 0 0-.571.734c.136.201.336.457.571.733a17.979 17.979 0 0 0 2.752 2.58l-1.046 1.073c-.152-.119-.304-.24-.455-.365C2.598 15.134 1 13.272 1 12.586c0-.686 1.598-2.548 3.38-4.021C6.915 6.472 9.52 5.335 12 5.335zm4.156 7.25c0 2.348-1.86 4.25-4.156 4.25a4.07 4.07 0 0 1-2.235-.666l1.078-1.1c.35.171.743.267 1.157.267 1.485 0 2.689-1.231 2.689-2.75 0-.424-.094-.826-.261-1.184l1.076-1.102a4.3 4.3 0 0 1 .652 2.286zM12 8.336c.39 0 .766.056 1.124.158l-1.32 1.35c-1.329.098-2.39 1.183-2.486 2.541l-1.32 1.35c-.1-.365-.154-.75-.154-1.148 0-2.347 1.861-4.25 4.156-4.25zm7.553-4.115a.742.742 0 0 1-.012 1.049L4.691 20.118a.742.742 0 0 1-1.048.012.742.742 0 0 1 .012-1.05L18.504 4.232a.742.742 0 0 1 1.049-.011z" />
                  </g>
                </svg>
            }
          </div>
          <p className={
            this.props.loginError === errorCodes.fieldRequired
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Acest camp este obligatoriu.</p>
          <p className={
            this.props.loginError === errorCodes.invalidPassword
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Parola incorecta.</p>
          <p className={
            this.props.loginError && this.props.loginError.includes(errorCodes.invalidCaptcha)
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Codul captcha introdus este incorect.</p>
          <p className={
            this.props.passwordCaptcha
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Introduceti codul din imagine.</p>
          {
            this.props.passwordCaptcha &&
            <Row noGutters className="captcha">
              <Captcha
                captchaStyleName="btTichetCaptcha"
                ref={(captcha) => { this.captcha = captcha }} />
              <input
                id="btTichetCaptchaInputCode"
                className="form-control"
                type="text"
                tab-index="10"
                onKeyUp={this.handleKeyUp}
                placeholder="Cod Captcha" />
            </Row>
          }
          <Row noGutters className="button-container">
            {
              !this.props.isForgotPassword &&
              !this.props.isChangePassword &&
              <Col className="forgot-password-link"><label className="form-label" onClick={() => this.forgotPasswordClick()}>Mi-am uitat parola</label></Col>
            }
            <Col className="main-button-container">
              <button className='main-button' onClick={this.submitPassword} ref={this.submitPasswordButton} id="submitPasswordButton">
                <span className="spinner-border spinner-border-sm"></span>
                INAINTE
              </button>
            </Col>
          </Row>
        </div >
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  verifyPasswordRequest,
  push,
  setLoginError
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordView);

