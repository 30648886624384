import { Captcha, captchaSettings } from 'reactjs-captcha';
import errorCodes from 'utils/errorCodes';
import React from 'react';
import { Redirect } from 'react-router';
import routes from 'utils/routes';
import Row from 'react-bootstrap/Row';

class ForgotPasswordView extends React.Component {
  constructor(props) {
    super(props);

    this.submitPhoneNumberButton = React.createRef();
    this.getShortMaskedPhoneNumber = this.getShortMaskedPhoneNumber.bind(this);
    this.submitPhoneNumber = this.submitPhoneNumber.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.shouldRedirect = this.shouldRedirect.bind(this);
    this.phoneNumberOnChange = this.phoneNumberOnChange.bind(this);
    this.usernameClick = this.usernameClick.bind(this);

    captchaSettings.set({
      captchaEndpoint: 'simple-captcha-endpoint.ashx'
    });

    this.state = {
      phoneNumber: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.phoneNumber == this.state.phoneNumber && prevProps && prevProps.phoneNumberCaptcha) {
      this.captcha && this.captcha.reloadImage();
      setTimeout(function() { 
        var captchaInput = document.getElementById('btTichetCaptchaInputCode');
        if (captchaInput) {
          captchaInput.disabled = false;
        }
      }, 500);
    }
  }

  getShortMaskedPhoneNumber() {
    return this.props.maskedPhoneNumber
      ? this.props.maskedPhoneNumber.slice(-3)
      : null;
  }

  submitPhoneNumber() {
    const captchaCode = this.captcha && this.captcha.getUserEnteredCaptchaCode(),
      captchaId = this.captcha && this.captcha.getCaptchaId();

    if (this.state.phoneNumber) {
      this.props.submitPhoneNumberRequest({
        phoneNumber: this.state.phoneNumber,
        captchaCode: captchaCode,
        captchaId: captchaId
      });
      this.submitPhoneNumberButton.current.classList.add('loading', 'disabled');
    } else {
      this.props.setLoginError(errorCodes.fieldRequired);
    }
  }

  phoneNumberOnChange(event) {
    if (this.props.loginError) {
      this.props.setLoginError('');
    }
    this.setState({
      phoneNumber: event && event.target.value,
    });
  }

  handleKeyUp(event) {
    const code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      this.submitPhoneNumber();
    }
  }

  shouldRedirect() {
    return !this.props.username;
  }

  usernameClick() {
    if (this.props.isForgotPassword) {
      this.props.saveUserInformation({ isForgotPassword: false });
    }
    this.props.push(routes.accountLogin);
  }

  componentDidMount() {
    this.props.forgotPasswordRequest();
  }

  render() {
    if (this.shouldRedirect()) {
      this.props.saveUserInformation({ isForgotPassword: false });
      return <Redirect to={routes.accountLogin} />;
    }

    return (
      <div className="login-form">
        <div className="password-view">
          <h1>Resetare Parola</h1>

          <Row noGutters className="justify-content-sm-center">
            <div className="username-display" onClick={() => this.usernameClick()}>
              <i className="material-icons">person_outline</i>
              {this.props.username}
            </div>
          </Row>

          {
            this.props.isForgotPassword &&
            <Row noGutters className="forgot-password-details">
              <span className="small-font">
                Completeaza intregul numarul de telefon terminat in ***{this.getShortMaskedPhoneNumber()}, la care se va trimite SMS-ul cu parola temporara.
          </span>
            </Row>
          }
          <div className="form-group-label phone-number-input">
            <input
              id="phoneNumber"
              className="form-control"
              type="text"
              placeholder="Telefon"
              autoFocus
              onKeyUp={this.handleKeyUp}
              onChange={(event) => { this.phoneNumberOnChange(event) }} />
            <label className="form-label" htmlFor="phoneNumber">Telefon</label>
          </div>
          <p className={
            this.props.loginError === errorCodes.fieldRequired
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Acest camp este obligatoriu.</p>
          <p className={
            this.props.loginError === errorCodes.invalidPhoneNumber
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Numar de telefon incorect.</p>
          <p className={
            this.props.loginError && this.props.loginError.includes('Urmatoarea resetare')
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>{this.props.loginError}</p>
          <p className={
            this.props.loginError && this.props.loginError.includes(errorCodes.invalidCaptcha)
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Codul captcha introdus este incorect.</p>
          <p className={
            this.props.phoneNumberCaptcha
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Introduceti codul din imagine.</p>
          {
            this.props.phoneNumberCaptcha &&
            <Row noGutters className="captcha">
              <Captcha
                captchaStyleName="btTichetCaptcha"
                ref={(captcha) => { this.captcha = captcha }} />
              <input
                id="btTichetCaptchaInputCode"
                className="form-control"
                type="text"
                tab-index="10"
                onKeyUp={this.handleKeyUp}
                placeholder="Cod Captcha" />
            </Row>
          }
          <Row noGutters className="button-container">
            <button className='main-button' onClick={this.submitPhoneNumber} ref={this.submitPhoneNumberButton} id="submitPhoneNumberButton">
              <span className="spinner-border spinner-border-sm"></span>
              TRIMITE SMS
            </button>
          </Row>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordView;
