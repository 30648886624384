import { BranchUsersBadge, ConfirmationModal } from 'components';
import { Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { disableBranch, enableBranch, setCurrentBranch, showModalForEdit } from 'actions/branch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import React from 'react';
import routes from 'utils/routes';
import { showLoadingSpinner } from 'actions/loadingSpinner';
import { userRole } from 'utils/enums';

class BranchCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      itemActions: [
        { title: 'Modifica', clickHandler: this.editBranch },
        { title: 'Dezactiveaza', clickHandler: this.disableBranch }
      ],
      showConfirmationModal: false,
      requestActionForBranchId: null,
      confirmationModalTitle: null,
      confirmationModalQuestion: null,
      confirmationModalConfirmHandler: null
    };

    this.navigateToCards = this.navigateToCards.bind(this);
    this.editBranch = this.editBranch.bind(this);
    this.disableBranch = this.disableBranch.bind(this);
    this.dropdownItemOnClickHandler = this.dropdownItemOnClickHandler.bind(this);
    this.confirmBranchDisable = this.confirmBranchDisable.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
  }

  setBranch() {
    const currentBranch = this.props.branches && this.props.branches.filter(x => x.id == this.props.id)[0];
    this.props.setCurrentBranch(currentBranch);
  }

  navigateToCards() {
    this.setBranch();
    this.props.push(routes.dashboardCards);
  }

  dropdownItemOnClickHandler(event, action, itemId) {
    let value = (event.currentTarget && event.currentTarget.text);
    if (value === action.title)
      action.clickHandler(itemId);
  }

  editBranch(context) {
    const currentBranch = context.props.branches.filter(x => x.id == context.props.id)[0];
    context.props.setCurrentBranch(currentBranch);
    context.props.showModalForEdit();
  }

  disableBranch(context) {
    const currentBranch = context.props.branches.filter(x => x.id == context.props.id)[0];
    context.props.setCurrentBranch(currentBranch);

    context.setState({
      showConfirmationModal: true,
      requestActionForBranchId: currentBranch.id,
      confirmationModalTitle: `Dezactiveaza ${currentBranch.name}`,
      confirmationModalQuestion: 'Esti sigur ca vrei sa dezactivezi acest punct de lucru? Il poti reactiva oricand doresti din sectiunea "Puncte de lucru".',
      confirmationModalConfirmHandler: context.confirmBranchDisable
    });
  }

  confirmBranchDisable() {
    this.props.disableBranch(this.state.requestActionForBranchId);
    this.closeConfirmationModal();
    this.props.showLoadingSpinner();
  }

  closeConfirmationModal() {
    this.setState({
      showConfirmationModal: false,
      requestActionForBranchId: null,
      confirmationModalTitle: null,
      confirmationModalQuestion: null,
      confirmationModalConfirmHandler: null
    });
  }

  render() {
    const currentBranch = this.props.branches && this.props.branches.filter(x => x.id == this.props.id)[0],
      itemActionsButton = <svg width="20" height="20" viewBox="0 0 24 24">
        <path id="a" d="M12 9c.81 0 1.512.298 2.107.893.595.595.893 1.297.893 2.107 0 .81-.298 1.512-.893 2.107-.595.595-1.297.893-2.107.893-.81 0-1.512-.298-2.107-.893C9.298 13.512 9 12.81 9 12c0-.81.298-1.512.893-2.107C10.488 9.298 11.19 9 12 9zm8 0c.81 0 1.512.298 2.107.893.595.595.893 1.297.893 2.107 0 .81-.298 1.512-.893 2.107-.595.595-1.297.893-2.107.893-.81 0-1.512-.298-2.107-.893C17.298 13.512 17 12.81 17 12c0-.81.298-1.512.893-2.107C18.488 9.298 19.19 9 20 9zM4 9c.81 0 1.512.298 2.107.893C6.702 10.488 7 11.19 7 12c0 .81-.298 1.512-.893 2.107C5.512 14.702 4.81 15 4 15c-.81 0-1.512-.298-2.107-.893C1.298 13.512 1 12.81 1 12c0-.81.298-1.512.893-2.107C2.488 9.298 3.19 9 4 9z" />
      </svg>;

    return (
      <>
        <div className="branch-card">
          <div>
            <Row noGutters className="branch-name-container">
              <Col sm="auto">
                <svg className="location-sign" width="24" height="24" viewBox="0 0 24 24">
                  <path id="a" d="M11.984 13.1c.592 0 1.141-.149 1.649-.446a3.341 3.341 0 0 0 1.204-1.196c.296-.5.444-1.047.444-1.642s-.148-1.148-.444-1.658a3.303 3.303 0 0 0-1.204-1.212 3.201 3.201 0 0 0-1.649-.446c-.592 0-1.136.149-1.633.446a3.342 3.342 0 0 0-1.188 1.212 3.248 3.248 0 0 0-.444 1.658c0 .914.317 1.69.95 2.327.635.638 1.406.957 2.315.957zM12 1c1.604 0 3.077.344 4.42 1.031 1.323.688 2.376 1.613 3.157 2.777a6.898 6.898 0 0 1 1.173 3.887c0 1.075-.3 2.309-.902 3.702-.521 1.18-1.243 2.459-2.165 3.834a50.723 50.723 0 0 1-2.616 3.41 42.432 42.432 0 0 1-2.165 2.434L12 23l-.902-.925a42.432 42.432 0 0 1-2.165-2.433 50.723 50.723 0 0 1-2.616-3.411c-.922-1.375-1.644-2.653-2.165-3.834-.601-1.393-.902-2.627-.902-3.702 0-1.41.39-2.706 1.173-3.887.781-1.164 1.834-2.09 3.157-2.777C8.923 1.344 10.396 1 12 1z" />
                </svg>
              </Col>
              <Col className="branch-name">
                {this.props.name}
              </Col>
              {
                this.props.userRoleId === userRole.ADMIN &&
                <Col sm="auto" className="branch-edit">
                  <DropdownButton alignRight size='sm' variant='secondary' drop='left' title={itemActionsButton}
                    className='item-actions-dropdown'>
                    {
                      this.state.itemActions.map(action => {
                        return <Dropdown.Item
                          key={`${action.title}`}
                          onClick={(event) => this.dropdownItemOnClickHandler(event, action, this)}>{action.title}
                        </Dropdown.Item>
                      })
                    }
                  </DropdownButton>
                </Col>
              }
            </Row>
            <p className="branch-address">{this.props.address}</p>
          </div>
          {(!this.props.contactPersonName || !this.props.contactPersonPhoneNumber) &&
            <div>
              <p className="cp-warning"><i>Pentru a plasa o comanda, completati datele persoanei de contact pe acest punct de lucru.</i></p>
            </div>}
          <div>
            <BranchUsersBadge
              branchUserIds={currentBranch.userIds}
              branchId={this.props.id}
              branchName={this.props.name}
              users={this.props.users}
              userRoleId={this.props.userRoleId}
              upperCaseTitle={true}
            />
            <hr />
            <div onClick={() => this.navigateToCards()}>
              <Row noGutters className="cards">
                <Col className="cards-description">
                  <svg className='card-sign' width="20" height="20" viewBox="0 0 24 24">
                    <path d="M21.02 6.967c1.013 0 1.833.843 1.833 1.882v10.165c0 1.04-.82 1.882-1.833 1.882H4.75c-1.013 0-1.834-.842-1.834-1.882v-.085h-.138c-.471 0-.924-.192-1.257-.534A1.85 1.85 0 0 1 1 17.104V6.826C1 5.817 1.796 5 2.778 5h16.39c.979.005 1.77.821 1.77 1.826v.141h.082zM1.917 8.275v1.94H20.03v-1.94H1.917zm.861-2.334a.873.873 0 0 0-.861.885v.508H20.03v-.508a.873.873 0 0 0-.862-.885H2.778zm-.861 11.163c0 .488.385.884.861.884h16.39a.873.873 0 0 0 .862-.884v-5.949H1.917v5.949zm20.02 1.882l.009-10.137a.93.93 0 0 0-.917-.94h-.082v9.195c0 .484-.188.948-.521 1.29a1.755 1.755 0 0 1-1.258.535H3.833v.057c0 .52.41.941.916.941H21.02a.93.93 0 0 0 .917-.941zm-7.086-2.202a1.657 1.657 0 0 1-1.626-1.267 1.711 1.711 0 0 1 .817-1.92 1.62 1.62 0 0 1 2.01.363 1.629 1.629 0 0 1 1.82-.417 1.704 1.704 0 0 1 1.047 1.584c0 .699-.415 1.326-1.047 1.584a1.629 1.629 0 0 1-1.82-.417c-.32.324-.753.5-1.201.49zm2.392-2.457a.753.753 0 0 0-.742.762c0 .421.332.763.742.763s.743-.342.743-.763a.753.753 0 0 0-.743-.762zm-2.392 0a.753.753 0 0 0-.743.762c0 .421.333.763.743.763s.742-.342.742-.763a.753.753 0 0 0-.742-.762z" />
                  </svg>
                  <span>CARDURI</span>
                </Col>
                <Col sm="auto" className="cards-go-to">
                  <svg className="chevron-right" width="20" height="20" viewBox="0 0 24 24">
                    <path id="a" d="M7.957 6.184L13.214 12l-5.257 5.816a1.584 1.584 0 0 0-.002 2.122 1.284 1.284 0 0 0 1.91 0l6.534-7.27a1 1 0 0 0 0-1.337L9.864 4.062a1.284 1.284 0 0 0-1.91 0 1.584 1.584 0 0 0 .003 2.122z" />
                  </svg>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {
          !this.props.isActive &&
          <div className="branch-card-inactive">
            {
              this.props.userRoleId === userRole.ADMIN &&
              <DropdownButton alignRight size='sm' variant='secondary' drop='left' title={itemActionsButton} className='branch-enable item-actions-dropdown'>
                <Dropdown.Item
                  key='Activeaza'
                  onClick={() => this.props.enableBranch({ branchId: this.props.id, branchName: this.props.name })}>Activeaza
                </Dropdown.Item>
              </DropdownButton>
            }
          </div>
        }
        {
          this.state.showConfirmationModal &&
          <ConfirmationModal
            title={this.state.confirmationModalTitle}
            confirmationQuestion={this.state.confirmationModalQuestion}
            confirm={this.state.confirmationModalConfirmHandler}
            cancel={this.closeConfirmationModal}
          />
        }
      </>
    );
  }
}

const mapStateToProps = state => { return { userRoleId: state.user.roleId, ...state.branch, ...state.router } };
const mapDispatchToProps = dispatch => bindActionCreators({
  setCurrentBranch,
  push,
  showLoadingSpinner,
  showModalForEdit,
  disableBranch,
  enableBranch
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BranchCard);
