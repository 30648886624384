import React from 'react';

class UploadFileMealTicketHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="upload-status">
                        <div className={`step-indicator ${this.props.location.pathname === this.props.expectedUploadUrl ? 'active' : 'hidden'}`}>1</div>
                        <div className={`step-indicator icon ${this.props.location.pathname === this.props.expectedUploadUrl ? 'hidden' : 'active'}`}>
                            <svg width="16" height="16" viewBox="0 0 24 24">
                                <path id="a" d="M19.785 4.383a1.247 1.247 0 0 1 1.78-.019l.074.076a1.29 1.29 0 0 1 0 1.79L8.292 20h-.155L2.36 14.042a1.29 1.29 0 0 1-.007-1.784l.042-.045a1.247 1.247 0 0 1 1.78-.032l4.039 4.166 11.57-11.964z" />
                            </svg>
                        </div>
                    </div>
                    <div className={this.props.location.pathname === this.props.expectedUploadUrl ? 'active' : 'inactive'}>INCARCA DATE</div>
                </div>
                <hr className="connector" />
                <div className="container container-validation">
                    <div className="validation-status">
                        <div className={`step-indicator ${this.props.location.pathname !== this.props.expectedUploadUrl ? 'active' : ''}`}>2</div>
                    </div>
                    <div className={this.props.location.pathname !== this.props.expectedUploadUrl ? 'active' : 'inactive'}>VERIFICARE DATE</div>
                </div>
            </>
        );
    }
}

export default UploadFileMealTicketHeader
