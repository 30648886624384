import { ConfirmationModal } from 'components';
import { Prompt } from 'react-router-dom';
import React from 'react';

export class RouteLeavingGuard extends React.Component {
  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleBlockedNavigation = this.handleBlockedNavigation.bind(this);
    this.handleConfirmNavigationClick = this.handleConfirmNavigationClick.bind(this);

    this.state = {
      modalVisible: false,
      nextLocation: null,
      confirmedNavigation: false
    };
  }

  showModal(location) {
    this.setState({
      modalVisible: true,
      nextLocation: location,
    })
  }

  closeModal() {
    this.setState({ modalVisible: false });
    setTimeout(() => { document.activeElement.blur(); }, 50);
  }

  handleBlockedNavigation(nextLocation) {
    if (!this.state.confirmedNavigation && this.props.shouldBlockNavigation) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  }

  handleConfirmNavigationClick() {
    this.closeModal();

    const { nextLocation } = this.state;
    if (nextLocation) {
      this.setState({
        confirmedNavigation: true
      }, () => {
        this.props.navigate(nextLocation.pathname);
      })
    }
  }

  render() {
    return (
      <>
        <Prompt when={this.props.when} message={this.handleBlockedNavigation} />
        {
          this.state.modalVisible &&
          <ConfirmationModal
            title={this.props.title}
            confirmationQuestion={this.props.confirmationQuestion}
            confirm={this.handleConfirmNavigationClick}
            cancel={this.closeModal}
          />
        }
      </>
    )
  }
}

export default RouteLeavingGuard;
