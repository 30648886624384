export const CARD_FIND_CARDS_REQUEST = 'CARD_FIND_CARDS_REQUEST';
export const CARD_FIND_CARDS_FAILURE = 'CARD_FIND_CARDS_FAILURE';
export const CARD_FIND_CARDS_SUCCESS = 'CARD_FIND_CARDS_SUCCESS';

export const CARD_ADD_ITEM_TO_SELECTION = 'CARD_ADD_ITEM_TO_SELECTION';
export const CARD_REMOVE_ITEM_FROM_SELECTION = 'CARD_REMOVE_ITEM_FROM_SELECTION';
export const CARD_SELECT_ALL = 'CARD_SELECT_ALL';
export const CARD_FIND_MINIMAL_CARDS_REQUEST = 'CARD_FIND_MINIMAL_CARDS_REQUEST';
export const CARD_FIND_MINIMAL_CARDS_SUCCESS = 'CARD_FIND_MINIMAL_CARDS_SUCCESS';
export const CARD_FIND_MINIMAL_CARDS_FAILURE = 'CARD_FIND_MINIMAL_CARDS_FAILURE';
export const CARD_CLEAR_SELECTION = 'CARD_CLEAR_SELECTION';
export const SET_SELECTED_CARDS = 'SET_SELECTED_CARDS';

export const CARD_SET_CARDS = 'CARD_SET_CARDS';
export const CARD_SET_CURRENT_PAGE = 'CARD_SET_CURRENT_PAGE';
export const CARD_SET_PAGE_COUNT = 'CARD_SET_PAGE_COUNT';
export const CARD_SET_PAGE_SIZE = 'CARD_SET_PAGE_SIZE';
export const CARD_SET_TOTAL_COUNT = 'CARD_SET_TOTAL_COUNT';

export const REISSUE_PIN_REQUEST = 'REISSUE_PIN_REQUEST';
export const REISSUE_PIN_FAILURE = 'REISSUE_PIN_FAILURE';
export const REISSUE_PIN_SUCCESS = 'REISSUE_PIN_SUCCESS';
export const REISSUE_PIN_SHOW_MODAL = 'REISSUE_PIN_SHOW_MODAL';
export const REISSUE_PIN_HIDE_MODAL = 'REISSUE_PIN_HIDE_MODAL';
export const REISSUE_PIN_SET_MODAL_ERROR = 'REISSUE_PIN_SET_MODAL_ERROR';

export const REISSUE_CARD_REQUEST = 'REISSUE_CARD_REQUEST';
export const REISSUE_CARD_FAILURE = 'REISSUE_CARD_FAILURE';
export const REISSUE_CARD_SUCCESS = 'REISSUE_CARD_SUCCESS';
export const REISSUE_CARD_SHOW_MODAL = 'REISSUE_CARD_SHOW_MODAL';
export const REISSUE_CARD_HIDE_MODAL = 'REISSUE_CARD_HIDE_MODAL';
export const REISSUE_CARD_SET_MODAL_ERROR = 'REISSUE_CARD_SET_MODAL_ERROR';

export const CARD_DELETE_REQUEST = 'CARD_DELETE_REQUEST';
export const CARD_DELETE_FAILURE = 'CARD_DELETE_FAILURE';
export const CARD_DELETE_SUCCESS = 'CARD_DELETE_SUCCESS';

export const MIGRATE_CARDS_REQUEST = 'MIGRATE_CARDS_REQUEST';
export const MIGRATE_CARDS_FAILURE = 'MIGRATE_CARDS_FAILURE';
export const MIGRATE_CARDS_SUCCESS = 'MIGRATE_CARDS_SUCCESS';
export const MIGRATE_CARDS_SHOW_MODAL = 'MIGRATE_CARDS_SHOW_MODAL';
export const MIGRATE_CARDS_HIDE_MODAL = 'MIGRATE_CARDS_HIDE_MODAL';

export const CHANGE_TICKET_VALUE_REQUEST = 'CHANGE_TICKET_VALUE_REQUEST';
export const CHANGE_TICKET_VALUE_FAILURE = 'CHANGE_TICKET_VALUE_FAILURE';
export const CHANGE_TICKET_VALUE_SUCCESS = 'CHANGE_TICKET_VALUE_SUCCESS';
export const CHANGE_TICKET_VALUE_SHOW_MODAL = 'CHANGE_TICKET_VALUE_SHOW_MODAL';
export const CHANGE_TICKET_VALUE_HIDE_MODAL = 'CHANGE_TICKET_VALUE_HIDE_MODAL';

export const CARD_CONFIRMATION_SHOW_MODAL = 'CARD_CONFIRMATION_SHOW_MODAL';
export const CARD_CONFIRMATION_HIDE_MODAL = 'CARD_CONFIRMATION_HIDE_MODAL';

export const CARD_REISSUE_SHOW_CONFIRMATION_MODAL = 'CARD_REISSUE_SHOW_CONFIRMATION_MODAL';
export const CARD_REISSUE_HIDE_CONFIRMATION_MODAL = 'CARD_REISSUE_HIDE_CONFIRMATION_MODAL';

export const CARD_CONFIRM_CARDS_REQUEST = 'CARD_CONFIRM_CARDS_REQUEST';
export const CARD_CONFIRM_CARDS_FAILURE = 'CARD_CONFIRM_CARDS_FAILURE';
export const CARD_CONFIRM_CARDS_SUCCESS = 'CARD_CONFIRM_CARDS_SUCCESS';

export const CARD_REISSUE_CARDS_REQUEST = 'CARD_REISSUE_CARDS_REQUEST';
export const CARD_REISSUE_CARDS_FAILURE = 'CARD_REISSUE_CARDS_FAILURE';
export const CARD_REISSUE_CARDS_SUCCESS = 'CARD_REISSUE_CARDS_SUCCESS';

export const SET_CARDS_LOADED_FLAG = 'SET_CARDS_LOADED_FLAG';
export const CARD_SET_CARD_FILTERS = 'CARD_SET_CARD_FILTERS';

export const CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS = 'CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS';

export const findCardsRequest = currentBranch => ({ type: CARD_FIND_CARDS_REQUEST, payload: currentBranch });
export const findCardsFailure = errorMessage => ({ type: CARD_FIND_CARDS_FAILURE, payload: { errorMessage: errorMessage } });
export const findCardsSuccess = (cards, totalCount) => ({ type: CARD_FIND_CARDS_SUCCESS, payload: { cards: cards, totalCount: totalCount } });

export const setCards = cards => ({ type: CARD_SET_CARDS, payload: cards });
export const setCardsCurrentPage = pageNumber => ({ type: CARD_SET_CURRENT_PAGE, payload: pageNumber });
export const setCardsPageCount = pageCount => ({ type: CARD_SET_PAGE_COUNT, payload: pageCount });
export const setCardsPageSize = pageSize => ({ type: CARD_SET_PAGE_SIZE, payload: pageSize });
export const setCardsTotalCount = totalCount => ({ type: CARD_SET_TOTAL_COUNT, payload: totalCount });

export const clearSelection = () => ({ type: CARD_CLEAR_SELECTION });
export const selectAllCards = () => ({ type: CARD_FIND_MINIMAL_CARDS_REQUEST });
export const findMinimalCardsSuccess = cards => ({ type: CARD_FIND_MINIMAL_CARDS_SUCCESS, payload: cards })
export const findMinimalCardsFailure = errorMessage => ({ type: CARD_FIND_MINIMAL_CARDS_FAILURE, payload: errorMessage })

export const addCardToSelection = card => ({ type: CARD_ADD_ITEM_TO_SELECTION, payload: card });
export const removeCardFromSelection = card => ({ type: CARD_REMOVE_ITEM_FROM_SELECTION, payload: card });
export const setSelectedCards = (cards, selectionType) => ({ type: SET_SELECTED_CARDS, payload: { cards, selectionType } });

export const reissuePin = pinReissueModel => ({ type: REISSUE_PIN_REQUEST, payload: pinReissueModel });
export const reissuePinFailure = (status, errorMessage) => ({ type: REISSUE_PIN_FAILURE, payload: { status: status, errorMessage: errorMessage } });
export const reissuePinSuccess = (cardId, cardMigrated) => ({ type: REISSUE_PIN_SUCCESS, payload: { cardId, cardMigrated } });
export const showPinReissueModal = cardId => ({ type: REISSUE_PIN_SHOW_MODAL, payload: cardId });
export const hidePinReissueModal = () => ({ type: REISSUE_PIN_HIDE_MODAL });
export const setPinReissueModalError = error => ({ type: REISSUE_PIN_SET_MODAL_ERROR, payload: error });

export const reissueCard = cardReissueModel => ({ type: REISSUE_CARD_REQUEST, payload: cardReissueModel });
export const reissueCardFailure = (status, errorMessage) => ({ type: REISSUE_CARD_FAILURE, payload: { status: status, errorMessage: errorMessage } });
export const reissueCardSuccess = (cardId, cardMigrated) => ({ type: REISSUE_CARD_SUCCESS, payload: { cardId, cardMigrated } });
export const showCardReissueModal = cardId => ({ type: REISSUE_CARD_SHOW_MODAL, payload: cardId });
export const hideCardReissueModal = () => ({ type: REISSUE_CARD_HIDE_MODAL });
export const setCardReissueModalError = error => ({ type: REISSUE_CARD_SET_MODAL_ERROR, payload: error });

export const deleteCard = cardId => ({ type: CARD_DELETE_REQUEST, payload: cardId });
export const deleteCardFailure = errorMessage => ({ type: CARD_DELETE_FAILURE, payload: { errorMessage: errorMessage } });
export const deleteCardSuccess = (cardId, branchId) => ({ type: CARD_DELETE_SUCCESS, payload: { cardId: cardId, branchId: branchId } });

export const migrateCards = toBranchId => ({ type: MIGRATE_CARDS_REQUEST, payload: toBranchId });
export const migrateCardsFailure = errorMessage => ({ type: MIGRATE_CARDS_FAILURE, payload: { errorMessage: errorMessage } });
export const migrateCardsSuccess = (status, text, migratedCardsCount, remainingSelectedCardIds) => ({ type: MIGRATE_CARDS_SUCCESS, payload: { status, text, migratedCardsCount, remainingSelectedCardIds } });
export const showCardMigrationModal = () => ({ type: MIGRATE_CARDS_SHOW_MODAL });
export const hideCardMigrationModal = () => ({ type: MIGRATE_CARDS_HIDE_MODAL });

export const changeTicketValue = ticketValue => ({ type: CHANGE_TICKET_VALUE_REQUEST, payload: ticketValue });
export const changeTicketValueFailure = errorMessage => ({ type: CHANGE_TICKET_VALUE_FAILURE, payload: { errorMessage: errorMessage } });
export const changeTicketValueSuccess = (status, text) => ({ type: CHANGE_TICKET_VALUE_SUCCESS, payload: { status, text } });
export const showChangeTicketValueModal = () => ({ type: CHANGE_TICKET_VALUE_SHOW_MODAL });
export const hideChangeTicketValueModal = () => ({ type: CHANGE_TICKET_VALUE_HIDE_MODAL });

export const showModalForCardConfirmation = () => ({ type: CARD_CONFIRMATION_SHOW_MODAL });
export const hideModalForCardConfirmation = () => ({ type: CARD_CONFIRMATION_HIDE_MODAL });

export const showConfirmatiomModalForCardReissue = () => ({ type: CARD_REISSUE_SHOW_CONFIRMATION_MODAL });
export const hideConfirmationModalForCardReissue = () => ({ type: CARD_REISSUE_HIDE_CONFIRMATION_MODAL });

export const confirmCards = () => ({ type: CARD_CONFIRM_CARDS_REQUEST });
export const confirmCardsFailure = errorMessage => ({ type: CARD_CONFIRM_CARDS_FAILURE, payload: { errorMessage: errorMessage } });
export const confirmCardsSuccess = (status, text) => ({ type: CARD_CONFIRM_CARDS_SUCCESS, payload: { status, text } });

export const reissueCards = () => ({ type: CARD_REISSUE_CARDS_REQUEST });
export const reissueCardsFailure = errorMessage => ({ type: CARD_REISSUE_CARDS_FAILURE, payload: { errorMessage: errorMessage } });
export const reissueCardsSuccess = (status, text) => ({ type: CARD_REISSUE_CARDS_SUCCESS, payload: { status, text } });

export const setCardsLoadedFlag = loaded => ({ type: SET_CARDS_LOADED_FLAG, payload: loaded });
export const setCardFilters = filters => ({ type: CARD_SET_CARD_FILTERS, payload: filters });

export const setSendReissueCardInProgress = value => ({ type: CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS, payload: value });
