import './PageSelector.scss';
import React from 'react';
import ReactPaginate from 'react-paginate';

class PageSelector extends React.Component {
  constructor(props) {
    super(props);

    this.pageClickHandler = this.pageClickHandler.bind(this);
  }

  pageClickHandler(value) {
    this.props.pageSelectionCallback(value.selected + 1);
  }

  render() {
    return (
      <ReactPaginate
        forcePage={this.props.currentPage - 1}
        previousLabel={''}
        nextLabel={''}
        breakLabel={'...'}
        breakClassName={'custom-page-break'}
        pageLinkClassName={'custom-page-link'}
        previousLinkClassName={'custom-previous-link'}
        nextLinkClassName={'custom-next-link'}
        pageCount={this.props.pageCount}
        marginPagesDisplayed={3}
        pageRangeDisplayed={2}
        onPageChange={this.pageClickHandler}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    );
  }
}

export default PageSelector;
