import { applyMiddleware, combineReducers, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import middlewares from 'middlewares';
import reducers from 'reducers';
import thunk from 'redux-thunk';

export default function configureStore(history, initialState) {
  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });
  const loadedMiddlewares = [...middlewares, thunk, routerMiddleware(history)];

  return createStore(rootReducer, initialState, applyMiddleware(...loadedMiddlewares));
}
