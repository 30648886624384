import { AFTER_CURRENT_BRANCH_WAS_SET, hideModal } from 'actions/branch';

import {
  clearSelection,
  findCardsRequest,
  hideCardMigrationModal,
  hideCardReissueModal,
  hideChangeTicketValueModal,
  hidePinReissueModal,
  setCardFilters,
  setCardsCurrentPage,
  setCardsPageSize,
} from 'actions/card';
import { fileUploadType, userRole } from 'utils/enums';
import { findManagersRequest, managersHideModal, setManagersCurrentPage, setManagersPageSize, setManagersSearchTerm } from 'actions/manager';
import { findSapInvoices, setSapCurrentPage } from 'actions/sapInvoice';
import { loadAudit, setAuditFilter } from 'actions/audit';
import { LOCATION_CHANGE, push, replace } from 'connected-react-router';
import {
  saveUserInformation,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_GENERATE_SMS_TOKEN_FAILURE,
  USER_IS_FIRST_LOGIN,
  USER_SUBMIT_PASSWORD_SUCCESS,
  USER_SUBMIT_SMS_TOKEN_SUCCESS,
  USER_SUBMIT_USERNAME_SUCCESS,
  USER_VERIFY_PASSWORD_SUCCESS
} from 'actions/user';

import { UPLOAD_CSV_FILE_SUCCESS, UPLOAD_SET_VALIDATION_STATUS } from 'actions/upload';

import datetime from 'utils/datetime';
import { findBranchUsersRequest } from 'actions/branchUser';
import { findOrders } from 'actions/order';
import { isFirstLogin } from 'actions/user';

import routes from 'utils/routes';
import { showToast } from 'actions/toast';
import userStatus from 'utils/userStatus';

function isCurrentLocationCardUploadFile(currentLocation) {
  const isCurrentLocationOrdersUploadCorrection = currentLocation === routes.dashboardOrdersUploadCorrection;
  const isCurrentLocationOrdersUpload = currentLocation === routes.dashboardOrdersUpload;
  const isCurrentLocationDeleteUpload = currentLocation === routes.dashboardCardsDeleteBulk;
  const isCurrentLocationDeleteUploadCorrection = currentLocation === routes.dashboardCardsDeleteBulkCorrection;
  const isCurrentLocationModifyTicketValueBulk = currentLocation === routes.dashboardCardsModifyTicketValueBulk;
  const isCurrentLocationModifyTicketValueBulkCorrection = currentLocation === routes.dashboardCardsModifyTicketValueBulkCorrection;

  return isCurrentLocationOrdersUploadCorrection ||
    isCurrentLocationOrdersUpload ||
    isCurrentLocationDeleteUpload ||
    isCurrentLocationDeleteUploadCorrection ||
    isCurrentLocationModifyTicketValueBulk ||
    isCurrentLocationModifyTicketValueBulkCorrection
}

function router({ getState, dispatch }) {
  return next => action => {
    switch (action.type) {
      case AFTER_CURRENT_BRANCH_WAS_SET: {
        var currentLocation = getState().router.location.pathname;

        if (isCurrentLocationCardUploadFile(currentLocation)) {
          dispatch(replace(routes.dashboardCards, { currentBranch: action.payload }));
        }
        else if (currentLocation === routes.dashboardBranchImportCorrection) {
          dispatch(replace(routes.dashboardBranch, { currentBranch: action.payload }));
        }
        break;
      }
      case UPLOAD_CSV_FILE_SUCCESS: {
        switch (getState().itemCorrection.uploadTypeId) {
          case fileUploadType.branchesImport:
            next(replace(routes.dashboardBranchImportCorrection));
            break;
          case fileUploadType.cardsDelete:
            next(replace(routes.dashboardCardsDeleteBulkCorrection));
            break;
          case fileUploadType.cardsModifyTicketValue:
            next(replace(routes.dashboardCardsModifyTicketValueBulkCorrection))
            break;
          default:
            next(replace(routes.dashboardOrdersUploadCorrection));
            break;
        }

        break;
      }
      case UPLOAD_SET_VALIDATION_STATUS: {
        if (action.payload === true) {
          switch (getState().itemCorrection.uploadTypeId) {
            default:
              next(replace(routes.dashboardOrdersUploadSuccess));
              break;
          }
        }

        break;
      }
      case USER_CHANGE_PASSWORD_SUCCESS: {
        if (getState().user.isChangePassword) {
          next(push(routes.homePage));
          dispatch(saveUserInformation({ isChangePassword: false }));
          next(showToast({ type: 'success', text: 'Parola a fost schimbata cu succes.' }));
          break;
        }
        if (getState().user.isForgotPassword) {
          dispatch(saveUserInformation({ isForgotPassword: false }));
        }
        window.location.replace(`${window.location.protocol}//${window.location.host}${routes.homePage}`);
        break;
      }
      case USER_IS_FIRST_LOGIN: {
        next(push(routes.accountLoginPasswordSetup));

        break;
      }
      case USER_GENERATE_SMS_TOKEN_FAILURE: {
        if (action.payload.errorMessage) {
          next(showToast({ type: 'error', text: action.payload.text }));
        }
        if (!getState().user.isChangePassword) {
          next(replace(routes.accountLogin));
        }
        break;
      }
      case USER_SUBMIT_SMS_TOKEN_SUCCESS: {
        const firstLogin = action.payload.isFirstLogin;

        if (!firstLogin) {
          if (getState().user.isForgotPassword || getState().user.userStatus === userStatus.resetPassword || getState().user.userStatus === userStatus.createdResetPassword) {
            next(push(routes.accountForgotPasswordPasswordSetup));
          }
          else {
            if (getState().user.isChangePassword) {
              next(replace(routes.changePasswordPasswordSetup, { stopRedirecting: true }));
            } else {
              window.location.replace(`${window.location.protocol}//${window.location.host}${routes.homePage}`);
            }
          }
        } else {
          dispatch(isFirstLogin());
        }
        break;
      }
      case USER_SUBMIT_PASSWORD_SUCCESS: {
        if (getState().user.isForgotPassword) {
          next(push(routes.accountForgotPasswordSmsToken));
        }
        else {
          next(push(routes.accountLoginSmsToken));
        }
        break;
      }

      case USER_VERIFY_PASSWORD_SUCCESS: {
        dispatch(replace(routes.changePasswordSmsToken, { stopRedirecting: true }));
        break;
      }

      case USER_SUBMIT_USERNAME_SUCCESS: {
        next(push(routes.accountLoginPassword));
        break;
      }
      case LOCATION_CHANGE: {
        const { pathname, search, state } = action.payload.location;

        if (Object.values(routes).includes(pathname)) {
          if (pathname === routes.accountLogin && search === '?sessionExpired=true') {
            window.localStorage.setItem('sessionExpired', true);
          }
          if (!pathname.includes(routes.accountForgotPassword)) {
            dispatch(saveUserInformation({ isForgotPassword: false }));
          }
          if (pathname.includes(routes.changePassword) && (!state || !state.stopRedirecting)) {
            window.location.replace(`${window.location.protocol}//${window.location.host}${routes.homePage}`);
          }

          if (!pathname.includes(routes.accountLogin)) {
            if (window.localStorage.getItem('sessionExpired')) {
              window.location.replace(`${window.location.protocol}//${window.location.host}${routes.accountLogin}?sessionExpired=true`);
            } else if (window.localStorage.getItem('userLoggedOut')) {
              window.location.replace(`${window.location.protocol}//${window.location.host}${routes.accountLogin}`);
            }
          }
          if (pathname == routes.dashboardCards) {
            if (getState().branch.currentBranch) {
              dispatch(setCardsCurrentPage(1));
              dispatch(setCardsPageSize(10));
              dispatch(setCardFilters({ firstOrLastName: null, cnp: null, orderId: null, statusId: null, ticketValue: null }));

              dispatch(hideCardMigrationModal());
              dispatch(hideCardReissueModal());
              dispatch(hideChangeTicketValueModal());
              dispatch(hidePinReissueModal());
              next(clearSelection());
            }

            const currentBranch = state && state.currentBranch ? state.currentBranch : getState().branch.currentBranch;
            if (currentBranch) {
              if (currentBranch.id) {
                dispatch(findCardsRequest(currentBranch));
                dispatch(findOrders(currentBranch.id, state ? state.viewLastCreatedOrder : null));
              } else {
                dispatch(findCardsRequest());
                dispatch(findOrders());
              }
            }
          }
          if (pathname === routes.dashboardManagers) {
            if (window.localStorage.getItem('userRoleId') == userRole.STANDARD) {
              window.location.replace(`${window.location.protocol}//${window.location.host}${routes.notFound}`);
            } else {
              dispatch(setManagersSearchTerm(''));
              dispatch(setManagersCurrentPage(1));
              dispatch(setManagersPageSize(10));
              dispatch(findManagersRequest());
              dispatch(managersHideModal());
            }
          }
          if (pathname === routes.dashboardBranch) {
            if (window.localStorage.getItem('userRoleId') == userRole.STANDARD) {
              window.location.replace(`${window.location.protocol}//${window.location.host}${routes.notFound}`);
            } else {
              dispatch(findBranchUsersRequest());
              dispatch(hideModal());
            }
          }
          if (pathname === routes.dashboardSapInvoices) {
            if (window.localStorage.getItem('userRoleId') == userRole.ADMIN) {
              dispatch(setSapCurrentPage(1));
              dispatch(findSapInvoices(datetime.getFormattedTodayMinusOneYear(), datetime.getFormattedToday(), 1, 10));
            } else {
              window.location.replace(`${window.location.protocol}//${window.location.host}${routes.notFound}`);
            }
          }
          if (pathname === routes.dashboardAudit) {
            const filter = {
              startDate: '',
              endDate: '',
              username: '',
              pageNumber: 1,
              pageSize: 10,
              actionType: null
            };
            dispatch(setAuditFilter(filter));
            dispatch(loadAudit({
              pageNumber: 1,
              pageSize: 10,
              employerId: getState().user.employerId
            }));
          }
        }
        else {
          next(push(routes.notFound));
        }
      }
    }

    const returnValue = next(action)

    return returnValue
  }
}

export default router;
