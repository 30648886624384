import './AddBranchModal.scss';
import { Col, Form, Modal } from 'react-bootstrap';
import { findCities, findLocalities, upsertBranch } from 'actions/branch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import errorCodes from 'utils/errorCodes';
import React from 'react';
import validator from 'utils/validator';

const ERROR_BRANCH_MANDATORY_FIELD = 'Campul este obligatoriu';
const ERROR_BRANCH_NAME_TOO_LOW = 'Campul trebuie sa contina cel putin 3 caractere';
const ERROR_BRANCH_NAME_TOO_HIGH = 'Campul trebuie sa contina cel mult 30 caractere';
const ERROR_BRANCH_STREET_TOO_LOW = 'Strada trebuie sa contina cel putin 3 caractere';
const ERROR_BRANCH_STREET_TOO_HIGH = 'Strada trebuie sa contina cel mult 30 caractere';
const ERROR_BRANCH_STREET_NUMBER_TOO_HIGH = 'Numar trebuie sa contina cel mult 10 caractere';
const ERROR_BRANCH_DETALII_TOO_HIGH = 'Alte detalii nu trebuie sa depaseasca 50 caractere';
const ERROR_BRANCH_ZIP_CODE_TOO_HIGH = 'Codul postal trebuie sa contina cel mult 10 caractere';
const ERROR_BRANCH_CONTACT_PERSON_PHONE_NUMBER_INVALID = 'Numar de telefon invalid';

class AddBranchModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.initialState();

    this._isMounted = false;

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onBtnCloseModal = this.onBtnCloseModal.bind(this);
    this.onShowModal = this.onShowModal.bind(this);
    this.validateBranchName = this.validateBranchName.bind(this);
    this.validateBranchCity = this.validateBranchCity.bind(this);
    this.validateBranchLocality = this.validateBranchLocality.bind(this);
    this.validateBranchStreet = this.validateBranchStreet.bind(this);
    this.validateBranchStreetNumber = this.validateBranchStreetNumber.bind(this);
    this.validateBranchDetail = this.validateBranchDetail.bind(this);
    this.validateZipCode = this.validateZipCode.bind(this);
    this.validateContactPersonName = this.validateContactPersonName.bind(this);
    this.validateContactPersonPhoneNumber = this.validateContactPersonPhoneNumber.bind(this);
    this.mapUiErrorCode = this.mapUiErrorCode.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initialState() {
    return {
      isBranchNameError: false,
      branchName: '',
      branchNameError: '',

      isBranchCityError: false,
      branchCity: '-1',
      branchCityError: '',

      isBranchLocalityError: false,
      branchLocality: '-1',
      branchLocalityError: '',

      isBranchStreetError: false,
      branchStreet: '',
      branchStreetError: '',

      isBranchStreetNumberError: false,
      branchStreetNumber: '',
      branchStreetNumberError: '',

      isBranchZipCodeError: false,
      branchZipCode: '',
      branchZipCodeError: '',

      isBranchDetailError: false,
      branchDetail: '',
      branchDetailError: '',
      isSubmitting: false,
      oldLocality: '',

      isContactPersonNameError: false,
      contactPersonName: '',
      contactPersonNameError: '',

      isContactPersonPhoneNumberError: false,
      contactPersonPhoneNumber: '',
      contactPersonPhoneNumberError: ''
    };
  }

  resetState() {
    this.setState(this.initialState());
  }

  submit() {
    let isValidData = this.isModalDataValid();

    if (isValidData) {
      var newBranch = {
        name: this.state.branchName,
        streetName: this.state.branchStreet,
        streetNumber: this.state.branchStreetNumber,
        zipCode: this.state.branchZipCode,
        details: this.state.branchDetail,
        cityId: this.state.branchCity,
        localityId: this.state.branchLocality,
        status: this.props.currentBranch ? this.props.currentBranch.status : 1,
        deliveryCode: this.props.currentBranch ? this.props.currentBranch.deliveryCode : null,
        contactPersonName: this.state.contactPersonName,
        contactPersonPhoneNumber: this.state.contactPersonPhoneNumber
      };

      if (this.props.isModalInEditMode) {
        newBranch = {
          ...newBranch,
          Id: this.props.currentBranch.id,
          NewCity: this.props.cities.find(c => c.id == this.state.branchCity).name,
          NewLocality: this.props.localities.find(l => l.id == this.state.branchLocality).name,
          OldCity: this.props.cities.find(c => c.id == this.state.oldCityId).name,
          OldLocality: this.state.oldLocality,
        };
      }

      this.setState({ isSubmitting: true }, () => {
        this.props.upsertBranch(newBranch).then(() => {
          if (this._isMounted == true)
            this.setState({ isSubmitting: false })
        });
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (this.props.isModalInEditMode && this.state.oldLocality === '')
      this.setState({ oldLocality: this.props.localities.find(l => l.id == this.state.oldLocalityId).name });

    if (name === 'branchCity') {
      // trigger localities loading
      this.props.findLocalities(event.target.value);

      // reset branchLocality when city is not selected
      this.setState({ branchLocality: '-1' });
    }

    this.setState({
      [name]: value
    });
  }

  validateBranchName() {
    if (this.state.branchName.trim() === '') {
      this.setState({ isBranchNameError: true, branchNameError: ERROR_BRANCH_MANDATORY_FIELD });
    } else if (this.state.branchName.length < 3) {
      this.setState({ isBranchNameError: true, branchNameError: ERROR_BRANCH_NAME_TOO_LOW });
    } else if (this.state.branchName.length > 30) {
      this.setState({ isBranchNameError: true, branchNameError: ERROR_BRANCH_NAME_TOO_HIGH });
    } else {
      this.setState({ isBranchNameError: false, branchNameError: '' });
      return true;
    }
    return false;
  }

  validateBranchCity() {
    if (this.state.branchCity === '-1') {
      this.setState({ isBranchCityError: true, branchCityError: ERROR_BRANCH_MANDATORY_FIELD });
    } else {
      this.setState({ isBranchCityError: false, branchCityError: '' });
      return true;
    }
    return false;
  }

  validateBranchLocality() {
    if (this.state.branchLocality === '-1') {
      this.setState({ isBranchLocalityError: true, branchLocalityError: ERROR_BRANCH_MANDATORY_FIELD });
    } else {
      this.setState({ isBranchLocalityError: false, branchLocalityError: '' });
      return true;
    }
    return false;
  }

  validateBranchStreet() {
    if (this.state.branchStreet.trim() === '') {
      this.setState({ isBranchStreetError: true, branchStreetError: ERROR_BRANCH_MANDATORY_FIELD });
    } else if (this.state.branchStreet.length < 3) {
      this.setState({ isBranchStreetError: true, branchStreetError: ERROR_BRANCH_STREET_TOO_LOW });
    } else if (this.state.branchStreet.length > 30) {
      this.setState({ isBranchStreetError: true, branchStreetError: ERROR_BRANCH_STREET_TOO_HIGH });
    } else {
      this.setState({ isBranchStreetError: false, branchStreetError: '' });
      return true;
    }
    return false;
  }

  validateBranchStreetNumber() {
    if (this.state.branchStreetNumber.trim() === '') {
      this.setState({ isBranchStreetNumberError: true, branchStreetNumberError: ERROR_BRANCH_MANDATORY_FIELD });
    } else if (this.state.branchStreetNumber.length > 10) {
      this.setState({ isBranchStreetNumberError: true, branchStreetNumberError: ERROR_BRANCH_STREET_NUMBER_TOO_HIGH });
    } else {
      this.setState({ isBranchStreetNumberError: false, branchStreetNumberError: '' });
      return true;
    }
    return false;
  }

  validateBranchDetail() {
    if (this.state.branchDetail.trim() !== '' && this.state.branchDetail.length > 50) {
      this.setState({ isBranchDetailError: true, branchDetailError: ERROR_BRANCH_DETALII_TOO_HIGH });
    } else {
      this.setState({ isBranchDetailError: false, branchDetailError: '' });
      return true;
    }
    return false;
  }

  validateZipCode() {
    if (this.state.branchZipCode.trim() !== '' && this.state.branchZipCode.length > 10) {
      this.setState({ isBranchZipCodeError: true, branchZipCodeError: ERROR_BRANCH_ZIP_CODE_TOO_HIGH });
    } else {
      this.setState({ isBranchZipCodeError: false, branchZipCodeError: '' });
      return true;
    }
    return false;
  }

  validateContactPersonName() {
    if (!this.state.contactPersonName || this.state.contactPersonName.trim() === '') {
      this.setState({ isContactPersonNameError: true, contactPersonNameError: ERROR_BRANCH_MANDATORY_FIELD });
    }
    else {
      this.setState({ isContactPersonNameError: false, contactPersonNameError: '' });
      return true;
    }
    return false;
  }

  validateContactPersonPhoneNumber() {
    if (!this.state.contactPersonPhoneNumber || this.state.contactPersonPhoneNumber.trim() === '') {
      this.setState({ isContactPersonPhoneNumberError: true, contactPersonPhoneNumberError: ERROR_BRANCH_MANDATORY_FIELD });
    }
    else if (!validator.isValidPhoneNumber(this.state.contactPersonPhoneNumber)) {
      this.setState({ isContactPersonPhoneNumberError: true, contactPersonPhoneNumberError: ERROR_BRANCH_CONTACT_PERSON_PHONE_NUMBER_INVALID });
    } else {
      this.setState({ isContactPersonPhoneNumberError: false, contactPersonPhoneNumberError: '' });
      return true;
    }
    return false;
  }

  isModalDataValid() {
    this.validateBranchName();
    this.validateBranchCity();
    this.validateBranchLocality();
    this.validateBranchStreet();
    this.validateBranchStreetNumber();
    this.validateBranchDetail();
    this.validateZipCode();
    this.validateContactPersonName();
    this.validateContactPersonPhoneNumber();
    if (this.validateBranchName()
      && this.validateBranchCity()
      && this.validateBranchLocality()
      && this.validateBranchStreet()
      && this.validateBranchStreetNumber()
      && this.validateBranchDetail()
      && this.validateZipCode()
      && this.validateContactPersonName()
      && this.validateContactPersonPhoneNumber()) {
      return true;
    } else {
      return false;
    }
  }

  onBtnCloseModal() {
    this.props.onHide();
  }

  onShowModal() {
    this.resetState();
    if (this.props.isModalInEditMode) {
      const { name, cityId, localityId, streetName, streetNumber, zipCode, details, contactPersonName, contactPersonPhoneNumber } = this.props.currentBranch;

      this.setState({
        branchName: name,
        branchCity: cityId,
        branchLocality: localityId,
        branchStreet: streetName,
        branchStreetNumber: streetNumber,
        branchZipCode: zipCode,
        branchDetail: details || '',
        oldCityId: cityId,
        oldLocalityId: localityId,
        contactPersonName: contactPersonName,
        contactPersonPhoneNumber: contactPersonPhoneNumber
      });

      this.props.findCities();
      this.props.findLocalities(cityId);

    } else {
      this.props.findCities();
    }
  }

  mapUiErrorCode(errorCode) {
    if (errorCode === errorCodes.branchCreationFailed) {
      return errorCodes.genericErrorMessage;
    } else if (errorCode === errorCodes.branchDuplicated) {
      return 'Inregistrarea nu a putut fi salvata: Numele exista deja inregistrat in sistem.';
    } else if (errorCode === errorCodes.modalInvalid) {
      return 'Nu toate datele introduse respecta formatul acceptat.';
    }
  }

  render() {
    let cityOptions = this.props.cities && this.props.cities.map(c =>
      <option key={c.id} value={c.id}>{c.name}</option>);

    let localitiesOptions = [];
    if (this.props.localities && this.props.localities.length === 0) {
      localitiesOptions.push(<option key='-1' value='-1'></option>)
    } else {
      localitiesOptions = this.props.localities && this.props.localities.map(c =>
        <option key={c.id} value={c.id}>{c.name}</option>
      );
    }

    return (
      <Modal
        className="modal-branch"
        show={this.props.show || this.state.upsertBranchStatus}
        onShow={this.onShowModal}
        onHide={this.props.onHide}
        scrollable="true"
        dialogClassName="branch-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalii punct de lucru
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="form-group-label" controlId="branch-name">
              {this.state.isBranchNameError ? <span className="error-label">{this.state.branchNameError}</span> : null}
              <Form.Control autoFocus
                name="branchName" type="text" placeholder="Denumire punct de lucru"
                onChange={this.handleInputChange}
                value={this.state.branchName}
                onBlur={this.validateBranchName} />
              <Form.Label>Denumire</Form.Label>
            </Form.Group>

            <div className="label-section-modal">Adresa</div>

            <Form.Row>
              <Form.Group className="form-group-label spacer-right" as={Col} controlId="branch-city">
                {this.state.isBranchCityError ? <span className="error-label">{this.state.branchCityError}</span> : null}
                <Form.Control name="branchCity" as="select" placeholder="Judet"
                  onChange={this.handleInputChange}
                  value={this.state.branchCity}
                  onBlur={this.validateBranchCity}>
                  {cityOptions}
                </Form.Control>
                <Form.Label className={this.state.branchCity !== '-1' ? 'select-label active' : 'select-label'}>Judet</Form.Label>
              </Form.Group>

              <Form.Group as={Col} className="form-group-label" controlId="branch-locality">
                {this.state.isBranchLocalityError ? <span className="error-label">{this.state.branchLocalityError}</span> : null}
                <Form.Control name="branchLocality" as="select" placeholder="Localitate"
                  onChange={this.handleInputChange}
                  value={this.state.branchLocality}
                  onBlur={this.validateBranchLocality}>
                  {localitiesOptions}
                </Form.Control>
                <Form.Label className={this.state.branchLocality !== '-1' ? 'select-label active' : 'select-label'}>Localitate</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Group className="form-group-label" controlId="branch-street">
              {this.state.isBranchStreetError ? <span className="error-label">{this.state.branchStreetError}</span> : null}
              <Form.Control type="text" placeholder="Strada" name="branchStreet"
                onChange={this.handleInputChange}
                value={this.state.branchStreet}
                onBlur={this.validateBranchStreet} />
              <Form.Label>Strada</Form.Label>
            </Form.Group>

            <Form.Row>
              <Form.Group className="form-group-label spacer-right" as={Col} controlId="branch-street-number">
                {this.state.isBranchStreetNumberError ? <span className="error-label">{this.state.branchStreetNumberError}</span> : null}
                <Form.Control type="text" placeholder="Numar" name="branchStreetNumber"
                  onChange={this.handleInputChange}
                  value={this.state.branchStreetNumber}
                  onBlur={this.validateBranchStreetNumber} />
                <Form.Label>Numar</Form.Label>
              </Form.Group>

              <Form.Group className="form-group-label" as={Col} controlId="branch-zip-code">
                {this.state.isBranchZipCodeError ? <span className="error-label">{this.state.branchZipCodeError}</span> : null}
                <Form.Control type="text" placeholder="Cod Postal" name="branchZipCode"
                  onChange={this.handleInputChange}
                  value={this.state.branchZipCode}
                  onBlur={this.validateZipCode} />
                <Form.Label>Cod postal</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Group className="form-group-label" controlId="branch-detail">
              {this.state.isBranchDetailError ? <span className="error-label">{this.state.branchDetailError}</span> : null}
              <Form.Control type="text" placeholder="Alte detalii" name="branchDetail"
                onChange={this.handleInputChange}
                value={this.state.branchDetail}
                onBlur={this.validateBranchDetail} />
              <Form.Label>Alte detalii</Form.Label>
            </Form.Group>

            <Form.Row>
              <Form.Group className="form-group-label spacer-right" as={Col} controlId="contact-person-name">
                {this.state.isContactPersonNameError ? <span className="error-label">{this.state.contactPersonNameError}</span> : null}
                <Form.Control type="text" placeholder="Nume persoana de contact" name="contactPersonName"
                  onChange={this.handleInputChange}
                  value={this.state.contactPersonName}
                  onBlur={this.validateContactPersonName} />
                <Form.Label>Nume persoana de contact</Form.Label>
              </Form.Group>

              <Form.Group className="form-group-label" as={Col} controlId="contact-person-phone-number">
                {this.state.isContactPersonPhoneNumberError ? <span className="error-label">{this.state.contactPersonPhoneNumberError}</span> : null}
                <Form.Control type="text" placeholder="Telefon persoana de contact" name="contactPersonPhoneNumber"
                  onChange={this.handleInputChange}
                  value={this.state.contactPersonPhoneNumber}
                  onBlur={this.validateContactPersonPhoneNumber} />
                <Form.Label>Telefon persoana de contact</Form.Label>
              </Form.Group>
            </Form.Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.props.isUpsertBranchError === true ? <span className="error-label">{this.mapUiErrorCode(this.props.upsertBranchErrorMessage)}</span> : null}
          <button className="main-button white" onClick={this.onBtnCloseModal}>RENUNTA</button>
          <button
            className={'main-button ' + (this.state.isSubmitting ? 'loading disabled' : '')}
            onClick={this.submit}
            disabled={this.state.isSubmitting}>
            <span className="spinner-border spinner-border-sm"></span>
            {this.props.isModalInEditMode ? 'SALVEAZA' : 'ADAUGA'}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

}

const mapStateToProps = state => {
  return {
    ...state.branch,
    branchUser: state.branchUser.branchUsers
  }
};
const mapDispatchToProps = dispatch => bindActionCreators({
  findCities,
  findLocalities,
  upsertBranch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddBranchModal);
