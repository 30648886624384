import audit from './audit';
import branch from './branch';
import branchUser from './branchUser';
import card from './card';
import customModal from './customModal';
import itemCorrection from './itemCorrection';
import loadingSpinner from './loadingSpinner';
import manager from './manager';
import order from './order';
import sapInvoice from './sapInvoice';
import toast from './toast';
import upload from './upload';
import user from './user';

export default {
  audit,
  user,
  card,
  customModal,
  branch,
  order,
  loadingSpinner,
  upload,
  toast,
  itemCorrection,
  branchUser,
  manager,
  sapInvoice
};
