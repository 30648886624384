import './CardFilters.scss';
import { Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { cardStatus } from 'utils/enums';
import React from 'react';
import SearchInput from '../../SearchInput/SearchInput';

let timeout = {};

class CardFilters extends React.Component {
    constructor(props) {
        super(props);

        this.renderStatusFilter = this.renderStatusFilter.bind(this);
        this.renderOrderFilter = this.renderOrderFilter.bind(this);

        this.filterByFirstOrLastName = this.filterByFirstOrLastName.bind(this);
        this.filterByCnp = this.filterByCnp.bind(this);
        this.filterByTicketValue = this.filterByTicketValue.bind(this);
        this.filterByStatus = this.filterByStatus.bind(this);
        this.filterByOrder = this.filterByOrder.bind(this);
    }

    filterByFirstOrLastName(event) {
        this.filterByField({ firstOrLastName: event.target.value });
    }

    filterByCnp(event) {
        const onlyNumbersRegex = /^\d+$/;

        if (onlyNumbersRegex.test(event.target.value) || event.target.value === '') {
            this.filterByField({ cnp: event.target.value });
        }
    }

    filterByTicketValue(event) {
        const ticketValueRegex = /^[0-9]+(\.)?([0-9]+)?$/;

        if (ticketValueRegex.test(event.target.value) || event.target.value === '') {
            if (Number(event.target.value) <= Number(localStorage.getItem('maxTicketValue'))) {
                this.filterByField({ ticketValue: event.target.value });
            }
            else {
                this.filterByField({ ticketValue: event.target.value }, false);
            }
        }
    }

    filterByStatus(statusId) {
        this.filterByField({ statusId: statusId });
    }

    filterByOrder(orderId) {
        this.filterByField({ orderId: orderId });
    }

    filterByField(field, callApi = true) {
        this.props.setCardFilters(field);
        this.props.setCardsCurrentPage(1);

        //if field's size is less or equal than max size permitted by application => call api
        //otherwise no call will be made, empty result will be returned.
        if (callApi) {
            this.props.setCardsLoadedFlag(false);
            clearTimeout(timeout); // cancel the old timer
            timeout = setTimeout(() => {
                this.props.findCardsRequest();
            }, 250);
        }
        else {
            this.props.setCardsLoadedFlag(true);
            this.props.setCards([]);
        }
    }

    renderOrderFilter() {
        const currentOrder = this.props.orders.filter(o => o.id === this.props.filters.orderId)[0];
        return (
            <DropdownButton className='card-filter-dropdown order order-data-filter' title={currentOrder && currentOrder.creationDate ? currentOrder.creationDate : ''}>
                {this.props.orders && this.props.orders.map(order => {
                    return (
                        <Dropdown.Item onClick={() => this.filterByOrder(order.id)} key={order.id}>
                            {order.creationDate}
                        </Dropdown.Item>
                    )
                })}
            </DropdownButton>
        );
    }

    renderStatusFilter() {
        const statuses = ['Toate statusurile', cardStatus.inProcess, cardStatus.existing, cardStatus.emitted, cardStatus.confirmed, cardStatus.migrated];

        return (
            <Dropdown className="card-filter-dropdown status status-filter">
                <Dropdown.Toggle>
                    <span>{this.props.filters && Number.isInteger(this.props.filters.statusId) ? cardStatus.properties[this.props.filters.statusId] : statuses[0]}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {statuses.map((status, index) => {

                        if (!Number.isInteger(status)) {
                            return (
                                <Dropdown.Item key={index} value={null} onClick={() => { this.filterByStatus('') }}>
                                    {status}
                                </Dropdown.Item>
                            );
                        }

                        return (
                            <Dropdown.Item key={index} value={status} onClick={() => { this.filterByStatus(status) }}>
                                {cardStatus.properties[status]}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>

            </Dropdown>
        );
    }

    render() {
        return (
            <>
                <Row noGutters className='justify-content-between'>
                    <Col sm="auto" className='filters-gap-container firstOrLastName'>
                        <SearchInput className='first-lastname-filter'
                            searchInputId='first-lastname-input'
                            placeholder="Nume, Prenume"
                            value={(this.props.filters && this.props.filters.firstOrLastName) || ''}
                            onChange={this.filterByFirstOrLastName}
                            rightAlign={true}
                            noRightMargin={true}
                        />
                    </Col>
                    <Col sm="auto" className='filters-gap-container cnp'>
                        <SearchInput className='cnp-filter'
                            searchInputId='cnp-input'
                            placeholder="CNP"
                            value={(this.props.filters && this.props.filters.cnp) || ''}
                            onChange={this.filterByCnp}
                            rightAlign={true}
                            noRightMargin={true}
                        />
                    </Col>
                    <Col sm="auto" className='filters-gap-container status'>
                        {this.renderStatusFilter()}
                    </Col>
                    <Col sm="auto" className='filters-gap-container order'>
                        {this.renderOrderFilter()}
                    </Col>
                    <Col sm="auto" className='filters-gap-container ticket-value'>
                        <SearchInput className='ticket-value-filter'
                            searchInputId='ticket-value-input'
                            placeholder="Valoare tichet"
                            value={(this.props.filters && this.props.filters.ticketValue) || ''}
                            onChange={this.filterByTicketValue}
                            rightAlign={true}
                            noRightMargin={true}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default CardFilters;
