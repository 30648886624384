export const actionType = {
    all: null,
    login: 0,
    forgotPassword: 1,
    changedPassword: 2,

    userCreate: 3,
    userUpdate: 4,
    userDelete: 5,
    userAssignToBranch: 6,
    userRemoveFromBranch: 7,

    branchCreate: 8,
    branchUpdate: 9,
    branchDeactivation: 10,
    branchEnable:18,

    cardsOrder: 11,
    cardsDelete: 12,
    deliveryConfirmation: 13,
    cardReissue: 14,
    pinReissue: 15,
    changeTicketValue: 16,
    cardsMigration: 17,

    invoiceCancel: 19,
    invoiceRectify: 20,

    getValue(id) {
        switch (id) {
            case 0: return 'Login';
            case 1: return 'Parola uitata';
            case 2: return 'Schimbare parola';

            case 3: return 'Creare utilizator';
            case 4: return 'Modificare utilizator';
            case 5: return 'Stergere utilizator';
            case 6: return 'Alocare utilizator la punct de lucru';
            case 7: return 'Dealocare utilizator la punct de lucru';

            case 8: return 'Adaugare punct de lucru';
            case 9: return 'Modificare punct de lucru';
            case 10: return 'Dezactivare punct de lucru';

            case 11: return 'Comanda de carduri';
            case 12: return 'Stergere carduri';
            case 13: return 'Confirmare de predare carduri';
            case 14: return 'Reemitere carduri';
            case 15: return 'Reemitere cod PIN';
            case 16: return 'Modificare valoare tichet';
            case 17: return 'Migrare de carduri';
            case 18: return 'Reactivare punct de lucru';
            case 19: return 'Anulare factura';
            case 20: return 'Stornare factura';

            default: return 'Toate';
        }
    }
}
