import './SuccessfullUploadScreen.scss';
import { Col, Row } from 'react-bootstrap';
import React from 'react';

class SuccessfullUploadScreen extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick();
  }

  render() {
    return (
      <div>
        <Row>
          <Col className='center successfull-upload-icon'>
            <svg width="72" height="72" viewBox="0 0 24 24">
              <path id="a" d="M12 1c6.072 0 11 4.928 11 11s-4.928 11-11 11S1 18.072 1 12 5.928 1 12 1zm0 2c-4.967 0-9 4.033-9 9s4.033 9 9 9 9-4.033 9-9-4.033-9-9-9zm-1.59 10.625l5.06-5.134a1.163 1.163 0 0 1 1.7.055c.44.441.44 1.148 0 1.59l-6.76 6.814-3.58-3.605a1.123 1.123 0 0 1-.007-1.583l.042-.043a1.162 1.162 0 0 1 1.627-.029l1.919 1.935z" />
            </svg>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className='center'>
            <p className="cards-order-title">{this.props.title}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className='center'>
            <p className="cards-loading-message">{this.props.description}</p>
          </Col>
        </Row>
        <Row>
          <Col className='center button-container'>
            <button onClick={this.handleClick} className="view-orders-button main-button icon">
              <svg width="20" height="20" viewBox="0 0 24 24">
                <path id="a" d="M12 4C6 4 2.7 8.1 1 12.5 2.7 16.9 6 21 12 21s9.3-4.1 11-8.5C21.3 8.1 18 4 12 4zm0 13.5c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z" />
              </svg>
              {this.props.buttonText}
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SuccessfullUploadScreen;
