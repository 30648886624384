import './LoadingSpinner.scss';
import { connect } from 'react-redux';
import React from 'react';

class LoadingSpinner extends React.Component {
  render() {
    return (
      <div id="loadingSpinner" className={this.props.isVisible ? '' : 'hidden'}>
        <div className="spinner-border" role="status"></div>
      </div>
    )
  }
}

const mapStateToProps = state => { return { isVisible: state.loadingSpinner.isVisible } };

export default connect(mapStateToProps)(LoadingSpinner);
