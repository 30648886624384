import {
    createManagerRequest,
    deleteManagerRequest,
    findManagersRequest,
    managersHideModal,
    managersSetModalError,
    managersShowModal,
    setCurrentManager,
    setManagersCurrentPage,
    setManagersPageSize,
    setManagersSearchTerm,
    showUpdateManagerModal,
    updateManagerRequest
} from 'actions/manager';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ManagersDashboard from './ManagersDashboard';
import { showLoadingSpinner } from 'actions/loadingSpinner';
import { showToast } from 'actions/toast';

const mapStateToProps = state => {
    return {
        currentBranch: state.branch.currentBranch,
        userRoleId: state.user.roleId,
        branches: state.branch.branches,
        currentManager: state.manager.currentManager,
        ...state.manager
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    createManagerRequest,
    deleteManagerRequest,
    findManagersRequest,
    managersHideModal,
    managersSetModalError,
    managersShowModal,
    showUpdateManagerModal,
    setManagersCurrentPage,
    setManagersPageSize,
    setManagersSearchTerm,
    updateManagerRequest,
    setCurrentManager,
    showLoadingSpinner,
    showToast
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ManagersDashboard)
