import { Captcha, captchaSettings } from 'reactjs-captcha';
import React from 'react';
import { Row } from 'react-bootstrap';

class CaptchaModalBody extends React.Component {
    constructor(props) {
        super(props);

        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);

        this.captchaRef = React.createRef(null);

        this.state = {
            captchaCode: '',
            captchaId: null
        };

        captchaSettings.set({
            captchaEndpoint: 'simple-captcha-endpoint.ashx'
        });
    }

    handleOnChange(event) {
        const { value } = event.target,
            captchaId = this.captchaRef.current.getCaptchaId();

        this.setState({ captchaCode: value, captchaId: captchaId });
    }

    componentWillUnmount() {
        //if the captcha modal's visibility is false, then reset the parent's state
        this.props.onStateChange(null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.captchaRef.current && this.props.isCaptchaValid === false) {
            this.captchaRef.current.reloadImage();
            this.props.onImageReloaded();
            setTimeout(function () {
                var captchaInput = document.getElementById('btTichetCaptchaInputCode');
                if (captchaInput) {
                    captchaInput.disabled = false;
                }
            }, 500);
        }

        if (prevState && prevState !== this.state) {
            this.props.onStateChange(this.state);
        }
    }

    handleKeyUp(event) {
        const code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            this.props.onKeyUp();
        }
    }

    render() {
        return (
            <>
                <p className='input-validation-error visible'>Introduceti codul din imagine.</p>
                <Row noGutters className="captcha">
                    <Captcha
                        captchaStyleName="btTichetCaptcha"
                        ref={this.captchaRef} />
                    <input
                        id="btTichetCaptchaInputCode"
                        className="form-control"
                        type="text"
                        tab-index="10"
                        onKeyUp={this.handleKeyUp}
                        placeholder="Cod Captcha"
                        value={this.state.captchaCode}
                        onChange={this.handleOnChange} />
                </Row>
            </>
        )
    }
}

export default CaptchaModalBody;
