import { bindActionCreators } from 'redux';
import { blockCurrentBranchSelection } from 'actions/branch';
import CardsOrder from './CardsOrder';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { setUploadConfig } from 'actions/upload';

const mapStateToProps = state => { return { ...state.router, ...state.itemCorrection, currentBranch: state.branch.currentBranch, userRole: state.user.roleId } };
const mapDispatchToProps = dispatch => bindActionCreators({
  setUploadConfig,
  blockCurrentBranchSelection,
  replace,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardsOrder);
