import './UploadFileScreen.scss'
import { Container } from 'react-bootstrap';
import React from 'react'
import UploadFileScreenBodyContainer from './Body/index';
import UploadFileScreenHeaderContainer from './Header/index';

class UploadFileScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const [uploadFileScreen, uploadSteps] = this.props.uploadFileContent;

        return (
            <Container fluid className="upload-file-screen-container view-container">
                <UploadFileScreenHeaderContainer>
                    {this.props.uploadFileHeader}
                </UploadFileScreenHeaderContainer>

                <UploadFileScreenBodyContainer uploadFileScreen={uploadFileScreen}
                    uploadSteps={uploadSteps}
                />
            </Container>
        );
    }
}

export default UploadFileScreen;
