import {
  SET_CANCELLED_UPLOAD_FLAG,
  SHOW_LOADING_SCREEN,
  UPLOAD_SET_CONFIG,
  UPLOAD_SET_FILE_NAME,
  UPLOAD_SET_UPLOAD_ERROR,
  UPLOAD_SET_VALIDATION_STATUS,
} from 'actions/upload';

export default function upload(state, action) {
  state = state || {
    cards: null,
    selectState: 'none',
    selectedCards: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    searchTerm: null,
    correctionScreenShow: false,
    itemsValidated: false
  };

  switch (action.type) {
    case UPLOAD_SET_UPLOAD_ERROR:
      return {
        ...state,
        uploadError: action.payload
      };
    case SHOW_LOADING_SCREEN:
      action.payload
        ? window.onbeforeunload = () => true
        : window.onbeforeunload = undefined;

      return {
        ...state,
        loadingScreenShow: action.payload
      }
    case UPLOAD_SET_FILE_NAME: {
      return {
        ...state,
        fileName: action.payload
      }
    }
    case UPLOAD_SET_VALIDATION_STATUS: {
      return {
        ...state,
        itemsValidated: action.payload
      }
    }
    case SET_CANCELLED_UPLOAD_FLAG:
      // eslint-disable-next-line no-case-declarations
      let statusList = { ...state.uploadRequestsStatus } || {};
      statusList[action.payload.requestId] = !action.payload.uploadCancelled;

      return {
        ...state,
        uploadRequestsStatus: statusList
      }
    case UPLOAD_SET_CONFIG:
      return {
        ...state,
        config: action.payload
      }
    default:
      return state;
  }
}
