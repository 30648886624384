export const AUDIT_SET_STATE = 'AUDIT_SET_STATE';
export const AUDIT_SET_ENTRIES = 'AUDIT_SET_ENTRIES';
export const AUDIT_SET_FILTER = 'AUDIT_SET_FILTER';
export const AUDIT_SHOW_LOADING_ICON = 'AUDIT_SHOW_LOADING_ICON';
export const AUDIT_HIDE_LOADING_ICON = 'AUDIT_HIDE_LOADING_ICON';
export const AUDIT_LOAD ='AUDIT_LOAD';

export const setAuditState = auditState => ({ type: AUDIT_SET_STATE, payload: auditState });
export const setAuditEntries = data => ({ type: AUDIT_SET_ENTRIES, payload: data });
export const setAuditFilter = filter => ({ type: AUDIT_SET_FILTER, payload: filter });
export const showAuditLoadingIcon = () => ({ type: AUDIT_SHOW_LOADING_ICON });
export const hideAuditLoadingIcon = () => ({ type: AUDIT_HIDE_LOADING_ICON });
export const loadAudit = filter => ({ type: AUDIT_LOAD, payload: filter });
