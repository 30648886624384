import { Col, Modal, Row } from 'react-bootstrap';
import { confirmCards, hideModalForCardConfirmation } from 'actions/card';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import { showLoadingSpinner } from 'actions/loadingSpinner';

class CardConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionCount: ''
    }

    this.confirmCards = this.confirmCards.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectionCount: this.props.selectedCardsCount == 1 ? '1 selectie' : `${this.props.selectedCardsCount} selectii`
    });
  }

  confirmCards() {
    this.props.confirmCards();
    this.props.hideModalForCardConfirmation();
    this.props.showLoadingSpinner();
  }

  render() {
    return (
      <Modal className="confirmation-modal"
        show
        backdrop='static'
        onHide={this.props.hideModalForCardConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Confirma predarea cardurilor ({this.state.selectionCount})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Esti sigur ca vrei sa confirmi predarea cardurilor selectate?</p>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm="auto">
              <button className="main-button white" onClick={this.props.hideModalForCardConfirmation}>
                RENUNTA
              </button>
            </Col>
            <Col sm="auto">
              <button className="main-button" onClick={this.confirmCards}>
                CONFIRMA
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.card };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  confirmCards,
  hideModalForCardConfirmation,
  showLoadingSpinner
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardConfirmationModal);
