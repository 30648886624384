import { Col, Modal, Row } from 'react-bootstrap';
import { hideConfirmationModalForCardReissue, reissueCards } from 'actions/card';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import { showLoadingSpinner } from 'actions/loadingSpinner';

class CardReissueConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionCount: ''
    }

    this.reissueCards = this.reissueCards.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectionCount: this.props.selectedCardsCount == 1 ? '1 selectie' : `${this.props.selectedCardsCount} selectii`
    });
  }

  reissueCards() {
    this.props.reissueCards();
    this.props.hideConfirmationModalForCardReissue();
    this.props.showLoadingSpinner();
  }

  render() {
    return (
      <Modal className="confirmation-modal"
        show
        backdrop='static'
        onHide={this.props.hideConfirmationModalForCardReissue}>
        <Modal.Header closeButton>
          <Modal.Title>Confirma reemiterea cardurilor ({this.state.selectionCount})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Esti sigur ca vrei sa reemiti cardurile selectate?</p>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm="auto">
              <button className="main-button white" onClick={this.props.hideConfirmationModalForCardReissue}>
                RENUNTA
              </button>
            </Col>
            <Col sm="auto">
              <button className="main-button" onClick={this.reissueCards}>
                CONFIRMA
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.card };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  hideConfirmationModalForCardReissue,
  reissueCards,
  showLoadingSpinner
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardReissueConfirmationModal);
