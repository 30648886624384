import { bindActionCreators } from 'redux';
import { blockCurrentBranchSelection } from 'actions/branch';
import BulkDelete from './BulkDelete';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { setUploadConfig } from 'actions/upload';

const mapStateToProps = state => { return { ...state.router } };
const mapDispatchToProps = dispatch => bindActionCreators({
    setUploadConfig,
    blockCurrentBranchSelection,
    replace,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BulkDelete);
