import {
  USER_SAVE_INFORMATION,
  USER_SET_LOGIN_ERROR,
  USER_SET_OTP_CAPTCHA,
  USER_SET_PASSWORD_CAPTCHA,
  USER_SET_PHONE_NUMBER_CAPTCHA,
  USER_SET_SMS_CODE,
  USER_SET_USERNAME_CAPTCHA
} from 'actions/user';

export default function user(state, action) {
  state = state || {};

  switch (action.type) {
    case USER_SAVE_INFORMATION:
      return { ...state, ...action.payload };
    case USER_SET_LOGIN_ERROR:
      return { ...state, loginError: action.payload };
    case USER_SET_SMS_CODE:
      return { ...state, smsToken: action.payload.smsToken };
    case USER_SET_USERNAME_CAPTCHA:
      return { ...state, usernameCaptcha: action.payload.captcha, loginError: action.payload.error };
    case USER_SET_OTP_CAPTCHA:
      return { ...state, otpCaptcha: action.payload.captcha, loginError: action.payload.error };
    case USER_SET_PASSWORD_CAPTCHA:
      return { ...state, passwordCaptcha: action.payload.captcha, loginError: action.payload.error };
    case USER_SET_PHONE_NUMBER_CAPTCHA:
      return { ...state, phoneNumberCaptcha: action.payload.captcha, loginError: action.payload.error };
    default:
      return state;
  }
}
