import {
  ADD_USER_TO_BRANCH_REQUEST,
  BRANCH_DISABLE_REQUEST,
  BRANCH_ENABLE_REQUEST,
  BRANCH_FIND_REQUEST,
  BRANCH_UPSERT_REQUEST,
  CITIES_FIND_REQUEST,
  LOCALITIES_FIND_REQUEST,
  REMOVE_USER_FROM_BRANCH_REQUEST
} from 'actions/branch';

import {
  addUserToBranchFailure,
  addUserToBranchSuccess,
  disableBranchFailure,
  disableBranchSuccess,
  enableBranchFailure,
  enableBranchSuccess,
  findBranchesFailure,
  findBranchesSuccess,
  findCitiesFailure,
  findCitiesSuccess,
  findLocalitiesFailure,
  findLocalitiesSuccess,
  removeUserFromBranchFailure,
  removeUserFromBranchSuccess,
  setBranchesLoadedFlag,
  upsertBranchFailure,
  upsertBranchSuccess
} from 'actions/branch';

import {
  AUDIT_LOAD,
  hideAuditLoadingIcon,
  setAuditEntries,
  showAuditLoadingIcon
} from 'actions/audit';

import {
  BRANCH_USER_FIND_REQUEST,
  findBranchUsersFailure,
  findBranchUsersSuccess,
} from 'actions/branchUser';

import {
  CARD_CONFIRM_CARDS_REQUEST,
  CARD_DELETE_REQUEST,
  CARD_FIND_CARDS_REQUEST,
  CARD_FIND_MINIMAL_CARDS_REQUEST,
  CARD_REISSUE_CARDS_REQUEST,
  CHANGE_TICKET_VALUE_REQUEST,
  changeTicketValueFailure,
  changeTicketValueSuccess,
  confirmCardsFailure,
  confirmCardsSuccess,
  deleteCardFailure,
  deleteCardSuccess,
  findCardsFailure,
  findCardsSuccess,
  findMinimalCardsFailure,
  findMinimalCardsSuccess,
  MIGRATE_CARDS_REQUEST,
  migrateCardsFailure,
  migrateCardsSuccess,
  REISSUE_CARD_REQUEST,
  REISSUE_PIN_REQUEST,
  reissueCardFailure,
  reissueCardsFailure,
  reissueCardsSuccess,
  reissueCardSuccess,
  reissuePinFailure,
  reissuePinSuccess,
  setCardFilters,
  setCardsLoadedFlag
} from 'actions/card';

import {
  changePasswordFailure,
  changePasswordSuccess,
  expiredPassword,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  generateSmsTokenFailure,
  generateSmsTokenSuccess,
  getUserDataFailure,
  getUserDataSuccess,
  saveUserInformation,
  submitPasswordFailure,
  submitPasswordSuccess,
  submitPhoneNumberFailure,
  submitPhoneNumberSuccess,
  submitSmsTokenFailure,
  submitSmsTokenSuccess,
  submitUsernameFailure,
  submitUsernameSuccess,
  verifyPasswordFailure,
  verifyPasswordSuccess,
} from 'actions/user';

import {
  createManagerFailure,
  createManagerSuccess,
  deleteManagerFailure,
  deleteManagerSuccess,
  findManagersFailure,
  findManagersSuccess,
  MANAGER_CREATE_REQUEST,
  MANAGER_DELETE_REQUEST,
  MANAGER_FIND_REQUEST,
  MANAGER_UPDATE_REQUEST,
  setManagersLoadedFlag,
  updateManagerFailure,
  updateManagerSuccess
} from 'actions/manager';

import { fileUploadType, selectionType } from 'utils/enums';

import {
  findOrdersFailure,
  findOrdersSuccess,
  ORDER_FIND_REQUEST,
  setOrdersLoadedFlag
} from 'actions/order';

import {
  findSapInvoicesFailure,
  findSapInvoicesSuccess,
  rectifySapInvoiceFail,
  rectifySapInvoiceSuccess,
  SAP_INVOICE_FIND_REQUEST,
  SAP_INVOICE_RECTIFY_REQUEST,
  showSapInvoiceLoadingIcon
} from 'actions/sapInvoice';

import {
  ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_REQUEST,
  sendCorrectedItemsFailure,
  sendCorrectedItemsSuccess,
  setIsCaptchaValid,
  setSendCorrectedItemsInProgress
} from 'actions/itemCorrection';

import {
  setCancelledUploadFlag,
  showLoadingScreen,
  UPLOAD_CSV_FILE_REQUEST,
  uploadCsvFileFailure,
  uploadCsvFileSuccess
} from 'actions/upload';

import { toggleModalVisibility, toggleSubmitButton } from 'actions/customModal';

import {
  USER_CHANGE_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_GENERATE_SMS_TOKEN_REQUEST,
  USER_GENERATE_XSRF_TOKEN,
  USER_GET_USER_DATA_REQUEST,
  USER_LOGOUT,
  USER_PING_SERVER,
  USER_SUBMIT_PASSWORD_REQUEST,
  USER_SUBMIT_PHONE_NUMBER_REQUEST,
  USER_SUBMIT_SMS_TOKEN_REQUEST,
  USER_SUBMIT_USERNAME_REQUEST,
  USER_VERIFY_PASSWORD_REQUEST
} from 'actions/user';

import errorCodes from 'utils/errorCodes';
import { hideLoadingSpinner } from 'actions/loadingSpinner';
import httpClient from 'utils/httpClient';
import { showToast } from 'actions/toast';

const buildURL = (baseUrl, pageNumber, pageSize, branchId, filters) => {
  const firstOrLastNameQuery = filters && filters.firstOrLastName ? `&firstOrLastName=${filters.firstOrLastName}` : '';
  const cnpQuery = filters && filters.cnp ? `&cnp=${filters.cnp}` : '';
  const statusIdQuery = `?statusId=${filters && Number.isInteger(filters.statusId) ? filters.statusId : null}`;
  const ticketValueQuery = filters && filters.ticketValue ? `&ticketValue=${filters.ticketValue}` : '';
  const orderIdQuery = filters && Number.isInteger(filters.orderId) ? `&orderId=${filters.orderId}` : '';
  const branchIdToSend = !branchId || branchId <= 0 ? null : branchId;

  const url = baseUrl +
    statusIdQuery +
    `&pageNumber=${pageNumber}` +
    `&pageSize=${pageSize}` +
    `&branchId=${branchIdToSend}` +
    orderIdQuery +
    firstOrLastNameQuery +
    cnpQuery +
    ticketValueQuery;

  return url;
}

const cardFiltersAreSet = (filters) => {
  return (filters.firstOrLastName && filters.firstOrLastName !== '') ||
    (filters.cnp && filters.cnp !== '') ||
    (filters.statusId !== null && filters.statusId !== undefined && filters.statusId !== '') ||
    (filters.ticketValue && filters.ticketValue !== '') ||
    (filters.orderId && filters.orderId !== '');
}

const api = ({ getState, dispatch }) => {
  return next => async (action) => {
    try {
      switch (action.type) {
        case BRANCH_FIND_REQUEST: {
          dispatch(setBranchesLoadedFlag(false));

          const url = '/branch/findBranches',
            response = await httpClient.get(url),
            result = await response.json();
          if (result) {
            next(findBranchesSuccess(result));
          } else {
            next(findBranchesFailure());
          }
          break;
        }
        case CITIES_FIND_REQUEST: {
          const url = '/location/FindAllCities',
            response = await httpClient.get(url),
            result = await response.json();

          if (result) {
            next(findCitiesSuccess(result));
          } else {
            next(findCitiesFailure());
          }
          break;
        }
        case LOCALITIES_FIND_REQUEST: {
          const url = `/location/FindAllLocalitiesByCityId?cityId=${action.payload}`,
            response = await httpClient.get(url),
            result = await response.json();

          if (result) {
            next(findLocalitiesSuccess(result));
          } else {
            next(findLocalitiesFailure());
          }
          break;
        }
        case BRANCH_USER_FIND_REQUEST: {
          const url = '/user/find',
            response = await httpClient.get(url),
            result = await response.json();

          if (result) {
            next(findBranchUsersSuccess(result));
          } else {
            next(findBranchUsersFailure());
          }
          break;
        }
        case ADD_USER_TO_BRANCH_REQUEST: {
          const { userId, branchId } = action.payload,
            url = `/user/addUserToBranch?userId=${userId}&branchId=${branchId}`,
            response = await httpClient.post(url),
            result = await response.json();

          if (result) {
            if (result.errorMessage) {
              next(addUserToBranchFailure(result.errorMessage));
            } else {
              next(addUserToBranchSuccess(userId, branchId));
            }
          } else {
            next(addUserToBranchFailure('A intervenit o eroare'));
          }
          break;
        }
        case REMOVE_USER_FROM_BRANCH_REQUEST: {
          const { userId, branchId } = action.payload,
            url = `/user/removeUserFromBranch?userId=${userId}&branchId=${branchId}`,
            response = await httpClient.post(url),
            result = await response.json();

          if (result) {
            if (result.errorMessage) {
              next(removeUserFromBranchFailure(result.errorMessage));
            } else {
              next(removeUserFromBranchSuccess(userId, branchId));
            }
          } else {
            next(removeUserFromBranchFailure('A intervenit o eroare'));
          }
          break;
        }
        case BRANCH_UPSERT_REQUEST: {
          const url = '/branch/upsertBranch',
            response = await httpClient.post(url, action.payload),
            result = await response.json();

          if (!result.errorMessage && result.branch) {
            next(upsertBranchSuccess(result.branch));
          } else {
            next(upsertBranchFailure(result.errorMessage));
          }
          break;
        }
        case MANAGER_FIND_REQUEST: {
          dispatch(setManagersLoadedFlag(false));
          const { currentPage, pageSize, searchTerm } = getState().manager;

          const searchTermQuery = searchTerm ? `&searchTerm=${searchTerm}` : '',
            url = '/user/findPaginated' +
              `?pageNumber=${currentPage}` +
              `&pageSize=${pageSize}` +
              searchTermQuery;

          if (url !== null && url !== '') {
            let response = await httpClient.get(url);
            let result = await response.json();
            if (result) {
              next(findManagersSuccess(result.entities, result.totalCount));
            } else {
              next(findManagersFailure());
            }
          }

          break;
        }
        case MANAGER_CREATE_REQUEST: {
          let response = await httpClient.post('/BranchUser/CreateAndAssignManager', action.payload),
            result = await response.json();

          if (result.errorMessage) {
            next(createManagerFailure(result.errorMessage));
          } else {
            if (result.userId) {
              next(createManagerSuccess({ ...action.payload, userId: result.userId, employerId: action.payload.employerId }, action.payload.showToast));
            }
          }
          break;
        }
        case MANAGER_UPDATE_REQUEST: {
          let response = await httpClient.post('/User/Update', action.payload),
            result = await response.json();

          if (result.errorMessage) {
            next(updateManagerFailure(result.errorMessage));
          } else {
            if (result.userId) {
              next(updateManagerSuccess({ ...action.payload, userId: result.userId, employerId: action.payload.employerId }, action.payload.showToast));
            }
          }
          break;
        }
        case MANAGER_DELETE_REQUEST: {
          let response = await httpClient.post(`/User/Delete?userId=${action.payload.userId}`),
            result = await response.json();

          next(hideLoadingSpinner());
          if (result.errorMessage) {
            next(deleteManagerFailure(result.errorMessage));
          } else {
            next(deleteManagerSuccess(action.payload.lastName + ' ' + action.payload.firstName, action.payload.userId));
          }
          break;
        }
        case CARD_FIND_CARDS_REQUEST: {
          dispatch(setCardsLoadedFlag(false));

          const stateCurrentBranch = getState().branch.currentBranch,
            branchId = action.payload ? action.payload.id : stateCurrentBranch ? stateCurrentBranch.id : null,
            stateCard = getState().card,
            currentPage = stateCard.currentPage,
            pageSize = stateCard.pageSize,
            filters = stateCard.filters,
            url = buildURL('/card/find', currentPage, pageSize, branchId, filters);
          if (url !== null && url !== '') {
            let response = await httpClient.get(url),
              result = await response.json();
            if (result) {
              next(findCardsSuccess(result.cards, result.totalCount));
            } else {
              next(findCardsFailure());
            }
          }
          break;
        }
        case CARD_FIND_MINIMAL_CARDS_REQUEST: {
          const stateCurrentBranch = getState().branch.currentBranch,
            branchId = stateCurrentBranch ? stateCurrentBranch.id : null,
            stateCard = getState().card,
            filters = stateCard.filters,
            url = buildURL('/card/findMinimalCardsInfo', null, null, branchId, filters);

          if (url !== null && url !== '') {
            let response = await httpClient.get(url),
              result = await response.json();

            if (response.ok && result) {
              const cards = result.minimalCardsInfos.map(card => (
                {
                  cardId: card.id,
                  fullName: card.lastName + ' ' + card.firstName,
                  cnp: card.cnp,
                  statusId: card.statusId,
                  ticketValue: card.ticketValue,
                  orderId: card.orderId
                }));

              next(findMinimalCardsSuccess(cards));
            } else {
              next(findMinimalCardsFailure(result.errorMessage));
            }
          }
          break;
        }
        case CARD_DELETE_REQUEST: {
          const cardId = action.payload,
            card = getState().card.cards.filter(c => c.id === cardId)[0],
            url = `/card/delete?cardId=${cardId}&branchId=${card.branchId}`,
            response = await httpClient.post(url),
            result = response ? await response.json() : null,
            errorMessage = result ? result.errorMessage : null;

          if (!result || errorMessage) {
            next(hideLoadingSpinner());
            next(deleteCardFailure(errorMessage));
          } else {
            next(deleteCardSuccess(cardId, card.branchId));
          }
          break;
        }
        case REISSUE_CARD_REQUEST: {
          const url = '/card/reissueCard',
            response = await httpClient.post(url, action.payload),
            result = await response.json(),
            errorMessage = result ? result.errorMessage : null;

          if (!result || errorMessage) {
            next(reissueCardFailure(result.status, errorMessage));
          } else {
            next(reissueCardSuccess(action.payload.CardId, result.cardMigrated));
          }
          break;
        }
        case REISSUE_PIN_REQUEST: {
          const url = '/card/reissuePin',
            response = await httpClient.post(url, action.payload),
            result = await response.json(),
            errorMessage = result ? result.errorMessage : null;

          if (!result || errorMessage) {
            next(reissuePinFailure(result.status, errorMessage));
          } else {
            next(reissuePinSuccess(action.payload.CardId, result.cardMigrated));
          }
          break;
        }
        case ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_REQUEST: {
          let items, uploadUrl, response, result, itemsToBeCorrected, branchId, uploadTypeId;
          uploadTypeId = getState().itemCorrection.uploadTypeId;
          switch (uploadTypeId) {
            case fileUploadType.cardsImport:
            case fileUploadType.cardsDelete:
            case fileUploadType.cardsImportAllBranches:
            case fileUploadType.cardsModifyTicketValue:
              {
                const { captchaId, captchaCode } = action.payload || {},
                  captcha = { captchaId: captchaId, captchaCode: captchaCode };

                items = getState().itemCorrection.itemsToBeCorrected.map(i => {
                  return {
                    firstName: i.firstName,
                    lastName: i.lastName,
                    cnp: i.cnp,
                    phoneNumber: i.phoneNumber,
                    email: i.email,
                    ticketValue: i.ticketValue,
                    branchName: i.branchName
                  }
                });
                branchId = getState().branch.currentBranch.id || -1;
                uploadUrl = '/card/upload';
                response = await httpClient.post(uploadUrl, {
                  cards: items,
                  employerId: getState().user.employerId,
                  branchId: branchId,
                  uploadTypeId: uploadTypeId,
                  captcha: captcha
                });
                result = await response.json();

                if (result.cardUploadResult && result.cardUploadResult.findIndex(cardEntity => cardEntity.errors.length != 0) === -1) {
                  next(sendCorrectedItemsSuccess());
                }
                else if (result.errorMessage && result.errorMessage.includes(errorCodes.captchaRequired)) {
                  //show captcha
                  next(toggleModalVisibility(true));
                }
                else if (result.errorMessage && result.errorMessage.includes(errorCodes.invalidCaptcha)) {
                  //captcha isn't valid => reload image
                  next(setIsCaptchaValid(false));
                }
                else if (result.errorMessage && result.errorMessage.includes(errorCodes.nullIBAN)) { 
                  next(setSendCorrectedItemsInProgress(false));
                  next(showToast({ type: 'error', text: 'Lista incarcata contine carduri fara IBAN.' }));
                }
                else if(result.errorMessage && result.errorMessage.includes(errorCodes.duplicateIBAN)) {
                  next(showToast({ type: 'error', text: 'Lista incarcata contine carduri cu IBAN-uri duplicate.' }));
                  next(setSendCorrectedItemsInProgress(false));
                }
                else {
                  itemsToBeCorrected = result.cardUploadResult && result.cardUploadResult.map((cardEntity, index) => { return { ...cardEntity, id: index }; });

                  next(sendCorrectedItemsFailure(itemsToBeCorrected, uploadTypeId));
                }

                next(toggleSubmitButton(true));

                break;
              }
            case fileUploadType.branchesImport:
              uploadUrl = '/branch/upload';
              response = await httpClient.post(uploadUrl, {
                branches: getState().itemCorrection.itemsToBeCorrected
              });
              result = await response.json();

              if (result.branchUploadResult && result.branchUploadResult.findIndex(cardEntity => cardEntity.errors.length != 0) === -1) {
                next(sendCorrectedItemsSuccess());
              }
              else {
                itemsToBeCorrected = result.branchUploadResult.map((cardEntity, index) => { return { ...cardEntity, id: index }; });

                next(sendCorrectedItemsFailure(itemsToBeCorrected, uploadTypeId));
              }
              break;
          }
          break
        }
        case UPLOAD_CSV_FILE_REQUEST: {
          const { requestId, csvFile } = action.payload;
          const uploadTypeId = parseInt(csvFile.get('uploadTypeId'));
          let uploadUrl, response, result;
          next(showLoadingScreen(true));
          next(setCancelledUploadFlag(false, requestId));

          switch (uploadTypeId) {
            case fileUploadType.cardsImport:
            case fileUploadType.cardsImportAllBranches:
            case fileUploadType.cardsDelete:
            case fileUploadType.cardsModifyTicketValue:
              uploadUrl = '/card/uploadFile';
              response = await httpClient.upload(uploadUrl, csvFile);
              result = await response.json();
              if (getState().upload.uploadRequestsStatus[requestId]) {
                next(showLoadingScreen(false));
                result.errorMessage
                  ? next(uploadCsvFileFailure(result.errorMessage))
                  : next(uploadCsvFileSuccess(result.cardUploadResult, uploadTypeId));
              }
              break;
            case fileUploadType.branchesImport:
              uploadUrl = '/branch/uploadFile';
              response = await httpClient.upload(uploadUrl, csvFile);
              result = await response.json();

              if (getState().upload.uploadRequestsStatus[requestId]) {
                next(showLoadingScreen(false));
                result.errorMessage
                  ? next(uploadCsvFileFailure(result.errorMessage))
                  : next(uploadCsvFileSuccess(result.branchUploadResult, uploadTypeId));
              }
              break;
          }

          break;
        }
        case USER_CHANGE_PASSWORD_REQUEST: {
          const { currentPassword, newPassword } = action.payload,
            response = await httpClient.post('/account/changepassword', {
              currentPassword,
              newPassword
            }),
            result = await response.json();

          const changePasswordButton = document.querySelector('.password-setup-view #changePasswordButton');
          changePasswordButton.classList.remove('loading', 'disabled');

          if (!result.errorMessage) {
            next(changePasswordSuccess());
          } else {
            next(changePasswordFailure(result.errorMessage));
          }
          break;
        }
        case USER_FORGOT_PASSWORD_REQUEST: {
          const username = getState().user.username,
            response = await httpClient.post(`/account/forgotpassword?username=${username}`),
            result = await response.json();

          if (!result.errorMessage) {
            next(forgotPasswordSuccess(result));
          } else {
            next(forgotPasswordFailure(result.errorMessage));
          }
          break;
        }
        case USER_SUBMIT_PHONE_NUMBER_REQUEST: {
          const username = getState().user.username,
            response = await httpClient.post('/account/submitphonenumber',
              {
                username: username,
                phoneNumber: action.payload.phoneNumber,
                captchaCode: action.payload.captchaCode,
                captchaId: action.payload.captchaId
              }),
            result = await response.json();

          const submitPhoneNumberButton = document.querySelector('.password-view #submitPhoneNumberButton');
          submitPhoneNumberButton.classList.remove('loading', 'disabled');
          if (!result.errorMessage) {
            next(submitPhoneNumberSuccess());
          } else {
            next(submitPhoneNumberFailure(result.errorMessage));
          }
          break;
        }
        case USER_GENERATE_SMS_TOKEN_REQUEST: {

          const isChangePassword = getState().user.isChangePassword,
            response = await httpClient.post(`/account/generatetoken?changepassword=${isChangePassword}`),
            result = await response.json();

          if (result && !result.errorMessage) {
            next(generateSmsTokenSuccess(result));
          } else {
            next(generateSmsTokenFailure(result));
          }

          break;
        }
        case USER_GET_USER_DATA_REQUEST: {
          const response = await httpClient.get('/account/getuser');
          const user = await response.json();
          if (user && user.status === 1) {
            next(getUserDataSuccess(user));
            window.localStorage.setItem('userRoleId', user.roleId);
          } else {
            next(getUserDataFailure());
          }

          break;
        }
        case USER_LOGOUT: {
          window.onbeforeunload = undefined;
          await httpClient.post('/account/logout');
          window.localStorage.removeItem('userRoleId');
          window.localStorage.setItem('userLoggedOut', true);
          window.location.reload();
          break;
        }

        case USER_SUBMIT_SMS_TOKEN_REQUEST: {

          const isChangePassword = getState().user.isChangePassword ? true : false,
            response = await httpClient.post('/account/verifytoken', {
              smsToken: action.payload.smsToken,
              isChangePassword: isChangePassword,
              captchaCode: action.payload.captchaCode,
              captchaId: action.payload.captchaId
            }),
            result = await response.json();

          const submitTokenButton = document.querySelector('.sms-token-view #submitTokenButton');
          submitTokenButton.classList.remove('loading', 'disabled');

          if (!result.errorMessage) {
            next(submitSmsTokenSuccess(result));
          } else {
            next(submitSmsTokenFailure(result.errorMessage));
          }

          break;
        }

        case USER_VERIFY_PASSWORD_REQUEST: {
          const username = getState().user.username,
            response = await httpClient.post('/account/verifyPassword', {
              username: username,
              password: action.payload.password,
              captchaCode: action.payload.captchaCode,
              captchaId: action.payload.captchaId
            }),
            result = await response.json();

          next(saveUserInformation({ password: action.payload, username: getState().user.username }));

          const submitPasswordButton = document.querySelector('.password-view #submitPasswordButton');
          submitPasswordButton.classList.remove('loading', 'disabled');

          if (!result.errorMessage) {
            next(verifyPasswordSuccess(action.payload));
            next(saveUserInformation({ username: getState().user.username, isChangePassword: true }));
          } else {
            next(verifyPasswordFailure(result.errorMessage));
          }

          break;
        }
        case USER_PING_SERVER: {
          let response = await httpClient.get('/home/heartbeat');
          if (response.redirected) {
            window.onbeforeunload = undefined;
            window.location.replace(`${window.location.protocol}//${window.location.host}/account/login?sessionExpired=true`);
          }

          break;
        }
        case USER_SUBMIT_USERNAME_REQUEST: {
          const response = await httpClient.post('/account/verifyuser', {
            username: action.payload.username,
            captchaCode: action.payload.captchaCode,
            captchaId: action.payload.captchaId
          }),
            result = await response.json();

          next(saveUserInformation({ username: action.payload.username }));

          // remove the loading icon on the button
          const loginButton = document.querySelector('.username-view #loginButton');
          loginButton.classList.remove('loading', 'disabled');

          if (!result.errorMessage) {
            next(submitUsernameSuccess(result));
          } else {
            next(submitUsernameFailure(result.errorMessage));
          }

          break;
        }
        case USER_SUBMIT_PASSWORD_REQUEST: {
          const username = getState().user.username,
            response = await httpClient.post('/account/login', {
              username,
              password: action.payload.password,
              captchaCode: action.payload.captchaCode,
              captchaId: action.payload.captchaId
            }),
            result = await response.json();

          next(saveUserInformation({ password: action.payload, username: getState().user.username }));

          const submitPasswordButton = document.querySelector('.password-view #submitPasswordButton');
          submitPasswordButton.classList.remove('loading', 'disabled');

          if (!result.errorMessage) {
            next(submitPasswordSuccess(action.payload));
            next(saveUserInformation({ username: getState().user.username }));
          }
          else if (result.errorMessage === errorCodes.passwordExpired) {
            next(saveUserInformation({ isForgotPassword: true }));
            next(expiredPassword(result));
          } else {
            next(submitPasswordFailure(result.errorMessage));
          }

          break;
        }
        case ORDER_FIND_REQUEST: {
          dispatch(setOrdersLoadedFlag(false));
          const { branchId, viewLastCreatedOrder } = action.payload,
            url = `/order/find?branchId=${branchId}`,
            response = await httpClient.get(url),
            orders = await response.json();

          if (orders) {
            const cardState = getState().card;
            const existingOrder = orders.filter(order => order.id === cardState.filters.orderId)[0];
            const filterValues = existingOrder ? { ...cardState.filters } : { ...cardState.filters, orderId: null };

            next(setCardFilters(filterValues));
            next(findOrdersSuccess(orders, viewLastCreatedOrder));
          } else {
            next(findOrdersFailure());
          }
          break;
        }
        case CARD_CONFIRM_CARDS_REQUEST: {
          const cardState = getState().card,
            selectType = cardFiltersAreSet(cardState.filters) ? selectionType.multiple : cardState.selectState,
            selectedCards = cardFiltersAreSet(cardState.filters) && selectType === selectionType.all ? cardState.cards.map(card => card.id) : cardState.selectedCards.map(card => card.cardId),
            requestPayload = {
              branchId: getState().branch.currentBranch.id,
              cardIds: selectedCards,
            },
            url = '/card/confirmcards', response = await httpClient.post(url, requestPayload),
            result = await response.json();
          next(hideLoadingSpinner());
          if (!result.errorMessage) {
            next(confirmCardsSuccess(result.status, result.text));
          } else {
            next(confirmCardsFailure(result.text));
          }
          break;
        }
        case CARD_REISSUE_CARDS_REQUEST: {
          const cardState = getState().card,
            selectType = cardFiltersAreSet(cardState.filters) ? selectionType.multiple : cardState.selectState,
            selectedCards = cardFiltersAreSet(cardState.filters) && selectType === selectionType.all ? cardState.cards.map(c => { return c.id }) : cardState.selectedCards.map(c => { return c.cardId }),
            requestPayload = {
              branchId: getState().branch.currentBranch.id,
              cardIds: selectedCards,
            },
            url = '/card/reissueCards',
            response = await httpClient.post(url, requestPayload),
            result = await response.json();

          if (!result.errorMessage) {
            next(reissueCardsSuccess(result.status, result.text));
          } else {
            next(hideLoadingSpinner());
            next(reissueCardsFailure(result.text));
          }
          break;
        }
        case MIGRATE_CARDS_REQUEST: {
          const { selectedCards } = getState().card,
            { currentBranch } = getState().branch;

          let requestPayload = {
            cardIds: [],
            fromBranchId: currentBranch ? currentBranch.id : null,
            branchId: action.payload
          };

          selectedCards.map(card => requestPayload.cardIds.push(card.cardId));

          const url = '/card/migrateCards',
            response = await httpClient.post(url, requestPayload),
            result = await response.json();

          next(hideLoadingSpinner());
          if (!result.errorMessage) {
            next(migrateCardsSuccess(result.status, result.text, result.migratedCardsCount, result.remainingSelectedCardIds));
          } else {
            next(migrateCardsFailure(result.text));
          }
          break;
        }
        case CHANGE_TICKET_VALUE_REQUEST: {
          const { selectedCards } = getState().card;

          let requestPayload = {
            cardIds: [],
            ticketValue: action.payload
          };

          selectedCards.map(card => requestPayload.cardIds.push(card.cardId));

          const url = '/card/changeTicketValue',
            response = await httpClient.post(url, requestPayload),
            result = await response.json();

          next(hideLoadingSpinner());
          if (!result.errorMessage) {
            next(changeTicketValueSuccess(result.status, result.text, result.metadata));
          } else {
            next(changeTicketValueFailure(result.text));
          }
          break;
        }
        case BRANCH_DISABLE_REQUEST: {
          const url = `/branch/disableBranch?branchId=${action.payload}`,
            response = await httpClient.post(url),
            result = await response.json();

          next(hideLoadingSpinner());
          if (result) {
            next(disableBranchSuccess(result.status, result.text));
          } else {
            next(disableBranchFailure(result.errorMessage));
          }
          break;
        }
        case BRANCH_ENABLE_REQUEST: {
          const url = `/branch/enableBranch?branchId=${action.payload.branchId}&branchName=${action.payload.branchName}`,
            response = await httpClient.post(url),
            result = await response.json();

          if (result) {
            next(enableBranchSuccess());
          } else {
            next(enableBranchFailure(result.errorMessage));
          }
          break;
        }
        case AUDIT_LOAD: {
          next(showAuditLoadingIcon());
          const url = '/Audit/GetPaginated?' +
            `actionType=${action.payload.actionType}&` +
            `startDate=${action.payload.startDate || ''}&` +
            `endDate=${action.payload.endDate || ''}&` +
            `username=${action.payload.username || ''}&` +
            `pageNumber=${action.payload.pageNumber}&` +
            `pageSize=${action.payload.pageSize}`,
            response = await httpClient.get(url),
            data = await response.json();

          next(setAuditEntries(data));
          next(hideAuditLoadingIcon());
          break;
        }
        case USER_GENERATE_XSRF_TOKEN: {
          const xsrfResponse = await httpClient.get('/antiforgery/get')
          const token = await xsrfResponse.text();
          localStorage.setItem('xsrfToken', token);
          break;
        }
        case SAP_INVOICE_FIND_REQUEST: {
          next(showSapInvoiceLoadingIcon());
          const { startDate, endDate, pageNumber, pageSize } = action.payload,
            url = `/bill/findSapInvoices?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
          const response = await httpClient.get(url);
          if (response.status === 200) {
            const result = await response.json();
            next(findSapInvoicesSuccess(result));
          } else {
            next(findSapInvoicesFailure());
          }
          break;
        }
        case SAP_INVOICE_RECTIFY_REQUEST: {
          const url = '/bill/RectifySapInvoice',
            response = await httpClient.post(url, action.payload),
            result = await response.ok;

          if (result) {
            next(rectifySapInvoiceSuccess());
          } else {
            next(rectifySapInvoiceFail());
          }
          break;
        }
      }
    }
    catch (err) {
      location.reload();
    }
    const returnValue = next(action);

    return returnValue;
  }
}

export default api;
