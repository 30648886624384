import './BranchDashboard.scss';
import { BranchImportContainer, SearchBar } from 'components';
import { branchStatus, userRole } from 'utils/enums';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Route, Switch } from 'react-router';
import BranchCard from './BranchCard';
import { connect } from 'react-redux';
import FirstBranchCard from './FirstBranchCard';
import React from 'react';
import routes from 'utils/routes';

class BranchDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: ''
    };
    this.searchBranches = this.searchBranches.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.renderBranches = this.renderBranches.bind(this);
    this.renderDashboard = this.renderDashboard.bind(this);
  }

  searchBranches(event) {
    const value = (event && event.currentTarget.value || '').toLowerCase();
    this.setState({
      searchTerm: value
    });
  }

  clearInput() {
    const input = document.getElementById('search-bar-input-branches');
    input.value = '';
    this.setState({ searchTerm: '' });
  }

  renderBranches(branches) {
    if (this.state.searchTerm !== '' && branches.length === 0) {
      return (
        <Row className="branch-cards-container">
          <Col className="col-md-auto">
            <FirstBranchCard />
          </Col>
          <Col className="branch-no-results text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
              <g fill="none">
                <path d="M0 0h24v24H0z"></path>
                <path fill="#414751" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
              </g>
            </svg>
            <span>Nu am gasit nici un rezultat.</span>
          </Col>
        </Row>
      );
    } else {
      const cards = branches.map(branch =>
        <Col key={branch.id} className="col-md-auto">
          <BranchCard
            users={this.props.users}
            name={branch.name}
            address={branch.address}
            branchUserIds={branch.userIds}
            id={branch.id}
            isActive={branch.status === branchStatus.enabled}
            contactPersonName={branch.contactPersonName}
            contactPersonPhoneNumber={branch.contactPersonPhoneNumber} />
        </Col>
      );

      return (
        <Row className="branch-cards-container">
          {
            this.props.userRoleId === userRole.ADMIN &&
            <Col className="col-md-auto">
              <FirstBranchCard />
            </Col>
          }
          {cards}
        </Row>
      );
    }
  }

  renderDashboard() {
    if (this.props.branchesLoaded) {
      let branches = this.props.branches;
      if (branches.length > 1) {
        branches = branches.slice(1);
      }
      branches = branches.filter(b => b.name.toLowerCase().startsWith(this.state.searchTerm));

      return (
        <div className="branch-dashboard">
          <Row className="justify-content-start">
            <Col sm="auto">
              <Row noGutters className="align-items-center">
                <Col sm="auto" className="branch-info-container">
                  <h2>Puncte de lucru</h2>
                </Col>
                <Col sm="auto">
                  {this.props.branches.length > 0 
                  ? <SearchBar
                    searchBarId='search-bar-input-branches'
                    placeholder='Cauta dupa nume'
                    showResetSearchButton={!!this.state.searchTerm}
                    handler={this.searchBranches}
                    clearHandler={this.clearInput}
                    value={this.state.searchTerm} />
                  : null}
                </Col>
              </Row>
            </Col>
          </Row>
          {this.renderBranches(branches)}
        </div>
      );
    }
    return (
      <Row noGutters className='branches-loading-spinner'>
        <Col className='center'>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Switch>
        <Route exact path={routes.dashboardBranch} render={() => this.renderDashboard()} />
        <Route path={routes.dashboardBranchImport} render={() => <BranchImportContainer />} />
      </Switch>
    );
  }
}

export default connect()(BranchDashboard);
