export const MANAGER_FIND_REQUEST = 'MANAGER_FIND_REQUEST';
export const MANAGER_FIND_FAILURE = 'MANAGER_FIND_FAILURE';
export const MANAGER_FIND_SUCCESS = 'MANAGER_FIND_SUCCESS';

export const MANAGER_CREATE_REQUEST = 'MANAGER_CREATE_REQUEST';
export const MANAGER_CREATE_FAILURE = 'MANAGER_CREATE_FAILURE';
export const MANAGER_CREATE_SUCCESS = 'MANAGER_CREATE_SUCCESS';

export const MANAGER_UPDATE_REQUEST = 'MANAGER_UPDATE_REQUEST';
export const MANAGER_UPDATE_FAILURE = 'MANAGER_UPDATE_FAILURE';
export const MANAGER_UPDATE_SUCCESS = 'MANAGER_UPDATE_SUCCESS';

export const MANAGER_DELETE_REQUEST = 'MANAGER_DELETE_REQUEST';
export const MANAGER_DELETE_SUCCESS = 'MANAGER_DELETE_SUCCESS';
export const MANAGER_DELETE_FAILURE = 'MANAGER_DELETE_FAILURE';

export const MANAGER_SET_SEARCH_TERM = 'MANAGER_SET_SEARCH_TERM';
export const MANAGER_SET_TOTAL_COUNT = 'MANAGER_SET_TOTAL_COUNT';
export const MANAGER_SET_PAGE_COUNT = 'MANAGER_SET_PAGE_COUNT';
export const MANAGER_SET_PAGE_SIZE = 'MANAGER_SET_PAGE_SIZE';
export const MANAGER_SET_CURRENT_PAGE = 'MANAGER_SET_CURRENT_PAGE';
export const SET_MANAGERS = 'SET_MANAGERS';

export const MANAGER_HIDE_MODAL = 'MANAGER_HIDE_MODAL';
export const MANAGER_SET_ERROR_MODAL = 'MANAGER_SET_ERROR_MODAL';
export const MANAGER_SHOW_MODAL = 'MANAGER_SHOW_MODAL';
export const SET_MANAGERS_LOADED_FLAG = 'SET_MANAGERS_LOADED_FLAG';

export const SHOW_UPDATE_MANAGER_MODAL = 'SHOW_UPDATE_MANAGER_MODAL';
export const SET_CURRENT_MANAGER = 'SET_CURRENT_MANAGER';

export const findManagersRequest = () => ({ type: MANAGER_FIND_REQUEST });
export const findManagersFailure = errorMessage => ({ type: MANAGER_FIND_FAILURE, payload: { errorMessage: errorMessage } });
export const findManagersSuccess = (entities, totalCount) => ({ type: MANAGER_FIND_SUCCESS, payload: { entities: entities, totalCount: totalCount } });

export const createManagerRequest = data => ({ type: MANAGER_CREATE_REQUEST, payload: data });
export const createManagerFailure = (errorMessage) => ({ type: MANAGER_CREATE_FAILURE, payload: { errorMessage } });
export const createManagerSuccess = (user, showToast) => ({ type: MANAGER_CREATE_SUCCESS, payload: { user, showToast } });

export const updateManagerRequest = user => ({ type: MANAGER_UPDATE_REQUEST, payload: user });
export const updateManagerFailure = (errorMessage) => ({ type: MANAGER_UPDATE_FAILURE, payload: { errorMessage } });
export const updateManagerSuccess = (user, showToast) => ({ type: MANAGER_UPDATE_SUCCESS, payload: { user, showToast } });

export const deleteManagerRequest = user => ({ type: MANAGER_DELETE_REQUEST, payload: user });
export const deleteManagerFailure = errorMessage => ({ type: MANAGER_DELETE_FAILURE, payload: { errorMessage: errorMessage } });
export const deleteManagerSuccess = (userFullName, userId) => ({ type: MANAGER_DELETE_SUCCESS, payload: { userFullName, userId } });

export const setManagers = managers => ({ type: SET_MANAGERS, payload: managers });
export const setManagersSearchTerm = searchTerm => ({ type: MANAGER_SET_SEARCH_TERM, payload: searchTerm });
export const setManagersTotalCount = totalCount => ({ type: MANAGER_SET_TOTAL_COUNT, payload: totalCount });
export const setManagersPageCount = pageCount => ({ type: MANAGER_SET_PAGE_COUNT, payload: pageCount });
export const setManagersPageSize = pageSize => ({ type: MANAGER_SET_PAGE_SIZE, payload: pageSize });
export const setManagersCurrentPage = currentPage => ({ type: MANAGER_SET_CURRENT_PAGE, payload: currentPage });

export const managersHideModal = () => ({ type: MANAGER_HIDE_MODAL });
export const managersSetModalError = error => ({ type: MANAGER_SET_ERROR_MODAL, payload: error });
export const managersShowModal = () => ({ type: MANAGER_SHOW_MODAL });
export const setManagersLoadedFlag = loaded => ({ type: SET_MANAGERS_LOADED_FLAG, payload: loaded });

export const showUpdateManagerModal = user => ({ type: SHOW_UPDATE_MANAGER_MODAL, payload: user });
export const setCurrentManager = user => ({ type: SET_CURRENT_MANAGER, payload: user });
