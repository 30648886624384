import './CardReissueModal.scss';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { hideCardReissueModal, reissueCard, setCardReissueModalError, setSendReissueCardInProgress } from 'actions/card';
import { bindActionCreators } from 'redux';
import { branchStatus } from 'utils/enums';
import { connect } from 'react-redux';
import React from 'react';
import Select from 'react-select';
import validator from 'utils/validator';

const ERROR_MANDATORY_FIELD = 'Campul este obligatoriu';
const ERROR_NAME_TOO_LONG = 'Combinatia "Nume Prenume" depaseste 23 de caractere';
const ERROR_PHONE_NUMBER_INVALID = 'Numar de telefon invalid';
const ERROR_NAME_WITH_DIGITS = 'Numele si Prenumele nu pot contine cifre';

class CardReissueModal extends React.Component {
  constructor(props) {
    super(props);

    const selectedCard = this.props.cards.filter(c => c.id === this.props.cardReissueModalShow.cardId)[0];
    this.state = {
      card: selectedCard,
      firstName: selectedCard.firstName,
      isFirstNameError: false,
      firstNameError: '',
      lastName: selectedCard.lastName,
      isLastNameError: false,
      lastNameError: '',
      isFullNameError: false,
      fullNameError: '',
      phoneNumber: selectedCard.phoneNumber,
      isPhoneNumberError: false,
      phoneNumberError: '',
      reason: -1,
      isReasonError: false,
      selectedBranch: {},
      selectedBranchLocation: '',
      reasonError: '',
      reasons: [
        { id: -1, name: 'Selecteaza motivul reemiterii...' },
        { id: 1, name: 'Card pierdut/furat' },
        { id: 4, name: 'Schimbare nume pe card' },
        { id: 5, name: 'Card deteriorat' }
      ]
    };

    this.getBranchLocation = this.getBranchLocation.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateReason = this.validateReason.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
  }

  componentDidMount() {
    let branchOptions = [];
    this.props.branches && this.props.branches.forEach(item => item.id && item.status === branchStatus.enabled && branchOptions.push({ value: item.id, label: item.name }));
    this.setState({ branchOptions });

    let selectedBranch = branchOptions.filter(b => b.value === this.state.card.branchId)[0]
    this.setState({ selectedBranch });

    let selectedBranchLocation = this.getBranchLocation(selectedBranch.value);
    this.setState({ selectedBranchLocation })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    if (name === 'reason' && value < 0) {
      this.setState({ isReasonError: true, reasonError: ERROR_MANDATORY_FIELD });
    } else {
      this.setState({ isReasonError: false, reasonError: '' });
    }
  }

  handleBranchChange(selectedBranch) {
    this.setState({ selectedBranch });

    let selectedBranchLocation = this.getBranchLocation(selectedBranch.value);
    this.setState({ selectedBranchLocation });
  }

  getBranchLocation(branchId) {
    const branch = this.props.branches.filter(b => b.id === branchId)[0];
    return branch.name + ', ' + branch.address;
  }

  validateName() {
    let isFullNameError = false,
      isFirstNameError = false,
      isLastNameError = false,
      fullNameError = '',
      firstNameError = '',
      lastNameError = '';

    if (this.state.firstName.trim() === '') {
      isFirstNameError = true;
      firstNameError = ERROR_MANDATORY_FIELD;
    }

    if (this.state.lastName.trim() === '') {
      isLastNameError = true;
      lastNameError = ERROR_MANDATORY_FIELD;
    }

    if (this.state.firstName.trim().length + this.state.lastName.trim().length > 23) {
      isFullNameError = true;
      fullNameError = ERROR_NAME_TOO_LONG;
    }

    if (this.state.firstName.match(/\d+/g) || this.state.lastName.match(/\d+/g)) {
      isFullNameError = true;
      fullNameError = ERROR_NAME_WITH_DIGITS;
    }

    this.setState({
      isFullNameError,
      fullNameError,
      isFirstNameError,
      firstNameError,
      isLastNameError,
      lastNameError
    });
  }

  validateReason() {
    if (this.state.reason < 0) {
      this.setState({ isReasonError: true, reasonError: ERROR_MANDATORY_FIELD });
    } else {
      this.setState({ isReasonError: false, reasonError: '' });
    }
  }

  validatePhoneNumber() {
    if (this.state.phoneNumber &&
      this.state.phoneNumber.trim() !== '' &&
      !validator.isValidPhoneNumber(this.state.phoneNumber)) {
      this.setState({ isPhoneNumberError: true, phoneNumberError: ERROR_PHONE_NUMBER_INVALID });
    } else {
      this.setState({ isPhoneNumberError: false, phoneNumberError: '' });
      this.props.setCardReissueModalError(null);
      return true;
    }
  }

  isModalValid() {
    this.validateName();
    this.validateReason();
    this.validatePhoneNumber();

    if (this.state.isFirstNameError
      || this.state.isLastNameError
      || this.state.isFullNameError
      || this.state.isReasonError
      || this.state.isPhoneNumberError) {
      return false;
    }
    return true;
  }

  submit() {
    setTimeout(() => {
      let isModalValid = this.isModalValid();
      if (isModalValid) {
        const { card, firstName, lastName, reason, phoneNumber } = { ...this.state }
        if (!this.props.sendReissueCardInProgress) {
          this.props.setSendReissueCardInProgress(true);
          this.props.reissueCard({
            CardId: card.id,
            FirstName: firstName,
            LastName: lastName,
            BranchId: this.state.selectedBranch.value,
            Reason: reason,
            PhoneNumber: phoneNumber
          });
        }
      }
    }, 200);
  }

  render() {
    let reasonOptions = this.state.reasons.map(r =>
      <option key={r.id} value={r.id}>{r.name}</option>
    );

    return (
      <Modal className='card-reissue confirmation-modal'
        show
        backdrop='static'
        onHide={this.props.hideCardReissueModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reemite cardul</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-lastname'>
                {this.state.isLastNameError ? <span className='error-label'>{this.state.lastNameError}</span> : null}
                {this.state.isFullNameError ? <span className='error-label fullname-error'>{this.state.fullNameError}</span> : null}
                <Form.Control autoFocus
                  name='lastName' type='text' placeholder='Nume'
                  onChange={this.handleInputChange}
                  value={this.state.lastName || ''}
                  onBlur={this.validateName} />
                <Form.Label>Nume</Form.Label>
              </Form.Group>
              <Form.Group className='form-group-label' as={Col} controlId='card-firstname'>
                {this.state.isFirstNameError ? <span className='error-label'>{this.state.firstNameError}</span> : null}
                {this.state.isFullNameError ? <span className='error-label fullname-error'></span> : null}
                <Form.Control autoFocus
                  name='firstName' type='text'
                  placeholder='Prenume'
                  onChange={this.handleInputChange}
                  value={this.state.firstName || ''}
                  onBlur={this.validateName} />
                <Form.Label>Prenume</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-cnp'>
                <Form.Control autoFocus readOnly
                  name='cnp' type='text'
                  placeholder='CNP'
                  value={this.state.card.cnp || ''} />
                <Form.Label>CNP</Form.Label>
              </Form.Group>

              <Form.Group className='form-group-label' as={Col} controlId='card-phoneNumber'>
                {this.state.isPhoneNumberError && <span className='error-label'>{this.state.phoneNumberError}</span>}
                {!this.state.isPhoneNumberError && this.props.cardReissueModalError && <span className="error-label">{this.props.cardReissueModalError}</span>}
                <Form.Control autoFocus
                  name='phoneNumber' type='text'
                  placeholder='Numar de Telefon'
                  onChange={this.handleInputChange}
                  value={this.state.phoneNumber || ''}
                  onBlur={this.validatePhoneNumber} />
                <Form.Label>Telefon</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-branch'>
                <Select
                  className='branches-multi-select has-items'
                  value={this.state.selectedBranch}
                  onChange={this.handleBranchChange}
                  isSearchable={true}
                  options={this.state.branchOptions}
                  placeholder=''
                />
                <Form.Label className='select-label active large-select'>Punct de lucru</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-location'>
                <Form.Control autoFocus readOnly
                  name='location' type='text'
                  placeholder='Locatie'
                  value={this.state.selectedBranchLocation} />
                <Form.Label>Locatie</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-iban'>
                <Form.Control autoFocus readOnly
                  name='iban' type='text'
                  placeholder='IBAN'
                  value={this.state.card.iban || ''} />
                <Form.Label>IBAN</Form.Label>
              </Form.Group>

              <Form.Group className='form-group-label' as={Col} controlId='card-status'>
                <Form.Control autoFocus readOnly
                  name='status' type='text'
                  placeholder='Status'
                  value={this.state.card.status || ''} />
                <Form.Label>Status</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-reissue-reason'>
                {this.state.isReasonError ? <span className='error-label'>{this.state.reasonError}</span> : null}
                <Form.Label className='select-label active'>Motivul reemiterii</Form.Label>
                <Form.Control name='reason' as='select' placeholder='Motivul reemiterii'
                  onChange={this.handleInputChange}
                  value={this.state.reason}
                  onBlur={this.validateReason}>
                  {reasonOptions}
                </Form.Control>
              </Form.Group>
            </Form.Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm='auto'>
              <div className='main-button white' onClick={this.props.hideCardReissueModal}>
                RENUNTA
              </div>
            </Col>
            <Col sm='auto'>
              <div className={`main-button 
              ${this.props.sendReissueCardInProgress ? 'loading disabled' : ''}`
              }
                onClick={this.submit}>
                <span className="spinner-border spinner-border-sm"></span>
                REEMITE CARD
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}



const mapStateToProps = state => {
  return {
    ...state.card,
    branches: state.branch.branches
  }
};
const mapDispatchToProps = dispatch => bindActionCreators({
  hideCardReissueModal,
  reissueCard,
  setCardReissueModalError,
  setSendReissueCardInProgress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardReissueModal);
