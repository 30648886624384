import {
    MANAGER_CREATE_FAILURE,
    MANAGER_CREATE_SUCCESS,
    MANAGER_HIDE_MODAL,
    MANAGER_SET_CURRENT_PAGE,
    MANAGER_SET_ERROR_MODAL,
    MANAGER_SET_PAGE_COUNT,
    MANAGER_SET_PAGE_SIZE,
    MANAGER_SET_SEARCH_TERM,
    MANAGER_SET_TOTAL_COUNT,
    MANAGER_SHOW_MODAL,
    MANAGER_UPDATE_FAILURE,
    MANAGER_UPDATE_SUCCESS,
    SET_CURRENT_MANAGER,
    SET_MANAGERS,
    SET_MANAGERS_LOADED_FLAG,
    SHOW_UPDATE_MANAGER_MODAL
} from 'actions/manager';

export default function manager(state, action) {
    state = state || {
        managers: [],
        searchTerm: '',
        pageCount: 1,
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
        isModalVisible: false,
        modalServerError: '',
    }

    switch (action.type) {
        case SET_MANAGERS:
            return {
                ...state,
                managers: action.payload
            }
        case MANAGER_SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload
            }
        case MANAGER_SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload
            }
        case MANAGER_SET_PAGE_COUNT:
            return {
                ...state,
                pageCount: action.payload
            }
        case MANAGER_SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload
            }
        case MANAGER_SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        case MANAGER_SHOW_MODAL:
            return {
                ...state,
                isModalVisible: true,
                isModalInEditMode: false
            }
        case MANAGER_HIDE_MODAL:
            return {
                ...state,
                isModalVisible: false,
                isUpdateModalVisible:false,
                modalServerError: ''
            }
        case MANAGER_CREATE_FAILURE:
            return {
                ...state,
                modalServerError: action.payload.errorMessage
            }
        case MANAGER_CREATE_SUCCESS:
            return {
                ...state,
                modalServerError: '',
                isModalVisible: false
            }
        case MANAGER_UPDATE_FAILURE:
            return {
                ...state,
                modalServerError: action.payload.errorMessage
            }
        case MANAGER_UPDATE_SUCCESS:
            return {
                ...state,
                modalServerError: '',
                isUpdateModalVisible: false
            }
        case SHOW_UPDATE_MANAGER_MODAL:
            return {
                ...state,
                isUpdateModalVisible: true,
                isModalInEditMode: true
            }
        case SET_CURRENT_MANAGER:
            return {
                ...state,
                currentManager: action.payload
            }
        case MANAGER_SET_ERROR_MODAL:
            return {
                ...state,
                modalServerError: action.payload.errorMessage
            }
        case SET_MANAGERS_LOADED_FLAG:
            return {
                ...state,
                managersLoaded: action.payload
            }
        default:
            return state;
    }
}
