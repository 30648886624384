import cookie from 'utils/cookie'

/**
 * Perform a GET operation
 * @param {string} url relative to the host name
 */
const httpGet = (url) => {
  return fetch(`${window.location.protocol}//${window.location.host}${url}`, {
    credentials: 'include',
    headers: {
      'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN')
    }
  });
};

/**
 * Perform a POST operation
 * @param {string} url relative to the host name
 * @param {object} content the data to send
 */
const httpPost = (url, content = {}) => {
  return fetch(`${window.location.protocol}//${window.location.host}${url}`, {
    method: 'POST', // GET, *POST, PUT, DELETE, etc.
    credentials: 'include', // *include, same-origin, omit
    headers: {
      'Content-Type': 'application/json', // *application/json, application/x-www-form-urlencoded
      'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN')
    },
    body: JSON.stringify(content)
  });
}

const httpUpload = (url, content = {}) => {
  return fetch(`${window.location.protocol}//${window.location.host}${url}`, {
    method: 'POST', // GET, *POST, PUT, DELETE, etc.
    credentials: 'include', // *include, same-origin, omit
    headers: {
      'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN')
    },
    body: content
  });
}

const httpDownload = (url) => {
  return httpGet(url)
    .then(async response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        let contentDisposition = response.headers
          .get('content-disposition');
        a.download = contentDisposition.split("''")[1];
        a.href = url;
        a.click();
      });
    });
}

export default {
  get: httpGet,
  post: httpPost,
  upload: httpUpload,
  download: httpDownload
};
