import { Col, Row } from 'react-bootstrap';
import React from 'react';

class UplaodFileScreenHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            this.props.location.pathname !== this.props.expectedUploadUrl &&
                this.props.itemsValidated === true ? null :
                <Row>
                    <Col className="wizzard-status">
                        {this.props.children}
                    </Col>
                </Row>
        );
    }
}

export default UplaodFileScreenHeader;
