import { Captcha, captchaSettings } from 'reactjs-captcha';
import errorCodes from 'utils/errorCodes';
import React from 'react';
import Row from 'react-bootstrap/Row';

class UsernameView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: this.props.username || '' };

    this.loginButton = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.submitUsername = this.submitUsername.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    captchaSettings.set({
      captchaEndpoint: 'simple-captcha-endpoint.ashx'
    });
  }

  componentDidMount() {
    this.props.setLoginError('');
    this.sessionExpired = new URLSearchParams(window.location.search).get('sessionExpired');
    if (this.sessionExpired) {
      this.props.showToast({
        dismissible: true,
        text: 'Sesiunea ta a expirat. Conecteaza-te din nou.'
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.username == this.state.username && prevProps && prevProps.usernameCaptcha) {
      this.captcha && this.captcha.reloadImage();
    }
  }

  handleChange(event) {
    this.setState({ username: event.target.value });

    if (this.props.loginError) {
      this.props.setLoginError('');
    }
  }

  submitUsername() {
    const username = document.getElementById('username').value,
      captchaCode = this.captcha && this.captcha.getUserEnteredCaptchaCode(),
      captchaId = this.captcha && this.captcha.getCaptchaId();

    if (username) {
      this.props.submitUsernameRequest({
        username: username,
        captchaCode: captchaCode,
        captchaId: captchaId
      });
      this.loginButton.current.classList.add('loading', 'disabled');
    } else {
      this.props.setLoginError(errorCodes.fieldRequired);
    }
  }

  handleKeyUp(event) {
    this.sessionExpired = new URLSearchParams(window.location.search).get('sessionExpired');
    if (this.sessionExpired) {
      this.props.hideToast();
    }

    const code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      this.submitUsername();
    }
  }

  render() {
    return (
      <>
        <div className='maintenance hidden'>
          <img src='\images\Mesaj_upgrade 1.png' alt="Maintenance" width="960" height="520"></img>
        </div>
        <div className="login-form">
          <div>
            <div className="username-view">
              <h1>Bine ai venit!</h1>
              <div className="form-group-label">
                <input
                  id="username"
                  className="form-control"
                  type="text"
                  placeholder="Nume utilizator"
                  autoFocus
                  onKeyUp={this.handleKeyUp}
                  onChange={this.handleChange}
                  value={this.state.username} />
                <label className="form-label" htmlFor="username">Utilizator</label>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path fill={this.state.username ? '#181818' : '#c0c0c0'} d="M18.2 7.449c0 2.732-1.511 5.068-3.924 6.008 1.433.208 3.102.858 5.008 1.948A5.378 5.378 0 0 1 22 20.073l-.001.926c0 1.105-.903 2-2.016 2H4.017A2.008 2.008 0 0 1 2.001 21L2 20.073a5.378 5.378 0 0 1 2.716-4.668c1.906-1.09 3.575-1.74 5.008-1.948C7.311 12.517 5.8 10.182 5.8 7.45c0-3.533 2.662-6.402 6.2-6.448 3.538.045 6.2 2.915 6.2 6.448zM12 15.5c4.724 0 7.356 1.439 7.896 4.316A1 1 0 0 1 18.913 21H5.087a1 1 0 0 1-.983-1.184C4.644 16.939 7.276 15.5 12 15.5zm.05-3.5c2.485 0 4.25-2.015 4.25-4.5S14.535 3 12.05 3C9.565 3 7.8 5.015 7.8 7.5S9.565 12 12.05 12z" />
                  </g>
                </svg>
              </div>
              <p className={
                this.props.loginError === errorCodes.invalidUser
                  ? 'input-validation-error visible'
                  : 'input-validation-error'
              }>Numele de utilizator introdus nu exista.</p>
              <p className={
                this.props.loginError === errorCodes.fieldRequired
                  ? 'input-validation-error visible'
                  : 'input-validation-error'
              }>Acest camp este obligatoriu.</p>
              <p className={
                this.props.loginError && this.props.loginError.includes(errorCodes.invalidCaptcha)
                  ? 'input-validation-error visible'
                  : 'input-validation-error'
              }>Codul captcha introdus este gresit.</p>
              <p className={
                this.props.usernameCaptcha
                  ? 'input-validation-error visible'
                  : 'input-validation-error'
              }>Introduceti codul din imagine.</p>
              {
                this.props.usernameCaptcha &&
                <Row noGutters className="captcha">
                  <Captcha
                    captchaStyleName="btTichetCaptcha"
                    ref={(captcha) => { this.captcha = captcha }} />
                  <input
                    id="btTichetCaptchaInputCode"
                    className="form-control"
                    type="text"
                    tab-index="10"
                    onKeyUp={this.handleKeyUp}
                    placeholder="Cod Captcha" />
                </Row>
              }
              <Row noGutters className="button-container">
                <button className='main-button' onClick={this.submitUsername} ref={this.loginButton} id="loginButton">
                  <span className="spinner-border spinner-border-sm"></span>
                  INAINTE
                </button>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UsernameView
