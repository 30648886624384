import './ConfirmationModal.scss';
import { Col, Modal, Row } from 'react-bootstrap';
import React from 'react';

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal className="confirmation-modal" show backdrop='static' 
        onHide={this.props.cancel}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.props.confirmationQuestion}</p>
          <p>{this.props.additionalInformation}</p>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col className="button-group" sm="auto">
              <div className="main-button white" onClick={this.props.cancel}>
                RENUNTA
              </div>
              <div className="main-button" onClick={this.props.confirm}>
                CONFIRMA
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default ConfirmationModal;
