import { TOAST_HIDE, TOAST_SHOW } from 'actions/toast';

export default function toast(state, action) {
  state = state || { show: false, type: 'info' };

  switch (action.type) {
    case TOAST_HIDE:
      return {
        ...state,
        show: false
      };
    case TOAST_SHOW:
      return {
        show: true,
        type: action.payload.type,
        text: action.payload.text,
        title: action.payload.title,
        smallText: action.payload.smallText,
        delay: action.payload.delay,
        dismissible: action.payload.dismissible
      };
    default:
      return state;
  }
}
