import './OrderCreation.scss';
import { Col, Row } from 'react-bootstrap';
import { branchStatus } from 'utils/enums';
import { Link } from 'react-router-dom';
import React from 'react';
import routes from 'utils/routes';
import { userRole } from 'utils/enums';

class OrderCreation extends React.Component {
  constructor(props) {
    super(props);

    this.renderOrderButton = this.renderOrderButton.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (window.scrollY == 0 && this.props.cardsLoaded) {
        window.scroll({ top: document.body.scrollHeight, behaviour: 'smooth' });
      }
    }, 50);
  }


  renderOrderButton() {
    let showButton = false;

    let currentBranchIsActive = this.props.currentBranch.status === branchStatus.enabled

    if (currentBranchIsActive ||
      this.props.currentBranch.id === null && this.props.userRole === userRole.ADMIN) {
      showButton = true;
    }

    if (showButton) {
      let disableButton = this.props.currentBranch.id && (!this.props.currentBranch.contactPersonName || !this.props.currentBranch.contactPersonPhoneNumber);

      return (
        <div className={disableButton ? 'new-order-button-wrapper disabled' : 'new-order-button-wrapper'}>
          <Link to={routes.dashboardOrdersUpload} className={disableButton ? 'new-order-button disabled main-button icon' : 'new-order-button main-button icon'}>
            <svg width="20" height="20" viewBox="0 0 24 24">
              <path id="a" d="M13.767 10.233h6.466a1.767 1.767 0 0 1 0 3.534h-6.466v6.466a1.767 1.767 0 0 1-3.534 0v-6.466H3.767a1.767 1.767 0 0 1 0-3.534h6.466V3.767a1.767 1.767 0 0 1 3.534 0v6.466z" />
            </svg>
            COMANDA NOUA
            <span className="centered-button-left tooltip-text">Completeaza datele persoanei de contact pe acest punct de lucru pentru a putea plasa o comanda.</span>
          </Link>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div className='cards-upload-container container-fluid'>
        <Row>
          <Col className='center info-icon'>
            <svg width="48" height="48" viewBox="0 0 24 24">
              <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>
          </Col>
        </Row>
        {!this.props.currentBranch.id && this.props.userRole !== userRole.ADMIN
          ?
          <Row>
            <Col>
              <p className="cards-upload-description">Nu ai selectat nici un punct de lucru. Pentru a incepe procesul de comanda carduri, selecteaza un punct de lucru din dropdown-ul de mai sus.</p>
            </Col>
          </Row>
          :
          <div>
            <Row>
              <Col>
                {
                  (this.props.currentBranch.status === branchStatus.enabled) ||
                    this.props.currentBranch.id === null && this.props.userRole === userRole.ADMIN
                    ? this.props.viewOrders
                      ? <p className="cards-upload-description">Nu exista carduri comandate. Plaseaza o comanda noua.</p>
                      : <p className="cards-upload-description">Nu exista carduri comandate. Plaseaza o comanda noua.</p>
                    : this.props.viewOrders
                      ? <p className="cards-upload-description">Nu exista istoric comenzi pentru acest punct de lucru.</p>
                      : <p className="cards-upload-description">Punctul de lucru este inactiv.</p>
                }
              </Col>
            </Row>
            <Row noGutters>
              <Col className='order-creation-button-group'>
                <div className='col-sm-auto'>
                  {
                    this.renderOrderButton()
                  }
                </div>
              </Col>
            </Row>
          </div>
        }
      </div>
    );
  }
}
export default OrderCreation;
