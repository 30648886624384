import './ManagersDashboard.scss';
import { Col, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import { ConfirmationModal, PageSelector, SearchBar } from 'components';
import AddManagerModal from './AddManagerModal';
import ManagersTable from './ManagersTable';
import React from 'react';
import UpdateManagerModal from './UpdateManagerModal';
import { userRole } from 'utils/enums';


let timeout;

class ManagersDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDeleteManagerModal: false, showUpdateModal: false };
    this.searchManagers = this.searchManagers.bind(this);
    this.dropdownItemOnClickHandler = this.dropdownItemOnClickHandler.bind(this);
    this.pageSelectionCallback = this.pageSelectionCallback.bind(this);
    this.handleDeleteManager = this.handleDeleteManager.bind(this);
    this.deleteManager = this.deleteManager.bind(this);
    this.handleUpdateManager = this.handleUpdateManager.bind(this);
    this.closeDeleteManagerModal = this.closeDeleteManagerModal.bind(this);
  }

  searchManagers(event) {
    const value = event && event.currentTarget.value || '';

    clearTimeout(timeout); // cancel the old timer
    timeout = setTimeout(() => {
      this.props.setManagersSearchTerm(value.trim());
      this.props.findManagersRequest();
    }, 200);
  }

  dropdownItemOnClickHandler(event) {
    let value = (event.currentTarget && event.currentTarget.text) || 10;
    this.props.setManagersPageSize(value);
    this.props.findManagersRequest();
  }

  pageSelectionCallback(selectedPage) {
    this.props.setManagersCurrentPage(selectedPage);
    this.props.findManagersRequest();
  }

  handleDeleteManager(item) {
    this.setState({ showDeleteManagerModal: true });
    this.setState({ selectedItem: item });
  }

  deleteManager() {
    var branches = this.props.branches.filter(b => b.contactPerson == this.state.selectedItem.userId)

    if (branches.length > 0) {
      const message = 'Managerul nu poate fi sters. Este persoana de contact ' + (branches.length == 1 ? 'pentru punctul de lucru ' + branches[0].name : 'pentru mai multe puncte de lucru');
      const toastConfiguration = {
        text: message
      };
      this.closeDeleteManagerModal();
      this.props.showToast(toastConfiguration);
    }
    else {
      this.props.deleteManagerRequest(this.state.selectedItem);
      this.closeDeleteManagerModal();
      this.props.showLoadingSpinner();
    }
  }

  handleUpdateManager(item) {
    this.props.setCurrentManager(item);
    this.props.showUpdateManagerModal(item);
  }

  closeDeleteManagerModal() {
    this.setState({ showDeleteManagerModal: false });
  }


  render() {
    return (
      <div className="managers-dashboard">
        <Row noGutters className="justify-content-between">
          <Col sm="auto">
            <Row noGutters>
              <Col sm="auto" className="manager-info-container">
                <h2>Manageri</h2>
              </Col>
              {
                this.props.currentBranch &&
                <Col sm="auto">
                  <SearchBar
                    searchBarId='search-bar-input-users'
                    placeholder='Cauta dupa nume sau telefon'
                    showResetSearchButton={!!this.props.searchTerm}
                    handler={this.searchManagers}
                    clearHandler={this.searchManagers}
                  />
                </Col>
              }
            </Row>
          </Col>
          {
            this.props.managersLoaded &&
            this.props.userRoleId !== userRole.STANDARD &&
            <Col sm="auto">
              <button className='main-button icon new-manager-button'
                onClick={() => { this.props.managersShowModal(); }}>
                <svg width="20" height="20" viewBox="0 0 24 24">
                  <path id="a" d="M13.767 10.233h6.466a1.767 1.767 0 0 1 0 3.534h-6.466v6.466a1.767 1.767 0 0 1-3.534 0v-6.466H3.767a1.767 1.767 0 0 1 0-3.534h6.466V3.767a1.767 1.767 0 0 1 3.534 0v6.466z" />
                </svg>
                MANAGER NOU
              </button>
            </Col>
          }
        </Row>
        {
          this.props.managersLoaded ?
            <div>
              <Row noGutters className="justify-content-between grid-info">
                <Col sm="auto">
                  {
                    this.props.searchTerm
                      ? <strong>Rezultatele cautarii</strong>
                      : <strong>Toti managerii</strong>
                  }
                </Col>
              </Row>
              <ManagersTable
                tableHeaderColumns={['Nume', 'Prenume', 'Telefon', 'Rol']}
                idColumn='userId'
                content={this.props.managers}
                fields={['lastName', 'firstName', 'phoneNumber', 'roleName']}
                userRoleId={this.props.userRoleId}
                showItemActions={this.props.userRoleId !== userRole.STANDARD}
                itemActions={[
                  { title: 'Sterge', clickHandler: this.handleDeleteManager },
                  { title: 'Modifica', clickHandler: this.handleUpdateManager },
                ]}
              />
              {
                this.props.managers && this.props.managers.length > 0 &&
                <Row noGutters>
                  <Col>
                    {this.props.managers && <span className='small-font'>
                      {this.props.managers && this.props.managers.length === 1 ?
                        `1 din ${this.props.totalCount} manageri afisat` :
                        `${this.props.managers.length} din ${this.props.totalCount} manageri afisati`}
                    </span>
                    }
                  </Col>
                  <Col>
                    <PageSelector pageCount={this.props.pageCount} currentPage={this.props.currentPage} pageSelectionCallback={this.pageSelectionCallback} />
                  </Col>
                  <Col className="right">
                    <DropdownButton alignRight size='sm' variant='secondary' drop='up' title={`${this.props.pageSize} pe pagina`} className='page-size-dropdown' >
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>10</Dropdown.Item>
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>25</Dropdown.Item>
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>50</Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              }
              {
                this.props.branches.length > 0 && this.props.isModalInEditMode === false &&
                <AddManagerModal
                  createManagerRequest={this.props.createManagerRequest}
                  hideModal={this.props.managersHideModal}
                  userRoleId={this.props.userRoleId}
                  branches={this.props.branches}
                  visible={this.props.isModalVisible}
                  modalServerError={this.props.modalServerError}
                  setModalError={this.props.managersSetModalError}
                />
              }
              {
                this.props.isModalInEditMode &&
                <UpdateManagerModal
                  updateManagerRequest={this.props.updateManagerRequest}
                  currentManager={this.props.currentManager}
                  hideModal={this.props.managersHideModal}
                  userRoleId={this.props.userRoleId}
                  branches={this.props.branches}
                  visible={this.props.isUpdateModalVisible}
                  modalServerError={this.props.modalServerError}
                  setModalError={this.props.managersSetModalError}
                />
              }
              {
                this.state.showDeleteManagerModal &&
                <ConfirmationModal
                  title='Confirma stergerea managerului'
                  confirmationQuestion={`Esti sigur ca vrei sa stergi managerul "${this.state.selectedItem.lastName + ' ' + this.state.selectedItem.firstName}"?`}
                  confirm={this.deleteManager}
                  cancel={this.closeDeleteManagerModal}
                />
              }
            </div>
            : <Row noGutters className='managers-loading-spinner'>
              <Col className='center'>
                <Spinner animation="border" variant="secondary" />
              </Col>
            </Row>
        }
      </div>
    );
  }
}

export default ManagersDashboard;
