import {
  ADD_USER_TO_BRANCH_SUCCESS,
  BRANCH_DISABLE_FAILURE,
  BRANCH_DISABLE_SUCCESS,
  BRANCH_ENABLE_FAILURE,
  BRANCH_ENABLE_SUCCESS,
  BRANCH_FIND_FAILURE,
  BRANCH_FIND_SUCCESS,
  BRANCH_UPSERT_FAILURE,
  BRANCH_UPSERT_SUCCESS,
  CITIES_FIND_FAILURE,
  CITIES_FIND_SUCCESS,
  LOCALITIES_FIND_FAILURE,
  LOCALITIES_FIND_SUCCESS,
  REMOVE_USER_FROM_BRANCH_SUCCESS,
} from '../actions/branch';

import {
  afterCurrentBranchWasSet,
  findBranches,
  hideModal,
  setBranches,
  setBranchesLoadedFlag,
  setCities,
  setCurrentBranch,
  setCurrentBranchUserIds,
  setLocalities,
  setUpsertBranchResult
} from '../actions/branch';

import { MANAGER_DELETE_SUCCESS } from 'actions/manager';
import { showToast } from 'actions/toast';

function branch(store) {
  return next => action => {
    switch (action.type) {
      case BRANCH_FIND_SUCCESS: {
        // eslint-disable-next-line no-case-declarations
        let updatedBranches = action.payload || [];
        if (action.payload && action.payload.length > 1) {
          updatedBranches = action.payload.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
          updatedBranches.splice(0, 0, { id: null, name: 'Toate punctele de lucru', userIds: [] });
        }

        next(setBranches(updatedBranches));
        next(setBranchesLoadedFlag(true));

        const { currentBranch } = store.getState().branch;
        if (!currentBranch) {
          const updatedBranch = updatedBranches[0];
          next(setCurrentBranch(updatedBranch));
          next(afterCurrentBranchWasSet(updatedBranch));
        } else {
          const updatedCurrentBranch = updatedBranches.filter(b => b.id === currentBranch.id)[0];
          next(setCurrentBranch(updatedCurrentBranch));
          next(afterCurrentBranchWasSet(updatedCurrentBranch));
        }
        break;
      }
      case BRANCH_FIND_FAILURE: {
        next(setBranches([]));
        break;
      }
      case CITIES_FIND_SUCCESS: {
        next(setCities(action.payload));
        break;
      }
      case CITIES_FIND_FAILURE: {
        next(setCities([]));
        break;
      }
      case LOCALITIES_FIND_SUCCESS: {
        next(setLocalities(action.payload));
        break;
      }
      case LOCALITIES_FIND_FAILURE: {
        next(setLocalities([]));
        break;
      }
      case ADD_USER_TO_BRANCH_SUCCESS: {
        const branchState = store.getState().branch,
          updatedAddBranches = branchState ? branchState.branches.map(b => {
            if (b.id !== null && b.id === action.payload.branchId) {
              b.userIds.push(action.payload.userId)
              next(setCurrentBranchUserIds([...b.userIds]));
            }
            return b;
          }) : [];

        next(setBranches(updatedAddBranches));
        break;
      }
      case REMOVE_USER_FROM_BRANCH_SUCCESS: {
        const branchState = store.getState().branch,
          updatedRemoveBranches = branchState ?
            branchState.branches.map(b => {
              if (b.id !== null && b.id === action.payload.branchId) {
                b.userIds = b.userIds.filter(uid => uid != action.payload.userId);
                next(setCurrentBranchUserIds([...b.userIds]));
              }
              return b;
            }) :
            [];

        next(setBranches(updatedRemoveBranches));
        break;
      }
      case BRANCH_UPSERT_SUCCESS: {
        next(setUpsertBranchResult(false, ''));
        next(setCurrentBranch(action.payload));
        next(hideModal());

        if (store.getState().branch.isModalInEditMode) {
          next(showToast({ text: 'Punctul de lucru a fost modificat cu succes', type: 'success' }));
        } else {
          next(showToast({ text: 'Punctul de lucru a fost adaugat cu succes', type: 'success' }));
        }

        store.dispatch(findBranches());
        break;
      }
      case BRANCH_UPSERT_FAILURE: {
        next(setUpsertBranchResult(true, action.payload.errorMessage));
        break;
      }
      case MANAGER_DELETE_SUCCESS: {
        const branchState = store.getState().branch;

        let updatedBranches = branchState ?
          branchState.branches.map(b => {
            b.userIds.splice(b.userIds.indexOf(action.payload.userId), 1);
            if (b.id !== null && branchState.currentBranch && b.id === branchState.currentBranch.id) {
              next(setCurrentBranchUserIds([...b.userIds]));
            }
            return b;
          }) :
          [];

        next(setBranches(updatedBranches));
        break;
      }
      case BRANCH_DISABLE_SUCCESS: {
        switch (action.payload.status) {
          case 'Success':
            next(showToast({ type: 'success', text: 'Punctul de lucru a fost dezactivat cu succes' }));
            store.dispatch(findBranches());
            // eslint-disable-next-line no-case-declarations
            const branchState = store.getState().branch;
            store.dispatch(setCurrentBranch(branchState.branches[0]));
            break;
          default:
            next(showToast({ type: 'info', title: 'Dezactivarea nu este posibila', text: action.payload.text }));
            break;
        }
        break;
      }
      case BRANCH_DISABLE_FAILURE: {
        next(showToast({ type: 'info', title: 'Dezactivarea nu este posibila', text: action.payload.errorMessage }));
        break;
      }
      case BRANCH_ENABLE_SUCCESS: {
        next(showToast({ type: 'success', text: 'Punctul de lucru a fost activat cu succes' }));
        store.dispatch(findBranches());
        // eslint-disable-next-line no-case-declarations
        const branchState = store.getState().branch;
        store.dispatch(setCurrentBranch(branchState.branches[0]));
        break;
      }
      case BRANCH_ENABLE_FAILURE: {
        next(showToast({ type: 'info', title: 'Dezactivarea nu este posibila', text: action.payload.errorMessage }));
        break;
      }
    }

    const returnValue = next(action);

    return returnValue;
  }
}

export default branch;
