import { CardsUploadErrorsContainer, UploadFileMealTicketHeaderContainer, UploadFileMealTicketScreenContainer, UploadFileMealTicketStepsContainer, UploadFileScreen } from 'components';
import { fileUploadType } from 'utils/enums';
import React from 'react';
import routes from 'utils/routes';

class BulkModifyTicketValue extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnUploadFile = this.handleOnUploadFile.bind(this);
        this.handleOnUploadFileFailed = this.handleOnUploadFileFailed.bind(this);

        this.configs = {
            expectedUploadUrl: routes.dashboardCardsModifyTicketValueBulk,
            uploadScreenBody: {
                acceptFiles: '.csv, .xls, .xlsx',
                validFileTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
                formDataParameters: [{ key: 'branchId', value: -1 }, { key: 'uploadTypeId', value: fileUploadType.cardsModifyTicketValue }],
                title: 'Modificare valoare bulk',
                description: 'Incarca fisierul csv sau xls(x) cu lista de angajati pentru care doresti modificarea valorii tichetelor.',
                onDownloadTemplate: this.handleOnDownloadTemplate,
                onUploadFile: this.handleOnUploadFile,
                onUploadFileFailed: this.handleOnUploadFileFailed,
                fileUploadErrors: <CardsUploadErrorsContainer />
            },
            successfullUpload: {
                title: 'Valoarea tichetului pentru cardurile alese a fost modificata cu succes.',
                description: 'Click mai jos pentru a merge inapoi la sectiunea cu carduri.',
                buttonText: 'Vezi carduri',
                onClick: this.handleOnClick
            }
        }
    }

    componentDidMount() {
        this.props.setUploadConfig(this.configs);
    }

    componentWillUnmount() {
        this.props.setUploadConfig(null);
    }

    handleOnClick() {
        this.props.replace(routes.dashboardCards, { viewLastCreatedOrder: true });
    }

    handleOnDownloadTemplate() {
        window.open('/resources/BT_TICHET_MODEL_MODIFICARE_VALOARE_CARDURI.xlsx', '_blank', 'noreferrer');
    }

    handleOnUploadFile() {
        this.props.blockCurrentBranchSelection(true);
    }

    handleOnUploadFileFailed() {
        this.props.blockCurrentBranchSelection(false);
    }

    render() {
        return (
            <UploadFileScreen
                uploadFileHeader={<UploadFileMealTicketHeaderContainer />}
                uploadFileContent={[<UploadFileMealTicketScreenContainer />,
                <UploadFileMealTicketStepsContainer />]}
            />
        );
    }
}

export default BulkModifyTicketValue;
