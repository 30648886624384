import './CardsOrder.scss';
import { CardsUploadErrorsContainer, UploadFileMealTicketHeaderContainer, UploadFileMealTicketScreenContainer, UploadFileMealTicketStepsContainer, UploadFileScreen } from 'components';
import { fileUploadType, userRole } from 'utils/enums';
import React from 'react';
import routes from 'utils/routes';

class CardsOrder extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnUploadFile = this.handleOnUploadFile.bind(this);
    this.handleOnUploadFileFailed = this.handleOnUploadFileFailed.bind(this);
    this.handleOnDownloadTemplate = this.handleOnDownloadTemplate.bind(this);

    this.configs = this.initializeConfig();
  }

  componentDidMount() {
    this.props.setUploadConfig(this.configs);
  }

  componentWillUnmount() {
    this.props.setUploadConfig(null);
  }

  initializeConfig() {
    let formDataParameters, description;

    //if id is null => all branches was selected, otherwise a branch was selected
    if (this.props.currentBranch.id) {
      formDataParameters = [{ key: 'branchId', value: this.props.currentBranch.id }, { key: 'uploadTypeId', value: fileUploadType.cardsImport }];
      description = 'Incarca fisierul csv sau xls(x) cu angajatii tai pentru care doresti sa comanzi cardurile de masa.';
    }
    else {
      formDataParameters = [{ key: 'branchId', value: -1 }, { key: 'uploadTypeId', value: fileUploadType.cardsImportAllBranches }];
      description = 'Incarca fisierul csv sau xls(x) cu angajatii tai pentru care doresti sa comanzi cardurile de masa.';
    }

    return {
      expectedUploadUrl: routes.dashboardOrdersUpload,
      uploadScreenBody: {
        acceptFiles: '.csv, .xls, .xlsx',
        validFileTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
        formDataParameters: formDataParameters,
        title: 'Comanda carduri',
        description: description,
        onDownloadTemplate: this.handleOnDownloadTemplate,
        onUploadFile: this.handleOnUploadFile,
        onUploadFileFailed: this.handleOnUploadFileFailed,
        fileUploadErrors: <CardsUploadErrorsContainer />
      },
      successfullUpload: {
        title: 'Comanda de carduri a fost trimisa spre procesare.',
        description: 'Poti vedea oricand detaliile si statusul comenzii de carduri.',
        buttonText: 'Vezi carduri',
        onClick: this.handleOnClick
      }
    }
  }

  handleOnClick() {
    if (this.props.uploadTypeId === fileUploadType.cardsImportAllBranches) {
      this.props.replace(routes.dashboardCards, { viewLastCreatedOrder: false })
    }
    else if (this.props.uploadTypeId === fileUploadType.cardsImport) {
      this.props.replace(routes.dashboardCards, { viewLastCreatedOrder: true });
    }
  }

  handleOnDownloadTemplate() {
    if (
      this.props.userRole === userRole.ADMIN &&
      this.props.currentBranch.id === null
    ) {
      window.open('/resources/BT_TICHET_MODEL_INCARCARE_CARDURI_PE_PUNCTE_DE_LUCRU.xlsx', '_blank', 'noreferrer');
    } else {
      window.open('/resources/BT_TICHET_MODEL_INCARCARE_CARDURI.xlsx', '_blank', 'noreferrer');
    }
  }

  handleOnUploadFile() {
    this.props.blockCurrentBranchSelection(true);
  }

  handleOnUploadFileFailed() {
    this.props.blockCurrentBranchSelection(false);
  }

  render() {
    return (
      <UploadFileScreen
        uploadFileHeader={<UploadFileMealTicketHeaderContainer />}
        uploadFileContent={[<UploadFileMealTicketScreenContainer />,
        <UploadFileMealTicketStepsContainer />]}
      />
    );
  }
}

export default CardsOrder;
