import { LOADING_SPINNER_HIDE, LOADING_SPINNER_SHOW } from 'actions/loadingSpinner';

export default function toast(state, action) {
  state = state || { isVisible: false };

  switch (action.type) {
    case LOADING_SPINNER_SHOW:
      return { isVisible: true };
    case LOADING_SPINNER_HIDE:
      return { isVisible: false };
    default:
      return state;
  }
}
