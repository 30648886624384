import { BranchHeader, BulkDeleteContainer, BulkModifyTicketValueContainer, CardsGrid, CardsOrder, OrderCreation } from 'components';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import React from 'react';
import routes from 'utils/routes';

class CardsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.renderCardsComponent = this.renderCardsComponent.bind(this);
    this.cardFiltersAreSet = this.cardFiltersAreSet.bind(this);
  }

  cardFiltersAreSet() {
    return (this.props.filters.firstOrLastName && this.props.filters.firstOrLastName !== '') ||
      (this.props.filters.cnp && this.props.filters.cnp !== '') ||
      (this.props.filters.statusId !== null && this.props.filters.statusId !== undefined && this.props.filters.statusId !== '') ||
      (this.props.filters.ticketValue && this.props.filters.ticketValue !== '') ||
      (this.props.filters.orderId && this.props.filters.orderId !== '');
  }

  renderCardsComponent() {
    if (this.props.cardsLoaded && this.props.cards.length === 0 && !this.cardFiltersAreSet()) {
      return <OrderCreation
        currentBranch={this.props.currentBranch}
        viewOrders={false}
        userRole={this.props.userRole}
      />
    }

    return <CardsGrid />;
  }

  renderSpinner() {
    return (
      <Row noGutters className='cards-loading-spinner'>
        <Col className='center'>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    );
  }

  render() {
    if (!this.props.currentBranch) {
      return this.renderSpinner();
    }
    else {
      return (
        <>
          <BranchHeader />
          <Switch>
            <Route exact path={routes.dashboardCards} render={() => this.renderCardsComponent()} />
            <Route path={routes.dashboardOrdersUpload} render={() => <CardsOrder />} />
            <Route path={routes.dashboardCardsDeleteBulk} render={() => <BulkDeleteContainer />} />
            <Route path={routes.dashboardCardsModifyTicketValueBulk} render={() => <BulkModifyTicketValueContainer />} />
          </Switch>
        </>
      );
    }
  }
}

export default connect()(CardsDashboard);
