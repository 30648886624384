import './SapInvoicesDashboard.scss';
import { Col, Dropdown, DropdownButton, Row, Spinner, Table } from 'react-bootstrap';
import { ConfirmationModal } from 'components';
import datetime from 'utils/datetime';
import httpClient from 'utils/httpClient';
import { PageSelector } from 'components';
import React from 'react';

let timeout = {};
const delayTime = 250;

class SapInvoicesDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tableHeaderColumns: ['Factura', 'Data'],
            fields: ['title', 'date'],
            idColumn: 'id',
            startDate: datetime.getFormattedTodayMinusOneYear(),
            endDate: datetime.getFormattedToday()
        }

        this.pageSelectionCallback = this.pageSelectionCallback.bind(this);
        this.pageSizeCallback = this.pageSizeCallback.bind(this);
        this.downloadSapInvoice = this.downloadSapInvoice.bind(this);
        this.downloadAllSapInvoices = this.downloadAllSapInvoices.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeEndDate = this.changeEndDate.bind(this);
        this.rectifySapInvoice = this.rectifySapInvoice.bind(this);
        this.closeRectifyModal = this.closeRectifyModal.bind(this);
    }

    pageSelectionCallback(selectedPage) {
        this.props.setSapCurrentPage(selectedPage);
        clearTimeout(timeout);
        timeout = setTimeout(() => { this.props.findSapInvoices(this.state.startDate, this.state.endDate, selectedPage, this.props.pageSize); }, delayTime);
    }

    pageSizeCallback(pageSize) {
        this.props.setPageSize(pageSize);
        this.props.setSapCurrentPage(1);
        clearTimeout(timeout);
        timeout = setTimeout(() => { this.props.findSapInvoices(this.state.startDate, this.state.endDate, this.props.currentPage, pageSize); }, delayTime);
    }

    async downloadSapInvoice(sapInvoiceId, date) {
        const url = `/bill/downloadSapInvoice?sapInvoiceId=${sapInvoiceId}&date=${date}`;
        var response = await httpClient.get(url);

        if (response.ok) {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                let contentDisposition = response.headers
                    .get('content-disposition');
                a.download = contentDisposition.split("''")[1];
                a.href = url;
                a.click();
            });
        }
        else {
            const result = await response.json();
            this.props.showToast({ type: 'error', text: result.validationMessage });
        }
    }

    downloadAllSapInvoices() {
        httpClient.download(`/bill/downloadAllSapInvoices?startDate=${this.state.startDate}&endDate=${this.state.endDate}`);
    }

    changeStartDate(e) {
        this.setState({
            ...this.state,
            startDate: e.target.value
        });

        if(e.target.value && this.state.endDate){
            let sDate = new Date(e.target.value), eDate = new Date(this.state.endDate);
            sDate.setHours(0, 0, 0);
            eDate.setHours(0, 0, 0);

            if (eDate >= sDate) {
                clearTimeout(timeout);
                timeout = setTimeout(() => { this.props.findSapInvoices(this.state.startDate, this.state.endDate, this.state.pageNumber, this.state.pageSize); }, delayTime);
            } else {
                this.props.showToast({ type: 'error', text: 'Data de inceput a cautarii trebuie sa fie mai mica sau egala cu cea de final.' });
            }
        }
        else{
            this.props.showToast({ type: 'error', text: 'Datele trebuie sa aiba o valoare valida.' });
        }
    }

    changeEndDate(e) {
        this.setState({
            ...this.state,
            endDate: e.target.value
        });

        if (this.state.startDate && e.target.value) {
            let sDate = new Date(this.state.startDate), eDate = new Date(e.target.value);
            sDate.setHours(0, 0, 0);
            eDate.setHours(0, 0, 0);

            if (eDate >= sDate) {
                clearTimeout(timeout);
                timeout = setTimeout(() => { this.props.findSapInvoices(this.state.startDate, this.state.endDate, this.state.pageNumber, this.state.pageSize); }, delayTime);
            } else {
                this.props.showToast({ type: 'error', text: 'Data de inceput a cautarii trebuie sa fie mai mica sau egala cu cea de final.' });
            }
        }
        else {
            this.props.showToast({ type: 'error', text: 'Datele trebuie sa aiba o valoare valida.' });
        }
    }

    rectifySapInvoice() {
        var sapInvoiceId = this.props.sapInvoiceToRectify.id;
        this.props.rectifySapInvoice(sapInvoiceId);
        this.closeRectifyModal();
    }

    openRectifyModal(sapInvoice) {
        this.props.setSapInvoiceToRectify(sapInvoice);
        this.props.showRectifySapInvoiceModal();
        return;
    }

    closeRectifyModal() {
        this.props.hideRectifySapInvoiceModal();
        this.props.setSapInvoiceToRectify(null);
    }

    handlePageSizeEvent(value){
        return () => this.pageSizeCallback(value)
    }

    renderSapInvoices() {
        return (
            !this.props.isLoading
                ? <div className="dashboard">
                    <Row className="justify-content-between order-info-container" noGutters>
                        <Col sm="auto">
                            <Row noGutters>
                                <Col sm="auto" className="order-info">
                                    <h2>Facturi</h2>
                                </Col>
                            </Row>
                        </Col>
                        {
                            this.props.sapInvoices && this.props.sapInvoices.length > 0 &&
                            <Col sm="auto">
                                <button
                                    className="new-order-button main-button icon"
                                    onClick={() => this.downloadAllSapInvoices()}>
                                    <svg width="20" height="20" viewBox="0 0 24 24">
                                        <path id="a" d="M5 20h14a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm7.34-3.15a.461.461 0 0 1-.678.003L5.14 9.894a.528.528 0 0 1 .003-.717.466.466 0 0 1 .337-.147h3.226V2.4c0-.22.179-.4.4-.4h5.79c.221 0 .4.18.4.4v6.63h3.226c.264 0 .479.227.479.507 0 .134-.05.262-.139.357l-6.52 6.956z" />
                                    </svg>
                                    DESCARCA TOATE FACTURILE
                                </button>
                            </Col>
                        }
                    </Row>
                    <Row noGutters className="mb-20">
                        <div className="col-auto"><strong>Filtre</strong></div>
                    </Row>
                    <Row noGutters className="mb-5 align-items-center">
                        <div className="col-auto date-filter">
                            De la
                            <input
                                id="date-filter-start"
                                type="date"
                                className="start-date-filter-audit ml-2 mr-2"
                                min={datetime.getFormattedTodayMinusOneYear()}
                                max={datetime.getFormattedToday()}
                                value={this.state.startDate}
                                onChange={this.changeStartDate} />
                            pana la
                            <input
                                id="date-filter-end"
                                type="date"
                                className="end-date-filter-audit ml-2"
                                min={datetime.getFormattedTodayMinusOneYear()}
                                max={datetime.getFormattedToday()}
                                value={this.state.endDate}
                                onChange={this.changeEndDate} />
                        </div>
                    </Row>
                    <Row noGutters className="mb-4 justify-content-between align-items-center">
                        <div className="col-auto">
                            <strong>Facturi</strong>
                        </div>
                    </Row>
                    {
                        this.props.sapInvoices && this.props.sapInvoices.length > 0
                            ? <div>
                                <div className='grid'>
                                    <Table hover className='dashboard-table'>
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.tableHeaderColumns.map(headerColumn => <td key={`_${headerColumn}`}>{headerColumn}</td>)
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.sapInvoices &&
                                                this.props.sapInvoices.map(item => {
                                                    return <tr className={item[this.state.idColumn] + ' grid-row'} key={item[this.state.idColumn]}>
                                                        {
                                                            this.state.fields.map(field => {
                                                                return <td key={`${item[this.state.idColumn]}_${field}`}>{item[field]}</td>
                                                            })
                                                        }
                                                        {
                                                            <td className='sm' key={item[this.state.idColumn] + '_dropdown_btn'}>
                                                                <button
                                                                    className="download-bill-button main-button small white icon only"
                                                                    onClick={() => this.downloadSapInvoice(item['id'], item['date'])}>
                                                                    <svg width="20" height="20" viewBox="0 0 24 24">
                                                                        <path id="a" d="M5 20h14a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm7.34-3.15a.461.461 0 0 1-.678.003L5.14 9.894a.528.528 0 0 1 .003-.717.466.466 0 0 1 .337-.147h3.226V2.4c0-.22.179-.4.4-.4h5.79c.221 0 .4.18.4.4v6.63h3.226c.264 0 .479.227.479.507 0 .134-.05.262-.139.357l-6.52 6.956z" />
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        }
                                                        {
                                                            <td>
                                                                {
                                                                    item['id'] &&
                                                                    <div className="dropdown">
                                                                        <button className="main-button small icon only white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" width="10px"
                                                                            position="relative">
                                                                            <svg width="20" height="20" viewBox="0 0 24 24">
                                                                                <path id="a" d="M12 9c.81 0 1.512.298 2.107.893.595.595.893 1.297.893 2.107 0 .81-.298 1.512-.893 2.107-.595.595-1.297.893-2.107.893-.81 0-1.512-.298-2.107-.893C9.298 13.512 9 12.81 9 12c0-.81.298-1.512.893-2.107C10.488 9.298 11.19 9 12 9zm8 0c.81 0 1.512.298 2.107.893.595.595.893 1.297.893 2.107 0 .81-.298 1.512-.893 2.107-.595.595-1.297.893-2.107.893-.81 0-1.512-.298-2.107-.893C17.298 13.512 17 12.81 17 12c0-.81.298-1.512.893-2.107C18.488 9.298 19.19 9 20 9zM4 9c.81 0 1.512.298 2.107.893C6.702 10.488 7 11.19 7 12c0 .81-.298 1.512-.893 2.107C5.512 14.702 4.81 15 4 15c-.81 0-1.512-.298-2.107-.893C1.298 13.512 1 12.81 1 12c0-.81.298-1.512.893-2.107C2.488 9.298 3.19 9 4 9z"></path>
                                                                            </svg>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                            <li><button className="dropdown-item" href="#" onClick={() => { this.openRectifyModal(item) }}>Storneaza</button></li>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div >
                                <Row noGutters>
                                    <Col>
                                        {this.props.sapInvoices &&
                                            <span className='small-font'>
                                                {this.props.sapInvoices.length === 1 ?
                                                    `1 din ${this.props.totalCount} facturi afisata` :
                                                    `${this.props.sapInvoices.length} din ${this.props.totalCount} facturi afisate`}
                                            </span>}
                                    </Col>
                                    <Col>
                                        <PageSelector currentPage={this.props.currentPage} pageCount={this.props.pageCount} pageSelectionCallback={this.pageSelectionCallback} />
                                    </Col>
                                    <Col className="right">
                                        <DropdownButton alignRight size='sm' variant='secondary' drop='up' title={`${this.props.pageSize} pe pagina`} className='page-size-dropdown' >
                                            <Dropdown.Item onClick={this.handlePageSizeEvent(10)}>10</Dropdown.Item>
                                            <Dropdown.Item onClick={this.handlePageSizeEvent(25)}>25</Dropdown.Item>
                                            <Dropdown.Item onClick={this.handlePageSizeEvent(50)}>50</Dropdown.Item>
                                        </DropdownButton>
                                    </Col>
                                </Row>
                            </div>
                            : <div>
                                <Row className='justify-content-md-center table-footer' noGutters>
                                    <Col sm="auto">
                                        <div className='no-bills'>
                                            <Row className='justify-content-md-center' noGutters>
                                                <Col sm="auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                                                        <g fill="none">
                                                            <path d="M0 0h24v24H0z" />
                                                            <path fill="#414751" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                        </g>
                                                    </svg>
                                                </Col>
                                            </Row>
                                            <Row noGutters><span>Nu exista facturi emise.</span></Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                    }
                </div>
                : <Row noGutters className='cards-loading-spinner'>
                    <Col className='center'>
                        <Spinner animation="border" variant="secondary" />
                    </Col>
                </Row>
        );
    }

    render() {
        return (
            <div>
                {this.renderSapInvoices()}
                {
                    this.props.showRectifyModal &&
                    <ConfirmationModal
                        title="Stornare factura"
                        confirmationQuestion="Continua aceasta actiune doar daca ai actualizat valoarea nominala a tichetului pe fiecare angajat"
                        confirm={this.rectifySapInvoice}
                        cancel={this.closeRectifyModal} />
                }
            </div>
        )
    }
}

export default SapInvoicesDashboard;
