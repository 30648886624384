import React from 'react';

class UploadFileScreenBody extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            this.props.location.pathname === this.props.expectedUploadUrl
                ? this.props.uploadFileScreen
                : this.props.uploadSteps
        );
    }
}

export default UploadFileScreenBody;
