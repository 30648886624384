import './FileUpload.scss';
import { Col, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import React from 'react';

class FileUpload extends React.Component {
  allowDrop(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  renderSpinner() {
    return (
      <div className="file-upload-spinner">
        <p className="cards-loading-message">Fisierul se incarca.</p>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  renderUploadInput() {
    return (
      <Row noGutters className="align-items-center justify-content-center">
        <Col xs={12} className="cards-upload-description mb-4">
          Drag & Drop aici sau click pe butonul de mai jos.
        </Col>
        <Col xs={12}>
          {this.props.children}
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div className="file-upload-area"
        onDrop={event => this.props.onDrop(event)}
        onDragOver={event => this.allowDrop(event)}
      >
        {
          this.props.uploadInProgress === true
            ? this.renderSpinner()
            : this.renderUploadInput()
        }
      </div>
    );
  }
}

const mapStateToProps = state => { return { uploadInProgress: state.upload.loadingScreenShow } };

export default connect(mapStateToProps)(FileUpload);
