import {
    AUDIT_HIDE_LOADING_ICON,
    AUDIT_SET_ENTRIES,
    AUDIT_SET_FILTER,
    AUDIT_SET_STATE,
    AUDIT_SHOW_LOADING_ICON
} from 'actions/audit';

export default function audit(state, action) {
    state = state || {
        filter: {
            startDate: '',
            endDate: '',
            username: '',
            pageNumber: 1,
            pageSize: 10
        },
        isAuditLoadingIconVisible:false
    };

    switch (action.type) {
        case AUDIT_SET_STATE:
            return {
                ...state,
                ...action.payload
            };
        case AUDIT_SET_ENTRIES:
            return {
                ...state,
                entries: action.payload.auditEntries,
                totalCount: action.payload.totalCount
            };
        case AUDIT_SET_FILTER:
            return {
                ...state,
                filter: { ...state.filter, ...action.payload }
            };
        case AUDIT_SHOW_LOADING_ICON:
            return {
                ...state,
                isAuditLoadingIconVisible: true
            };
        case AUDIT_HIDE_LOADING_ICON:
            return {
                ...state,
                isAuditLoadingIconVisible: false
            };
        default:
            return state;
    }
}
