import errorCodes from 'utils/errorCodes';
import React from 'react';

class BranchImportErrors extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <p className={
                    this.props.uploadError === errorCodes.wrongFileExtension
                        ? 'input-validation-error text-center visible'
                        : 'input-validation-error'
                }>Fisierul incarcat trebuie sa fie in format csv sau xls(x).</p>
                <p className={
                    this.props.uploadError === errorCodes.onlyCsvFile
                        ? 'input-validation-error text-center visible'
                        : 'input-validation-error'
                }>Fisierul incarcat trebuie sa fie in format csv.</p>
                <p className={
                    this.props.uploadError === errorCodes.wrongFileFormat
                        ? 'input-validation-error text-center visible'
                        : 'input-validation-error'
                }>Continutul fisierului incarcat nu este valid.</p>
                <p className={
                    this.props.uploadError === errorCodes.fileUploadFailed
                        ? 'input-validation-error text-center visible'
                        : 'input-validation-error'
                }>Incarcarea fisierului a esuat.</p>
                <p className={
                    this.props.uploadError === errorCodes.fileMaxSizeExceeded
                        ? 'input-validation-error text-center visible'
                        : 'input-validation-error'
                }>Fisierul incarcat depaseste dimensiunea maxima admisa (20MB).</p>
                <p className={
                    this.props.uploadError === errorCodes.noDataFound
                        ? 'input-validation-error text-center visible'
                        : 'input-validation-error'
                }>Fisierul incarcat nu contine date.</p>
                <p className={
                    this.props.uploadError === errorCodes.duplicateRecordsFound
                        ? 'input-validation-error text-center visible'
                        : 'input-validation-error'
                }>Fisierul incarcat contine elemente duplicate.</p>
            </>
        )
    }
}

export default BranchImportErrors;
