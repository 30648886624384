import {
  correctionScreenClearSelection,
  ITEM_CORRECTION_PAGINATE_ITEMS,
  ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE,
  ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS,
  ITEM_CORRECTION_SET_CURRENT_PAGE,
  ITEM_CORRECTION_SET_ERROR_FILTER,
  ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED,
  ITEM_CORRECTION_SET_PAGE_SIZE,
  ITEM_CORRECTION_SET_SEARCH_TERM,
  ITEM_CORRETION_REMOVE_SELECTED_ITEMS,
  paginateItemsToBeCorrected,
  setCorrectionScreenCurrentPage,
  setCorrectionScreenItems,
  setCorrectionScreenPageCount,
  setCorrectionScreenSearchTerm,
  setCorrectionScreenTotalCount,
  setErrorFilter,
  setFilteredItems,
  setItemsToBeCorrected,
  setSendCorrectedItemsInProgress
} from 'actions/itemCorrection';

import { errorFilter, fileUploadType } from 'utils/enums';
import errorCodes from 'utils/errorCodes';
import { setValidationStatus } from 'actions/upload';
import { showToast } from 'actions/toast';
import { toggleModalVisibility } from 'actions/customModal';

function itemCorrection(store) {
  return next => action => {
    switch (action.type) {
      case ITEM_CORRECTION_SET_ERROR_FILTER: {
        const { pageSize, lastNameOrCnp, itemsToBeCorrected } = store.getState().itemCorrection;

        let filteredItems = action.payload === errorFilter.onlyErrors
          ? itemsToBeCorrected.filter(item => { return item.errors.length > 0 })
          : itemsToBeCorrected;

        next(setCorrectionScreenCurrentPage(1));
        next(setFilteredItems(filteredItems));
        next(correctionScreenClearSelection());
        store.dispatch(paginateItemsToBeCorrected(filteredItems, 1, pageSize, lastNameOrCnp));

        break;
      }
      case ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED: {
        const pageSize = store.getState().itemCorrection.pageSize;

        next(setErrorFilter(errorFilter.all));
        next(setCorrectionScreenSearchTerm(''));
        next(setCorrectionScreenCurrentPage(1));
        next(correctionScreenClearSelection());
        next(setFilteredItems(action.payload.items));

        action.payload && store.dispatch(paginateItemsToBeCorrected(action.payload.items, 1, pageSize, '', errorFilter.all));

        let searchBarInput = document.getElementById('search-bar-input-cards');
        searchBarInput ? searchBarInput.value = '' : {};

        break;
      }
      case ITEM_CORRECTION_PAGINATE_ITEMS: {
        let pageNumber = action.payload.pageNumber,
          size = action.payload.pageSize,
          term = action.payload.lastNameOrCnp,
          items = action.payload.items,
          pageCount = pageNumber;
        const { uploadTypeId } = store.getState().itemCorrection;

        switch (uploadTypeId) {
          case fileUploadType.cardsImport:
          case fileUploadType.cardsDelete:
          case fileUploadType.cardsImportAllBranches:
          case fileUploadType.cardsModifyTicketValue:
            items = term && term !== ''
              ? items.filter(item => { return item.lastName.toLowerCase().startsWith(term) || item.cnp.startsWith(term) })
              : items;
            break;
          case fileUploadType.branchesImport:
            items = term && term !== ''
              ? items.filter(item => { return item.name.toLowerCase().includes(term) })
              : items;
            break;
        }

        pageCount = Math.ceil(items.length / size);

        if (pageNumber > pageCount) {
          pageNumber = pageCount;
        }

        if (items && items.length > 0) {
          next(setCorrectionScreenItems(items.slice((pageNumber - 1) * size, pageNumber * size)));
          next(setCorrectionScreenPageCount(pageCount));
          next(setCorrectionScreenCurrentPage(pageNumber));
          next(setCorrectionScreenTotalCount(items.length));
        } else {
          next(setCorrectionScreenItems([]));
          next(setCorrectionScreenPageCount(1));
          next(setCorrectionScreenTotalCount(0));
        }

        break;
      }
      case ITEM_CORRECTION_SET_CURRENT_PAGE: {
        const { pageSize, lastNameOrCnp, filteredItems, filter } = store.getState().itemCorrection;

        store.dispatch(paginateItemsToBeCorrected(filteredItems, action.payload, pageSize, lastNameOrCnp, filter));

        break;
      }
      case ITEM_CORRECTION_SET_SEARCH_TERM: {
        const { pageSize, filteredItems, filter } = store.getState().itemCorrection;

        store.dispatch(paginateItemsToBeCorrected(filteredItems, 1, pageSize, action.payload, filter));

        next(setCorrectionScreenCurrentPage(1));
        next(correctionScreenClearSelection());

        break;
      }
      case ITEM_CORRECTION_SET_PAGE_SIZE: {
        const { lastNameOrCnp, filteredItems, filter } = store.getState().itemCorrection;

        store.dispatch(paginateItemsToBeCorrected(filteredItems, 1, action.payload, lastNameOrCnp, filter));

        next(setCorrectionScreenCurrentPage(1));

        break;
      }
      case ITEM_CORRETION_REMOVE_SELECTED_ITEMS: {
        const {
          currentPage,
          pageSize,
          lastNameOrCnp,
          itemsToBeCorrected,
          filteredItems,
          selectedItems,
          uploadTypeId
        } = store.getState().itemCorrection;

        let selectedItemsIds = selectedItems.map(si => si.cardId),
          remainingItems = itemsToBeCorrected.filter(item => !selectedItemsIds.includes(item.id)),
          remainingFilteredItems = filteredItems.filter(item => !selectedItemsIds.includes(item.id));

        next(setItemsToBeCorrected(remainingItems, uploadTypeId));
        next(setFilteredItems(remainingFilteredItems));
        next(correctionScreenClearSelection());
        store.dispatch(paginateItemsToBeCorrected(remainingFilteredItems, currentPage, pageSize, lastNameOrCnp));

        break;
      }
      case ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE: {
        next(toggleModalVisibility(false));
        next(setValidationStatus(false));
        next(setSendCorrectedItemsInProgress(false));
        if (action.payload.items) {
          store.dispatch(setItemsToBeCorrected(action.payload.items, action.payload.uploadTypeId));
        }
        else {
          store.dispatch(showToast({ type: 'error', text: errorCodes.genericErrorMessage }))
        }
        break;
      }
      case ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS: {
        next(toggleModalVisibility(false));
        next(setValidationStatus(true));
        next(setSendCorrectedItemsInProgress(false));
        store.dispatch(setCorrectionScreenSearchTerm(''));
        break;
      }
    }

    const returnValue = next(action)

    return returnValue
  }
}

export default itemCorrection;
