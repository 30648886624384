import {
  addCardToSelection,
  clearSelection,
  deleteCard,
  findCardsRequest,
  hideCardMigrationModal,
  hideCardReissueModal,
  hideChangeTicketValueModal,
  hideConfirmationModalForCardReissue,
  hideModalForCardConfirmation,
  hidePinReissueModal,
  reissueCards,
  removeCardFromSelection,
  selectAllCards,
  setCardsCurrentPage,
  setCardsLoadedFlag,
  setCardsPageSize,
  setSelectedCards,
  showCardMigrationModal,
  showCardReissueModal,
  showChangeTicketValueModal,
  showConfirmatiomModalForCardReissue,
  showModalForCardConfirmation,
  showPinReissueModal
} from 'actions/card';

import { bindActionCreators } from 'redux';
import { branchStatus } from 'utils/enums';
import CardsGrid from './CardsGrid';
import { connect } from 'react-redux';
import { showLoadingSpinner } from 'actions/loadingSpinner';

const mapStateToProps = state => {
  return {
    ...state.card,
    ...state.branch,
    roleId: state.user.roleId,
    branchesCount: state.branch.branches ? state.branch.branches.filter(b => b.status === branchStatus.enabled).length : null
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  addCardToSelection,
  clearSelection,
  deleteCard,
  removeCardFromSelection,
  selectAllCards,
  setCardsCurrentPage,
  setCardsPageSize,
  setSelectedCards,
  showCardReissueModal,
  hideCardReissueModal,
  showPinReissueModal,
  hidePinReissueModal,
  showCardMigrationModal,
  hideCardMigrationModal,
  showChangeTicketValueModal,
  hideChangeTicketValueModal,
  showModalForCardConfirmation,
  hideModalForCardConfirmation,
  showConfirmatiomModalForCardReissue,
  hideConfirmationModalForCardReissue,
  findCardsRequest,
  showLoadingSpinner,
  reissueCards,
  setCardsLoadedFlag
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardsGrid);
