import { changePasswordRequest, setLoginError } from 'actions/user';
import { Col, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { Checkbox } from 'components';
import { connect } from 'react-redux';
import errorCodes from 'utils/errorCodes';
import { push } from 'connected-react-router';
import React from 'react';
import { Redirect } from 'react-router';
import routes from 'utils/routes';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import userStatus from 'utils/userStatus';

const mapStateToProps = state => ({ ...state.user, ...state.router });

class PasswordSetupView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptedTermsConds: false,
      termsCondsModalVisible: false,
      isSubmitted: false,
      password: '',
      passwordVisible: false,
      confirmPassword: '',
      confirmPasswordVisible: false
    };

    this.changePasswordButton = React.createRef();
    this.removeErrorMessage = this.removeErrorMessage.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.shouldRedirect = this.shouldRedirect.bind(this);
    this.showTermsAndConditions = this.showTermsAndConditions.bind(this);
    this.closeTermsAndConditions = this.closeTermsAndConditions.bind(this);
    this.handleTermsCondsAcceptance = this.handleTermsCondsAcceptance.bind(this);
    this.acceptTermsAndConditions = this.acceptTermsAndConditions.bind(this);
    this.togglePasswordShow = this.togglePasswordShow.bind(this);
    this.isFirstTimeLogin = this.isFirstTimeLogin.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      if (!this.state.isSubmitted) {
        if (this.state.isChangePassword) {
          this.props.push(routes.changePassword);
        }
        else {
          this.props.logout();
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.props.loginError === errorCodes.invalidPasswordFormat ||
      this.props.loginError === errorCodes.passwordsDoNotMatch) {
      document.getElementById('setPassword').value = '';
      document.getElementById('confirmSetPassword').value = '';
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loginError) {
      this.setState({
        password: '',
        passwordVisible: false,
        confirmPassword: '',
        confirmPasswordVisible: false
      });
    }
  }

  componentWillUnmount() {
    if (!this.state.isSubmitted) {
      if (this.props.isChangePassword) {
        this.props.push(routes.changePassword);
      }
      else {
        this.props.logout();
      }
    }
    window.onbeforeunload = undefined;
  }

  togglePasswordShow() {
    const pwdVisible = this.state.passwordVisible;
    this.setState({ passwordVisible: !pwdVisible });
  }

  toggleConfirmPasswordShow() {
    const confirmPwdVisible = this.state.confirmPasswordVisible;
    this.setState({ confirmPasswordVisible: !confirmPwdVisible });
  }

  passwordOnChange(event) {
    this.removeErrorMessage();
    this.setState({ password: event && event.target.value });

    if (!event.target.value) {
      this.setState({ passwordVisible: false });
    }
  }

  confirmPasswordOnChange(event) {
    this.removeErrorMessage();
    this.setState({ confirmPassword: event && event.target.value });

    if (!event.target.value) {
      this.setState({ confirmPasswordVisible: false });
    }
  }

  removeErrorMessage() {
    if (this.props.loginError) {
      this.props.setLoginError('');
    }
  }

  changePassword() {
    const currentPassword = this.props.password.password
    const newPassword = document.getElementById('setPassword').value;
    const confirmedNewPassword = document.getElementById('confirmSetPassword').value;

    if (newPassword === '') {
      this.props.setLoginError(errorCodes.fieldRequired);
      return;
    }

    if (newPassword === confirmedNewPassword) {
      if (!this.state.acceptedTermsConds && this.isFirstTimeLogin())
        this.props.setLoginError(errorCodes.termsAndConditionsNotAccepted);
      else {
        this.setState({ isSubmitted: true });
        this.changePasswordButton.current.classList.add('loading', 'disabled');
        this.props.changePasswordRequest(currentPassword, newPassword);
      }
    } else {
      this.props.setLoginError(errorCodes.passwordsDoNotMatch);
      this.setState({
        passwordVisible: false,
        confirmPasswordVisible: false
      });
    }
  }

  handleKeyUp(event) {
    const code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      this.changePassword();
    }
  }

  shouldRedirect() {
    if (!this.props.isForgotPassword || !this.props.isChangePassword) {
      return !this.props.smsTokenSubmitted;
    }
    return false;
  }

  showTermsAndConditions() {
    this.setState({
      termsCondsModalVisible: true
    });
  }

  closeTermsAndConditions() {
    this.setState({
      termsCondsModalVisible: false
    });
  }

  isFirstTimeLogin() {
    return this.props.userStatus === userStatus.created || this.props.userStatus === userStatus.createdResetPassword;
  }

  handleTermsCondsAcceptance(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      acceptedTermsConds: value
    });
  }

  acceptTermsAndConditions() {
    this.setState({
      acceptedTermsConds: true
    });

    this.closeTermsAndConditions();
  }

  render() {
    if (this.shouldRedirect()) {
      return <Redirect to={routes.accountLogin} />;
    }

    return (
      <div className="login-form">
        <div className="password-setup-view">
          <h1>Setare parola</h1>
          <div className="form-group-label">
            <input
              id="setPassword"
              className="form-control"
              type={this.state.passwordVisible ? 'text' : 'password'}
              placeholder="Introduceti o parola noua"
              autoFocus
              onKeyUp={this.handleKeyUp}
              onChange={(event) => { this.passwordOnChange(event) }} />
            <label className="form-label" htmlFor="setPassword">Parola noua</label>
            {
              this.state.passwordVisible
                ? <svg onClick={() => this.togglePasswordShow()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path fill={this.state.password ? '#181818' : '#c0c0c0'} d="M12 5c2.481 0 5.086 1.111 7.62 3.157C21.401 9.597 23 11.418 23 12.09c0 .67-1.598 2.491-3.38 3.932-2.534 2.045-5.139 3.157-7.62 3.157s-5.086-1.112-7.62-3.157C2.599 14.58 1 12.76 1 12.089c0-.67 1.598-2.492 3.38-3.932C6.915 6.111 9.52 5 12 5zm0 1.467c-2.115 0-4.423.994-6.698 2.831a17.798 17.798 0 0 0-2.172 2.074c-.235.27-.435.52-.571.717.136.197.336.447.571.717.61.697 1.37 1.425 2.172 2.073 2.275 1.838 4.583 2.832 6.698 2.832s4.423-.994 6.698-2.832a17.798 17.798 0 0 0 2.172-2.073c.188-.216.353-.42.482-.593l.09-.124-.09-.124a9.789 9.789 0 0 0-.482-.593 17.798 17.798 0 0 0-2.172-2.074C16.423 7.461 14.115 6.467 12 6.467zm0 1.466a4.156 4.156 0 1 1 0 8.311 4.156 4.156 0 0 1 0-8.31zM12 9.4a2.689 2.689 0 1 0 0 5.378A2.689 2.689 0 0 0 12 9.4z" />
                  </g>
                </svg>
                : <svg onClick={() => this.togglePasswordShow()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path fill={this.state.password ? '#181818' : '#c0c0c0'} d="M19.62 8.565C21.401 10.038 23 11.9 23 12.585c0 .687-1.598 2.549-3.38 4.022-2.534 2.092-5.139 3.229-7.62 3.229-1.51 0-3.065-.421-4.622-1.222l1.1-1.126c1.206.557 2.391.848 3.522.848 2.115 0 4.423-1.017 6.698-2.896a17.979 17.979 0 0 0 2.172-2.121c.188-.22.353-.429.482-.606l.09-.127-.09-.127a9.972 9.972 0 0 0-.482-.606A17.979 17.979 0 0 0 17.166 8.6l1.06-1.085c.466.318.93.668 1.393 1.05zM12 5.335c1.15 0 2.327.245 3.512.716L14.36 7.228A7.71 7.71 0 0 0 12 6.836c-2.115 0-4.423 1.017-6.698 2.896a17.979 17.979 0 0 0-2.172 2.12 8.892 8.892 0 0 0-.571.734c.136.201.336.457.571.733a17.979 17.979 0 0 0 2.752 2.58l-1.046 1.073c-.152-.119-.304-.24-.455-.365C2.598 15.134 1 13.272 1 12.586c0-.686 1.598-2.548 3.38-4.021C6.915 6.472 9.52 5.335 12 5.335zm4.156 7.25c0 2.348-1.86 4.25-4.156 4.25a4.07 4.07 0 0 1-2.235-.666l1.078-1.1c.35.171.743.267 1.157.267 1.485 0 2.689-1.231 2.689-2.75 0-.424-.094-.826-.261-1.184l1.076-1.102a4.3 4.3 0 0 1 .652 2.286zM12 8.336c.39 0 .766.056 1.124.158l-1.32 1.35c-1.329.098-2.39 1.183-2.486 2.541l-1.32 1.35c-.1-.365-.154-.75-.154-1.148 0-2.347 1.861-4.25 4.156-4.25zm7.553-4.115a.742.742 0 0 1-.012 1.049L4.691 20.118a.742.742 0 0 1-1.048.012.742.742 0 0 1 .012-1.05L18.504 4.232a.742.742 0 0 1 1.049-.011z" />
                  </g>
                </svg>
            }
          </div>
          <p className={
            this.props.loginError === errorCodes.invalidPasswordFormat
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Parola nu corespunde cerintelor.</p>
          <p className={
            this.props.loginError === errorCodes.fieldRequired
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Acest camp este obligatoriu.</p>
          <p className="info">Foloseste o combinatie de 8 sau mai multe litere mici, litere mari, cifre si simboluri.</p>
          <br />
          <div className="form-group-label">
            <input
              id="confirmSetPassword"
              className="form-control"
              type={this.state.confirmPasswordVisible ? 'text' : 'password'}
              placeholder="Reintroduceti parola setata mai sus"
              onKeyUp={this.handleKeyUp}
              onChange={(event) => { this.confirmPasswordOnChange(event) }} />
            <label className="form-label" htmlFor="confirmSetPassword">Confirma parola</label>
            {
              this.state.confirmPasswordVisible
                ? <svg onClick={() => this.toggleConfirmPasswordShow()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path fill={this.state.confirmPassword ? '#181818' : '#c0c0c0'} d="M12 5c2.481 0 5.086 1.111 7.62 3.157C21.401 9.597 23 11.418 23 12.09c0 .67-1.598 2.491-3.38 3.932-2.534 2.045-5.139 3.157-7.62 3.157s-5.086-1.112-7.62-3.157C2.599 14.58 1 12.76 1 12.089c0-.67 1.598-2.492 3.38-3.932C6.915 6.111 9.52 5 12 5zm0 1.467c-2.115 0-4.423.994-6.698 2.831a17.798 17.798 0 0 0-2.172 2.074c-.235.27-.435.52-.571.717.136.197.336.447.571.717.61.697 1.37 1.425 2.172 2.073 2.275 1.838 4.583 2.832 6.698 2.832s4.423-.994 6.698-2.832a17.798 17.798 0 0 0 2.172-2.073c.188-.216.353-.42.482-.593l.09-.124-.09-.124a9.789 9.789 0 0 0-.482-.593 17.798 17.798 0 0 0-2.172-2.074C16.423 7.461 14.115 6.467 12 6.467zm0 1.466a4.156 4.156 0 1 1 0 8.311 4.156 4.156 0 0 1 0-8.31zM12 9.4a2.689 2.689 0 1 0 0 5.378A2.689 2.689 0 0 0 12 9.4z" />
                  </g>
                </svg>
                : <svg onClick={() => this.toggleConfirmPasswordShow()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path fill={this.state.confirmPassword ? '#181818' : '#c0c0c0'} d="M19.62 8.565C21.401 10.038 23 11.9 23 12.585c0 .687-1.598 2.549-3.38 4.022-2.534 2.092-5.139 3.229-7.62 3.229-1.51 0-3.065-.421-4.622-1.222l1.1-1.126c1.206.557 2.391.848 3.522.848 2.115 0 4.423-1.017 6.698-2.896a17.979 17.979 0 0 0 2.172-2.121c.188-.22.353-.429.482-.606l.09-.127-.09-.127a9.972 9.972 0 0 0-.482-.606A17.979 17.979 0 0 0 17.166 8.6l1.06-1.085c.466.318.93.668 1.393 1.05zM12 5.335c1.15 0 2.327.245 3.512.716L14.36 7.228A7.71 7.71 0 0 0 12 6.836c-2.115 0-4.423 1.017-6.698 2.896a17.979 17.979 0 0 0-2.172 2.12 8.892 8.892 0 0 0-.571.734c.136.201.336.457.571.733a17.979 17.979 0 0 0 2.752 2.58l-1.046 1.073c-.152-.119-.304-.24-.455-.365C2.598 15.134 1 13.272 1 12.586c0-.686 1.598-2.548 3.38-4.021C6.915 6.472 9.52 5.335 12 5.335zm4.156 7.25c0 2.348-1.86 4.25-4.156 4.25a4.07 4.07 0 0 1-2.235-.666l1.078-1.1c.35.171.743.267 1.157.267 1.485 0 2.689-1.231 2.689-2.75 0-.424-.094-.826-.261-1.184l1.076-1.102a4.3 4.3 0 0 1 .652 2.286zM12 8.336c.39 0 .766.056 1.124.158l-1.32 1.35c-1.329.098-2.39 1.183-2.486 2.541l-1.32 1.35c-.1-.365-.154-.75-.154-1.148 0-2.347 1.861-4.25 4.156-4.25zm7.553-4.115a.742.742 0 0 1-.012 1.049L4.691 20.118a.742.742 0 0 1-1.048.012.742.742 0 0 1 .012-1.05L18.504 4.232a.742.742 0 0 1 1.049-.011z" />
                  </g>
                </svg>
            }
          </div>
          <p className={
            this.props.loginError === errorCodes.passwordsDoNotMatch
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Parolele nu se potrivesc. Incercati din nou.</p>
          <p className={
            this.props.loginError === errorCodes.passwordAlreadyUsed
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>{this.props.isChangePassword ? 'Setati o alta parola, diferita de cea actuala.' :
            'Setati o alta parola, diferita de cea initiala.'}
          </p>
          <p className={
            this.props.loginError === errorCodes.serverError
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>{errorCodes.genericErrorMessage}</p>
          <Row noGutters className="button-container">
            {
              (this.props.userStatus === userStatus.created || this.props.userStatus === userStatus.createdResetPassword) &&
              <Col sm="auto" className="terms-conds-link">
                <Checkbox checked={this.state.acceptedTermsConds} onChange={(event) => { this.handleTermsCondsAcceptance(event) }} />
                <label className="form-label">Accept</label>
                <label className="form-label link" onClick={() => this.showTermsAndConditions()}>termenii si conditiile</label>
              </Col>
            }
            <Col className="main-button-container">
              <button className={'main-button '}
                onClick={this.changePassword}
                ref={this.changePasswordButton}
                id="changePasswordButton">
                <span className="spinner-border spinner-border-sm"></span>
                FINALIZEAZA
              </button>
            </Col>
          </Row>
          <p className={
            this.props.loginError === errorCodes.termsAndConditionsNotAccepted
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Trebuie sa acceptati termenii si conditiile.</p>

          {
            this.state.termsCondsModalVisible &&
            <TermsAndConditionsModal close={this.closeTermsAndConditions} ok={this.acceptTermsAndConditions} />
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changePasswordRequest, setLoginError,
  push
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordSetupView);

