import './PinReissueModal.scss';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { hidePinReissueModal, reissuePin, setPinReissueModalError } from 'actions/card';
import { bindActionCreators } from 'redux';
import { branchStatus } from 'utils/enums';
import { connect } from 'react-redux';
import React from 'react';
import Select from 'react-select';
import validator from 'utils/validator';

const ERROR_PHONE_NUMBER_INVALID = 'Numar de telefon invalid';

class PinReissueModal extends React.Component {
  constructor(props) {
    super(props);

    const selectedCard = this.props.cards.filter(c => c.id === this.props.pinReissueModalShow.cardId)[0];
    this.state = {
      card: selectedCard,
      phoneNumber: selectedCard.phoneNumber,
      isPhoneNumberError: false,
      phoneNumberError: '',
      branchOptions: [],
      selectedBranch: {},
      selectedBranchLocation: ''
    };

    this.getBranchLocation = this.getBranchLocation.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
  }

  componentDidMount() {
    let branchOptions = [];
    this.props.branches && this.props.branches.forEach(item => item.id && item.status === branchStatus.enabled && branchOptions.push({ value: item.id, label: item.name }));
    this.setState({ branchOptions });

    let selectedBranch = branchOptions.filter(b => b.value === this.state.card.branchId)[0]
    this.setState({ selectedBranch });

    let selectedBranchLocation = this.getBranchLocation(selectedBranch.value);
    this.setState({ selectedBranchLocation })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleBranchChange(selectedBranch) {
    this.setState({ selectedBranch });

    let selectedBranchLocation = this.getBranchLocation(selectedBranch.value);
    this.setState({ selectedBranchLocation });
  }

  getBranchLocation(branchId) {
    const branch = this.props.branches.filter(b => b.id === branchId)[0];
    return branch.name + ', ' + branch.address;
  }

  validatePhoneNumber() {
    if (this.state.phoneNumber &&
      this.state.phoneNumber.trim() !== '' &&
      !validator.isValidPhoneNumber(this.state.phoneNumber)) {
      this.setState({ isPhoneNumberError: true, phoneNumberError: ERROR_PHONE_NUMBER_INVALID });
    } else {
      this.setState({ isPhoneNumberError: false, phoneNumberError: '' });
      this.props.setPinReissueModalError(null);
      return true;
    }
  }

  isModalValid() {
    this.validatePhoneNumber();

    if (this.state.isPhoneNumberError) {
      return false;
    }

    return true;
  }

  submit() {
    let isModalValid = this.isModalValid();
    if (isModalValid) {
      const { card, phoneNumber } = { ...this.state };
      this.props.reissuePin({
        CardId: card.id,
        BranchId: this.state.selectedBranch.value,
        PhoneNumber: phoneNumber,
        LastName: card.lastName,
        FirstName: card.firstName
      });
    }
  }

  render() {
    return (
      <Modal className='confirmation-modal pin-reissue'
        show
        backdrop='static'
        onHide={this.props.hidePinReissueModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reemitere PIN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-lastname'>
                <Form.Control autoFocus readOnly
                  name='lastName' type='text'
                  placeholder='Nume'
                  value={this.state.card.lastName || ''} />
                <Form.Label>Nume</Form.Label>
              </Form.Group>
              <Form.Group className='form-group-label' as={Col} controlId='card-firstname'>
                <Form.Control autoFocus readOnly
                  name='firstName' type='text'
                  placeholder='Prenume'
                  value={this.state.card.firstName || ''} />
                <Form.Label>Prenume</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-cnp'>
                <Form.Control autoFocus readOnly
                  name='cnp' type='text'
                  placeholder='CNP'
                  value={this.state.card.cnp || ''} />
                <Form.Label>CNP</Form.Label>
              </Form.Group>
              <Form.Group className='form-group-label' as={Col} controlId='card-phoneNumber'>
                {this.state.isPhoneNumberError && <span className='error-label'>{this.state.phoneNumberError}</span>}
                {!this.state.isPhoneNumberError && this.props.pinReissueModalError && <span className="error-label">{this.props.pinReissueModalError}</span>}
                <Form.Control autoFocus
                  name='phoneNumber' type='text'
                  placeholder='Numar de Telefon'
                  onChange={this.handleInputChange}
                  value={this.state.phoneNumber || ''}
                  onBlur={this.validatePhoneNumber} />
                <Form.Label>Telefon *</Form.Label>
              </Form.Group>
            </Form.Row>
            {
              !this.state.phoneNumber &&
              <Form.Row>
                <Form.Group className='form-group-label' as={Col} controlId='card-branch'>
                  <Select
                    className='branches-multi-select has-items'
                    value={this.state.selectedBranch}
                    onChange={this.handleBranchChange}
                    isSearchable={true}
                    options={this.state.branchOptions}
                    placeholder=''
                  />
                  <Form.Label className='select-label active large-select'>Punct de lucru</Form.Label>
                </Form.Group>
              </Form.Row>
            }
            <Form.Row>
              <Form.Group className='form-group-label' as={Col} controlId='card-location'>
                <Form.Control autoFocus readOnly
                  name='location' type='text'
                  placeholder='Locatie'
                  value={this.state.selectedBranchLocation} />
                <Form.Label>Locatie</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row className="phone-number-info">
              * PIN-ul se va trimite prin SMS la numarul de telefon introdus.
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm='auto'>
              <div className='main-button white' onClick={this.props.hidePinReissueModal}>
                RENUNTA
              </div>
            </Col>
            <Col sm='auto'>
              <div className='main-button' onClick={this.submit}>
                REEMITE PIN
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.card,
    branches: state.branch.branches
  }
};
const mapDispatchToProps = dispatch => bindActionCreators({
  hidePinReissueModal,
  reissuePin,
  setPinReissueModalError
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PinReissueModal);
