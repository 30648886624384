export const SAP_INVOICE_FIND_REQUEST = 'SAP_INVOICE_FIND_REQUEST';
export const SAP_INVOICE_FIND_FAILURE = 'SAP_INVOICE_FIND_FAILURE';
export const SAP_INVOICE_FIND_SUCCESS = 'SAP_INVOICE_FIND_SUCCESS';

export const SAP_INVOICE_SET_CURRENT_PAGE = 'SAP_INVOICE_SET_CURRENT_PAGE'
export const SAP_INVOICE_SET_PAGE_COUNT = 'SAP_INVOICE_SET_PAGE_COUNT'
export const SAP_INVOICE_SET_PAGE_SIZE = 'SAP_INVOICE_SET_PAGE_SIZE'
export const SAP_INVOICE_SET_TOTAL_COUNT = 'SAP_INVOICE_SET_TOTAL_COUNT'

export const SAP_INVOICE_HIDE_LOADING_ICON = 'SAP_INVOICE_HIDE_LOADING_ICON';
export const SAP_INVOICE_SHOW_LOADING_ICON = 'SAP_INVOICE_SHOW_LOADING_ICON';

export const SET_SAP_INVOICES = 'SET_SAP_INVOICES';

export const SAP_INVOICE_RECTIFY_REQUEST = 'SAP_INVOICE_RECTIFY_REQUEST';
export const SAP_INVOICE_RECTIFY_REQUEST_SUCCESS = 'SAP_INVOICE_RECTIFY_REQUEST_SUCCESS';
export const SAP_INVOICE_RECTIFY_REQUEST_FAIL = 'SAP_INVOICE_RECTIFY_REQUEST_FAIL';

export const SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY = 'SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY';
export const SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL = 'SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL';
export const SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL = 'SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL';   

export const findSapInvoices = (startDate, endDate, pageNumber, pageSize) => ({ type: SAP_INVOICE_FIND_REQUEST, payload: { startDate: startDate, endDate: endDate, pageNumber: pageNumber, pageSize: pageSize } });
export const findSapInvoicesFailure = errorMessage => ({ type: SAP_INVOICE_FIND_FAILURE, payload: { errorMessage: errorMessage }});
export const findSapInvoicesSuccess = sapInvoices => ({ type: SAP_INVOICE_FIND_SUCCESS, payload: { sapInvoices: sapInvoices.sapInvoices, totalCount: sapInvoices.totalCount } });

export const setSapCurrentPage = pageNumber => ({ type: SAP_INVOICE_SET_CURRENT_PAGE, payload: pageNumber });
export const setPageCount = pageCount => ({ type: SAP_INVOICE_SET_PAGE_COUNT, payload: pageCount });
export const setPageSize = pageSize => ({ type: SAP_INVOICE_SET_PAGE_SIZE, payload: pageSize });
export const setTotalCount = totalCount => ({ type: SAP_INVOICE_SET_TOTAL_COUNT, payload: totalCount });

export const showSapInvoiceLoadingIcon = () => ({ type: SAP_INVOICE_SHOW_LOADING_ICON });
export const hideSapInvoiceLoadingIcon = () => ({ type: SAP_INVOICE_HIDE_LOADING_ICON });

export const setSapInvoices = sapInvoices => ({ type: SET_SAP_INVOICES, payload: sapInvoices });

export const rectifySapInvoice = sapInvoiceId => ({ type: SAP_INVOICE_RECTIFY_REQUEST, payload: sapInvoiceId });
export const rectifySapInvoiceSuccess = () => ({ type: SAP_INVOICE_RECTIFY_REQUEST_SUCCESS });
export const rectifySapInvoiceFail = () => ({ type: SAP_INVOICE_RECTIFY_REQUEST_FAIL });

export const setSapInvoiceToRectify = sapInvoice => ({ type: SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY, payload: sapInvoice });
export const showRectifySapInvoiceModal = () => ({ type: SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL });
export const hideRectifySapInvoiceModal = () => ({ type: SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL });

