import { blockCurrentBranchSelection, findBranches } from 'actions/branch';
import { bindActionCreators } from 'redux';
import BranchImport from './BranchImport';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { setUploadConfig } from 'actions/upload';

const mapStateToProps = state => {
  return {
    ...state.router
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setUploadConfig,
  findBranches,
  blockCurrentBranchSelection,
  replace,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BranchImport);
