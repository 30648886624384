import { Col, Modal, Row } from 'react-bootstrap';
import React from 'react';

class TermsAndConditionsModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="login-form">
      <Modal className="confirmation-modal" 
        show 
        backdrop='static'
        scrollable="true"
        onHide={this.props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Termeni si conditii</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="terms-cond-content">
            <p>Bine ai venit in platforma Bancii Transilvania pentru gestionarea cardurilor de masa! </p>
            <p>Ai ajuns aici pentru ca angajatorul tau sau, dupa caz, un coleg desemnat de angajatorul tau, te-a nominalizat sa devii user in platforma. Drepturile pe care le ai ca user ti-au fost deja comunicate in e-mailul pe care ti l-am trimis. </p>
            <p>Pentru a ti se crea userul, pentru a-ti permite accesul si pentru gestionarea drepturilor tale in platforma, am primit numele, prenumele, adresa de e-mail si numarul tau de telefon prin intermediul angatorului tau. Daca va fi nevoie sa comunicam cu tine in legatura cu aspecte ce tin de platforma, vom folosi aceste date de contact, atat timp cat vei fi user. La aceste date are acces in platforma si angajatorul tau.</p>
            <p>Detalii despre modul in care BT prelucreaza datele cu caracter personal regasesti in <a href="https://www.bancatransilvania.ro/politica-prelucrare-si-protectie-date-personale/" target="_blank">Politica de confidentialitate BT</a>, disponibila pe website-ul <a href="https://www.bancatransilvania.ro/" target="_blank">www.bancatransilvania.ro</a> </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col className="button-group" sm="auto">
              <div className="main-button" onClick={this.props.ok}>
                Sunt de acord
              </div>
            </Col>
          </Row>
        </Modal.Footer>
        </Modal>
        </div>
    );
  }
}
export default TermsAndConditionsModal;
