import './BranchHeader.scss';
import { branchStatus, userRole } from 'utils/enums';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { BranchUsersBadge } from 'components';
import React from 'react';

class BranchHeader extends React.Component {
  constructor(props) {
    super(props);
    this.dropdownItemOnClickHandler = this.dropdownItemOnClickHandler.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.findBranchUsersRequest();
  }

  dropdownItemOnClickHandler(event) {
    let branchId = (event.currentTarget && event.currentTarget.id) || this.props.branches && this.props.branches[0].id;
    let currentBranch = this.props.branches && this.props.branches.filter(x => x.id == branchId)[0];

    this.props.setCurrentBranch(currentBranch);
    this.props.afterCurrentBranchWasSet(currentBranch);
  }

  render() {
    return (
      <Container fluid className='cards-header-container'>
        <Row noGutters>
          <Col>
            <Row noGutters>
              <Col md='auto'>
                <i className="material-icons">place</i>
              </Col>
              <Col md='auto' className='cards-header-selected-branch'>
                {this.props.currentBranch && <h2>{this.props.currentBranch.name}</h2>}
              </Col>
              {!this.props.blockCurrentBranchSelection && <Col md='auto'>
                <Dropdown>
                  <Dropdown.Toggle className='branch-toggle' as='div'></Dropdown.Toggle>
                  <Dropdown.Menu className='dropdown-menu'>
                    {this.props.branches && [...this.props.branches].map(branch =>
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)} id={branch.id} key={branch.id}>{branch.name}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              }
            </Row>
            <Row noGutters>
              <Col>
                {this.props.currentBranch && <p>{this.props.currentBranch.address}</p>}
              </Col>
            </Row>
          </Col>
          {
            this.props.currentBranch && this.props.currentBranch.id ?
              this.props.currentBranch.status === branchStatus.enabled
                ? <Col md='auto'>
                  {
                    this.props.currentBranch && this.props.currentBranch.id && this.props.userRoleId !== undefined && this.props.userRoleId != userRole.STANDARD &&
                    <div>
                      <BranchUsersBadge
                        upperCaseTitle={true}
                        branchUserIds={this.props.currentBranch ? this.props.currentBranch.userIds : []}
                        branchId={this.props.currentBranch ? this.props.currentBranch.id : null}
                        branchName={this.props.currentBranch ? this.props.currentBranch.name : null}
                        users={this.props.users}
                        userRoleId={this.props.userRoleId}
                        hideAddButton={this.props.fileUploadInProgress}
                      />
                      {
                        this.props.currentBranch &&
                        this.props.currentBranch.userIds &&
                        <div className="badge-users-subtitle">Manageri activi: {this.props.currentBranch.userIds.length}</div>
                      }
                    </div>
                  }
                </Col>
                : <Col md='auto' className='branch-disabled'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                    <g fill="none">
                      <path d="M0 0h24v24H0z" />
                      <path fill="#6a6a6a" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </g>
                  </svg>
                  <span>Punctul de lucru este inactiv.</span>
                </Col>
              : null
          }
        </Row>
      </Container>
    );
  }
}

export default BranchHeader;
