import {
  BRANCH_USER_HIDE_MODAL_WITH_USERS,
  BRANCH_USER_SHOW_MODAL_WITH_USERS,
  SET_BRANCH_USERS
} from 'actions/branchUser';

export default function branchUser(state, action) {
  state = state || {
    branchUsers: [],
    isModalWithUsersVisible: 0
  }

  switch (action.type) {
    case SET_BRANCH_USERS:
      return {
        ...state,
        branchUsers: action.payload
      }
    case BRANCH_USER_SHOW_MODAL_WITH_USERS:
      return {
        ...state,
        isModalWithUsersVisible: action.payload
      }
    case BRANCH_USER_HIDE_MODAL_WITH_USERS:
      return {
        ...state,
        isModalWithUsersVisible: 0
      }
    default:
      return state;
  }
}
