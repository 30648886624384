export const ITEM_CORRECTION_PAGINATE_ITEMS = 'ITEM_CORRECTION_PAGINATE_ITEMS';

export const ITEM_CORRECTION_SET_ITEMS = 'ITEM_CORRECTION_SET_ITEMS';
export const ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED = 'ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED';
export const ITEM_CORRECTION_SET_CURRENT_PAGE = 'ITEM_CORRECTION_SET_CURRENT_PAGE';
export const ITEM_CORRECTION_SET_PAGE_COUNT = 'ITEM_CORRECTION_SET_PAGE_COUNT';
export const ITEM_CORRECTION_SET_PAGE_SIZE = 'ITEM_CORRECTION_SET_PAGE_SIZE';
export const ITEM_CORRECTION_SET_SEARCH_TERM = 'ITEM_CORRECTION_SET_SEARCH_TERM';
export const ITEM_CORRECTION_SET_TOTAL_COUNT = 'ITEM_CORRECTION_SET_TOTAL_COUNT';
export const ITEM_CORRECTION_SET_ERROR_FILTER = 'ITEM_CORRECTION_SET_ERROR_FILTER';

export const ITEM_CORRECTION_ADD_ITEM_TO_SELECTION = 'ITEM_CORRECTION_ADD_ITEM_TO_SELECTION';
export const ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION = 'ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION';
export const ITEM_CORRECTION_SELECT_ALL = 'ITEM_CORRECTION_SELECT_ALL';
export const ITEM_CORRECTION_CLEAR_SELECTION = 'ITEM_CORRECTION_CLEAR_SELECTION';
export const ITEM_CORRETION_REMOVE_SELECTED_ITEMS = 'ITEM_CORRETION_REMOVE_SELECTED_ITEMS';

export const ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_REQUEST = 'ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_REQUEST';
export const ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE = 'ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE';
export const ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS = 'ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS';

export const ITEM_CORRECTION_CORRECT_ITEM = 'ITEM_CORRECTION_CORRECT_ITEM';

export const ITEM_CORRECTION_SET_FILTERED_ITEMS = 'ITEM_CORRECTION_SET_FILTERED_ITEMS';
export const ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS = 'ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS';

export const ITEM_CORRECTION_SET_IS_CAPTCHA_VALID = 'ITEM_CORRECTION_SET_IS_CAPTCHA_VALID';

export const paginateItemsToBeCorrected = (items, pageNumber = 1, pageSize = 10, lastNameOrCnp = '') => ({
  type: ITEM_CORRECTION_PAGINATE_ITEMS,
  payload: {
    items: items,
    pageNumber: pageNumber,
    pageSize: pageSize,
    lastNameOrCnp: lastNameOrCnp
  }
});
export const setItemsToBeCorrected = (items, uploadTypeId) => ({ type: ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED, payload: { items, uploadTypeId } });
export const setCorrectionScreenItems = items => ({ type: ITEM_CORRECTION_SET_ITEMS, payload: items });
export const correctItem = newItem => ({ type: ITEM_CORRECTION_CORRECT_ITEM, payload: newItem });
export const sendCorrectedItemsRequest = captchaModel => ({ type: ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_REQUEST, payload: captchaModel });
export const sendCorrectedItemsFailure = (items, uploadTypeId) => ({ type: ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE, payload: { items, uploadTypeId } });
export const sendCorrectedItemsSuccess = () => ({ type: ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS });

export const setCorrectionScreenCurrentPage = pageNumber => ({ type: ITEM_CORRECTION_SET_CURRENT_PAGE, payload: pageNumber });
export const setCorrectionScreenPageCount = pageCount => ({ type: ITEM_CORRECTION_SET_PAGE_COUNT, payload: pageCount });
export const setCorrectionScreenPageSize = pageSize => ({ type: ITEM_CORRECTION_SET_PAGE_SIZE, payload: pageSize });
export const setCorrectionScreenSearchTerm = searchTerm => ({ type: ITEM_CORRECTION_SET_SEARCH_TERM, payload: searchTerm });
export const setCorrectionScreenTotalCount = totalCount => ({ type: ITEM_CORRECTION_SET_TOTAL_COUNT, payload: totalCount });
export const setErrorFilter = filter => ({ type: ITEM_CORRECTION_SET_ERROR_FILTER, payload: filter });

export const correctionScreenClearSelection = () => ({ type: ITEM_CORRECTION_CLEAR_SELECTION });
export const correctionScreenSelectAllItems = () => ({ type: ITEM_CORRECTION_SELECT_ALL });
export const correctionScreenAddItemToSelection = itemId => ({ type: ITEM_CORRECTION_ADD_ITEM_TO_SELECTION, payload: itemId });
export const correctionScreenRemoveItemFromSelection = itemId => ({ type: ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION, payload: itemId });
export const correctionScreenRemoveSelectedItems = () => ({ type: ITEM_CORRETION_REMOVE_SELECTED_ITEMS });

export const setFilteredItems = items => ({ type: ITEM_CORRECTION_SET_FILTERED_ITEMS, payload: items });
export const setSendCorrectedItemsInProgress = value => ({ type: ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS, payload: value });

export const setIsCaptchaValid = value => ({ type: ITEM_CORRECTION_SET_IS_CAPTCHA_VALID, payload: value });
