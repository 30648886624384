export const ORDER_FIND_REQUEST = 'ORDER_FIND_REQUEST';
export const ORDER_FIND_FAILURE = 'ORDER_FIND_FAILURE';
export const ORDER_FIND_SUCCESS = 'ORDER_FIND_SUCCESS';

export const ORDER_SET_ORDERS = 'ORDER_SET_ORDERS';
export const SET_ORDERS_LOADED_FLAG = 'SET_ORDERS_LOADED_FLAG';

export const findOrders = (branchId, viewLastCreatedOrder) => ({ type: ORDER_FIND_REQUEST, payload: { branchId, viewLastCreatedOrder } });
export const findOrdersFailure = errorMessage => ({ type: ORDER_FIND_FAILURE, payload: { errorMessage: errorMessage } });
export const findOrdersSuccess = (orders, viewLastCreatedOrder) => ({ type: ORDER_FIND_SUCCESS, payload: { orders, viewLastCreatedOrder } });

export const setOrders = orders => ({ type: ORDER_SET_ORDERS, payload: orders });
export const setOrdersLoadedFlag = loaded => ({ type: SET_ORDERS_LOADED_FLAG, payload: loaded });
