export default {
  home: '/',
  homePage: '/home',
  dashboard: '/dashboard',
  dashboardCards: '/dashboard/carduri',
  dashboardCardsDeleteBulk: '/dashboard/carduri/stergere-bulk',
  dashboardCardsDeleteBulkCorrection: '/dashboard/carduri/stergere-bulk/corectie',
  dashboardCardsModifyTicketValueBulk: '/dashboard/carduri/modifica-valoare-bulk',
  dashboardCardsModifyTicketValueBulkCorrection: '/dashboard/carduri/modifica-valoare-bulk/corectie',
  dashboardBranch: '/dashboard/puncte-de-lucru',
  dashboardBranchImport: '/dashboard/puncte-de-lucru/import',
  dashboardBranchImportCorrection: '/dashboard/puncte-de-lucru/import/corectie',
  dashboardOrdersUpload: '/dashboard/comenzi/incarca',
  dashboardOrdersUploadCorrection: '/dashboard/comenzi/incarca/corectie',
  dashboardOrdersUploadSuccess: '/dashboard/comenzi/incarca/succes',
  dashboardManagers: '/dashboard/manageri',
  accountLogin: '/account/login',
  accountLoginPassword: '/account/login/password',
  accountLoginSmsToken: '/account/login/smstoken',
  accountLoginPasswordSetup: '/account/login/passwordsetup',
  accountForgotPassword: '/account/login/forgotpassword',
  accountForgotPasswordPassword: '/account/login/forgotpassword/smspassword',
  accountForgotPasswordSmsToken: '/account/login/forgotpassword/smstoken',
  accountForgotPasswordPasswordSetup: '/account/login/forgotpassword/newpassword',
  changePassword: '/changePassword',
  changePasswordSmsToken: '/changePasswordSmsToken',
  changePasswordPasswordSetup: '/changePasswordPasswordSetup',
  reports: '/rapoarte',
  notFound: '/not-found',
  dashboardAudit: '/audit',
  dashboardSapInvoices: '/facturiSap'
}
