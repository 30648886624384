import { errorFilter, selectionType } from 'utils/enums';
import {
  ITEM_CORRECTION_ADD_ITEM_TO_SELECTION,
  ITEM_CORRECTION_CLEAR_SELECTION,
  ITEM_CORRECTION_CORRECT_ITEM,
  ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION,
  ITEM_CORRECTION_SELECT_ALL,
  ITEM_CORRECTION_SET_CURRENT_PAGE,
  ITEM_CORRECTION_SET_ERROR_FILTER,
  ITEM_CORRECTION_SET_FILTERED_ITEMS,
  ITEM_CORRECTION_SET_IS_CAPTCHA_VALID,
  ITEM_CORRECTION_SET_ITEMS,
  ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED,
  ITEM_CORRECTION_SET_PAGE_COUNT,
  ITEM_CORRECTION_SET_PAGE_SIZE,
  ITEM_CORRECTION_SET_SEARCH_TERM,
  ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS,
  ITEM_CORRECTION_SET_TOTAL_COUNT,
} from 'actions/itemCorrection';

export default function itemCorrection(state, action) {
  state = state || {
    cards: null,
    filter: errorFilter.all,
    selectState: selectionType.none,
    selectedItems: [],
    unselectedItems: [],
    filteredItems: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    searchTerm: null,
  };

  switch (action.type) {
    case ITEM_CORRECTION_SET_ITEMS: {
      return {
        ...state,
        cards: action.payload
      };
    }
    case ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED: {
      return {
        ...state,
        itemsToBeCorrected: action.payload.items,
        uploadTypeId: action.payload.uploadTypeId
      };
    }
    case ITEM_CORRECTION_ADD_ITEM_TO_SELECTION: {
      let updatedSelectState;

      if (state.selectState !== selectionType.all && state.selectedItems.length + 1 === state.totalCount) {
        updatedSelectState = selectionType.all;
      }
      else if (state.selectState === selectionType.none) {
        updatedSelectState = selectionType.multiple;
      }
      else {
        updatedSelectState = state.selectState;
      }

      return {
        ...state,
        selectedItems: [...state.selectedItems, action.payload],
        unselectedItems: state.selectState === selectionType.all ? state.unselectedItems.filter(itemId => itemId != action.payload) : [],
        selectState: updatedSelectState
      };
    }
    case ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION: {
      let updatedSelectState;

      const isSelectStateDifferentThanMultiple = state.selectState !== selectionType.multiple;
      const selectedItemsCount = state.selectedItems.length;

      if (isSelectStateDifferentThanMultiple && selectedItemsCount > 1) {
        updatedSelectState = selectionType.multiple
      } else if (isSelectStateDifferentThanMultiple && selectedItemsCount <= 1) {
        updatedSelectState = selectionType.none
      } else if (!isSelectStateDifferentThanMultiple && selectedItemsCount > 1) {
        updatedSelectState = state.selectState
      } else {
        updatedSelectState = selectionType.none
      }
      return {
        ...state,
        selectedItems: state.selectedItems.filter(item => item.cardId != action.payload.cardId),
        unselectedItems: state.selectState === selectionType.all ? [...new Set([...state.unselectedItems, action.payload])] : [],
        selectState: updatedSelectState
      };
    }
    case ITEM_CORRECTION_SELECT_ALL: {
      let selectedItems = state.filteredItems,
        searchTerm = state.searchTerm;

      selectedItems = (searchTerm && searchTerm !== '') ?
        selectedItems.filter(item => {
          return item.lastName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
            item.cnp.startsWith(searchTerm.toLowerCase())
        }) : selectedItems;


      return {
        ...state,
        selectedItems: selectedItems.map(item => { return { cardId: item.id } }),
        unselectedItems: [],
        selectState: selectionType.all
      };
    }
    case ITEM_CORRECTION_CLEAR_SELECTION: {
      return {
        ...state,
        selectedItems: [],
        unselectedItems: [],
        selectState: selectionType.none
      };
    }
    case ITEM_CORRECTION_SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      };
    }
    case ITEM_CORRECTION_SET_PAGE_COUNT: {
      return {
        ...state,
        pageCount: action.payload
      };
    }
    case ITEM_CORRECTION_SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload
      };
    }
    case ITEM_CORRECTION_SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload
      };
    }
    case ITEM_CORRECTION_SET_TOTAL_COUNT: {
      return {
        ...state,
        totalCount: action.payload
      };
    }
    case ITEM_CORRECTION_SET_ERROR_FILTER: {
      return {
        ...state,
        filter: action.payload
      }
    }
    case ITEM_CORRECTION_SET_FILTERED_ITEMS: {
      return {
        ...state,
        filteredItems: action.payload
      }
    }
    case ITEM_CORRECTION_CORRECT_ITEM: {
      let items = state.itemsToBeCorrected,
        itemIndex = items.findIndex(item => item.id === action.payload.id)
      items.splice(itemIndex, 1, action.payload);
      return {
        ...state,
        itemsToBeCorrected: [...items]
      }
    }
    case ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS: {
      return {
        ...state,
        sendCorrectedItemsInProgress: action.payload
      }
    }
    case ITEM_CORRECTION_SET_IS_CAPTCHA_VALID: {
      return {
        ...state,
        isCaptchaValid: action.payload
      }
    }
    default: {
      return state;
    }
  }
}
