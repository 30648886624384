/* eslint-disable no-console */
import 'components/layout.scss'
import 'components/CardsUpload/CardsUpload.scss'
import 'components/CardsUploadScreen/CardsUploadScreen.scss'
import { Home, LoginPage, NotFound404, TelemetryProvider, Toast } from 'components';
import { Route, Switch } from 'react-router';
import configureStore from 'utils/store';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { getAppInsights } from 'utils/telemetryService';
import httpClient from 'utils/httpClient';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import routes from 'utils/routes';

const history = createBrowserHistory();
const initialState = {
  card: {
    cards: null,
    selectState: 'none',
    selectedCards: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    filters: {
      firstOrLastName: '',
      cnp: '',
      statusId: null,
      ticketValue: '',
      orderId: null
    },
    correctionScreenShow: false
  },
  branch: {
    branches: [],
    cities: [],
    localities: [],
    users: [],
    isModalVisibile: false,
    isUpsertBranchError: false,
    upsertBranchErrorMessage: ''
  },
  order: {
    orders: [],
    ordersLoaded: true
  },
  branchUser: {
    branchUsers: [],
    isModalWithUsersVisible: 0
  },
  manager: {
    managers: null,
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    searchTerm: null,
    modalServerError: '',
    isModalVisible: false
  },
  sapInvoice: {
    sapInvoices: null,
    currentPage: 1,
    pageCount: 1,
    pageSize: 10
  }
};
const store = configureStore(history, initialState);

document.getElementById('root-index') && ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <TelemetryProvider instrumentationKey={localStorage.getItem('instrumentationKey')} history={history} after={() => { getAppInsights() }}>
        <Switch>
          <Route path={routes.notFound} render={() => <NotFound404 />} />
          <Route path={routes.home} render={() => <Home />} />
        </Switch>
      </TelemetryProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root-index')
);

document.getElementById('root-login') && ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <TelemetryProvider instrumentationKey={localStorage.getItem('instrumentationKey')} history={history} after={() => { getAppInsights() }}>
        <Switch>
          <Route path={routes.notFound} render={() => <NotFound404 />} />
          <Route path={routes.accountLogin} render={() => <LoginPage />} />
        </Switch>
        <Toast />
      </TelemetryProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root-login')
);

(async () => {
  const response = await httpClient.get('/home/getAppSettings');
  const appSettings = await response.json();
  localStorage.setItem('instrumentationKey', appSettings.instrumentationKey);
  localStorage.setItem('maxTicketValue', appSettings.maxTicketValue);
})();

(function (whitelist = []) {
  const consoleFunction = { log: console.error }
  console.error = function () {
    const callingFile = new Error().stack.split('\n')[2];
    if (whitelist.some(entry => callingFile.includes(entry))) {
      consoleFunction.log.apply(console, arguments)
    }
  }
})(['localhost']);
