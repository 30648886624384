import './HomeHeader.scss';
import { Col, Nav, Navbar } from 'react-bootstrap';
import { Header, Userbox } from 'components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';
import routes from 'utils/routes';
import { userRole } from 'utils/enums';

class HomeHeader extends React.Component {

  render() {
    return (
      <div className="home-header">
        <Header className='main-header'>
          <Col md={5}>
            <Navbar expand="lg" variant="light">
              <Nav className="mr-auto tiny-font">
                <Link className={`${this.props.location.pathname && this.props.location.pathname.includes('home') ? 'active' : ''}`}
                  to={routes.homePage}>Acasa</Link>
                <Link className={`${this.props.location.pathname && this.props.location.pathname.includes('dashboard') ? 'active' : ''}`}
                  to={routes.dashboard}>Dashboard</Link>
                {
                  this.props.roleId == userRole.ADMIN &&
                  <Link className={`${this.props.location.pathname && this.props.location.pathname === '/facturiSap' ? 'active' : ''}`}
                    to={routes.dashboardSapInvoices}>Facturi</Link>
                }
                {this.props.roleId === userRole.ADMIN &&
                  <Link className={`${this.props.location.pathname && this.props.location.pathname.includes('audit') ? 'active' : ''}`}
                    to={routes.dashboardAudit}>Audit</Link>}
              </Nav>
            </Navbar>
          </Col>
          <Col md={2} className="center">
            <img src="/images/logo.png" className="app-logo" />
          </Col>
          <Col md={5}>
            <Userbox />
          </Col>
        </Header>
      </div>
    );
  }
}

export default connect(
  state => { return { ...state.user, ...state.router } }
)(HomeHeader);
