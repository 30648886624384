import './CustomTable.scss';
import { cardStatus, selectionType } from 'utils/enums';
import { Col, Dropdown, DropdownButton, Row, Table } from 'react-bootstrap';
import { Checkbox } from 'components';
import { connect } from 'react-redux';
import React from 'react';

class CustomTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.content || [],
      selectedItems: this.props.selectedItems || [],
      unselectedItems: this.props.unselectedItems || [],
      selectState: this.props.selectState || 'none',
      totalCount: this.props.totalCount
    }

    this.selectAllItemsOnChangeHandler = this.selectAllItemsOnChangeHandler.bind(this);
    this.selectItemOnChangeHandler = this.selectItemOnChangeHandler.bind(this);
    this.updateField = this.updateField.bind(this);
    this.dropdownItemOnClickHandler = this.dropdownItemOnClickHandler.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.REEMITE_CARD_TITLE = 'Reemite card';
    this.REEMITE_PIN_TITLE = 'Reemite PIN';
  }

  selectAllItemsOnChangeHandler(event) {
    event.currentTarget.checked ? this.props.callbackSelectAllItems() : this.props.callbackSelectNone();
  }

  selectItemOnChangeHandler(item, event) {
    event.currentTarget.checked ? this.props.callbackSelectItem(item) : this.props.callbackDeselectItem(item);
  }

  handlePaste(e) {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData,
      pastedData = clipboardData.getData('Text');

    e.target.innerText = pastedData;
  }

  componentDidMount() {
    this.setState({ content: this.props.content });

    const editableElements = document.getElementsByClassName('value');
    for (var i = 0; i < editableElements.length; i++) {
      editableElements[i].addEventListener('paste', this.handlePaste);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      content: nextProps.content,
      selectedItems: nextProps.selectedItems,
      unselectedItems: nextProps.unselectedItems,
      selectState: nextProps.selectState,
      totalCount: nextProps.totalCount
    });
  }

  updateField(event, item, field) {
    let value = event.target.textContent;

    item[field] = value;
    item.errors = item.errors.filter(error => error.fieldName !== field);
    this.props.correctItem(item);
  }

  dropdownItemOnClickHandler(event, action, itemId) {
    let value = (event.currentTarget && event.currentTarget.text);
    if (value === action.title)
      action.clickHandler(itemId);
  }

  render() {
    const itemActionsButton = <svg width="24" height="24" viewBox="0 0 24 24">
      <path id="a" d="M12 9c.81 0 1.512.298 2.107.893.595.595.893 1.297.893 2.107 0 .81-.298 1.512-.893 2.107-.595.595-1.297.893-2.107.893-.81 0-1.512-.298-2.107-.893C9.298 13.512 9 12.81 9 12c0-.81.298-1.512.893-2.107C10.488 9.298 11.19 9 12 9zm8 0c.81 0 1.512.298 2.107.893.595.595.893 1.297.893 2.107 0 .81-.298 1.512-.893 2.107-.595.595-1.297.893-2.107.893-.81 0-1.512-.298-2.107-.893C17.298 13.512 17 12.81 17 12c0-.81.298-1.512.893-2.107C18.488 9.298 19.19 9 20 9zM4 9c.81 0 1.512.298 2.107.893C6.702 10.488 7 11.19 7 12c0 .81-.298 1.512-.893 2.107C5.512 14.702 4.81 15 4 15c-.81 0-1.512-.298-2.107-.893C1.298 13.512 1 12.81 1 12c0-.81.298-1.512.893-2.107C2.488 9.298 3.19 9 4 9z" />
    </svg>;

    return (
      <div className='grid'>
        <Table hover className='dashboard-table'>
          <thead>
            <tr>
              {
                this.props.selectable &&
                <td className='checkbox-column' key='_checkbox'><Checkbox checked={(this.props.selectState === selectionType.all &&
                  this.props.unselectedItems && this.props.unselectedItems.length === 0) ||
                  (this.props.selectedItems.length === this.props.totalCount && this.props.totalCount > 0)}
                  onChange={(event) => { this.selectAllItemsOnChangeHandler(event) }} />
                </td>
              }
              {
                this.props.tableHeaderColumns.map(headerColumn =>
                  <td key={`_${headerColumn}`} className={this.props.editable ? 'editable-grid-column' : ''}>{headerColumn}</td>
                )
              }
            </tr>
          </thead>
          <tbody>
            {this.state.content && this.state.content.map(item => {
              return <tr key={item[this.props.idColumn]} className='grid-row'>
                {
                  this.props.selectable &&
                  <td className='checkbox-column' key={item[this.props.idColumn] + '_checkbox'}>
                    <Checkbox checked={(this.props.selectState === selectionType.all &&
                      this.props.unselectedItems && this.props.unselectedItems.findIndex(unselectedItem => unselectedItem.cardId === item[this.props.idColumn]) === -1) ||
                      (this.props.selectedItems &&
                        this.props.selectedItems.findIndex(selectedItem => selectedItem.cardId === item[this.props.idColumn]) > -1)}
                      onChange={(event) => {
                        this.selectItemOnChangeHandler({
                          cardId: item[this.props.idColumn],
                          statusId: item.statusId,
                          cnp: item.cnp,
                          ticketValue: item.ticketValue,
                          orderId: item.orderId,
                          fullName: item.lastName + ' ' + item.firstName
                        }, event)
                      }} />
                  </td>
                }

                {this.props.fields.map((field, index) => {
                  return this.props.editable ?
                    <td key={`${item[this.props.idColumn]}_${field}`} onClick={() => document.getElementById(`${item[this.props.idColumn]}_${field}`).focus()}
                      className={
                        this.props.editable 
                          ? 'editable-grid-column ' + (this.props.columnsCustomWidth ? '' : (this.props.longColumns.indexOf(field) > -1 ? 'long-text' : 'short-text'))
                          : ''
                      }
                      style={this.props.columnsCustomWidth ? {width: this.props.columnsCustomWidth[index]} : {}}
                    >
                      {item.errors && item.errors.findIndex(error => { return error.fieldName === field }) !== -1 &&
                        <span className={`tooltip-text ${item.errors.find(error => { return error.fieldName === field }).errorMessage.length > 30 ? 'long' : ''}`}>
                          {item.errors.find(error => { return error.fieldName === field }).errorMessage}
                        </span>}
                      {item.errors && item.errors.findIndex(error => { return error.fieldName === field }) !== -1 && <div className='warning-sign'>
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path id="a" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm-1.65-5.52c0 .464.16.858.481 1.183.321.325.71.487 1.169.487.458 0 .848-.162 1.169-.487a1.62 1.62 0 0 0 .481-1.183c0-.464-.16-.851-.481-1.163A1.616 1.616 0 0 0 12 15.85c-.458 0-.848.156-1.169.467-.32.312-.481.7-.481 1.163zm3.19-10.54a1.54 1.54 0 0 0-3.08 0v5.72a1.54 1.54 0 0 0 3.08 0V6.94z" />
                        </svg>
                      </div>}
                      <Row noGutters className='justify-content-md-left editable-input'>
                        <Col id={`${item[this.props.idColumn]}_${field}`} className='value word-wrap' contentEditable={Boolean(this.props.editable)} suppressContentEditableWarning='true'
                          onBlur={(event) => this.updateField(event, item, field)}>
                          {item[field]}
                        </Col>
                        <Col md='auto' className='pencil-sign'>
                          <svg width="20" height="20" viewBox="0 0 24 24">
                            <path d="M15.777 3.035L3.553 15.28a.9.9 0 0 0-.24.423l-1.286 5.163a.91.91 0 0 0 1.104 1.107l5.155-1.29a.91.91 0 0 0 .422-.24L20.93 8.2A3.63 3.63 0 0 0 22 5.618c0-.977-.379-1.893-1.069-2.583-1.376-1.38-3.776-1.38-5.154 0zM7.599 18.972l-3.437.86.859-3.442L15.133 6.26l2.577 2.581L7.599 18.972zM19.643 6.907L19 7.55l-2.577-2.58.643-.645c.689-.69 1.89-.69 2.577 0 .343.344.534.802.534 1.29 0 .489-.19.945-.534 1.29z" />
                          </svg>
                        </Col>
                      </Row>
                    </td> :
                    <td key={`${item[this.props.idColumn]}_${field}`}>{item[field]}</td>
                })}

                <td className='sm dropdown-button-column' key={item[this.props.idColumn] + '_dropdown_btn'}>
                  {
                    this.props.currentBranchId && this.props.hasItemActions && this.props.itemActions &&
                    item.status !== cardStatus.properties[cardStatus.inProcess] &&
                    item.status !== cardStatus.properties[cardStatus.reissued] &&
                    <DropdownButton disabled={item.status === cardStatus.properties[cardStatus.deleted]} alignRight size='sm' variant='secondary' drop='left' title={itemActionsButton} className='item-actions-dropdown' >
                      {
                        this.props.itemActions.map(action => {
                          const isCardStatusEmitted = item.status === cardStatus.properties[cardStatus.emitted],
                            isCardStatusConfirmed = item.status === cardStatus.properties[cardStatus.confirmed],
                            isCardStatusMigrated = item.status === cardStatus.properties[cardStatus.migrated],
                            isCardStatusExisting = item.status === cardStatus.properties[cardStatus.existing]

                          const isCardStatusValid = isCardStatusConfirmed || isCardStatusMigrated || isCardStatusExisting;
                          const isCardEmittedAndDifferentThanReissueOperations = isCardStatusEmitted && action.title !== this.REEMITE_CARD_TITLE && action.title !== this.REEMITE_PIN_TITLE;

                          if (isCardEmittedAndDifferentThanReissueOperations || isCardStatusValid)
                            return <Dropdown.Item
                              key={`${this.props.idColumn}_${action.title}`}
                              onClick={(event) => this.dropdownItemOnClickHandler(event, action, item[this.props.idColumn])}>{action.title}
                            </Dropdown.Item>
                        })
                      }
                    </DropdownButton>
                  }
                </td>

              </tr>
            })
            }
          </tbody>
        </Table>
        {this.props.content && this.props.content.length === 0 &&
          <div className='grid-no-result'>
            <Row className='justify-content-md-center' noGutters>
              <Col sm="auto">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                  <g fill="none">
                    <path d="M0 0h24v24H0z" />
                    <path fill="#414751" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </g>
                </svg>
              </Col>
            </Row>
            <Row noGutters><span>Nu am gasit nici un rezultat.</span></Row>
          </div>
        }
      </div >
    );
  }
}

export default connect()(CustomTable);
