import {
  correctionScreenAddItemToSelection,
  correctionScreenClearSelection,
  correctionScreenRemoveItemFromSelection,
  correctionScreenRemoveSelectedItems,
  correctionScreenSelectAllItems,
  correctItem,
  sendCorrectedItemsRequest,
  setCorrectionScreenCurrentPage,
  setCorrectionScreenPageSize,
  setCorrectionScreenSearchTerm,
  setErrorFilter,
  setIsCaptchaValid,
  setSendCorrectedItemsInProgress
} from 'actions/itemCorrection';

import { toggleModalVisibility, toggleSubmitButton } from 'actions/customModal';

import { bindActionCreators } from 'redux';
import { blockCurrentBranchSelection } from 'actions/branch';
import { connect } from 'react-redux';
import ItemCorrectionScreen from './ItemCorrectionScreen';
import { push } from 'connected-react-router';

const mapStateToProps = state => { return { ...state.itemCorrection, ...state.branch, isCaptchaModalShowable: state.customModal.isModalShowable, isCaptchaValid: state.itemCorrection.isCaptchaValid } };
const mapDispatchToProps = dispatch => bindActionCreators({
  blockCurrentBranchSelection,
  correctionScreenAddItemToSelection,
  correctionScreenClearSelection,
  correctItem,
  correctionScreenRemoveItemFromSelection,
  correctionScreenRemoveSelectedItems,
  correctionScreenSelectAllItems,
  push,
  sendCorrectedItemsRequest,
  setErrorFilter,
  setCorrectionScreenCurrentPage,
  setCorrectionScreenSearchTerm,
  setCorrectionScreenPageSize,
  setSendCorrectedItemsInProgress,
  toggleModalVisibility,
  setIsCaptchaValid,
  toggleSubmitButton
},
  dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCorrectionScreen);
