import { Captcha, captchaSettings } from 'reactjs-captcha';
import { Col, Row } from 'react-bootstrap';
import { generateSmsTokenRequest, setLoginError, submitSmsTokenRequest } from 'actions/user';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import errorCodes from 'utils/errorCodes';
import { push } from 'connected-react-router';
import React from 'react';
import { Redirect } from 'react-router';
import routes from 'utils/routes';

const mapStateToProps = state => ({ ...state.user, ...state.router });

class SmsTokenView extends React.Component {
  constructor(props) {
    super(props);

    this.submitTokenButton = React.createRef();
    this.resendTokenButton = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.submitToken = this.submitToken.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.shouldRedirect = this.shouldRedirect.bind(this);
    this.usernameClick = this.usernameClick.bind(this);
    this.tick = this.tick.bind(this);
    this.generateToken = this.generateToken.bind(this);
    this.setInitialCode = this.setInitialCode.bind(this);

    this.state = {
      smsToken: this.props.smsToken || '',
      remainingSeconds: '',
      isMounted: false,
      timeExpired: false
    };

    captchaSettings.set({
      captchaEndpoint: 'simple-captcha-endpoint.ashx'
    });
  }

  tick() {
    var countdown = this.state.remainingSeconds;
    if (countdown > 1) {
      countdown = countdown - 1;
    } else {
      var timer = this.state.timerCountDown;
      if (timer) {
        this.setState({ timeExpired: true });
        clearInterval(timer);
      }
    }

    this.setState({ remainingSeconds: countdown });
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.smsToken == this.state.smsToken &&
      prevState.remainingSeconds == this.state.remainingSeconds &&
      prevProps && prevProps.otpCaptcha) {
      this.captcha && this.captcha.reloadImage();
      setTimeout(function () {
        var captchaInput = document.getElementById('btTichetCaptchaInputCode');
        if (captchaInput) {
          captchaInput.disabled = false;
        }
      }, 500);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.remainingSeconds !== nextProps.remainingSeconds) {
      this.setState({ remainingSeconds: nextProps.remainingSeconds, timeExpired: false });
      this.setInitialCode(nextProps.smsToken);
      var timerCountDown = setInterval(() => this.tick(), 1000);
      this.setState({ timerCountDown });
    }
  }

  generateToken() {
    this.props.setLoginError('');
    this.resendTokenButton.current.classList.add('loading', 'disabled');
    this.props.generateSmsTokenRequest().then(() => {
      if (this.state.isMounted) {
        this.setState({ remainingSeconds: this.props.remainingSeconds, timeExpired: false });
        this.setInitialCode(this.props.smsToken);
        var timerCountDown = setInterval(() => this.tick(), 1000);
        this.setState({ timerCountDown });
        this.resendTokenButton.current &&
          this.resendTokenButton.current.classList.remove('loading', 'disabled');
      }
    });
  }

  componentWillUnmount() {
    console.log('will dispatch', this.state.isMounted) // eslint-disable-line no-console
    this.state.isMounted = false;
    this.props.saveUserInformation({ remainingSeconds: null });

    if (this.state.timerCountDown) {
      this.setState({ timeExpired: true });
      clearInterval(this.state.timerCountDown);
    }
  }

  setInitialCode(code) {
    const inputField = document.getElementById('smsToken');
    inputField.value = code;
  }

  handleChange(event) {
    this.setState({ smsToken: event.target.value });

    if (this.props.loginError) {
      this.props.setLoginError('');
    }
  }

  submitToken() {
    const smsToken = document.getElementById('smsToken').value,
      captchaCode = this.captcha && this.captcha.getUserEnteredCaptchaCode(),
      captchaId = this.captcha && this.captcha.getCaptchaId();

    if (smsToken) {
      this.submitTokenButton.current.classList.add('loading', 'disabled');
      this.props.submitSmsTokenRequest({
        smsToken: smsToken,
        captchaCode: captchaCode,
        captchaId: captchaId
      });
    } else {
      this.props.setLoginError(errorCodes.fieldRequired)
    }
  }

  handleKeyUp(event) {
    const code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      if (this.state.timeExpired) {
        this.generateToken();
      } else {
        this.submitToken();
      }
    }
  }

  shouldRedirect() {
    return !this.props.username || !this.props.password;
  }

  usernameClick() {
    if (this.props.isForgotPassword) {
      this.props.saveUserInformation({ isForgotPassword: false });
    }
    else {
      if (this.props.isChangePassword) return;
    }
    this.props.push(routes.accountLogin);
  }

  render() {
    if (this.shouldRedirect()) {
      return <Redirect to={routes.accountLogin} />;
    }

    return (
      <div className="login-form">
        <div className="sms-token-view">
          {
            this.props.isForgotPassword &&
            <h1>Resetare parola</h1>
          }
          {
            this.props.isChangePassword &&
            <h1>Schimbare parola</h1>
          }
          {
            !this.props.isForgotPassword && !this.props.isChangePassword &&
            <h1>Bine ai venit!</h1>
          }
          <Row noGutters className="justify-content-sm-center">
            <div className={this.props.isChangePassword ? 'username-display readonly' : 'username-display'} onClick={() => this.usernameClick()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path fill="#181818" d="M18.2 7.449c0 2.732-1.511 5.068-3.924 6.008 1.433.208 3.102.858 5.008 1.948A5.378 5.378 0 0 1 22 20.073l-.001.926c0 1.105-.903 2-2.016 2H4.017A2.008 2.008 0 0 1 2.001 21L2 20.073a5.378 5.378 0 0 1 2.716-4.668c1.906-1.09 3.575-1.74 5.008-1.948C7.311 12.517 5.8 10.182 5.8 7.45c0-3.533 2.662-6.402 6.2-6.448 3.538.045 6.2 2.915 6.2 6.448zM12 15.5c4.724 0 7.356 1.439 7.896 4.316A1 1 0 0 1 18.913 21H5.087a1 1 0 0 1-.983-1.184C4.644 16.939 7.276 15.5 12 15.5zm.05-3.5c2.485 0 4.25-2.015 4.25-4.5S14.535 3 12.05 3C9.565 3 7.8 5.015 7.8 7.5S9.565 12 12.05 12z" />
                </g>
              </svg>
              {this.props.username}
            </div>
          </Row>
          <p className="info">
            Ti-am trimis un SMS la numarul terminat in {this.props.maskedPhoneNumber}.
            Te rugam sa-l introduci mai jos.
          </p>
          <div className="form-group-label">
            <input
              autoComplete='off'
              id="smsToken"
              className="form-control"
              type="text"
              placeholder="Cod SMS"
              autoFocus
              onKeyUp={this.handleKeyUp}
              onChange={this.handleChange} />
            <label className="form-label" htmlFor="smsToken">Cod SMS</label>
          </div>

          <p className={
            this.props.loginError === errorCodes.fieldRequired
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Acest camp este obligatoriu.</p>
          <p className={
            this.props.loginError === errorCodes.invalidOtp
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Codul introdus este incorect.</p>
          <p className={
            this.props.loginError === errorCodes.otpExpired
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Codul introdus a expirat. Genereaza un nou cod.</p>
          <p className={
            this.props.loginError === errorCodes.otpNotGenerated || this.props.loginError === errorCodes.serverError
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>{errorCodes.genericErrorMessage}</p>
          <p className={
            this.props.loginError && this.props.loginError.includes(errorCodes.invalidCaptcha)
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Codul captcha introdus este incorect.</p>
          <p className={
            this.props.otpCaptcha
              ? 'input-validation-error visible'
              : 'input-validation-error'
          }>Introduceti codul din imagine.</p>
          {
            this.props.otpCaptcha &&
            <Row noGutters className="captcha">
              <Captcha
                captchaStyleName="btTichetCaptcha"
                ref={(captcha) => { this.captcha = captcha }} />
              <input
                id="btTichetCaptchaInputCode"
                className="form-control"
                type="text"
                tab-index="10"
                onKeyUp={this.handleKeyUp}
                placeholder="Cod Captcha" />
            </Row>
          }

          <Row noGutters className="button-container">
            <Col className="forgot-password-link">
              {
                this.state.remainingSeconds != '' && !this.state.timeExpired &&
                <p className="otp-message">Codul expira in {this.state.remainingSeconds} secunde.</p>
              }

              {
                this.state.timeExpired &&
                <p className="otp-message">Codul SMS a expirat</p>
              }
            </Col>
            <Col sm="auto" className="main-button-container">
              {
                this.state.timeExpired &&
                <button className='main-button' onClick={this.generateToken} ref={this.resendTokenButton}>
                  <span className="spinner-border spinner-border-sm"></span>
                  RETRIMITE SMS
                </button>
              }
              {
                !this.state.timeExpired &&
                <button className='main-button' onClick={this.submitToken} ref={this.submitTokenButton} id="submitTokenButton">
                  <span className="spinner-border spinner-border-sm"></span>
                  INAINTE
                </button>
              }
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  submitSmsTokenRequest, setLoginError, generateSmsTokenRequest, push
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmsTokenView);
