import {
    TOGGLE_MODAL_VISIBILITY,
    TOGGLE_SUBMIT_BUTTON
} from 'actions/customModal';

export default function customModal(state, action) {
    state = state || {
        isSubmitButtonDisabled: false,
        isModalShowable: false
    };

    switch (action.type) {
        case TOGGLE_SUBMIT_BUTTON:
            return {
                ...state,
                isSubmitButtonEnabled: action.payload
            }
        case TOGGLE_MODAL_VISIBILITY:
            return {
                ...state,
                isModalShowable: action.payload
            }
        default: return state
    }
}
