import { setUploadError, setValidationStatus, UPLOAD_CSV_FILE_FAILURE, UPLOAD_CSV_FILE_SUCCESS } from 'actions/upload';
import { blockCurrentBranchSelection } from 'actions/branch';
import { setItemsToBeCorrected } from 'actions/itemCorrection';

function upload(store) {
  return next => action => {
    switch (action.type) {
      case UPLOAD_CSV_FILE_SUCCESS: {
        const { fileUploadResults, uploadTypeId } = action.payload,
          itemsToBeCorrected = fileUploadResults.map((cardEntity, index) => { return { ...cardEntity, id: index }; });

        store.dispatch(setItemsToBeCorrected(itemsToBeCorrected, uploadTypeId));
        next(setUploadError(''));
        next(setValidationStatus(false));
        break;
      }
      case UPLOAD_CSV_FILE_FAILURE: {
        next(setUploadError(action.payload.errorMessage));
        store.dispatch(blockCurrentBranchSelection(false));
        break;
      }
    }

    const returnValue = next(action)

    return returnValue
  }
}

export default upload;
