import './AuditDashboard.scss';
import { Col,Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import { actionType } from 'utils/actionType';
import PageSelector from 'components/PageSelector';
import React from 'react';
import SearchInput from 'components/SearchInput';

let timeout = {};

class AuditDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.filterByUsername = this.filterByUsername.bind(this);
    this.renderAudit = this.renderAudit.bind(this);
    this.clearUsername = this.clearUsername.bind(this);
    this.filterByActionType = this.filterByActionType.bind(this);
    this.filterByStartDate = this.filterByStartDate.bind(this);
    this.filterByEndDate = this.filterByEndDate.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
  }

  filterByUsername(event) {
    const username = event.target.value;

    this.props.setAuditFilter({ username: username, pageNumber: 1 });
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.props.loadAudit({
        actionType: this.props.auditFilter.actionType,
        startDate: this.props.auditFilter.startDate,
        endDate: this.props.auditFilter.endDate,
        username: username,
        pageNumber: 1,
        pageSize: this.props.auditFilter.pageSize
      });
    }, 250);
  }

  clearUsername() {
    this.props.setAuditFilter({ username: '', pageNumber: 1 });
    this.props.loadAudit({
      actionType: this.props.auditFilter.actionType,
      startDate: this.props.auditFilter.startDate,
      endDate: this.props.auditFilter.endDate,
      username: '',
      pageNumber: 1,
      pageSize: this.props.auditFilter.pageSize
    });
  }

  filterByActionType(id) {
    this.props.setAuditFilter({ actionType: id, pageNumber: 1 });
    this.props.loadAudit({
      actionType: id,
      startDate: this.props.auditFilter.startDate,
      endDate: this.props.auditFilter.endDate,
      username: this.props.auditFilter.username,
      pageNumber: 1,
      pageSize: this.props.auditFilter.pageSize
    });
  }

  filterByStartDate(event) {
    const startDate = event.target.value;

    this.props.setAuditFilter({ startDate: startDate, pageNumber: 1 });
    this.props.loadAudit({
      actionType: this.props.auditFilter.actionType,
      startDate: startDate,
      endDate: this.props.auditFilter.endDate,
      username: this.props.auditFilter.username,
      pageNumber: 1,
      pageSize: this.props.auditFilter.pageSize
    });
  }

  filterByEndDate(event) {
    const endDate = event.target.value;

    this.props.setAuditFilter({ endDate: endDate, pageNumber: 1 });
    this.props.loadAudit({
      actionType: this.props.auditFilter.actionType,
      startDate: this.props.auditFilter.startDate,
      endDate: endDate,
      username: this.props.auditFilter.username,
      pageNumber: 1,
      pageSize: this.props.auditFilter.pageSize
    });
  }

  changePage(pageNr) {
    this.props.setAuditFilter({ pageNumber: pageNr });
    this.props.loadAudit({
      actionType: this.props.auditFilter.actionType,
      startDate: this.props.auditFilter.startDate,
      endDate: this.props.auditFilter.endDate,
      username: this.props.auditFilter.username,
      pageNumber: pageNr,
      pageSize: this.props.auditFilter.pageSize
    });
  }

  changePageSize(sizeNr) {
    if (this.props.auditFilter.pageSize !== sizeNr) {
      this.props.setAuditFilter({
        pageNumber: 1,
        pageSize: sizeNr,
        isAuditLoadingIconVisible: false
      });
      this.props.loadAudit({
        actionType: this.props.auditFilter.actionType,
        startDate: this.props.auditFilter.startDate,
        endDate: this.props.auditFilter.endDate,
        username: this.props.auditFilter.username,
        pageNumber: 1,
        pageSize: sizeNr
      });
    }
  }

  renderAudit() {
    return (
      !this.props.isAuditLoadingIconVisible ?
        this.props.auditEntries.map(entry =>
          <div className="audit-entry mb-3" key={entry.id}>
            <div className="row no-gutters audit-entry-header"
              data-toggle={entry.details ? 'collapse' : null}
              data-target={`#auditEntryDetails-${entry.id}`}
              aria-expanded="false"
              aria-controls={`auditEntryDetails-${entry.id}`}
            >
              <div className="col-auto mb-0 pl-3 pr-3"><span className="d-inline-block"><b>{entry.time}</b></span></div>
              <div className="col-auto mb-0 pl-3 pr-3"><span className="d-inline-block">Companie: <b>{entry.employerName}</b></span></div>
              <div className="col-auto mb-0 pl-3 pr-3"><span className="d-inline-block">Utilizator: <b>{entry.username}</b></span></div>
              <div className="col-auto mb-0 pl-3 pr-3"><span className="d-inline-block">Actiune: <b>{entry.actionName}</b></span></div>
            </div>
            <div id={`auditEntryDetails-${entry.id}`} className="collapse">
              <div className="p-3">
                <div className="row no-gutters">
                  <div className="col">Detalii: {entry.details}</div>
                </div>
                {
                  entry.jsonData &&
                  <div className="mt-2">
                    <div className="row">
                      <div className="col-6">
                        <p className="mb-1">Valoare veche</p>
                        <pre className="old">{JSON.stringify(entry.jsonData.oldValue)}</pre>
                      </div>
                      <div className="col-6">
                        <p className="mb-1">Valoare noua</p>
                        <pre className="new">{JSON.stringify(entry.jsonData.newValue)}</pre>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        )
        : <Row noGutters className='cards-loading-spinner'>
          <Col className='center'>
            <Spinner animation="border" variant="secondary" />
          </Col>
        </Row>
    )
  }

  renderAuditResults() {
    if (this.props.auditEntries.length > 0)
      return (
        <div>
          <div className="row no-gutters mb-4 justify-content-between align-items-center">
            <div className="col-auto">
              <strong>Actiuni desfasurate</strong>
            </div>
          </div>

          {this.renderAudit()}

          <div className="row no-gutters align-items-center mt-4">
            <div className="col-3 small-font">
              {this.props.auditEntries.length} din {this.props.auditTotalCount} actiuni afisate
          </div>
            <div className="col-6">
              <PageSelector
                pageCount={Math.ceil(this.props.auditTotalCount / this.props.auditFilter.pageSize)}
                pageSelectionCallback={this.changePage}
                currentPage={this.props.auditFilter.pageNumber}
              />
            </div>
            <div className="col-3 text-right">
              <DropdownButton alignRight size='sm' variant='secondary' drop='up' title={`${this.props.auditFilter.pageSize} pe pagina`} className='page-size-dropdown' >
                <Dropdown.Item onClick={() => this.changePageSize(10)}>10</Dropdown.Item>
                <Dropdown.Item onClick={() => this.changePageSize(25)}>25</Dropdown.Item>
                <Dropdown.Item onClick={() => this.changePageSize(50)}>50</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      )

    return (
      <div className="no-content text-center">
        <svg width="48" height="48" viewBox="0 0 24 24">
          <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
        </svg>
        <p>Nu am gasit nici un rezultat.</p>
      </div>);
  }

  render() {
    if (!this.props.auditEntries) {
      return null;
    }

    return (
      <div id="audit" className="mt-4 ml-4 mr-4">
        <div className="row no-gutters mb-2">
          <div className="col-auto"><strong>Filtre</strong></div>
        </div>
        <div className="row no-gutters mb-5 align-items-center">
          <div className="col-auto date-filter">
            De la
            <input
              id="date-filter-audit"
              type="date"
              className="start-date-filter-audit ml-2 mr-2"
              value={this.props.auditFilter.startDate}
              onChange={this.filterByStartDate} />
            pana la
            <input
              id="date-filter-audit"
              type="date"
              className="end-date-filter-audit ml-2"
              value={this.props.auditFilter.endDate}
              onChange={this.filterByEndDate} />
          </div>
          <div id="search-user" className="col-auto ml-3 search-user">
            <SearchInput
              placeholder="Utilizator"
              value={this.props.auditFilter.username}
              onChange={this.filterByUsername}
              onClear={this.clearUsername}
            />
          </div>
          <div className="col-auto ml-3 action-filter">
            <DropdownButton id="dropdown-basic-button" className="audit-dropdown" title={`Tip actiune - ${actionType.getValue(this.props.auditFilter.actionType)}`} alignRight>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.all)}>{actionType.getValue(actionType.all)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.login)}>{actionType.getValue(actionType.login)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.forgotPassword)}>{actionType.getValue(actionType.forgotPassword)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.changedPassword)}>{actionType.getValue(actionType.changedPassword)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.userCreate)}>{actionType.getValue(actionType.userCreate)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.userUpdate)}>{actionType.getValue(actionType.userUpdate)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.userDelete)}>{actionType.getValue(actionType.userDelete)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.userAssignToBranch)}>{actionType.getValue(actionType.userAssignToBranch)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.userRemoveFromBranch)}>{actionType.getValue(actionType.userRemoveFromBranch)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.branchCreate)}>{actionType.getValue(actionType.branchCreate)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.branchUpdate)}>{actionType.getValue(actionType.branchUpdate)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.branchDeactivation)}>{actionType.getValue(actionType.branchDeactivation)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.cardsOrder)}>{actionType.getValue(actionType.cardsOrder)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.cardsDelete)}>{actionType.getValue(actionType.cardsDelete)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.deliveryConfirmation)}>{actionType.getValue(actionType.deliveryConfirmation)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.cardReissue)}>{actionType.getValue(actionType.cardReissue)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.pinReissue)}>{actionType.getValue(actionType.pinReissue)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.changeTicketValue)}>{actionType.getValue(actionType.changeTicketValue)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.cardsMigration)}>{actionType.getValue(actionType.cardsMigration)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.branchEnable)}>{actionType.getValue(actionType.branchEnable)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.invoiceCancel)}>{actionType.getValue(actionType.invoiceCancel)}</Dropdown.Item>
              <Dropdown.Item onClick={() => this.filterByActionType(actionType.invoiceRectify)}>{actionType.getValue(actionType.invoiceRectify)}</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        {this.renderAuditResults()}
      </div>
    );
  }
}

export default AuditDashboard;
