import './Userbox.scss';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { getUserDataRequest, saveUserInformation } from 'actions/user';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'actions/user';
import React from 'react';
import { replace } from 'connected-react-router';
import routes from 'utils/routes';


class Userbox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordVisible: false
    };

    this.changePasswordClick = this.changePasswordClick.bind(this);
  }

  getInitials() {
    if (this.props.firstName && this.props.lastName) {
      return this.props.firstName.charAt(0) + this.props.lastName.charAt(0)
    }

    return '';
  }

  changePasswordClick() {
    this.props.getUserDataRequest().then(() => this.props.saveUserInformation({ isChangePassword: true}));
    this.props.replace(routes.changePassword, { stopRedirecting: true });
  }

  render() {
    return (<div className='user-box'>
      <Row noGutters className="justify-content-end">
        <Col md="auto" className="user-initials">
          <span data-letters={this.getInitials()} />
        </Col>
        <Col md="auto">
          <Row>
            <Col><span className='small-font username'>{this.props.firstName} {this.props.lastName}</span></Col>
          </Row>
          <Row>
            <Col md="auto"><span className='company-name small-font'>{this.props.employerName}</span></Col>
          </Row>
        </Col>
        <Col md="auto">
          <Dropdown>
            <Dropdown.Toggle as='div'></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.changePasswordClick}>Schimba parola</Dropdown.Item>
              <Dropdown.Item onClick={this.props.logout}>Deconecteaza-te</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </div>);
  }
}

export default connect(
  state => state.user,
  dispatch => bindActionCreators({ logout, getUserDataRequest, saveUserInformation, replace }, dispatch)
)(Userbox);
