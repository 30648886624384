import './ChangeTicketValueModal.scss';
import { changeTicketValue, hideChangeTicketValueModal } from 'actions/card';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import { showLoadingSpinner } from 'actions/loadingSpinner';

const ERROR_TICKET_VALUE_MANDATORY_FIELD = 'Campul este obligatoriu';
const ERROR_TICKET_VALUE_NUMBER_REQUIRED = `Valoarea tichetului de masa poate fi intre 1 si ${localStorage.getItem('maxTicketValue')} RON`;
const ERROR_TICKET_VALUE_EXCEEDED_THRESHOLD = `Valoarea tichetului de masa poate fi intre 1 si ${localStorage.getItem('maxTicketValue')} RON`;

class ChangeTicketValueModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTicketValueError: false,
      ticketValue: '',
      ticketValueError: '',
      selectionCount: ''
    }

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isModalValid = this.isModalValid.bind(this);
    this.validateTicketValue = this.validateTicketValue.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectionCount: this.props.selectedCardsCount == 1 ? '1 selectie' : `${this.props.selectedCardsCount} selectii`
    });
  }

  validateTicketValue() {
    if (this.state.ticketValue === '') {
      this.setState({ isTicketValueError: true, ticketValueError: ERROR_TICKET_VALUE_MANDATORY_FIELD });
    } else if (isNaN(this.state.ticketValue)) {
      this.setState({ isTicketValueError: true, ticketValueError: ERROR_TICKET_VALUE_NUMBER_REQUIRED });
    } else if (this.state.ticketValue.charAt(0) == '0') {
      this.setState({ isTicketValueError: true, ticketValueError: ERROR_TICKET_VALUE_NUMBER_REQUIRED });
    } else if (this.state.ticketValue < 1 || this.state.ticketValue > parseFloat(localStorage.getItem('maxTicketValue'))) {
      this.setState({ isTicketValueError: true, ticketValueError: ERROR_TICKET_VALUE_EXCEEDED_THRESHOLD });
    } else {
      this.setState({ isTicketValueError: false, ticketValueError: '' });
      return true;
    }
    return false;
  }

  isModalValid() {
    return this.validateTicketValue();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleKeyPress(event) {
    const code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      event.preventDefault();
    }
  }

  submit() {
    if (this.isModalValid()) {
      this.props.changeTicketValue(this.state.ticketValue);
      this.props.hideChangeTicketValueModal();
      this.props.showLoadingSpinner();
    }
  }

  render() {
    return (
      <Modal className="confirmation-modal"
        show
        backdrop='static'
        onHide={this.props.hideChangeTicketValueModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modificare valoare tichet ({this.state.selectionCount})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Introdu valoarea tichetului pentru cardurile selectate</p>
          <Form>
            <Form.Row>
              <Form.Group className="form-group-label" as={Col} controlId="ticketValue">
                {this.state.isTicketValueError ? <span className="error-label">{this.state.ticketValueError}</span> : null}
                <Form.Control autoFocus
                  name="ticketValue" type="text" placeholder="Valoare tichet"
                  onChange={this.handleInputChange}
                  value={this.state.ticketValue}
                  onKeyPress={this.handleKeyPress} />
                <Form.Label>Valoare tichet</Form.Label>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm="auto">
              <button className="main-button white" onClick={this.props.hideChangeTicketValueModal}>
                RENUNTA
              </button>
            </Col>
            <Col sm="auto">
              <button className="main-button" onClick={this.submit}>
                MODIFICA VALOAREA
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.card };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  hideChangeTicketValueModal,
  changeTicketValue,
  showLoadingSpinner
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTicketValueModal);
