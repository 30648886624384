import './CardsGrid.scss';
import { branchStatus, cardStatus, selectionType, userRole } from 'utils/enums';
import { CardConfirmationModal, CardMigrationModal, CardReissueConfirmationModal, CardReissueModal, ChangeTicketValueModal, ConfirmationModal, CustomTable, PageSelector, PinReissueModal, /*SearchBar*/ } from 'components';
import { Col, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import { CardFilters } from 'components';
import { connect } from 'react-redux';
import httpClient from 'utils/httpClient';
import { Link } from 'react-router-dom';
import React from 'react';
import routes from 'utils/routes';

class CardsGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: false,
      requestActionForCardId: null,
      confirmationModalTitle: null,
      confirmationModalQuestion: null,
      confirmationModalConfirmHandler: null,
      showCardConfirmation: false
    };

    this.dropdownItemOnClickHandler = this.dropdownItemOnClickHandler.bind(this);
    this.getSelectedCardsCount = this.getSelectedCardsCount.bind(this);
    this.pageSelectionCallback = this.pageSelectionCallback.bind(this);
    this.exportCards = this.exportCards.bind(this);
    this.handleDeleteCardClick = this.handleDeleteCardClick.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.confirmCardDeletion = this.confirmCardDeletion.bind(this);
    this.bulkActionsBtnsEnabled = this.bulkActionsBtnsEnabled.bind(this);
    this.renderOrderButton = this.renderOrderButton.bind(this);
    this.selectAllCards = this.selectAllCards.bind(this);
    this.canBeReissued = this.canBeReissued.bind(this);
    this.getBranchNameById = this.getBranchNameById.bind(this);
    this.setSelectionInfoText = this.setSelectionInfoText.bind(this);

    this.TABLE_HEADERS = ['Nume', 'Prenume', 'CNP', 'IBAN', 'Telefon', 'Valoare tichet (RON)', 'Status'];
    this.TABLE_FIELDS = ['lastName', 'firstName', 'cnp', 'iban', 'phoneNumber', 'ticketValue', 'status'];
  }

  dropdownItemOnClickHandler(event) {
    let value = (event.currentTarget && event.currentTarget.text) || 10;
    this.props.setCardsPageSize(value);
    this.props.findCardsRequest();
  }

  getSelectedCardsCount() {
    return this.props.selectedCards.length > 0 ?
      this.props.selectedCards.length :
      (this.props.selectState === selectionType.all ? this.props.totalCount : 0);
  }

  pageSelectionCallback(selectedPage) {
    this.props.setCardsCurrentPage(selectedPage);
    this.props.findCardsRequest();
  }

  exportCards() {
    httpClient.download('/card/export');
  }

  closeConfirmationModal() {
    this.setState({
      showConfirmationModal: false,
      requestActionForCardId: null,
      confirmationModalTitle: null,
      confirmationModalQuestion: null,
      confirmationModalConfirmHandler: null
    });
  }

  handleDeleteCardClick(cardId) {
    this.setState({
      showConfirmationModal: true,
      requestActionForCardId: cardId,
      confirmationModalTitle: 'Confirma stergerea cardului',
      confirmationModalQuestion: 'Esti sigur ca vrei sa stergi cardul selectat?',
      confirmationModalConfirmHandler: this.confirmCardDeletion
    });
  }

  confirmCardDeletion() {
    this.props.deleteCard(this.state.requestActionForCardId);
    this.closeConfirmationModal();
    this.props.showLoadingSpinner();
  }

  bulkActionsBtnsEnabled() {
    return this.props.selectedCards.length > 0 || this.props.selectState == selectionType.all;
  }

  isConfirmable() {
    const selectedCardsCount = this.getSelectedCardsCount();
    const isAllSelectedCardsEmitted = selectedCardsCount > 0 && this.props.selectedCards.every(card => card.statusId === cardStatus.emitted);

    return this.bulkActionsBtnsEnabled() && isAllSelectedCardsEmitted
  }

  isModifiable() {
    const validCardStatuses = [cardStatus.existing, cardStatus.emitted, cardStatus.confirmed, cardStatus.migrated];
    const isValidCard = this.getSelectedCardsCount() > 0 && this.props.selectedCards.every(card => validCardStatuses.includes(card.statusId));

    return this.bulkActionsBtnsEnabled() && isValidCard;
  }

  selectAllCards() {
    this.props.setCardsLoadedFlag(false);
    this.props.selectAllCards();
  }

  isMigrable() {
    const areCardsEligibleForMigration = this.props.selectedCards.length > 0 &&
      this.props.selectedCards.every(
        card =>
          card.statusId === cardStatus.existing ||
          card.statusId === cardStatus.confirmed ||
          card.statusId === cardStatus.migrated);
    return this.bulkActionsBtnsEnabled() && areCardsEligibleForMigration
  }

  canBeReissued() {
    const isValidCard = this.getSelectedCardsCount() > 0 && this.props.selectedCards.every(card => card.statusId === cardStatus.emitted),
      isCurrentBranchSelected = this.props.currentBranch.id !== null;

    return this.bulkActionsBtnsEnabled() && isValidCard && isCurrentBranchSelected;
  }

  getBranchNameById(branchId) {
    const index = this.props.branches.findIndex(branch => { return branch.id === branchId });

    return index !== -1 ? this.props.branches[index].name : '';
  }

  renderOrderButton() {
    let showButton = false,
      disabledButton = this.props.currentBranch.id && (!this.props.currentBranch.contactPersonName || !this.props.currentBranch.contactPersonPhoneNumber);

    // A branch is selected, and enabled
    if (this.props.currentBranch.id !== null &&
      this.props.currentBranch.status === branchStatus.enabled) {
      showButton = true;
    }

    // No branch is selected, but the user is ADMIN
    if (this.props.currentBranch.id === null &&
      this.props.roleId === userRole.ADMIN) {
      showButton = true;
    }

    if (showButton) {
      return (
        <div className={disabledButton ? 'new-order-button-wrapper disabled' : 'new-order-button-wrapper'}>
          <Link to={routes.dashboardOrdersUpload} className={disabledButton ? 'new-order-button disabled main-button icon' : 'new-order-button main-button icon'}>
            <svg width="20" height="20" viewBox="0 0 24 24">
              <path id="a" d="M13.767 10.233h6.466a1.767 1.767 0 0 1 0 3.534h-6.466v6.466a1.767 1.767 0 0 1-3.534 0v-6.466H3.767a1.767 1.767 0 0 1 0-3.534h6.466V3.767a1.767 1.767 0 0 1 3.534 0v6.466z" />
            </svg>
            <span className="tooltip-text">Pentru a plasa o comanda, punctul de lucru trebuie sa aiba alocata o persoana de contact</span>
            COMANDA NOUA
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  setSelectionInfoText() {
    if (this.getSelectedCardsCount() === 0) {
      return null;
    } else {
      if (this.getSelectedCardsCount() === 1) {
        return '1 selectie'
      }
      return this.getSelectedCardsCount() + ' selectii'
    }
  }

  render() {
    if (!this.props.currentBranch) {
      return null;
    }

    let tableHeaderValues = [],
      tableContent = [],
      tableFields = [];

    if (this.props.cardsLoaded) {
      tableHeaderValues = this.props.currentBranch && this.props.currentBranch.id ? this.TABLE_HEADERS : [...this.TABLE_HEADERS, 'Punct de lucru'];
      tableContent = this.props.currentBranch && this.props.currentBranch.id ? this.props.cards : this.props.cards.map(card => ({ ...card, branchName: this.getBranchNameById(card.branchId) }));
      tableFields = this.props.currentBranch && this.props.currentBranch.id ? this.TABLE_FIELDS : [...this.TABLE_FIELDS, 'branchName'];
    }

    return (
      <div className="cards-dashboard">
        <Row className="justify-content-between cards-info-container order-info-container" noGutters>
          <Col sm="auto">
            <Row noGutters>
              <Col sm="auto">
                <h2>Carduri</h2>
              </Col>
              <Col sm="auto" className="separator selection ml-3 mr-3">
                {this.getSelectedCardsCount() > 0 ? '|' : ''}
              </Col>
              <Col sm="auto" className="selection-info selection">
                {this.setSelectionInfoText()}
              </Col>
            </Row>
          </Col>
          <Col sm="auto">
            <Row noGutters>
              <Col sm="auto">
                {
                  this.props.cardsLoaded &&
                    (!this.props.currentBranch.id || this.props.branchesCount === 1) &&
                    this.props.roleId === userRole.ADMIN ?

                    <Dropdown className="bulk-actions">
                      <Dropdown.Toggle as="button" className="main-button white">
                        <span>ACTIUNI</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {
                          <Dropdown.Item as={Link} to={routes.dashboardCardsModifyTicketValueBulk} title="Modifica valoare bulk">
                            <svg width="20" height="20" viewBox="0 0 24 24">
                              <path d="M14.5 11.5c2.403 0 4.37 1.082 4.494 2.635l.006.157v5.574a.885.885 0 0 1-.065.33C18.57 21.583 16.711 22.5 14.5 22.5c-2.21 0-4.068-.918-4.435-2.303a.891.891 0 0 1-.058-.217L10 19.866v-5.574c0-1.636 2.017-2.792 4.5-2.792zm2.792 7.67c-.775.37-1.75.576-2.792.576-1.043 0-2.017-.205-2.792-.575v.537c0 .422 1.218 1.038 2.792 1.038 1.574 0 2.792-.615 2.792-1.038v-.537zM2.536 3h18.928c.776 0 1.452.55 1.529 1.294l.007.141v12.121c0 .767-.628 1.37-1.392 1.438l-.144.006H20v-2h.18c.427 0 .76-.227.813-.495l.007-.074V10H3v5.431c0 .274.297.524.706.564L3.82 16H9v2H2.536c-.779 0-1.452-.557-1.529-1.302L1 16.556V4.436c0-.767.63-1.362 1.392-1.43L2.536 3h18.928zm14.756 13.508c-.775.37-1.75.575-2.792.575-1.043 0-2.017-.205-2.792-.575v.446c0 .422 1.218 1.038 2.792 1.038 1.574 0 2.792-.616 2.792-1.038v-.446zM14.5 13.254c-1.574 0-2.792.616-2.792 1.038 0 .422 1.218 1.038 2.792 1.038 1.574 0 2.792-.616 2.792-1.038 0-.422-1.218-1.038-2.792-1.038zm5.689-8.28H3.83c-.435 0-.77.485-.816.899l-.005.094v2.006h18V5.967c0-.43-.352-.994-.821-.994z" />
                            </svg>
                            <span className='label'>MODIFICA VALOARE</span>
                          </Dropdown.Item>
                        }
                        {
                          <Dropdown.Item as={Link} to={routes.dashboardCardsDeleteBulk} title="Stergere carduri bulk">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                              <path id="a" d="M5 19.64C5 20.938 6.133 22 7.517 22h8.966C17.867 22 19 20.938 19 19.64V7.2c0-.11-.09-.199-.198-.199H5.198C5.088 7 5 7.08 5 7.199V19.64zM20.5 3.704a.507.507 0 0 0-.505-.507h-4.217l-.738-.818C14.85 2.169 14.467 2 14.2 2H9.8c-.275 0-.648.166-.84.379l-.738.818H4.005c-.279 0-.505.22-.505.507v1.378c0 .28.215.508.49.508h16.02c.27 0 .49-.221.49-.508V3.704z" />
                            </svg>
                            <span className="label">STERGERE CARDURI</span>
                          </Dropdown.Item>
                        }
                        {
                          this.props.roleId === userRole.ADMIN && (this.props.branchesCount === 1 || !this.props.currentBranch.id) &&
                          <Dropdown.Item>
                            {
                              <span onClick={this.exportCards} title="Export carduri confirmate">
                                <svg width="20" height="20" viewBox="0 0 24 24">
                                  <path id="a" d="M5 20h14a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm7.34-3.15a.461.461 0 0 1-.678.003L5.14 9.894a.528.528 0 0 1 .003-.717.466.466 0 0 1 .337-.147h3.226V2.4c0-.22.179-.4.4-.4h5.79c.221 0 .4.18.4.4v6.63h3.226c.264 0 .479.227.479.507 0 .134-.05.262-.139.357l-6.52 6.956z" />
                                </svg>
                                <span className="label">DESCARCA RAPORT</span>
                              </span>
                            }
                          </Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    </Dropdown> : null
                }
              </Col>
              <Col sm="auto">
                {this.renderOrderButton()}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-between grid-info" noGutters>
          <Col sm="auto">
            <CardFilters />
          </Col>
          <Col sm="auto">
            <Row noGutters className="small-font center-small-fonts-content">
              {
                this.props.branchesCount > 1 &&
                <Col sm="auto" className="action-button-container">
                  <button className={'migrate-cards-button plain-button' + (!this.isMigrable() ? ' disabled' : '')}
                    onClick={this.isMigrable() ? this.props.showCardMigrationModal : null}>
                    <svg width="20" height="20" viewBox="0 0 24 24">
                      <path d="M17.526 14v5.473H19L16.5 23 14 19.473h1.474V14h2.052zM12.5 11l2.5 3.527h-1.474V20h-2.052v-5.473H10L12.5 11zM2.536 3h18.928c.776 0 1.452.55 1.529 1.294l.007.141v12.121c0 .767-.628 1.37-1.392 1.438l-.144.006H20v-2h.18c.427 0 .76-.227.813-.495l.007-.074V10H3v5.431c0 .274.297.524.706.564L3.82 16H9v2H2.536c-.779 0-1.452-.557-1.529-1.302L1 16.556V4.436c0-.767.63-1.362 1.392-1.43L2.536 3h18.928zM20.19 4.973H3.83c-.435 0-.77.486-.816.9l-.005.094v2.006h18V5.967c0-.43-.352-.994-.821-.994z" />
                    </svg>
                    MIGREAZA
                    <span className="tooltip-text">Pentru a migra cardurile pe alt punct de lucru, toate cardurile selectate trebuie sa fie in unul din statusurile: Existent, Confirmat, Migrat.</span>
                  </button>
                </Col>
              }
              <Col sm="auto" className={'action-button-container'}>
                <button className={'change-value-button plain-button' + (!this.isModifiable() ? ' disabled' : '')}
                  onClick={this.isModifiable() ? this.props.showChangeTicketValueModal : null}>
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path d="M14.5 11.5c2.403 0 4.37 1.082 4.494 2.635l.006.157v5.574a.885.885 0 0 1-.065.33C18.57 21.583 16.711 22.5 14.5 22.5c-2.21 0-4.068-.918-4.435-2.303a.891.891 0 0 1-.058-.217L10 19.866v-5.574c0-1.636 2.017-2.792 4.5-2.792zm2.792 7.67c-.775.37-1.75.576-2.792.576-1.043 0-2.017-.205-2.792-.575v.537c0 .422 1.218 1.038 2.792 1.038 1.574 0 2.792-.615 2.792-1.038v-.537zM2.536 3h18.928c.776 0 1.452.55 1.529 1.294l.007.141v12.121c0 .767-.628 1.37-1.392 1.438l-.144.006H20v-2h.18c.427 0 .76-.227.813-.495l.007-.074V10H3v5.431c0 .274.297.524.706.564L3.82 16H9v2H2.536c-.779 0-1.452-.557-1.529-1.302L1 16.556V4.436c0-.767.63-1.362 1.392-1.43L2.536 3h18.928zm14.756 13.508c-.775.37-1.75.575-2.792.575-1.043 0-2.017-.205-2.792-.575v.446c0 .422 1.218 1.038 2.792 1.038 1.574 0 2.792-.616 2.792-1.038v-.446zM14.5 13.254c-1.574 0-2.792.616-2.792 1.038 0 .422 1.218 1.038 2.792 1.038 1.574 0 2.792-.616 2.792-1.038 0-.422-1.218-1.038-2.792-1.038zm5.689-8.28H3.83c-.435 0-.77.485-.816.899l-.005.094v2.006h18V5.967c0-.43-.352-.994-.821-.994z" />
                  </svg>
                  MODIFICA VALOARE
                  <span className="tooltip-text">
                    {
                      this.getSelectedCardsCount() === 0 ?
                        'Pentru a modifica valoarea tichetului, selecteaza cel putin un card cu statusul: Existent, Emis, Confirmat, Migrat.' :
                        'Pentru a modifica valoarea tichetului, selecteaza doar cardurile cu unul din statusurile: Existent, Emis, Confirmat, Migrat.'
                    }
                  </span>
                </button>
              </Col>
              <Col sm="auto" className={'action-button-container'}>
                <button
                  className={'confirm-cards-button plain-button' + (!this.isConfirmable() ? ' disabled' : '')}
                  onClick={this.isConfirmable() ? this.props.showModalForCardConfirmation : null}>
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path id="a" d="M.719 12.45a1.014 1.014 0 0 1 1.446-.007l5.05 5.08-1.32 1.333a.2.2 0 0 1-.285 0L.725 13.922a1.05 1.05 0 0 1-.006-1.471zM23.256 5.74a1.05 1.05 0 0 1 .006 1.484L11.745 18.857a.2.2 0 0 1-.284 0l-4.912-4.92a1.046 1.046 0 0 1 .009-1.486 1.061 1.061 0 0 1 1.498.014l3.547 3.605L21.777 5.747a1.044 1.044 0 0 1 1.48-.008zm-5.836 1.48l-5.817 5.92-1.438-1.477 5.794-5.919a1.023 1.023 0 0 1 1.458-.004c.406.41.407 1.069.003 1.48z" />
                  </svg>
                  CONFIRMA PREDAREA
                  <span className="tooltip-text">
                    {
                      this.getSelectedCardsCount() === 0 ?
                        'Pentru confirmarea predarii cardurilor, selecteaza cel putin un card cu status Emis.' :
                        'Pentru confirmare predarii cardurilor, selecteaza doar cardurile cu status Emis.'
                    }
                  </span>
                </button>
              </Col>
              <Col sm="auto" className={'action-button-container'}>
                <button
                  className={'reissue-cards-button plain-button' + (!this.canBeReissued() ? ' disabled' : '')}
                  onClick={this.canBeReissued() ? this.props.showConfirmatiomModalForCardReissue : null}>
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path d="M14.83 12.448h4.8c.511 0 .931.392.989.897l.006.118v3.564h2.087a.29.29 0 0 1 .288.288.284.284 0 0 1-.023.112l-.03.052-3.078 4.398a.29.29 0 0 1-.428.052l-.046-.052-3.08-4.398a.288.288 0 0 1 .173-.445l.063-.007h2.085v-2.549H14.83c-.549 0-.994-.455-.994-1.015 0-.52.384-.95.878-1.008l.116-.007h4.8zM10.37 12c.07 0 .138.026.19.072l.046.052 3.08 4.398a.287.287 0 0 1-.173.445l-.064.007h-2.085v2.66h3.806c.55 0 .995.454.994 1.014 0 .52-.383.95-.878 1.008l-.116.007h-4.8c-.51 0-.931-.392-.989-.897l-.006-.118v-3.674H7.289A.29.29 0 0 1 7 16.685c0-.038.008-.076.023-.111l.03-.052 3.079-4.398a.289.289 0 0 1 .237-.124zM2.535 3h18.928c.776 0 1.452.55 1.529 1.294l.007.141V12h-2v-2H3v5.431c0 .274.297.524.706.564L3.82 16H6v2H2.536c-.779 0-1.452-.557-1.529-1.302L1 16.556V4.436c0-.767.63-1.362 1.392-1.43L2.536 3h18.928zM20.19 4.973H3.83c-.435 0-.77.486-.816.9l-.005.094v2.006h18V5.967c0-.43-.352-.994-.821-.994z" />
                  </svg>
                  REEMITE
                  <span className="tooltip-text">Selecteaza un punct de lucru si un card cu status Emis pentru a putea reemite un card.</span>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        {
          this.props.cardsLoaded ?
            <div>
              {<CustomTable selectable={tableContent.length > 0}
                currentBranchId={this.props.currentBranch.id}
                selectedItems={this.props.selectedCards}
                selectState={this.props.selectState}
                totalCount={this.props.totalCount}
                idColumn='id'
                tableHeaderColumns={tableHeaderValues}
                content={tableContent} fields={tableFields}
                callbackSelectAllItems={this.selectAllCards}
                callbackSelectNone={this.props.clearSelection}
                callbackSelectItem={this.props.addCardToSelection}
                callbackDeselectItem={this.props.removeCardFromSelection}
                hasItemActions={true}
                itemActions={[
                  { title: 'Reemite PIN', clickHandler: this.props.showPinReissueModal },
                  { title: 'Reemite card', clickHandler: this.props.showCardReissueModal },
                  { title: 'Sterge card', clickHandler: this.handleDeleteCardClick }
                ]}
              />}
              {this.props.cards && this.props.cards.length > 0 &&
                <Row>
                  <Col>
                    <span className="small-font">
                      {this.props.cards && this.props.cards.length === 1 ?
                        `1 din ${this.props.totalCount} carduri afisat` :
                        `${this.props.cards.length} din ${this.props.totalCount} carduri afisate`}
                    </span>
                  </Col>
                  <Col>
                    <PageSelector currentPage={this.props.currentPage} pageCount={this.props.pageCount} pageSelectionCallback={this.pageSelectionCallback} />
                  </Col>
                  <Col className="right">
                    <DropdownButton alignRight size='sm' variant='secondary' drop='up' title={`${this.props.pageSize} pe pagina`} className='page-size-dropdown' >
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>10</Dropdown.Item>
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>25</Dropdown.Item>
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>50</Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              }
            </div>
            :
            <Row noGutters className='cards-loading-spinner'>
              <Col className='center'>
                <Spinner animation="border" variant="secondary" />
              </Col>
            </Row>
        }
        {
          this.state.showConfirmationModal &&
          <ConfirmationModal
            title={this.state.confirmationModalTitle}
            confirmationQuestion={this.state.confirmationModalQuestion}
            confirm={this.state.confirmationModalConfirmHandler}
            cancel={this.closeConfirmationModal}
          />
        }
        {
          this.props.cardReissueModalShow &&
          this.props.cardReissueModalShow.displayed &&
          <CardReissueModal />
        }
        {
          this.props.pinReissueModalShow &&
          this.props.pinReissueModalShow.displayed &&
          <PinReissueModal />
        }
        {
          this.props.cardMigrationModalShow &&
          <CardMigrationModal selectedCardsCount={this.getSelectedCardsCount()} />
        }
        {
          this.props.changeTicketValueModalShow &&
          <ChangeTicketValueModal selectedCardsCount={this.getSelectedCardsCount()} />
        }
        {
          this.props.cardConfirmationIsVisible &&
          <CardConfirmationModal selectedCardsCount={this.getSelectedCardsCount()} />
        }
        {
          this.props.cardReissueConfirmationIsVisible &&
          <CardReissueConfirmationModal selectedCardsCount={this.getSelectedCardsCount()} />
        }
      </div >
    );
  }
}

export default connect()(CardsGrid);
