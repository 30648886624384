export const userRole = {
  ADMIN: 0,
  POWER: 1,
  STANDARD: 2,
  properties: {
    0: 'Admin',
    1: 'Power',
    2: 'Standard'
  }
};

export const selectionType = {
  all: 0,
  multiple: 1,
  none: 2
}

export const cardStatus = {
  inProcess: 0,
  existing: 1,
  emitted: 2,
  confirmed: 3,
  deleted: 4,
  migrated: 5,
  reissued: 7,
  properties: {
    0: 'In procesare',
    1: 'Existent',
    2: 'Emis',
    3: 'Confirmat',
    4: 'Sters',
    5: 'Migrat',
    7: 'Reemis',
    8: 'Eroare'
  },
  getId(value) {
    for (let propName in this.properties) {
      if (this.properties[propName] === value) return propName;
    }
    return null;
  }
}

export const errorFilter = {
  all: 0,
  onlyErrors: 1
}

export const branchStatus = {
  disabled: 0,
  enabled: 1
}

export const fileUploadType = {
  cardsImport: 0,
  cardsDelete: 1,
  cardsImportAllBranches: 2,
  branchesImport: 3,
  cardsModifyTicketValue: 5
}

export const invoiceStatus = {
  ACTIVE: 1,
  CANCELLED: 2,
  UNDEFINED: 3,
  properties: {
    1: 'Activa',
    2: 'Anulata',
    4: 'Stornata',
    5: 'Inactiva'
  }
};
