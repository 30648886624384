import './NotFound404.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import routes from 'utils/routes';

class NotFound404 extends React.Component {

  render() {
    return (
      <Container fluid>
        <Row noGutters className="justify-content-sm-center">
          <Col xs="auto" className="not-found-404 center">
            <h1>404</h1>
            <h3>Ne pare rau, dar aceasta pagina nu exista.</h3>
            <h3>Apasati butonul de mai jos pentru a merge la pagina principala.</h3>
            <Link to={routes.home} className="main-button large">Pagina principala</Link>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NotFound404;
