import './ItemCorrectionScreen.scss';
import { Checkbox, CustomTable, PageSelector, RouteLeavingGuard, SearchBar } from 'components';
import { Col, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import { errorFilter, fileUploadType } from 'utils/enums';
import CaptchaModalBody from '../CustomModal/CaptchaModalBody';
import CustomModal from '../CustomModal/CustomModal';
import { Link } from 'react-router-dom';
import React from 'react';
import routes from 'utils/routes';

let timeout = {};

class ItemCorrectionScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      captchaModal: null
    }

    this.validateAndSendButton = React.createRef();
    this.displayOnlyErrors = this.displayOnlyErrors.bind(this);
    this.validateAndSend = this.validateAndSend.bind(this);
    this.searchBarOnInputChangeHandler = this.searchBarOnInputChangeHandler.bind(this);
    this.pageSelectionCallback = this.pageSelectionCallback.bind(this);
    this.getInvalidItemsCount = this.getInvalidItemsCount.bind(this);
    this.removeSelected = this.removeSelected.bind(this);
    this.getSelectedItemsCount = this.getSelectedItemsCount.bind(this);
    this.renderCancelButton = this.renderCancelButton.bind(this);
    this.renderRouteLeavingGuard = this.renderRouteLeavingGuard.bind(this);
    this.dropdownItemOnClickHandler = this.dropdownItemOnClickHandler.bind(this);
    this.tableHasCardsDisplayed = this.tableHasCardsDisplayed.bind(this);
    this.getValidationOutcomeText = this.getValidationOutcomeText.bind(this);
    this.getDeleteButtonText = this.getDeleteButtonText.bind(this);
    this.getDeleteButtonTooltipText = this.getDeleteButtonTooltipText.bind(this);
    this.getVisibleItemsDescription = this.getVisibleItemsDescription.bind(this);
    this.handleOnHideModal = this.handleOnHideModal.bind(this);
    this.handleCaptchaStateChange = this.handleCaptchaStateChange.bind(this);
    this.submitCaptcha = this.submitCaptcha.bind(this);
    this.handleOnImageReloaded = this.handleOnImageReloaded.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => true
    this.props.blockCurrentBranchSelection(true);

    setTimeout(() => {
      const headersHeight = 315;
      window.scroll({ top: headersHeight, behaviour: 'smooth' });
    }, 100);
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined
    this.props.blockCurrentBranchSelection(false);
  }

  getInvalidItemsCount() {
    let withErrors;

    if (this.props.itemsToBeCorrected) {
      withErrors = this.props.itemsToBeCorrected.filter(item => { return item.errors.length != 0 }).length;
    }

    return withErrors;
  }

  removeSelected() {
    this.props.correctionScreenRemoveSelectedItems();
  }

  displayOnlyErrors(event) {
    if (!event.currentTarget.checked) {
      this.props.setErrorFilter(errorFilter.all);
      this.props.setCorrectionScreenCurrentPage(1);
    } else {
      this.props.setErrorFilter(errorFilter.onlyErrors);
      this.props.setCorrectionScreenCurrentPage(1);
    }
  }

  validateAndSend() {
    // prevent multiple clicks on the button
    this.props.setSendCorrectedItemsInProgress(true);
    if (this.props.cards && this.props.cards.findIndex(item => { return item.errors.length != 0 }) === -1) {
      this.props.sendCorrectedItemsRequest(this.state.captchaModal);
      this.props.correctionScreenClearSelection();
    }
  }

  searchBarOnInputChangeHandler(event) {
    const value = (event && event.currentTarget && event.currentTarget.value) || '';

    if (!event) {
      document.getElementById('search-bar-input-cards').value = '';
    }

    clearTimeout(timeout); // cancel the old timer
    timeout = setTimeout(() => { this.props.setCorrectionScreenSearchTerm(value.trim().toLowerCase()) }, 200);
  }

  pageSelectionCallback(selectedPage) {
    this.props.setCorrectionScreenCurrentPage(selectedPage);
  }

  dropdownItemOnClickHandler(event) {
    let value = (event.currentTarget && event.currentTarget.text) || 10;
    this.props.setCorrectionScreenPageSize(value);
  }

  tableHasCardsDisplayed() {
    return ((this.props.firstOrLastName !== '' && this.props.cards.length > 0) ||
      (this.props.firstOrLastName === '' && this.props.cards.length > 0));
  }

  getSelectedItemsCount() {
    return this.props.selectedItems.length;
  }

  getValidationOutcomeText() {
    switch (this.props.uploadTypeId) {
      case fileUploadType.branchesImport:
        return `${this.props.itemsToBeCorrected.length - this.getInvalidItemsCount()} puncte de lucru validate cu succes, ${this.getInvalidItemsCount()} cu erori`;
      default:
        return `${this.props.itemsToBeCorrected.length - this.getInvalidItemsCount()} carduri validate cu succes, ${this.getInvalidItemsCount()} cu erori`;
    }
  }

  getDeleteButtonText() {
    switch (this.props.uploadTypeId) {
      case fileUploadType.branchesImport:
        return 'sterge punctele de lucru selectate';
      default:
        return 'sterge cardurile selectate';
    }
  }

  getDeleteButtonTooltipText() {
    switch (this.props.uploadTypeId) {
      case fileUploadType.branchesImport:
        return 'Pentru stergere selecteaza cel putin un punct de lucru';
      default:
        return 'Pentru stergere selecteaza cel putin un card';
    }
  }

  getVisibleItemsDescription() {
    switch (this.props.uploadTypeId) {
      case fileUploadType.branchesImport:
        return `${this.props.cards.length} din ${this.props.totalCount} puncte de lucru afisate`;
      default:
        return `${this.props.cards.length} din ${this.props.totalCount} carduri afisate`;
    }
  }

  renderCancelButton() {
    const uploadTypeId = this.props.uploadTypeId;

    switch (uploadTypeId) {
      case fileUploadType.cardsImport:
      case fileUploadType.cardsImportAllBranches:
      case fileUploadType.cardsDelete:
      case fileUploadType.cardsModifyTicketValue:
        return <Link to={routes.dashboardCards} className="main-button white cancel-link">RENUNTA</Link>;
      case fileUploadType.branchesImport:
        return <Link to={routes.dashboardBranch} className="main-button white cancel-link">RENUNTA</Link>;
    }
  }

  renderRouteLeavingGuard() {
    const uploadTypeId = this.props.uploadTypeId;
    switch (uploadTypeId) {
      case fileUploadType.cardsImport:
      case fileUploadType.cardsImportAllBranches:
        return (
          <RouteLeavingGuard
            when={true}
            navigate={path => this.props.push(path)}
            shouldBlockNavigation={true}
            title='Confirmare abandonare comanda'
            confirmationQuestion='Esti sigur ca vrei sa renunti la aceasta comanda?'
          />
        );
      case fileUploadType.cardsDelete:
        return (
          <RouteLeavingGuard
            when={true}
            navigate={path => this.props.push(path)}
            shouldBlockNavigation={true}
            title='Confirmare abandonare proces'
            confirmationQuestion='Esti sigur ca vrei sa renunti la stergerea cardurilor?'
          />
        );
      case fileUploadType.cardsModifyTicketValue:
        return (
          <RouteLeavingGuard
            when={true}
            navigate={path => this.props.push(path)}
            shouldBlockNavigation={true}
            title='Confirmare abandonare proces'
            confirmationQuestion='Esti sigur ca vrei sa renunti la modificarea valorii cardurilor?'
          />
        );
      case fileUploadType.branchesImport:
        return (
          <RouteLeavingGuard
            when={true}
            navigate={path => this.props.push(path)}
            shouldBlockNavigation={true}
            title='Confirmare abandonare proces'
            confirmationQuestion='Esti sigur ca vrei sa renunti la incarcarea punctelor de lucru?'
          />
        );
    }
  }

  handleOnHideModal() {
    this.props.toggleModalVisibility(false);
    this.props.setSendCorrectedItemsInProgress(false);
    this.setState({ captchaModal: null });
  }

  handleCaptchaStateChange(captchaData) {
    this.setState({ captchaModal: captchaData });
  }

  handleOnImageReloaded() {
    this.props.setIsCaptchaValid(true)
  }

  submitCaptcha() {
    this.props.toggleSubmitButton(false);
    this.props.sendCorrectedItemsRequest(this.state.captchaModal);
  }

  renderCaptchaModal() {
    return (
      <CustomModal title="Comanda noua - Captcha"
        submitText="Confirma"
        closeButtonText="RENUNTA"
        isScrollable={true}
        isCentered={true}
        isModalEnabled={this.props.isCaptchaModalShowable}
        onHideModelCallback={this.handleOnHideModal}
        onSubmit={this.submitCaptcha}
        cssFileName='CaptchaModal'
        cssClass='captcha-modal-content'
      >
        <CaptchaModalBody
          onStateChange={this.handleCaptchaStateChange}
          onKeyUp={this.submitCaptcha}
          isCaptchaValid={this.props.isCaptchaValid}
          onImageReloaded={this.handleOnImageReloaded}
        />
      </CustomModal>
    )
  }

  render() {
    let subTitle, columns, fields, longColumns, searchPlaceHolder, tableName, columnsCustomWidth;
    const uploadTypeId = this.props.uploadTypeId;

    if (this.getInvalidItemsCount() > 0 || (this.props.itemsToBeCorrected && this.props.itemsToBeCorrected.length === 0)) {
      this.validateAndSendButton.current && this.validateAndSendButton.current.classList.remove('loading');
    }

    switch (uploadTypeId) {
      case fileUploadType.cardsImport:
        subTitle = 'Comanda carduri';
        columns = ['Nume', 'Prenume', 'Telefon', 'CNP', 'Valoare tichet (RON)'];
        fields = ['lastName', 'firstName', 'phoneNumber', 'cnp', 'ticketValue'];
        longColumns = ['firstName', 'phoneNumber', 'cnp'];
        searchPlaceHolder = 'Cauta card dupa CNP sau nume';
        tableName = 'Carduri';
        break;
      case fileUploadType.cardsImportAllBranches:
        subTitle = 'Comanda carduri';
        columns = ['Nume', 'Prenume', 'CNP', 'Telefon', 'Valoare tichet (RON)', 'Punct de lucru'];
        fields = ['lastName', 'firstName', 'cnp', 'phoneNumber', 'ticketValue', 'branchName'];
        longColumns = ['firstName', 'phoneNumber', 'cnp', 'branchName'];
        searchPlaceHolder = 'Cauta card dupa CNP sau nume';
        tableName = 'Carduri';
        break;
      case fileUploadType.cardsDelete:
        subTitle = 'Sterge carduri';
        columns = ['Nume', 'Prenume', 'CNP'];
        fields = ['lastName', 'firstName', 'cnp'];
        longColumns = ['firstName', 'cnp'];
        searchPlaceHolder = 'Cauta card dupa CNP sau nume';
        tableName = 'Carduri';
        break;
      case fileUploadType.branchesImport:
        subTitle = 'Incarca puncte de lucru';
        columns = ['Nume', 'Judet', 'Localitate', 'Strada', 'Numar', 'Cod postal', 'Nume persoana de contact', 'Telefon pesoana de contact'];
        fields = ['name', 'countyName', 'localityName', 'streetName', 'streetNumber', 'zipCode', 'contactPersonName', 'contactPersonPhoneNumber'];
        longColumns=['streetName'];
        columnsCustomWidth=['12%', '140px', '150px', '190px', '100px', '120px', '12%', '150px'];
        searchPlaceHolder = 'Cauta punct de lucru';
        tableName = 'Puncte de lucru';
        break;
      case fileUploadType.cardsModifyTicketValue:
        subTitle = 'Modificare valoare carduri';
        columns = ['Nume', 'Prenume', 'CNP', 'Valoare tichet noua'];
        fields = ['lastName', 'firstName', 'cnp', 'ticketValue'];
        longColumns = ['firstName', 'cnp'];
        searchPlaceHolder = 'Cauta card dupa CNP sau nume';
        tableName = 'Carduri';
        break;
    }

    return (
      this.props.cards ?
        <>
          <div className='card-correction-grid'>
            <Row className='justify-content-between correction-info-container' noGutters>
              <Col md="auto">
                <Row noGutters>
                  <Col md="auto">
                    <h2>{subTitle}</h2>
                  </Col>
                  <Col md="auto">
                    <SearchBar
                      searchBarId='search-bar-input-cards'
                      placeholder={searchPlaceHolder}
                      clearHandler={this.searchBarOnInputChangeHandler}
                      handler={this.searchBarOnInputChangeHandler}
                      showResetSearchButton={!!this.props.firstOrLastName}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="auto">
                <Row noGutters>
                  <Col md="auto">
                    {this.renderCancelButton()}
                  </Col>
                  <Col md="auto">
                    <span className={`validate-button-error-message ${this.getInvalidItemsCount() > 0 || this.props.itemsToBeCorrected.length === 0 ? '' : 'hidden'}`}>
                      Rezolva erorile pentru a continua
                    </span>
                    <button
                      className={`main-button validate-button 
                    ${this.props.sendCorrectedItemsInProgress ? 'loading disabled' : ''}
                    ${this.getInvalidItemsCount() > 0 || this.props.itemsToBeCorrected.length === 0 ? 'disabled' : ''}`}
                      onClick={this.validateAndSend}
                      ref={this.validateAndSendButton}>
                      <span className="spinner-border spinner-border-sm"></span>
                      VALIDEAZA SI TRIMITE
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="grid-info" noGutters>
              {
                this.props.filter === errorFilter.onlyErrors
                  ? <Col><strong>{tableName} cu erori</strong></Col>
                  : <Col><strong>{tableName}</strong></Col>
              }
              <Col md="auto" className="validation-info small-font">
                {this.getValidationOutcomeText()}
              </Col>
              {
                this.getSelectedItemsCount() === 0 ? null :
                  <Col md="auto" className="selection-info small-font">
                    {
                      this.getSelectedItemsCount() === 1
                        ? '1 selectie'
                        : `${this.getSelectedItemsCount()} selectii`
                    }
                  </Col>
              }
              {
                this.getSelectedItemsCount() === 0 ?
                  <Col md="auto" className="delete-button-container small-font">
                    <button disabled className="plain-button disabled" onClick={this.removeSelected}>
                      <svg width="20" height="20" viewBox="0 0 24 24">
                        <path id="a" d="M5 19.64C5 20.938 6.133 22 7.517 22h8.966C17.867 22 19 20.938 19 19.64V7.2c0-.11-.09-.199-.198-.199H5.198C5.088 7 5 7.08 5 7.199V19.64zM20.5 3.704a.507.507 0 0 0-.505-.507h-4.217l-.738-.818C14.85 2.169 14.467 2 14.2 2H9.8c-.275 0-.648.166-.84.379l-.738.818H4.005c-.279 0-.505.22-.505.507v1.378c0 .28.215.508.49.508h16.02c.27 0 .49-.221.49-.508V3.704z" />
                      </svg>
                      {this.getDeleteButtonText()}
                      <span className="tooltip-text">{this.getDeleteButtonTooltipText()}</span>
                    </button>
                  </Col>
                  :
                  <Col md="auto" className="delete-button-container small-font">
                    <button className="plain-button" onClick={this.removeSelected}>
                      <svg width="20" height="20" viewBox="0 0 24 24">
                        <path id="a" d="M5 19.64C5 20.938 6.133 22 7.517 22h8.966C17.867 22 19 20.938 19 19.64V7.2c0-.11-.09-.199-.198-.199H5.198C5.088 7 5 7.08 5 7.199V19.64zM20.5 3.704a.507.507 0 0 0-.505-.507h-4.217l-.738-.818C14.85 2.169 14.467 2 14.2 2H9.8c-.275 0-.648.166-.84.379l-.738.818H4.005c-.279 0-.505.22-.505.507v1.378c0 .28.215.508.49.508h16.02c.27 0 .49-.221.49-.508V3.704z" />
                      </svg>
                      {this.getDeleteButtonText()}
                    </button>
                  </Col>
              }
            </Row>
            <div>
              <CustomTable selectable={true}
                selectedItems={this.props.selectedItems}
                unselectedItems={this.props.unselectedItems}
                selectState={this.props.selectState}
                totalCount={this.props.totalCount}
                idColumn='id'
                tableHeaderColumns={columns}
                content={this.props.cards}
                fields={fields}
                callbackSelectAllItems={this.props.correctionScreenSelectAllItems}
                callbackSelectNone={this.props.correctionScreenClearSelection}
                callbackSelectItem={this.props.correctionScreenAddItemToSelection}
                callbackDeselectItem={this.props.correctionScreenRemoveItemFromSelection}
                editable='true'
                longColumns={longColumns}
                correctItem={this.props.correctItem}
                columnsCustomWidth={columnsCustomWidth}
              />
              {this.props.itemsToBeCorrected && this.props.itemsToBeCorrected.length > 0 &&
                <Row noGutters>
                  <Col>
                    <Row noGutters>
                      <Col md="auto">
                        <span className="small-font">
                          {this.props.cards ? this.getVisibleItemsDescription() : null}
                        </span>
                      </Col>
                      <Col md="auto">
                        {
                          <div className='filter-checkbox'>
                            <Checkbox checked={this.props.filter === errorFilter.onlyErrors} onChange={(event) => { this.displayOnlyErrors(event) }} text='Arata doar cele cu erori' />
                          </div>
                        }
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <PageSelector currentPage={this.props.currentPage} pageCount={this.props.pageCount} pageSelectionCallback={this.pageSelectionCallback} />
                  </Col>
                  <Col className="right">
                    <DropdownButton alignRight size='sm' variant='secondary' drop='up' title={`${this.props.pageSize} pe pagina`} className='page-size-dropdown' >
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>10</Dropdown.Item>
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>25</Dropdown.Item>
                      <Dropdown.Item onClick={(event) => this.dropdownItemOnClickHandler(event)}>50</Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              }
            </div>
            {this.renderRouteLeavingGuard()}
          </div >
          {this.renderCaptchaModal()}
        </>
        :
        <Row noGutters className='cards-loading-spinner'>
          <Col className='center'>
            <Spinner animation="border" variant="secondary" />
          </Col>
        </Row>
    );
  }
}

export default ItemCorrectionScreen;
