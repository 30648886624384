export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';

export const USER_GENERATE_SMS_TOKEN_REQUEST = 'USER_GENERATE_SMS_TOKEN_REQUEST';
export const USER_GENERATE_SMS_TOKEN_FAILURE = 'USER_GENERATE_SMS_TOKEN_FAILURE';
export const USER_GENERATE_SMS_TOKEN_SUCCESS = 'USER_GENERATE_SMS_TOKEN_SUCCESS';

export const USER_GET_USER_DATA_REQUEST = 'USER_GET_USER_DATA_REQUEST';
export const USER_GET_USER_DATA_FAILURE = 'USER_GET_USER_DATA_FAILURE';
export const USER_GET_USER_DATA_SUCCESS = 'USER_GET_USER_DATA_SUCCESS';

export const USER_SUBMIT_SMS_TOKEN_REQUEST = 'USER_SUBMIT_SMS_TOKEN_REQUEST';
export const USER_SUBMIT_SMS_TOKEN_FAILURE = 'USER_SUBMIT_SMS_TOKEN_FAILURE';
export const USER_SUBMIT_SMS_TOKEN_SUCCESS = 'USER_SUBMIT_SMS_TOKEN_SUCCESS';

export const USER_SUBMIT_USERNAME_REQUEST = 'USER_SUBMIT_USERNAME_REQUEST';
export const USER_SUBMIT_USERNAME_FAILURE = 'USER_SUBMIT_USERNAME_FAILURE';
export const USER_SUBMIT_USERNAME_SUCCESS = 'USER_SUBMIT_USERNAME_SUCCESS';

export const USER_SUBMIT_PASSWORD_REQUEST = 'USER_SUBMIT_PASSWORD_REQUEST';
export const USER_SUBMIT_PASSWORD_FAILURE = 'USER_SUBMIT_PASSWORD_FAILURE';
export const USER_SUBMIT_PASSWORD_SUCCESS = 'USER_SUBMIT_PASSWORD_SUCCESS';

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_FAILURE = 'USER_FORGOT_PASSWORD_FAILURE';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_EXPIRED_PASSWORD = 'USER_EXPIRED_PASSWORD';

export const USER_VERIFY_PASSWORD_REQUEST = 'USER_VERIFY_PASSWORD_REQUEST';
export const USER_VERIFY_PASSWORD_FAILURE = 'USER_VERIFY_PASSWORD_FAILURE';
export const USER_VERIFY_PASSWORD_SUCCESS = 'USER_VERIFY_PASSWORD_SUCCESS';

export const USER_SUBMIT_PHONE_NUMBER_REQUEST = 'USER_SUBMIT_PHONE_NUMBER_REQUEST';
export const USER_SUBMIT_PHONE_NUMBER_FAILURE = 'USER_SUBMIT_PHONE_NUMBER_FAILURE';
export const USER_SUBMIT_PHONE_NUMBER_SUCCESS = 'USER_SUBMIT_PHONE_NUMBER_SUCCESS';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_IS_FIRST_LOGIN = 'USER_IS_FIRST_LOGIN';
export const USER_SAVE_INFORMATION = 'USER_SAVE_INFORMATION';
export const USER_SET_LOGIN_ERROR = 'USER_SET_LOGIN_ERROR';
export const USER_SET_SMS_CODE = 'USER_SET_SMS_CODE';
export const USER_PING_SERVER = 'USER_PING_SERVER';
export const USER_SET_USERNAME_CAPTCHA = 'USER_SET_USERNAME_CAPTCHA';
export const USER_SET_OTP_CAPTCHA = 'USER_SET_OTP_CAPTCHA';
export const USER_SET_PASSWORD_CAPTCHA = 'USER_SET_PASSWORD_CAPTCHA';
export const USER_SET_PHONE_NUMBER_CAPTCHA = 'USER_SET_PHONE_NUMBER_CAPTCHA';

export const USER_GENERATE_XSRF_TOKEN = 'USER_GENERATE_XSRF_TOKEN';

export const changePasswordRequest = (currentPassword, newPassword) => ({ type: USER_CHANGE_PASSWORD_REQUEST, payload: { currentPassword, newPassword } });
export const changePasswordFailure = errorMessage => ({ type: USER_CHANGE_PASSWORD_FAILURE, payload: errorMessage });
export const changePasswordSuccess = () => ({ type: USER_CHANGE_PASSWORD_SUCCESS });

export const generateSmsTokenRequest = () => ({ type: USER_GENERATE_SMS_TOKEN_REQUEST });
export const generateSmsTokenFailure = errorMessage => ({ type: USER_GENERATE_SMS_TOKEN_FAILURE, payload: errorMessage });
export const generateSmsTokenSuccess = result => ({ type: USER_GENERATE_SMS_TOKEN_SUCCESS, payload: result });

export const getUserDataRequest = () => ({ type: USER_GET_USER_DATA_REQUEST });
export const getUserDataFailure = () => ({ type: USER_GET_USER_DATA_FAILURE });
export const getUserDataSuccess = result => ({ type: USER_GET_USER_DATA_SUCCESS, payload: result });

export const submitSmsTokenRequest = tokenVerfication => ({ type: USER_SUBMIT_SMS_TOKEN_REQUEST, payload: tokenVerfication });
export const submitSmsTokenFailure = errorMessage => ({ type: USER_SUBMIT_SMS_TOKEN_FAILURE, payload: errorMessage });
export const submitSmsTokenSuccess = result => ({ type: USER_SUBMIT_SMS_TOKEN_SUCCESS, payload: result });

export const submitUsernameRequest = userVerficiation => ({ type: USER_SUBMIT_USERNAME_REQUEST, payload: userVerficiation });
export const submitUsernameFailure = errorMessage => ({ type: USER_SUBMIT_USERNAME_FAILURE, payload: errorMessage });
export const submitUsernameSuccess = result => ({ type: USER_SUBMIT_USERNAME_SUCCESS, payload: result });

export const submitPasswordRequest = passwordVerficiation => ({ type: USER_SUBMIT_PASSWORD_REQUEST, payload: passwordVerficiation });
export const submitPasswordFailure = errorMessage => ({ type: USER_SUBMIT_PASSWORD_FAILURE, payload: errorMessage });
export const submitPasswordSuccess = result => ({ type: USER_SUBMIT_PASSWORD_SUCCESS, payload: result });

export const verifyPasswordRequest = passwordVerficiation => ({ type: USER_VERIFY_PASSWORD_REQUEST, payload: passwordVerficiation });
export const verifyPasswordFailure = errorMessage => ({ type: USER_VERIFY_PASSWORD_FAILURE, payload: errorMessage });
export const verifyPasswordSuccess = result => ({ type: USER_VERIFY_PASSWORD_SUCCESS, payload: result });

export const submitPhoneNumberRequest = phoneNumber => ({ type: USER_SUBMIT_PHONE_NUMBER_REQUEST, payload: phoneNumber });
export const submitPhoneNumberFailure = errorMessage => ({ type: USER_SUBMIT_PHONE_NUMBER_FAILURE, payload: errorMessage });
export const submitPhoneNumberSuccess = result => ({ type: USER_SUBMIT_PHONE_NUMBER_SUCCESS, payload: result });

export const forgotPasswordRequest = () => ({ type: USER_FORGOT_PASSWORD_REQUEST });
export const forgotPasswordFailure = errorMessage => ({ type: USER_FORGOT_PASSWORD_FAILURE, payload: errorMessage });
export const forgotPasswordSuccess = result => ({ type: USER_FORGOT_PASSWORD_SUCCESS, payload: result });
export const expiredPassword = result => ({ type: USER_EXPIRED_PASSWORD, payload: result });

export const logout = () => ({ type: USER_LOGOUT });
export const pingServer = () => ({ type: USER_PING_SERVER });
export const setLoginError = errorMessage => ({ type: USER_SET_LOGIN_ERROR, payload: errorMessage });
export const saveUserInformation = userInfo => ({ type: USER_SAVE_INFORMATION, payload: { ...userInfo } });
export const setSmsToken = smsToken => ({ type: USER_SET_SMS_CODE, payload: { smsToken } });
export const isFirstLogin = () => ({ type: USER_IS_FIRST_LOGIN });
export const generateXsrfToken = () => ({ type: USER_GENERATE_XSRF_TOKEN });
export const setUsernameCaptcha = captcha => ({ type: USER_SET_USERNAME_CAPTCHA, payload: captcha });
export const setPasswordCaptcha = captcha => ({ type: USER_SET_PASSWORD_CAPTCHA, payload: captcha });
export const setOtpCaptcha = captcha => ({ type: USER_SET_OTP_CAPTCHA, payload: captcha });
export const setPhoneNumberCaptcha = captcha => ({ type: USER_SET_PHONE_NUMBER_CAPTCHA, payload: captcha });
