import './SearchBar.scss';
import { Col, Row } from 'react-bootstrap';
import React from 'react';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.renderInput = this.renderInput.bind(this);
  }

  renderInput() {
    if (this.props.value !== undefined) {
      return (
        <input
          id={this.props.searchBarId}
          type='text'
          autoComplete='off'
          className='search-input'
          name='lastname'
          placeholder={this.props.placeholder}
          onChange={(event) => { this.props.handler(event) }}
          value={this.props.value}
        />
      );
    } else {
      return (
        <input
          id={this.props.searchBarId}
          type='text'
          autoComplete='off'
          className='search-input'
          name='lastname'
          placeholder={this.props.placeholder}
          onChange={(event) => { this.props.handler(event) }}
        />
      );
    }
  }

  render() {
    return (
      <div className="search-bar">
        <Row noGutters>
          <Col sm="auto">
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 24 24">
              <path id="a" d="M22.817 21.037l-5.692-5.734a.636.636 0 0 1-.07-.8C19.389 10.923 18.845 6 15.4 3.06 12.088.23 7.045.328 3.835 3.275.15 6.657.057 12.415 3.557 15.916c2.985 2.987 7.604 3.35 10.996 1.106a.633.633 0 0 1 .804.07l5.68 5.722a.628.628 0 0 0 .89.002l.889-.89a.63.63 0 0 0 .001-.889zm-17.482-6.9a6.244 6.244 0 0 1 0-8.816 6.232 6.232 0 0 1 8.809 0 6.244 6.244 0 0 1 0 8.816 6.233 6.233 0 0 1-8.81 0z" />
            </svg>
          </Col>
          <Col>
            {this.renderInput()}
          </Col>
          {
            this.props.showResetSearchButton &&
            <Col sm="auto">
              <div className="search-clear" onClick={() => { this.props.clearHandler() }}>
                <svg width="16" height="16" viewBox="0 0 24 24">
                  <path id="a" d="M12 1c6.072 0 11 4.928 11 11s-4.928 11-11 11S1 18.072 1 12 5.928 1 12 1zm4.757 7.73a1.051 1.051 0 0 0-1.487-1.487L12 10.513l-3.27-3.27A1.051 1.051 0 1 0 7.243 8.73l3.27 3.27-3.27 3.27a1.051 1.051 0 1 0 1.487 1.487l3.27-3.27 3.27 3.27a1.051 1.051 0 0 0 1.487-1.487L13.487 12l3.27-3.27z" />
                </svg>
              </div>
            </Col>
          }
        </Row>
      </div >
    );
  }
}

export default SearchBar;
