import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import Header from 'components/Header';
import LoginFormContainer from './LoginFormContainer';
import React from 'react';


class LoginPage extends React.Component {
  render() {
    return (
      <div>
        <Header className="main-header">
          <Col className="center">
            <img src="/images/logo.png" className="app-logo"/>
          </Col>
        </Header>
        <LoginFormContainer {...this.props} />
      </div>
    );
  }
}

export default connect()(LoginPage);
