import {
    SAP_INVOICE_HIDE_LOADING_ICON,
    SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL,
    SAP_INVOICE_SET_CURRENT_PAGE,
    SAP_INVOICE_SET_PAGE_COUNT,
    SAP_INVOICE_SET_PAGE_SIZE,
    SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY,
    SAP_INVOICE_SET_TOTAL_COUNT,    
    SAP_INVOICE_SHOW_LOADING_ICON,   
    SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL,
    SET_SAP_INVOICES
  } from '../actions/sapInvoice';
  
  export default function sapInvoice(state, action) {
    state = state || {
      sapInvoices: null,
      isLoading: false,
      currentPage: 1,
      pageCount: 1,
      pageSize: 10,      
    };
  
    switch (action.type) {
      case SAP_INVOICE_HIDE_LOADING_ICON:
        return {
          ...state,
          isLoading: false
        }
      case SAP_INVOICE_SHOW_LOADING_ICON:
        return {
          ...state,
          isLoading: true
        }
      case SET_SAP_INVOICES:
        return {
          ...state,
          sapInvoices: action.payload
        }
      case SAP_INVOICE_SET_CURRENT_PAGE:
        return{
          ...state,
          currentPage: action.payload
        }
      case SAP_INVOICE_SET_PAGE_COUNT: {
        return {
          ...state,
          pageCount: action.payload
        };
      }
      case SAP_INVOICE_SET_PAGE_SIZE: {
        return {
          ...state,
          pageSize: action.payload
        };
      }
      case SAP_INVOICE_SET_TOTAL_COUNT: {
        return {
          ...state,
          totalCount: action.payload
        };
      }
      case SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY: {
        return {
          ...state,
          sapInvoiceToRectify: action.payload
        };
      }
      case SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL: {
        return {
          ...state,
          showRectifyModal: true
        };
      }
      case SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL: {
        return {
          ...state,
          showRectifyModal: false
        };
      }
      default:
        return state;
    }
  }
  
