import './AddUserToBranchModal.scss';
import { addUserToBranch, removeUserFromBranch } from 'actions/branch';
import { Col, Form, Modal, ModalBody } from 'react-bootstrap';
import { createManagerRequest, managersSetModalError } from 'actions/manager';
import { bindActionCreators } from 'redux';
import { branchUsersHideModalWithUsers } from 'actions/branchUser';
import { connect } from 'react-redux';
import React from 'react';
import { userRole } from 'utils/enums';
import validator from 'utils/validator';

class AddUserToBranchModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.initialState();

    this.filterUsers = this.filterUsers.bind(this);
    this.addUserToBranch = this.addUserToBranch.bind(this);
    this.removeUserFromBranch = this.removeUserFromBranch.bind(this);
    this.onShowModal = this.onShowModal.bind(this);
    this.handleErrorOnAddRemoveUserToBranch = this.handleErrorOnAddRemoveUserToBranch.bind();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateManagerUserName = this.validateManagerUserName.bind(this);
    this.validateManagerLastName = this.validateManagerLastName.bind(this);
    this.validateManagerFirstName = this.validateManagerFirstName.bind(this);
    this.validateManagerPhoneNumber = this.validateManagerPhoneNumber.bind(this);
    this.validateManagerRole = this.validateManagerRole.bind(this);
    this.resetState = this.resetState.bind(this);
    this.onBtnCloseModal = this.onBtnCloseModal.bind(this);
    this.submit = this.submit.bind(this);
    this.mapUiErrorCode = this.mapUiErrorCode.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
  }

  initialState() {
    return {
      usersToDisplay: this.props.users,
      showAddSection: false,

      managerUserName: '',
      isManagerUserNameError: false,
      managerUserNameError: '',

      managerFirstName: '',
      isManagerFirstNameError: false,
      managerFirstNameError: '',

      managerLastName: '',
      isManagerLastNameError: false,
      managerLastNameError: '',

      managerPhoneNumber: '',
      isManagerPhoneNumberError: false,
      managerPhoneNumberError: '',

      managerRole: this.props.userRoleId === userRole.POWER ? userRole.STANDARD : '-1',
      isManagerRoleError: false,
      managerRoleError: '',
      isSubmitting: false,
    };
  }

  resetState() {
    this.setState(this.initialState());
  }

  filterUsers(event) {
    const name = event.target.value;
    const filteredUsers = this.props.users
      .filter(u => u.fullName.toLowerCase().includes(name.toLowerCase()));

    this.setState({
      usersToDisplay: filteredUsers
    });
  }

  handleErrorOnAddRemoveUserToBranch(userId) {
    const pError = document.getElementById('error-' + userId),
      pAdded = document.getElementById('info-added-' + userId),
      pRemoved = document.getElementById('info-removed-' + userId);

    pError.className = pError.className.replace('hidden', '');
    pRemoved.className = 'hidden';
    pAdded.className = 'hidden';

    setTimeout(() => { pError.className += ' hidden' }, 2000);
  }

  addUserToBranch(userId, branchId, branchNames) {
    this.setState({ isSubmitting: true }, () => {
      this.props.addUserToBranch(userId, branchId, branchNames).then(() => {
        this.setState({ isSubmitting: false });
        if (this.props.addRemoveUserToBranchErrorMessage) {
          this.handleErrorOnAddRemoveUserToBranch(userId);
        } else {
          const pAdded = document.getElementById('info-added-' + userId),
            pRemoved = document.getElementById('info-removed-' + userId);

          pAdded.className = pAdded.className.replace('hidden', '');
          pRemoved.className = 'hidden';

          setTimeout(() => { pAdded.className = 'hidden' }, 2000);
        }
      }
      );
    });
  }

  removeUserFromBranch(userId, branchId) {
    // check if the user is about to remove the only assigned manager of the branch, and prevent the action
    if (this.props.branchUserIds.length == 1 && this.props.branchUserIds.includes(userId)) {
      return;
    }
    this.setState({ isSubmitting: true }, () => {
      this.props.removeUserFromBranch(userId, branchId).then(() => {
        this.setState({ isSubmitting: false });
        if (this.props.addRemoveUserToBranchErrorMessage) {
          this.handleErrorOnAddRemoveUserToBranch(userId);
        } else {
          const pAdded = document.getElementById('info-added-' + userId),
            pRemoved = document.getElementById('info-removed-' + userId);

          pRemoved.className = pRemoved.className.replace('hidden', '');
          pAdded.className = 'hidden';

          setTimeout(() => { pRemoved.className = 'hidden' }, 2000);
        }
      }
      );
    });
  }

  onShowModal() {
    this.resetState();
  }

  componentDidMount() {

    this.setState({
      usersToDisplay: this.props.users
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  validateManagerUserName() {
    const result = validator.validateManagerUserName(this.state.managerUserName);
    this.setState({
      isManagerUserNameError: result.isManagerUserNameError,
      managerUserNameError: result.managerUserNameError
    })
    if (result.isManagerUserNameError) {
      this.props.managersSetModalError('');
      return false;
    } else return true;
  }

  validateManagerLastName() {
    const result = validator.validateManagerLastName(this.state.managerLastName);
    this.setState({
      isManagerLastNameError: result.isManagerLastNameError,
      managerLastNameError: result.managerLastNameError
    })
    if (result.isManagerLastNameError) {
      this.props.managersSetModalError('');
      return false;
    } else return true;
  }

  validateManagerFirstName() {
    const result = validator.validateManagerFirstName(this.state.managerFirstName);
    this.setState({
      isManagerFirstNameError: result.isManagerFirstNameError,
      managerFirstNameError: result.managerFirstNameError
    })
    if (result.isManagerFirstNameError) {
      this.props.managersSetModalError('');
      return false;
    } else return true;
  }

  validateManagerPhoneNumber() {
    const result = validator.validateManagerPhoneNumber(this.state.managerPhoneNumber);
    this.setState({
      isManagerPhoneNumberError: result.isManagerPhoneNumberError,
      managerPhoneNumberError: result.managerPhoneNumberError
    })
    if (result.isManagerPhoneNumberError) {
      this.props.managersSetModalError('');
      return false;
    } else return true;
  }

  validateManagerRole() {
    const result = validator.validateManagerRole(this.state.managerRole);
    this.setState({
      isManagerRoleError: result.isManagerRoleError,
      managerRoleError: result.managerRoleError
    })
    if (result.isManagerRoleError) {
      this.props.managersSetModalError('');
      return false;
    } else return true;
  }

  onBtnCloseModal() {
    if (this.state.showAddSection) {
      this.resetState();
      this.setState({ showAddSection: false });
    }
  }

  onHideModal() {
    if (this.props.branchId === this.props.isModalWithUsersVisible)
      this.props.branchUsersHideModalWithUsers(this.props.branchId);
  }

  isModalValid() {
    this.validateManagerUserName();
    this.validateManagerFirstName();
    this.validateManagerLastName();
    this.validateManagerPhoneNumber();
    this.validateManagerRole();

    if (this.validateManagerUserName()
      && this.validateManagerLastName()
      && this.validateManagerFirstName()
      && this.validateManagerPhoneNumber()
      && this.validateManagerRole()) {
      return true;
    } else {
      return false;
    }
  }

  submit() {
    let isModalValid = this.isModalValid();
    if (isModalValid) {
      var data = {
        username: this.state.managerUserName.trim(),
        lastName: this.state.managerLastName.trim(),
        firstName: this.state.managerFirstName.trim(),
        phoneNumber: this.state.managerPhoneNumber.trim(),
        roleId: this.state.managerRole,
        branchIds: [this.props.branchId],
        branchNames: [this.props.branchName]
      };

      this.setState({ isSubmitting: true }, () => {
        this.props.createManagerRequest({ ...data, showToast: true }).then(() => {
          this.setState({ isSubmitting: false });
          if (!this.props.modalServerError && this.state.showAddSection) {
            this.resetState();
            this.setState({ showAddSection: false });
          }
        }
        );
      });
    }
  }

  mapUiErrorCode(error) {
    switch (error) {
      case 'UserAlreadyExists':
        return 'Acest nume de utilizator exista deja. Incearca o alta varianta (ex. stefanescu1.robert)';
      case 'PhoneNumberAlreadyExistsOnSameEmployer':
        return 'Numarul de telefon este deja asignat altei persoane pentru acest angajator.';
      case 'ModalInvalid':
        return 'Nu toate datele introduse respecta formatul acceptat.'
      default:
        return 'A aparut o erroare. Te rugam incearca din nou.';
    }
  }

  render() {
    let userRows = this.state.usersToDisplay.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1).map(u =>
      <div key={u.id} className="form-row">
        <div className="user-avatar">
          <div className="badge-name"><span>{u.code}</span></div>
        </div>
        <div className="user-details">
          <div className="truncate-with-ellipsis"><b>{u.fullName}</b></div>
          <div>{u.userRole}</div>
        </div>
        {
          (this.props.roleId === userRole.ADMIN ||
            (this.props.roleId === userRole.POWER && u.roleIds[0] === userRole.STANDARD)) &&
          <div className="user-add-remove">
            <p id={'info-added-' + u.id} className='hidden'>Alocat cu succes</p>
            <p id={'info-removed-' + u.id} className='hidden'>Dealocat cu succes</p>
            {this.props.addRemoveUserToBranchErrorMessage && <p id={'error-' + u.id} className="error-message hidden">{this.props.addRemoveUserToBranchErrorMessage}</p>}
            {
              this.props.branchUserIds
                && this.props.branchUserIds.includes(u.id)
                ?
                <button disabled={this.state.isSubmitting} className={this.props.branchUserIds.length == 1 ? 'main-button white icon only user-remove-button disabled' : 'main-button white icon only user-remove-button'} onClick={() => this.removeUserFromBranch(u.id, this.props.branchId)}>
                  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
                    <path id='a' d="M240,75H90c-49.626,0-90,40.374-90,90s40.374,90,90,90h150c49.626,0,90-40.374,90-90S289.626,75,240,75z M240,225c-33.084,0-60-26.916-60-60s26.916-60,60-60s60,26.916,60,60S273.084,225,240,225z" />
                  </svg>
                  {
                    this.props.branchUserIds.length == 1 && this.props.branchUserIds.includes(u.id)
                      ? <span className="tooltip-text">Nu se poate dealoca unicul manager</span>
                      : null
                  }
                </button>
                : <button disabled={this.state.isSubmitting} className="main-button white icon only user-add-button" onClick={() => this.addUserToBranch(u.id, this.props.branchId, this.props.branchName)}>
                  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490">
                    <path id='a' d="M350.2,384.8c77.2,0,139.8-62.6,139.8-139.8s-62.6-139.8-139.8-139.8H139.8C62.6,105.2,0,167.8,0,245 s62.6,139.8,139.8,139.8H350.2z M48,245c0-51.7,41.9-93.7,93.7-93.7c51.7,0,93.7,41.9,93.7,93.7s-41.9,93.7-93.7,93.7 C90,338.7,48,296.7,48,245z" />
                  </svg>
                </button>
            }
          </div>
        }
      </div>
    );

    return (
      !this.state.showAddSection ?
        <Modal
          scrollable={true}
          show={this.props.isVisible}
          onShow={this.onShowModal}
          onHide={this.onHideModal}
          dialogClassName="user-to-branch-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Manageri - {this.props.branchName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group-label user-search">
              <input name="userName" type="text" placeholder="Cauta manager" autoComplete='off'
                onChange={this.filterUsers}
              />
              <label>Cauta manager</label>
              <svg width='20' height='20' viewBox='0 0 24 24'>
                <path id="a" d="M22.817 21.037l-5.692-5.734a.636.636 0 0 1-.07-.8C19.389 10.923 18.845 6 15.4 3.06 12.088.23 7.045.328 3.835 3.275.15 6.657.057 12.415 3.557 15.916c2.985 2.987 7.604 3.35 10.996 1.106a.633.633 0 0 1 .804.07l5.68 5.722a.628.628 0 0 0 .89.002l.889-.89a.63.63 0 0 0 .001-.889zm-17.482-6.9a6.244 6.244 0 0 1 0-8.816 6.232 6.232 0 0 1 8.809 0 6.244 6.244 0 0 1 0 8.816 6.233 6.233 0 0 1-8.81 0z" />
              </svg>
            </div>

            <div className="form-row user-group">
              {
                userRows.length > 0
                  ? userRows
                  : <div className="no-result">Nu am gasit nici un rezultat.</div>
              }
            </div>
            <div className="form-row user-group-footer">
              <hr />
              <span>Nu gasesti managerul in lista? Adauga-l acum.</span>
              <button className="main-button icon only user-to-branch-add" onClick={() => { this.setState({ showAddSection: true }) }}>
                <svg width="20" height="20" viewBox="0 0 24 24">
                  <path id="a" d="M13.767 10.233h6.466a1.767 1.767 0 0 1 0 3.534h-6.466v6.466a1.767 1.767 0 0 1-3.534 0v-6.466H3.767a1.767 1.767 0 0 1 0-3.534h6.466V3.767a1.767 1.767 0 0 1 3.534 0v6.466z" />
                </svg>
              </button>
            </div>
          </Modal.Body>
        </Modal>
        :
        <Modal
          scrollable={true}
          show={this.state.showAddSection}
          onShow={this.onShowModal}
          onHide={this.onBtnCloseModal}
          dialogClassName="user-to-branch-new-user-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Manageri - {this.props.branchName}
            </Modal.Title>
          </Modal.Header>
          <ModalBody>
            <div className="label-section-modal">MANAGER NOU</div>
            <Form>
              <Form.Group className="form-group-label" controlId="manager-userName">
                {this.state.isManagerUserNameError ? <span className="error-label">{this.state.managerUserNameError}</span> : null}
                <Form.Control autoFocus
                  name="managerUserName" type="text" placeholder="Utilizator"
                  onChange={this.handleInputChange}
                  value={this.state.managerUserName}
                  onBlur={this.validateManagerUserName} />
                <Form.Label>Utilizator</Form.Label>
              </Form.Group>
              <Form.Row>
                <Form.Group className="form-group-label spacer-right" as={Col} controlId="manager-lastName">
                  {this.state.isManagerLastNameError ? <span className="error-label">{this.state.managerLastNameError}</span> : null}
                  <Form.Control type="text" placeholder="Nume" name="managerLastName"
                    onChange={this.handleInputChange}
                    value={this.state.managerLastName}
                    onBlur={this.validateManagerLastName} />
                  <Form.Label>Nume</Form.Label>
                </Form.Group>
                <Form.Group className="form-group-label" as={Col} controlId="manager-firstName">
                  {this.state.isManagerFirstNameError ? <span className="error-label">{this.state.managerFirstNameError}</span> : null}
                  <Form.Control
                    type="text" placeholder="Prenume" name="managerFirstName"
                    onChange={this.handleInputChange}
                    value={this.state.managerFirstName}
                    onBlur={this.validateManagerFirstName} />
                  <Form.Label>Prenume</Form.Label>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group className="form-group-label spacer-right" as={Col} controlId="manager-phone-number">
                  {this.state.isManagerPhoneNumberError ? <span className="error-label">{this.state.managerPhoneNumberError}</span> : null}
                  <Form.Control type="text" placeholder="Telefon" name="managerPhoneNumber"
                    onChange={this.handleInputChange}
                    value={this.state.managerPhoneNumber}
                    onBlur={this.validateManagerPhoneNumber} />
                  <Form.Label>Telefon</Form.Label>
                </Form.Group>
                <Form.Group className="form-group-label" as={Col} controlId="manager-role">
                  {this.state.isManagerRoleError ? <span className="error-label">{this.state.managerRoleError}</span> : null}
                  <Form.Control as="select" placeholder="Alege rol" name="managerRole"
                    onChange={this.handleInputChange}
                    value={this.state.managerRole}
                    onBlur={this.validateManagerRole}>
                    {
                      this.props.userRoleId === userRole.ADMIN &&
                      <>
                        <option value="-1"></option>
                        <option value="1">Power</option>
                        <option value="2">Standard</option>
                      </>
                    }
                    {
                      this.props.userRoleId === userRole.POWER &&
                      <>
                        <option value="2">Standard</option>
                      </>
                    }
                  </Form.Control>
                  <Form.Label className={this.state.managerRole !== '-1' ? 'select-label active' : 'select-label'}>Rol</Form.Label>
                </Form.Group>
              </Form.Row>
            </Form>
          </ModalBody>
          <Modal.Footer>
            {this.props.modalServerError ? <span className="error-label">{this.mapUiErrorCode(this.props.modalServerError)}</span> : null}
            <button type="button" className="main-button white" onClick={this.onBtnCloseModal}>RENUNTA</button>
            <button
              className={'main-button ' + (this.state.isSubmitting ? 'loading disabled' : '')}
              onClick={this.submit}
              disabled={this.state.isSubmitting}>
              <span className="spinner-border spinner-border-sm"></span>
              ADAUGA
            </button>
          </Modal.Footer>
        </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.branch,
    ...state.user,
    users: state.branchUser.branchUsers,
    userRoleId: state.user.roleId,
    modalServerError: state.manager.modalServerError,
    isModalWithUsersVisible: state.branchUser.isModalWithUsersVisible
  }
};
const mapDispatchToProps = dispatch => bindActionCreators({
  addUserToBranch,
  branchUsersHideModalWithUsers,
  removeUserFromBranch,
  createManagerRequest,
  managersSetModalError
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToBranchModal);
