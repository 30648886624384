export const UPLOAD_CSV_FILE_REQUEST = 'UPLOAD_CSV_FILE_REQUEST';
export const UPLOAD_CSV_FILE_FAILURE = 'UPLOAD_CSV_FILE_FAILURE';
export const UPLOAD_CSV_FILE_SUCCESS = 'UPLOAD_CSV_FILE_SUCCESS';

export const UPLOAD_SET_UPLOAD_ERROR = 'UPLOAD_SET_UPLOAD_ERROR';
export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const UPLOAD_SET_VALIDATION_STATUS = 'UPLOAD_SET_VALIDATION_STATUS';
export const SET_CANCELLED_UPLOAD_FLAG = 'SET_CANCELLED_UPLOAD_FLAG';
export const UPLOAD_SET_FILE_NAME = 'UPLOAD_SET_FILE_NAME';

export const UPLOAD_SET_CONFIG = 'UPLOAD_SET_CONFIG';

export const uploadCsvFileRequest = (csvFile, requestId) => ({ type: UPLOAD_CSV_FILE_REQUEST, payload: { csvFile, requestId } });
export const uploadCsvFileFailure = errorMessage => ({ type: UPLOAD_CSV_FILE_FAILURE, payload: { errorMessage: errorMessage } });
export const uploadCsvFileSuccess = (fileUploadResults, uploadTypeId) => ({ type: UPLOAD_CSV_FILE_SUCCESS, payload: { fileUploadResults, uploadTypeId } });

export const setUploadError = errorType => ({ type: UPLOAD_SET_UPLOAD_ERROR, payload: errorType });
export const setValidationStatus = validationStatus => ({ type: UPLOAD_SET_VALIDATION_STATUS, payload: validationStatus });
export const setFileName = fileName => ({ type: UPLOAD_SET_FILE_NAME, payload: fileName });

export const showLoadingScreen = show => ({ type: SHOW_LOADING_SCREEN, payload: show });
export const setCancelledUploadFlag = (uploadCancelled, requestId) => ({ type: SET_CANCELLED_UPLOAD_FLAG, payload: { uploadCancelled, requestId } });

export const setUploadConfig = config => ({ type: UPLOAD_SET_CONFIG, payload: config });
