import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import React from 'react';

class CustomModal extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnShowModal = this.handleOnShowModal.bind(this);
        this.handleOnHide = this.handleOnHide.bind(this);

        this.state = {};
    }

    componentDidMount() {
        //dynamically load stylesheets for modal
        //if cssFileName was given, then it will override the Modal's default appearance
        //otherwise it will use the default one
        if (this.props.cssFileName) {
            // eslint-disable-next-line no-undef
            require(`../CustomModal/${this.props.cssFileName}.scss`)
        }
    }

    handleOnSubmit() {
        this.props.onSubmit();
    }

    handleOnShowModal() {
        //onShowModal is optional this way (we dont need this callback on delete)
        this.props.onShowModal && this.props.onShowModal();
    }

    handleOnHide() {
        this.props.onHideModelCallback();
    }

    render() {
        return (
            <Modal
                centered={this.props.isCentered}
                show={this.props.isModalEnabled}
                onShow={this.handleOnShowModal}
                scrollable={this.props.isScrollable}
                onHide={this.handleOnHide}
                dialogClassName={this.props.cssClass ? this.props.cssClass : ''}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.props.children}
                </Modal.Body>

                <Modal.Footer>
                    <Button className='main-button white'
                        onClick={this.handleOnHide}
                    >
                        {this.props.closeButtonText}
                    </Button>

                    <Button
                        className={`main-button ${this.props.isSubmitButtonEnabled ? '' : 'loading disabled'}`}
                        onClick={this.handleOnSubmit}>
                        <span className="spinner-border spinner-border-sm"></span>
                        {this.props.submitText}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isSubmitButtonEnabled: state.customModal.isSubmitButtonEnabled
    }
};

export default connect(mapStateToProps)(CustomModal);
