import './Toast.scss';
import { bindActionCreators } from 'redux';
import { Toast as BSToast } from 'react-bootstrap';
import { connect } from 'react-redux';
import { hideToast } from 'actions/toast';
import React from 'react';

class Toast extends React.Component {

  getIcon(type) {
    switch (type) {
      case 'success':
        return (
          <svg width="20" height="20" viewBox="0 0 24 24">
            <path id="a" d="M12 1c6.072 0 11 4.928 11 11s-4.928 11-11 11S1 18.072 1 12 5.928 1 12 1zm0 2c-4.967 0-9 4.033-9 9s4.033 9 9 9 9-4.033 9-9-4.033-9-9-9zm-1.59 10.625l5.06-5.134a1.163 1.163 0 0 1 1.7.055c.44.441.44 1.148 0 1.59l-6.76 6.814-3.58-3.605a1.123 1.123 0 0 1-.007-1.583l.042-.043a1.162 1.162 0 0 1 1.627-.029l1.919 1.935z" />
          </svg>
        );
      case 'warning':
        return (
          <svg width="20" height="20" viewBox="0 0 24 24">
            <path d="M12 5.39a4.23 4.23 0 0 1 2.195.594c.67.397 1.203.93 1.6 1.6a4.23 4.23 0 0 1 .594 2.195c0 .6-.176 1.154-.528 1.666-.212.317-.591.718-1.137 1.203-.547.485-.917.86-1.111 1.124-.137.185-.255.437-.352.755a1.1 1.1 0 0 1-1.052.778h-.287a.88.88 0 0 1-.864-1.05 3.31 3.31 0 0 1 .202-.695c.176-.388.45-.758.82-1.11.14-.124.466-.388.978-.794.388-.3.66-.564.82-.793.229-.317.343-.679.343-1.084 0-.6-.22-1.11-.66-1.534A2.178 2.178 0 0 0 12 7.611c-.6 0-1.12.211-1.56.634-.184.177-.34.444-.466.801a1.1 1.1 0 0 1-1.037.733h-.242a.91.91 0 0 1-.885-1.122c.11-.455.241-.812.396-1.073.396-.67.93-1.203 1.6-1.6A4.23 4.23 0 0 1 12 5.39zm0 15.442a8.596 8.596 0 0 0 4.442-1.217 8.563 8.563 0 0 0 3.173-3.173A8.596 8.596 0 0 0 20.832 12a8.596 8.596 0 0 0-1.217-4.442 8.563 8.563 0 0 0-3.173-3.173A8.596 8.596 0 0 0 12 3.168a8.596 8.596 0 0 0-4.442 1.217 8.563 8.563 0 0 0-3.173 3.173A8.596 8.596 0 0 0 3.168 12c0 1.587.406 3.067 1.217 4.442a8.563 8.563 0 0 0 3.173 3.173A8.596 8.596 0 0 0 12 20.832zM12 1c2.01 0 3.87.502 5.58 1.507a10.426 10.426 0 0 1 3.913 3.914A10.818 10.818 0 0 1 23 12c0 2.01-.502 3.87-1.507 5.58a10.426 10.426 0 0 1-3.914 3.913A10.818 10.818 0 0 1 12 23c-2.01 0-3.87-.502-5.58-1.507a10.623 10.623 0 0 1-3.913-3.94A10.77 10.77 0 0 1 1 12c0-1.992.502-3.843 1.507-5.553a10.826 10.826 0 0 1 3.94-3.94A10.77 10.77 0 0 1 12 1zm-1.084 16.526v-.052a1.084 1.084 0 0 1 2.168 0v.052a1.084 1.084 0 0 1-2.168 0z" />
          </svg>
        );
      case 'error':
        return (
          <svg width="20" height="20" viewBox="0 0 24 24">
            <path id="a" d="M12 20.8a8.8 8.8 0 1 0 0-17.6 8.8 8.8 0 0 0 0 17.6zm0 2.2C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm-1.65-5.52c0 .464.16.858.481 1.183.321.325.71.487 1.169.487.458 0 .848-.162 1.169-.487a1.62 1.62 0 0 0 .481-1.183c0-.464-.16-.851-.481-1.163A1.616 1.616 0 0 0 12 15.85c-.458 0-.848.156-1.169.467-.32.312-.481.7-.481 1.163zm3.19-10.54a1.54 1.54 0 0 0-3.08 0v5.72a1.54 1.54 0 0 0 3.08 0V6.94z" />
          </svg>
        );
      default:
        return (
          <svg width="20" height="20" viewBox="0 0 24 24">
            <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
        );
    }
  }

  render() {
    return (
      <BSToast 
        show={this.props.show}
        onClose={this.props.hideToast}
        delay={this.props.delay || 6000}
        autohide={!this.props.dismissible}
        className={this.props.type + (this.props.text && this.props.text.length < 150 ? ' small' : ' large') }>
        <BSToast.Header closeButton={true} className={this.props.title ? '' : 'no-title'}>
          {this.getIcon(this.props.type)}
          {this.props.title && <strong className="mr-auto">{this.props.title}</strong>}
          <small>{this.props.smallText}</small>
        </BSToast.Header>
        <BSToast.Body>{this.props.text}</BSToast.Body>
      </BSToast>
    );
  }
}

const mapStateToProps = state => state.toast;
const mapDispatchToProps = dispatch => bindActionCreators({ hideToast }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
