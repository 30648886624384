import { bindActionCreators } from 'redux';
import BranchUsersBadge from './BranchUsersBadge';
import { branchUsersShowModalWithUsers } from 'actions/branchUser';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    branch: state.branch,
    users: state.branchUser ? state.branchUser.branchUsers : [],
    isModalWithUsersVisible: state.branchUser.isModalWithUsersVisible
  }
};
const mapDispatchToProps = dispatch => bindActionCreators({
  branchUsersShowModalWithUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BranchUsersBadge);
