import {
  changePasswordRequest,
  forgotPasswordRequest,
  generateSmsTokenRequest,
  logout,
  saveUserInformation,
  setLoginError,
  submitPasswordRequest,
  submitPhoneNumberRequest,
  submitSmsTokenRequest,
  submitUsernameRequest,
  verifyPasswordRequest
} from 'actions/user';
import { hideToast, showToast } from 'actions/toast';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { push } from 'connected-react-router';

/**
 * A function that returns an object, usually formed by bits and pieces from the application's state (the store)
 * that are needed in the current component
 * @param {object} state
 */
const mapStateToProps = state => ({ ...state.user, ...state.router });

/**
 * A function which ultimately returns an object.
 * Each field of the object should be a function. Calling it will dispatch an action to the store.
 * @param {function} dispatch
 */
const mapDispatchToProps = dispatch => bindActionCreators({
  forgotPasswordRequest,
  submitPhoneNumberRequest,
  submitUsernameRequest,
  submitPasswordRequest,
  generateSmsTokenRequest,
  submitSmsTokenRequest,
  changePasswordRequest,
  setLoginError,
  push,
  saveUserInformation,
  showToast,
  hideToast,
  logout,
  verifyPasswordRequest
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
