import './CardMigrationModal.scss';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { hideCardMigrationModal, migrateCards } from 'actions/card';
import { bindActionCreators } from 'redux';
import { branchStatus } from 'utils/enums';
import { connect } from 'react-redux';
import React from 'react';
import Select from 'react-select';
import { showLoadingSpinner } from 'actions/loadingSpinner';

const ERROR_BRANCH_MANDATORY_FIELD = 'Campul este obligatoriu';

class CardMigrationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBranchError: false,
      branch: '-1',
      branchError: '',
      selectedBranch: {},
      branchOptions: {},
      selectionCount: '',
      inputHasValue: false
    }

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.searchInputChange = this.searchInputChange.bind(this);
    this.isModalValid = this.isModalValid.bind(this);
    this.validateBranch = this.validateBranch.bind(this);
  }

  componentDidMount() {
    let branchOptions = [];
    this.props.branches && this.props.branches.forEach(item => item.id &&
      item.status === branchStatus.enabled &&
      item.id !== this.props.currentBranchId &&
      branchOptions.push({ value: item.id, label: item.name }));

    this.setState({ branchOptions });

    this.setState({
      selectionCount: this.props.selectedCardsCount == 1 ? '1 selectie' : `${this.props.selectedCardsCount} selectii`
    });

    document.onkeypress = (event) => {
      const code = event.keyCode || event.which,
        isButton = document.activeElement.classList.contains('main-button');
      if (code === 13 && !isButton) {
        event.preventDefault();
      }
    }
  }

  componentWillUnmount() {
    document.onkeypress = null;
  }

  validateBranch() {
    if (this.state.branch === '-1') {
      this.setState({ isBranchError: true, branchError: ERROR_BRANCH_MANDATORY_FIELD });
    } else {
      this.setState({ isBranchError: false, branchError: '' });
      return true;
    }
    return false;
  }

  isModalValid() {
    return this.validateBranch();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleBranchChange(selectedBranch) {
    this.setState({
      selectedBranch,
      branch: selectedBranch.value
    });
  }

  searchInputChange(value) {
    if (value !== '') {
      !this.state.inputHasValue && this.setState({ inputHasValue: true });
    }
    else {
      this.state.inputHasValue && this.setState({ inputHasValue: false });
    }
  }

  submit() {
    if (this.isModalValid()) {
      this.props.migrateCards(this.state.branch);
      this.props.hideCardMigrationModal();
      this.props.showLoadingSpinner();
    }
  }

  render() {
    return (
      <Modal className='card-migration confirmation-modal'
        show
        backdrop='static'
        dialogClassName='modal-migrate-cards-container'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={this.props.hideCardMigrationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Migrare carduri ({this.state.selectionCount})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Selecteaza punctul de lucru la care vrei sa migrezi cardurile</p>
          <Form>
            <Form.Row>
              {this.state.isBranchError ? <span className='error-label-select'>{this.state.branchError}</span> : null}
              <Form.Group className='form-group-label' as={Col} controlId='branch'>
                <Select
                  className={this.state.branch !== '-1' ? 'branches-multi-select has-items' : 'branches-multi-select'}
                  placeholder=''
                  onChange={this.handleBranchChange}
                  onInputChange={this.searchInputChange}
                  isSearchable={true}
                  options={this.state.branchOptions}
                />
                <Form.Label className={this.state.branch !== '-1' || this.state.inputHasValue ? 'select-label active large-select' : 'select-label large-select'}>Punct de lucru</Form.Label>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm='auto'>
              <button className='main-button white' onClick={this.props.hideCardMigrationModal}>
                RENUNTA
              </button>
            </Col>
            <Col sm='auto'>
              <button className='main-button' onClick={this.submit}>
                MIGREAZA CARDURILE
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.card,
    currentBranchId: state.branch.currentBranch ? state.branch.currentBranch.id : null,
    branches: state.branch.branches
  }
};
const mapDispatchToProps = dispatch => bindActionCreators({
  hideCardMigrationModal,
  migrateCards,
  showLoadingSpinner
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardMigrationModal);
