import './HomePage.scss';
import { Carousel, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import React from 'react';

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onSelect = this.onSelect.bind(this);

    this.state = {
      interval: 6000
    }
  }

  onPlay() {
    this.setState({ interval: 200000 });
  }

  onPause() {
    this.setState({ interval: 6000 });
  }

  onSelect() {
    this.setState({ interval: 6000 });
  }

  render() {
    return (
      <div className='main-view'>
        <Row className='carousel-container' noGutters>
          <Carousel onSelect={this.onSelect} interval={this.state.interval} controls={false}>
            <Carousel.Item>
              <div className='carousel-video'>
                <img src='\images\Card de masa_banner_513x398.png'></img>
              </div>
              <Carousel.Caption>
                <div className='pt-150'>
                  <p>Dorim să vă reamintim că sumele de pe cardurile de masă Banca Transilvania urmează să expire la finalul anului conform legislației în vigoare. </p>
                  <p>Excepție fac tichetele de masă primite pe card în perioada 1 noiembrie – 31 decembrie, care pot fi utilizate până la data de 31 decembrie a anului următor.</p>
                  <p>Banca Transilvania</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className='carousel-video'>
                <video controls ref={this.video} onPlay={this.onPlay} onPause={this.onPause} muted={true} loop={false} id='video-element-carousel'>
                  <source src='https://apcmstorage.blob.core.windows.net/videos-btticket/Video%201%20-%20Cum%20adaugi%20un%20punct%20de%20lucru,%20manager%20si%20cum%20stergi%20un%20manager%20in%20BT%20Tichet.mp4' type='video/mp4' />
                </video>
              </div>
              <Carousel.Caption>
                <h1 className='hero'>Familiarizeaza-te cu BT-Tichet</h1>
                <p>Urmareste video tutorialul pentru a descoperi potentialul aplicatiei de management a cardurilor de tichete de la BT.</p>
                <p>Pasul 1 - Cum adaugi un punct de lucru sau un nou manager?</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className='carousel-video'>
                <video controls ref={this.video} onPlay={this.onPlay} onPause={this.onPause} muted={true} loop={false} id='video-element-carousel'>
                  <source src='https://apcmstorage.blob.core.windows.net/videos-btticket/Video%202%20-%20Cum%20comanzi%20cardurile%20de%20masa%20si%20cum%20confirmi%20primirea%20acestora%20in%20BT%20Tichet.mp4' type='video/mp4' />
                </video>
              </div>
              <Carousel.Caption>
                <h1 className='hero'>Familiarizeaza-te cu BT-Tichet</h1>
                <p>Urmareste video tutorialul pentru a descoperi potentialul aplicatiei de management a cardurilor de tichete de la BT.</p>
                <p>Pasul 2 - Cum comanzi cardurile de masa BT?</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className='carousel-video'>
                <video controls ref={this.video} onPlay={this.onPlay} onPause={this.onPause} muted={true} loop={false} id='video-element-carousel'>
                  <source src='https://apcmstorage.blob.core.windows.net/videos-btticket/Video%203%20-%20Cum%20modifici%20valoarea%20nominala%20a%20unui%20tichet%20in%20BT%20Tichet.mp4' type='video/mp4' />
                </video>
              </div>
              <Carousel.Caption>
                <h1 className='hero'>Familiarizeaza-te cu BT-Tichet</h1>
                <p>Urmareste video tutorialul pentru a descoperi potentialul aplicatiei de management a cardurilor de tichete de la BT.</p>
                <p>Pasul 3 - Cum modifici valoarea unui tichet?</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className='carousel-video'>
                <video controls ref={this.video} onPlay={this.onPlay} onPause={this.onPause} muted={true} loop={false} id='video-element-carousel'>
                  <source src='https://apcmstorage.blob.core.windows.net/videos-btticket/Video%204%20-%20Cum%20reemiti,%20migrezi%20si%20stergi%20un%20card%20in%20BT%20Tichet.mp4' type='video/mp4' />
                </video>
              </div>
              <Carousel.Caption>
                <h1 className='hero'>Familiarizeaza-te cu BT-Tichet</h1>
                <p>Urmareste video tutorialul pentru a descoperi potentialul aplicatiei de management a cardurilor de tichete de la BT.</p>
                <p>Pasul 4 - Cum reemiti PIN-ul si cardul de masa, migrezi cardul sau il stergi?</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Row>
        <Row className='partners-container' noGutters>
          <h1>Comercianti parteneri</h1>
          <ul></ul>
          <ol>
            <li>
              <img src='\images\lidl-supermarkets.png'></img>
              <p>Lidl</p>
            </li>
            <li>
              <img src='\images\carrefour-3.png'></img>
              <p>Carrefour</p>
            </li>
            <li>
              <img src='\images\mcdonald-s-7.png'></img>
              <p>McDonald's</p>
            </li>
            <li>
              <img src='\images\pizza-hut-4.png'></img>
              <p>Pizza Hut</p>
            </li>
            <li>
              <img src='\images\auchan-2@2x.png'></img>
              <p>Auchan</p>
            </li>
            <li>
              <img src='\images\kfc-1.png'></img>
              <p>KFC</p>
            </li>
          </ol>
        </Row>
        <Row className='center' noGutters>
          <button className='partners-button plain-button' onClick={() => { window.open('https://www.bancatransilvania.ro/comercianti-card-de-masa/') }}>
            VEZI LISTA COMERCIANTI
            <svg width='18' height='18' viewBox='0 0 24 24'>
              <path id='a' d='M4.648.667c.46 0 .833.373.833.833v.425c0 .46-.373.833-.833.833H2.542v12.7h12.716v-2.106c0-.46.373-.833.833-.833h.409c.46 0 .833.373.833.833v3.981H.667V.667h3.981zM17 .667c.184 0 .333.149.333.333v6.16c0 .646-.395 1.188-1.04 1.188-.68-.012-1.037-.529-1.035-1.188V4.474L9.294 10.33A1.149 1.149 0 0 1 7.67 8.706l5.84-5.948h-2.69c-.646-.002-1.136-.396-1.136-1.042s.49-1.05 1.136-1.05H17z' />
            </svg>
          </button>
        </Row>
        <Row className='benefits-container' noGutters>
          <h1>Beneficiile aplicatiei</h1>
          <ul></ul>
          <Row className='center' noGutters>
            <ol>
              <li>
                <img src='\images\card-value@2x.png'></img>
                <h3>Comanda carduri online</h3>
              </li>
              <li className='center-item'>
                <img src='\images\icon-lista-angajati@2x.png'></img>
                <h3>Accesare lista angajati cu carduri de masa emise</h3>
              </li>
              <li>
                <img src='\images\card-location@2x.png'></img>
                <h3>Livrare carduri in locatiile companiei</h3>
              </li>
            </ol>
          </Row>
          <Row className='center' noGutters>
            <ol>
              <li className='spacer-right'>
                <img src='\images\invoice@2x.png'></img>
                <h3>Vizualizeaza si descarcare facturi</h3>
              </li>
              <li className='spacer-left'>
                <img src='\images\bt-pay@2x.png'></img>
                <h3>Acces la aplicatia BT Pay pentru angajatii tai</h3>
              </li>
            </ol>
          </Row>
        </Row>
      </div>
    );
  }
}


export default connect(
  null,
  dispatch => bindActionCreators({ push }, dispatch)
)(HomePage);
