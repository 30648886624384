import './Dashboard.scss';
import { BranchDashboardContainer, CardsDashboard, CardsDashboardContainer, ManagersDashboard } from 'components';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';
import routes from 'utils/routes';
import { userRole } from 'utils/enums';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="dashboard">
        {
          this.props.user &&
            this.props.user.roleId !== undefined &&
            this.props.user.roleId !== userRole.ADMIN &&
            this.props.branchesLoaded &&
            !this.props.branches.length > 0
            ? <div>
              <Row className='justify-content-md-center table-footer' noGutters>
                <Col sm="auto">
                  <div className='no-active-branch'>
                    <Row className='justify-content-md-center' noGutters>
                      <Col sm="auto" className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                          <g fill="none">
                            <path d="M0 0h24v24H0z" />
                            <path fill="#414751" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11h2v6h-2v-6zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </g>
                        </svg>
                      </Col>
                    </Row>
                    <Row className='justify-content-md-center' noGutters><span>Nu esti alocat pe nici un punct de lucru activ.</span></Row>
                    <Row className='justify-content-md-center' noGutters><span>Ia legatura cu un admin.</span></Row>
                  </div>
                </Col>
              </Row>
            </div>
            : <div>
              <Navbar expand="lg" variant="light">
                <Nav className="mr-auto">
                  {
                    this.props.user &&
                    this.props.user.roleId !== undefined &&
                    this.props.user.roleId !== userRole.STANDARD &&
                    <Link to={routes.dashboardBranch} className={`${this.props.location.pathname && this.props.location.pathname.includes('puncte-de-lucru') ? 'active' : ''}`}>Puncte de lucru</Link>
                  }
                  {
                    this.props.currentBranch &&
                    this.props.user &&
                    this.props.user.roleId !== userRole.STANDARD &&
                    <Link className={`${this.props.location.pathname && this.props.location.pathname.includes('manageri') ? 'active' : ''}`} to={routes.dashboardManagers}>Manageri</Link>
                  }
                  {
                    this.props.currentBranch &&
                    <Link
                      className={`${this.props.location.pathname && this.props.location.pathname.includes('carduri') ? 'active' : ''}`}
                      to={routes.dashboardCards}
                    >Carduri</Link>
                  }
                </Nav>
              </Navbar>
              <Switch>
                {
                  this.props.user &&
                  this.props.user.roleId !== undefined &&
                  <Route exact path={routes.dashboard} render={() =>
                    <Redirect to={this.props.user.roleId !== userRole.STANDARD ? routes.dashboardBranch : routes.dashboardCards} />} />
                }
                <Route path={routes.dashboardCards} render={() => <CardsDashboard />} />
                {
                  this.props.user &&
                  this.props.user.roleId !== userRole.STANDARD &&
                  <Route path={routes.dashboardBranch} render={() => <BranchDashboardContainer />} />
                }
                <Route path={routes.dashboardOrdersUpload} render={() => <CardsDashboardContainer />} />
                {
                  this.props.user &&
                  this.props.user.roleId !== userRole.STANDARD &&
                  <Route path={routes.dashboardManagers} render={() => <ManagersDashboard />} />
                }
              </Switch>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => { return { ...state.branch, ...state.router, user: state.user } };

export default connect(mapStateToProps)(Dashboard);
