import './Home.scss';
import '../LoginPage/LoginForm.scss';
import { AuditDashboardContainer,Dashboard, HomeHeader, HomePage, LoadingSpinner, NotFound404, SapInvoicesDashboardContainer, Toast } from 'components';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { getUserDataRequest, pingServer, saveUserInformation } from 'actions/user';
import { PasswordSetupView, PasswordView, SmsTokenView } from '../LoginPage/LoginViews';
import { Redirect, Route, Switch } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { findBranches } from 'actions/branch';
import React from 'react';
import routes from 'utils/routes';

class Home extends React.Component {
  constructor(props) {
    super(props);

    window.lastActivityTimestamp = Date.now();
    this.userActivityThresholdInMilliseconds = 50 * 60000;
    this.timeToPingServerInMilliseconds = this.userActivityThresholdInMilliseconds / 2 + 1000;
  }

  componentDidMount() {
    window.localStorage.removeItem('sessionExpired');
    this.props.getUserDataRequest().then(() => this.props.findBranches());

    document.onclick = function () {
      window.lastActivityTimestamp = Date.now();
    };
    document.onmousemove = function () {
      window.lastActivityTimestamp = Date.now();
    };
    document.onkeypress = function () {
      window.lastActivityTimestamp = Date.now();
    };

    this.heartbeatInterval = setInterval(() => {
      if ((Date.now() - window.lastActivityTimestamp)
        < (this.userActivityThresholdInMilliseconds / 2)) {
        this.props.pingServer();
      }
      else if (Date.now() - window.lastActivityTimestamp
        > this.userActivityThresholdInMilliseconds) {
        this.props.pingServer();
      }
    }, this.timeToPingServerInMilliseconds);
  }

  componentWillUnmount() {
    clearInterval(this.heartbeatInterval);
  }

  renderSpinner() {
    return (
      <Row className="justify-content-md-center home-loading-spinner">
        <Col sm="auto">
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    );
  }

  renderRoutes() {
    return (
      <Switch>
        <Route exact path={routes.home} render={() => <Redirect to={routes.homePage} />} />
        <Route path={routes.dashboard} render={() => <Dashboard />} />
        <Route path={routes.dashboardSapInvoices} render={() => <SapInvoicesDashboardContainer/>} />  
        <Route path={routes.dashboardAudit} render={() => <AuditDashboardContainer />} />  
        <Route exact path={routes.notFound} render={() => <NotFound404 />} />
      </Switch>

    );
  }

  render() {
    return (
      <div className='main-view'>
        <HomeHeader />
        <Switch>
          <Route path={routes.homePage} render={() => <HomePage />} />
        </Switch>
        {
          (!this.props.id || !this.props.currentBranch) && (this.props.branches && this.props.branches.length != 0)
            ? this.renderSpinner()
            : this.renderRoutes()
        }
        <Container fluid>
          <Row noGutters className="justify-content-sm-center">
            <Col xs="auto">
              <Switch>
                <Route path={routes.changePassword} render={() => <PasswordView {...this.props} />} />
                <Route path={routes.changePasswordSmsToken} render={() => <SmsTokenView {...this.props} />} />
                <Route path={routes.changePasswordPasswordSetup} render={() => <PasswordSetupView {...this.props} />} />
              </Switch>
            </Col>
          </Row>
        </Container>
        <Toast />
        <LoadingSpinner />
      </div>
    );
  }
}

export default connect(
  state => { return { ...state.user, ...state.branch } },
  dispatch => bindActionCreators({ getUserDataRequest, findBranches, pingServer, saveUserInformation }, dispatch)
)(Home);
