import {
  findManagersRequest,
  MANAGER_CREATE_FAILURE,
  MANAGER_CREATE_SUCCESS,
  MANAGER_DELETE_FAILURE,
  MANAGER_DELETE_SUCCESS,
  MANAGER_FIND_FAILURE,
  MANAGER_FIND_SUCCESS,
  MANAGER_SET_PAGE_SIZE,
  MANAGER_SET_SEARCH_TERM,
  MANAGER_UPDATE_FAILURE,
  MANAGER_UPDATE_SUCCESS,
  managersSetModalError,
  setManagers,
  setManagersCurrentPage,
  setManagersLoadedFlag,
  setManagersPageCount,
  setManagersSearchTerm,
  setManagersTotalCount
} from 'actions/manager';

import { addBranchUser } from 'actions/branchUser';
import { addUserToBranchSuccess } from 'actions/branch';
import { findBranches } from '../actions/branch';
import { showToast } from 'actions/toast';

function manager(store) {
  return next => action => {
    switch (action.type) {
      case MANAGER_FIND_FAILURE: {
        next(setManagers([]));
        next(setManagersTotalCount(0));
        break;
      }
      case MANAGER_FIND_SUCCESS: {
        const { pageSize } = store.getState().manager,
          { entities, totalCount } = action.payload;

        next(setManagers(entities));
        next(setManagersTotalCount(totalCount));
        next(setManagersPageCount(Math.ceil(totalCount / pageSize)));
        next(setManagersLoadedFlag(true));
        store.dispatch(findBranches());
        break;
      }
      case MANAGER_SET_PAGE_SIZE: {
        next(setManagersCurrentPage(1));
        break;
      }
      case MANAGER_SET_SEARCH_TERM: {
        next(setManagersCurrentPage(1));
        if (action.payload === '') {
          let searchBarInput = document.getElementById('search-bar-input-users');
          searchBarInput ? searchBarInput.value = '' : {};
        }
        break;
      }
      case MANAGER_CREATE_SUCCESS: {
        let user = action.payload.user;
        store.dispatch(addBranchUser(user));
        store.dispatch(addUserToBranchSuccess(user.userId, user.branchIds[0]));
        store.dispatch(findBranches());

        store.dispatch(setManagersSearchTerm(''));
        store.dispatch(findManagersRequest());

        if (action.payload.showToast) {
          next(showToast({ type: 'success', text: `Managerul '${user.lastName} ${user.firstName}' a fost adaugat cu succes.` }));
        }
        break;
      }
      case MANAGER_CREATE_FAILURE: {
        store.dispatch(managersSetModalError(action.payload));
        break;
      }
      case MANAGER_UPDATE_SUCCESS: {
        let user = action.payload.user;
        if (action.payload.showToast) {
          next(showToast({ type: 'success', text: `Managerul '${user.lastName} ${user.firstName}' a fost editat cu succes.` }));
        }

        const { currentPage } = store.getState().manager;
        store.dispatch(setManagersCurrentPage(currentPage));
        store.dispatch(findManagersRequest());
        store.dispatch(findBranches());
        break;
      }
      case MANAGER_UPDATE_FAILURE: {
        if (action.payload.errorMessage === 'Unauthorized')
          next(showToast({ type: 'error', text: 'Nu esti autorizat pentru aceasta actiune.' }));
        else
          store.dispatch(managersSetModalError(action.payload));
        break;
      }
      case MANAGER_DELETE_SUCCESS: {
        next(showToast({ type: 'success', text: `Managerul '${action.payload.userFullName}' a fost sters cu succes.` }));

        const { managers, currentPage } = store.getState().manager;
        let pageNumber = managers.length == 1 ? currentPage - 1 : currentPage;
        if (pageNumber < 1) pageNumber = 1;

        store.dispatch(setManagersCurrentPage(pageNumber));
        store.dispatch(findManagersRequest());
        break;
      }
      case MANAGER_DELETE_FAILURE: {
        if (action.payload.errorMessage === 'Unauthorized')
          next(showToast({ type: 'error', text: 'Nu esti autorizat pentru aceasta actiune.' }));
        else
          next(showToast({ type: 'error', text: 'Managerul nu a putut fi sters.' }));
        break;
      }
    }

    const returnValue = next(action);

    return returnValue;
  }
}

export default manager;
