import {
  BRANCH_USER_ADD,
  BRANCH_USER_FIND_FAILURE,
  BRANCH_USER_FIND_SUCCESS,
  setBranchUsers,
} from 'actions/branchUser';

import { userRole } from 'utils/enums';

function branchUser(store) {
  return next => action => {
    switch (action.type) {
      case BRANCH_USER_FIND_FAILURE: {
        next(setBranchUsers([]));
        break;
      }
      case BRANCH_USER_FIND_SUCCESS: {
        // eslint-disable-next-line no-case-declarations
        let updatedUsers = [];
        action.payload.map(u => {
          let updatedUser = u;
          updatedUser.fullName = u.lastName + ' ' + u.firstName;
          updatedUser.code = u.firstName.charAt(0).toUpperCase() + u.lastName.charAt(0).toUpperCase();
          updatedUser.userRole = userRole.properties[u.roleIds[0]];

          updatedUsers.push(updatedUser);
        });

        next(setBranchUsers(updatedUsers));
        break;
      }
      case BRANCH_USER_ADD: {
        // eslint-disable-next-line no-case-declarations
        const { currentBranch } = store.getState().branch,
          { branchUsers } = store.getState().branchUser;
        let newUser = {
          code: action.payload.firstName.charAt(0).toUpperCase() + action.payload.lastName.charAt(0).toUpperCase(),
          employerId: currentBranch.employerId,
          firstName: action.payload.firstName,
          fullName: action.payload.lastName + ' ' + action.payload.firstName,
          id: action.payload.userId,
          lastName: action.payload.lastName,
          phoneNumber: action.payload.phoneNumber,
          roleIds: [Number.parseInt(action.payload.roleId)],
          status: 0,
          userRole: userRole.properties[action.payload.roleId],
          username: action.payload.username
        }
        
        next(setBranchUsers([...branchUsers, newUser]));
        break;
      }
    }

    const returnValue = next(action);

    return returnValue;
  }
}

export default branchUser;
